import SalesforceService from '../services/SalesforceService';
import Utils, { ns } from '../utils/Utils';

export class SearchHistories {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new SearchHistory(item));
		}
	}
}

export class SearchHistory {
	constructor(data) {
		this.SearchQuery__c = data[ns('SearchQuery__c')];
		this.NoOfSearchResults__c = data[ns('NoOfSearchResults__c')];
		this.SearchDate__c = data[ns('SearchDate__c')];
		this.SearchType__c = data[ns('SearchType__c')];
		this.TopScore__c = data[ns('TopScore__c')];
		this.OwnerId = data.OwnerId;
	}
}

export class SFDCSearchHistory {
	constructor(data, searchType) {
		this[ns('SearchQuery__c')] = data.query;
		this[ns('NoOfSearchResults__c')] = data?.featureRecords?.length
			? data?.featureRecords?.length
			: 0;
		this[ns('SearchDate__c')] = new Date().toISOString();
		this[ns('SearchType__c')] = searchType ? searchType : 'Global Search';
		this[ns('TopScore__c')] = Utils.getSearchTopScore(data);
		this.OwnerId = SalesforceService.getCurrentUserId();
	}
}
