import { SFDCSolution, Solutions } from '../models/Solution';

import ApiGateway from './ApiGateway';

import { NAMESPACE_PREFIX } from '../Constants';

const SolutionApi = {};

SolutionApi.findSolution = async (opptyId) => {
	if (opptyId) {
		var reqBody = { OpportunityId: opptyId };
		var resultData = await ApiGateway.invoke(
			'sf',
			'findsolution',
			{},
			undefined,
			JSON.stringify(reqBody),
		);
		return resultData;
	}
};

SolutionApi.findRelatedAccounts = async (featureId) => {
	if (featureId) {
		var queryStr =
			'SELECT id, name, ' +
			NAMESPACE_PREFIX +
			'Feature__c, ' +
			NAMESPACE_PREFIX +
			'Requirement__r.' +
			NAMESPACE_PREFIX +
			'Opportunity__r.Account.Name, ' +
			NAMESPACE_PREFIX +
			'Requirement__r.' +
			NAMESPACE_PREFIX +
			'Opportunity__r.Account.Id ' +
			'FROM ' +
			NAMESPACE_PREFIX +
			'Solution__c WHERE ' +
			NAMESPACE_PREFIX +
			"Feature__c = '" +
			featureId +
			"'" +
			' AND ' +
			NAMESPACE_PREFIX +
			'Requirement__r.' +
			NAMESPACE_PREFIX +
			'Opportunity__r.Account.Name != null';
		var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
		return new Solutions(resultData, true);
	}
};

SolutionApi.updateSolution = async (newSols) => {
	var newSFDCSols = [];
	// loop through sols if array
	if (Array.isArray(newSols)) {
		newSols.forEach((sol) => {
			// remove name as it's not updatable
			delete sol.Name;

			// send the rest out for update
			var newSFDCSol = new SFDCSolution(sol);
			newSFDCSols.push(newSFDCSol);
		});
	} else {
		// remove name as it's not updatable
		delete newSols.Name;

		// send the rest out for update
		var newSFDCSol = new SFDCSolution(newSols);
		newSFDCSols.push(newSFDCSol);
	}

	var body = {
		objectName: NAMESPACE_PREFIX + 'Solution__c',
		records: newSFDCSols,
	};
	var resultData = await ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body));
	return resultData;
};

export default SolutionApi;
