import React from 'react';

import { Grid } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { FWIconButton } from '../mui/FWButtons';

const FwBackNavigation = ({ title, subtitle, titleSx, subtitleSx, hideNavigation, onBack }) => (
	<Grid container justifyContent="flex-start" alignItems="center">
		{!hideNavigation && (
			<Grid item sx={{ padding: '0px 10px' }}>
				<FWIconButton title={title} aria-label="Back" onClick={() => onBack?.()}>
					<ArrowBackIcon fontSize="medium" />
				</FWIconButton>
			</Grid>
		)}
		{(title || subtitle) && (
			<Grid item paddingLeft={hideNavigation ? '10px' : undefined}>
				{title && (
					<Grid
						sx={{
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '20px',
							color: '#2220A7',
							...(titleSx || {}),
						}}
					>
						{title}
					</Grid>
				)}
				{subtitle && (
					<Grid
						sx={{
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '150%',
							letterSpacing: '0.15px',
							color: '#05283C',
							...(subtitleSx || {}),
						}}
					>
						{subtitle}
					</Grid>
				)}
			</Grid>
		)}
	</Grid>
);
export default FwBackNavigation;
