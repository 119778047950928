import { ns } from '../utils/Utils';

export class Configuration {
	constructor(data) {
		this.Id = data.Id;
		this.EnableSalesPlay__c = data[ns('EnableSalesPlay__c')];
		this.AccountSummaryPrompt__c = data[ns('AccountSummaryPrompt__c')];
	}
}

export class SFDCConfiguration {
	constructor(data) {
		this.Id = data.Id;
		this[ns('EnableSalesPlay__c')] = data?.EnableSalesPlay__c;
		this[ns('AccountSummaryPrompt__c')] = data?.AccountSummaryPrompt__c;
	}
}
export class SFDCRequirementConfiguration {
	constructor(data) {
		this.Id = data.Id;
		this[ns('EnableRequirements__c')] = data?.EnableRequirements__c;
	}
}
