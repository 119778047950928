import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	Assistant,
	AutoAwesomeOutlined,
	NoteAddOutlined,
	NoteAltOutlined,
	TipsAndUpdatesRounded,
	UnfoldLessOutlined,
	UnfoldMoreOutlined,
} from '@mui/icons-material';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

export default function GPTMenuCmp({ type = 'HelpMeWrite', onClick }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		setAnchorEl(null);
	};
	const handleMenuClick = (event, menuId) => {
		setAnchorEl(null);
		if (onClick) {
			onClick(menuId);
		}
	};

	return (
		<div style={{ marginBottom: '5px' }}>
			{type === 'HelpMeWrite' && (
				<Button
					endIcon={<TipsAndUpdatesRounded />}
					variant="contained"
					sx={{ padding: '2px', paddingLeft: '5px', paddingRight: '5px' }}
					onClick={(event) => {
						handleMenuClick(event, 'HelpMeWrite');
					}}
				>
					{'Help Me Write'}
				</Button>
			)}
			{type === 'Rewrite' && (
				<>
					<Button
						id="demo-customized-button"
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						variant="contained"
						size="small"
						disableElevation
						onClick={handleClick}
						startIcon={<Assistant />}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Rewrite
					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<MenuItem
							onClick={(event) => {
								handleMenuClick(event, 'AutoImprove');
							}}
							disableRipple
						>
							<AutoAwesomeOutlined />
							Auto Improve
						</MenuItem>
						<MenuItem
							onClick={(event) => {
								handleMenuClick(event, 'Rewrite');
							}}
							disableRipple
						>
							<NoteAltOutlined />
							Rewrite
						</MenuItem>
						<MenuItem
							onClick={(event) => {
								handleMenuClick(event, 'ImproveWithInput');
							}}
							disableRipple
						>
							<NoteAddOutlined />
							Rewrite with Input
						</MenuItem>
						<MenuItem
							onClick={(event) => {
								handleMenuClick(event, 'Shorten');
							}}
							disableRipple
						>
							<UnfoldLessOutlined />
							Shorten
						</MenuItem>
						<MenuItem
							onClick={(event) => {
								handleMenuClick(event, 'Expand');
							}}
							disableRipple
						>
							<UnfoldMoreOutlined />
							Expand
						</MenuItem>
						{/* <MenuItem onClick={(event)=>{handleMenuClick(event,'OnlyFixMistakes')}} disableRipple>
                <BoltOutlined /> Fix Mistakes
          </MenuItem> */}
					</StyledMenu>
				</>
			)}
		</div>
	);
}
