import React from 'react';
import SObjectFieldSelectorCmp from './sObjectSummary/SObjectFieldSelectorCmp';

export default function AdminSettingsScheduleFieldCmp({
	recordId,
	fields,
	sobjectApiName,
	sObjectLabel,
	onFieldSelectionChange,
	defaultFields,
}) {
	const onFieldSelectionChangeFn = (fields) => {
		onFieldSelectionChange?.(fields);
	};

	return (
		<div className="AdminSettingsScheduleField_container">
			<SObjectFieldSelectorCmp
				sobjectApiName={sobjectApiName}
				sobjectLabel={sObjectLabel}
				selectedFields={fields ? JSON.parse(fields) : undefined}
				onFieldSelectionChange={onFieldSelectionChangeFn}
				mandatoryFields={defaultFields}
			/>
		</div>
	);
}
