import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import SmallLoadingCmp from './SmallLoadingCmp';
import { FileTileCmp } from './common/UtilComponents';
import FileService from '../services/FileService';
import { GEN_FLOW_SUMMARIZE_SEARCH_RESULT } from '../Constants';
import GptCommonCmp from './common/GptCommonCmp';

function SearchResultSummaryCmp({ contentVersionId, title, description, onClose, recordId }) {
	const [isLoading, setIsLoading] = useState(true);

	const getFileContent = (_prompts) => {
		return new Promise((resolve, reject) => {
			let resolvedPromts = [];
			if (contentVersionId) {
				let filePromose = FileService.getFileTextContent(contentVersionId);
				filePromose.then((fileContent) => {
					let texts = fileContent?.text || [];
					if (texts.length > 0 && texts[texts.length - 1].indexOf('.') !== -1) {
						texts.pop();
					}
					let textContentStr = texts.join('\n');
					for (let prompt of _prompts || []) {
						let content = prompt.content;
						if (content) {
							content = content.replaceAll('{!content}', textContentStr);
						}
						resolvedPromts.push({
							content: content,
							role: prompt.role,
						});
					}
					resolve(resolvedPromts);
				});
			} else {
				for (let prompt of _prompts || []) {
					let content = prompt.content;
					if (content) {
						content = content.replaceAll('{!content}', description);
					}
					resolvedPromts.push({
						content: content,
						role: prompt.role,
					});
				}
				resolve(resolvedPromts);
			}
		});
	};

	useEffect(() => {
		setIsLoading(false);
	}, []);

	return (
		<Dialog
			open={true}
			scroll="paper"
			maxWidth="md"
			fullWidth={true}
			onClose={(event, reason) => {
				if (reason === 'backdropClick') return;
				onClose?.();
			}}
			height="900px"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">
				<center>{contentVersionId ? 'File Summary' : 'Search Result Summary'}</center>
			</DialogTitle>
			<DialogContent dividers={true}>
				<Grid container direction="column">
					{!contentVersionId && (
						<Grid item>
							<b>{title}</b>
						</Grid>
					)}
					{contentVersionId && <FileTileCmp fileName={description} label={title} />}
					<Grid item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						{isLoading && <SmallLoadingCmp />}
						{!isLoading && (
							<Grid item>
								<GptCommonCmp
									recordId={recordId}
									flow={GEN_FLOW_SUMMARIZE_SEARCH_RESULT}
									resolveMergeFields={getFileContent}
									stream={true}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose?.()}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SearchResultSummaryCmp;
