import { ALL_OPPTY_FILTER, MY_OPPTY_FILTER } from '../Constants';
import OpportunityApi from '../apis/OpportunityApi';
import Utils from '../utils/Utils';

const OpportunityService = {};

OpportunityService.getOpps = async (queryFilter) => {
	return OpportunityApi.getOpps(queryFilter);
};

OpportunityService.getOppDetail = async (
	opptyId,
	includeSalesPlaybook,
	includeRequirement,
	includeAccount,
) => {
	return await OpportunityApi.getOppDetail(
		opptyId,
		includeSalesPlaybook,
		includeRequirement,
		includeAccount,
	);
};

const searchFilterToStr = (filters) => {
	let output = '';
	filters.forEach((filter, idx) => {
		if (idx === 0) {
			// add NOT first if it's not like
			if (filter.operator === 'NOT LIKE') {
				output += 'NOT';
			}

			// first item
			output += filter.field;
		} else {
			// add NOT first if it's not like
			if (filter.operator === 'NOT LIKE') {
				output += ' AND (NOT ' + filter.field;
			} else {
				// subsequent items, add AND
				output += ' AND ' + filter.field;
			}
		}
		if (filter.operator === 'NOT LIKE') {
			output += ' LIKE ';
		} else {
			output += ' ' + filter.operator + ' ';
		}
		if (filter.operator === 'IN' || filter.operator === 'NOT IN') {
			// add ()
			output += '(';
			var valueList = filter?.value?.split(',');
			if (valueList?.length > 0) {
				valueList.forEach((item, itemIdx) => {
					output += "'" + item.trim() + "'";
					if (itemIdx !== valueList?.length - 1) {
						output += ',';
					}
				});
			}
			output += ')';
		} else if (filter.operator === 'LIKE' || filter.operator === 'NOT LIKE') {
			output += "'%" + filter.value + "%'";
		} else {
			output += "'" + filter.value + "'";
		}
		if (filter.operator === 'NOT LIKE') {
			output += ')';
		}
	});
	return output;
};

const getOpptiesFilterString = (currentUserId, onlyDefaultFilters) => {
	// grab filters from cache
	const defaultSearchFilters = Utils.getDefaultOpportunitySearchFilters();
	const opptyFilterCached = localStorage.getItem(
		currentUserId ? MY_OPPTY_FILTER : ALL_OPPTY_FILTER,
	);
	const opptyFilterStr = searchFilterToStr(
		!onlyDefaultFilters && opptyFilterCached
			? JSON.parse(opptyFilterCached)
			: defaultSearchFilters,
	);
	return currentUserId ? `OwnerId = '${currentUserId}' AND ${opptyFilterStr}` : opptyFilterStr;
};

export const setOpptiesRequirements = (data) =>
	data?.records?.map?.((o) => ({
		...o,
		requirementsMet: Utils.getRequirementMet(o?.Requirements__r?.records),
		totalRequirements: o?.Requirements__r?.records?.length || 0,
	})) || [];

export const getMyOppties = async (currentUserId, onlyDefaultFilters) =>
	setOpptiesRequirements(
		await OpportunityApi.getOpps(getOpptiesFilterString(currentUserId, onlyDefaultFilters)),
	);

export const getAllOppties = async (onlyDefaultFilters) =>
	setOpptiesRequirements(
		await OpportunityApi.getOpps(getOpptiesFilterString(undefined, onlyDefaultFilters)),
	);

export default OpportunityService;
