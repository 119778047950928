import ConfigureDocumentApi from '../apis/ConfigureDocumentApi';
import { NAMESPACE_PREFIX } from '../Constants';

const ConfigureDocumentService = {};

ConfigureDocumentService.getFiles = ({ recordId, fileType, isContentDocumentsOnly }) => {
	return ConfigureDocumentApi.getFiles({
		recordId,
		fileType,
		isContentDocumentsOnly,
	});
};

ConfigureDocumentService.getContentSize = function (items) {
	let itemsStr = JSON.stringify(items);
	let itemsSize = new TextEncoder().encode(itemsStr).length / 1024; //Convert size to KB = bytes * 1024
	return itemsSize;
};

ConfigureDocumentService.formatPrefix = function (text, importPrefix) {
	if (importPrefix || !text?.trim?.()) return text;

	return text.replace(/^[0-9.•\s]+/gm, '').replace(/^[a-zA-Z]\.+/gm, '');
};

ConfigureDocumentService.uploadFile = (formdata) => {
	return ConfigureDocumentApi.uploadFile(formdata);
};

ConfigureDocumentService.getFileDetails = async (fileId) => {
	let fileDtls = await ConfigureDocumentApi.getFileDetails(fileId);
	return fileDtls;
};

ConfigureDocumentService.getExistingMapping = async (fileId) => {
	return ConfigureDocumentApi.getExistingMapping(fileId);
};

ConfigureDocumentService.importExistingFiles = async (fileIds) => {
	return ConfigureDocumentApi.importExistingFiles(fileIds);
};

ConfigureDocumentService.getProducts = async (productItemIds) => {
	return new Promise((resolve, reject) => {
		let tags = [];
		ConfigureDocumentApi.getTags({ productItemIds }).then((tagRecords) => {
			if (!Array.isArray(tagRecords)) {
				resolve(tags);
				return;
			}

			for (let tag of tagRecords) {
				let productItem = {
					Id: tag.Id,
					productName: tag.Name,
					productDesc: tag[NAMESPACE_PREFIX + 'Description__c'],
					systemTag: tag[NAMESPACE_PREFIX + 'SystemTag__c'],
					type: tag[NAMESPACE_PREFIX + 'Type__c'],
				};

				if (!productItem.type || productItem.type === '') {
					if (productItem.systemTag === true) {
						productItem.type = 'Tag';
					} else {
						productItem.type = 'Product';
					}
				}
				let owners = [];
				if (tag[NAMESPACE_PREFIX + 'TagOwners__r']) {
					for (let productOwner of tag[NAMESPACE_PREFIX + 'TagOwners__r'].records) {
						owners.push({
							Id: productOwner.Id,
							OwnerId: productOwner[NAMESPACE_PREFIX + 'OwnerId__c'],
							Name: productOwner[NAMESPACE_PREFIX + 'OwnerId__r']['Name'],
						});
					}
				}
				productItem.owners = owners;

				tags.push(productItem);
			}
			resolve(tags);
		});
	});
};

// ConfigureDocumentService.getTags = async (onlyProducts) => {
// 	return new Promise((resolve, reject) => {
// 		let tags = [];
// 		ConfigureDocumentApi.getTags({onlyProducts}).then((tagRecords) => {
// 			for (let tag of tagRecords) {
// 				tags.push({
// 					text: tag.Name,
// 					Id: tag.Id,
// 					description: tag[NAMESPACE_PREFIX + 'Description__c'],
// 				});
// 			}
// 			resolve(tags);
// 		});
// 	});
// };
ConfigureDocumentService.getTags = async ({ productItemIds, onlyProducts }) => {
	return new Promise((resolve, reject) => {
		let tags = [];
		ConfigureDocumentApi.getTags({ productItemIds, onlyProducts }).then((tagRecords) => {
			if (!Array.isArray(tagRecords)) {
				resolve(tags);
				return;
			}

			for (let tag of tagRecords) {
				tags.push({
					text: tag.Name,
					Id: tag.Id,
					description: tag[NAMESPACE_PREFIX + 'Description__c'],
					systemTag: tag[NAMESPACE_PREFIX + 'SystemTag__c'],
					type: tag[NAMESPACE_PREFIX + 'Type__c'],
				});
			}
			resolve(tags);
		});
	});
};

ConfigureDocumentService.getSalesplayTags = async (salesplayId) => {
	return new Promise((resolve, reject) => {
		let tags = [];
		ConfigureDocumentApi.getSalesplayTags(salesplayId).then((tagRecords) => {
			if (!Array.isArray(tagRecords)) {
				resolve(tags);
				return;
			}

			for (let tag of tagRecords) {
				let productItem = {
					Id: tag[NAMESPACE_PREFIX + 'TagId__r']['Id'],
					text: tag[NAMESPACE_PREFIX + 'TagId__r']['Name'],
					description:
						tag[NAMESPACE_PREFIX + 'TagId__r'][NAMESPACE_PREFIX + 'Description__c'],
				};
				tags.push(productItem);
			}
			resolve(tags);
		});
	});
};

ConfigureDocumentService.saveOrUpdateTags = async (tags) => {
	return ConfigureDocumentApi.saveOrUpdateTags(tags);
};

ConfigureDocumentService.saveProductOwners = (productId, ownerIds) => {
	return ConfigureDocumentApi.saveProductOwners(productId, ownerIds);
};

ConfigureDocumentService.deleteTag = async (tagId, objectType) => {
	return ConfigureDocumentApi.deleteTag(tagId, objectType);
};

ConfigureDocumentService.saveFileTags = async (versionIds, tags) => {
	return await ConfigureDocumentApi.saveFileTags(versionIds, tags);
};

export default ConfigureDocumentService;
