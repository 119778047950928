import { INTERNAL_PRODUCT_TAG } from '../Constants';
import SalesforceService from '../services/SalesforceService';
import Utils, { ns } from '../utils/Utils';

export class SearchResults {
	constructor(data, fileTagMap) {
		this.records = [];
		if (Array.isArray(data.featureRecords) && data.featureRecords.length > 0) {
			// convert to search result records
			this.records = data.featureRecords.map((item) => new SearchResult(item, fileTagMap));
		}
	}
}

export class SearchResult {
	constructor(data, fileTagMap) {
		const instanceUrl = SalesforceService.getInstanceUrl();
		this.type = 'sfdc';
		this.Id = data.id;
		this.fileExtension = null;
		this.Highlight = data.highlight;
		this.Description = data?.data?.[ns('Description__c')];
		this.FeatureName = data?.data?.[ns('FeatureName__c')];
		this.Category = data.category;
		this.Subcategory = data.subcategory;
		this.RelevanceScore = data.relavencyScore;
		this.SearchScore = data.searchScore;
		this.externalId = data?.data?.[ns('ExternalId__c')];
		if (data?.contentType === 'file') {
			// this is a file record
			this.subType = 'file';
			this.Header =
				data?.data?.[ns('FileId__r')]?.Name ||
				data?.data?.[ns('FeatureName__c')] ||
				data?.data?.Name;
			this.ContentVersionId = data?.data?.[ns('FileId__r')]?.[ns('ContentVersionId__c')];
			this.Title = data?.data?.[ns('FileId__r')]?.[ns('Title__c')];
			this.FileName = data?.data?.[ns('FileId__r')]?.Name;
		} else {
			// this is a feature record
			this.subType = 'feature';
			this.Header = `${data?.data?.[ns('FeatureName__c')] || ''} ${data?.data?.Name || ''}`;
		}
		this.link = data?.data?.attributes?.url;
		// get file related info
		if (data?.data?.[ns('FileId__r')]) {
			// get file tags
			let fileAttribute = data?.data?.[ns('FileId__r')]?.attributes;
			if (fileAttribute?.type === ns('File__c')) {
				this.fileId = Utils.getFileId(data);
			} else {
				this.fileId = null;
			}
			this.fileTitle = data?.data?.[ns('FileId__r')]?.[ns('Title__c')];
			this.fileTags = [];
			this.isInternal = false;
			if (this.fileId) {
				if (fileTagMap) {
					if (fileTagMap.has(this.fileId)) {
						this.fileTags = fileTagMap.get(this.fileId);
						if (Array.isArray(this.fileTags) && this.fileTags.length > 0) {
							// check for internal or external
							this.fileTags.forEach((tag) => {
								if (tag?.Name?.toLowerCase() === INTERNAL_PRODUCT_TAG) {
									this.isInternal = true;
								}
							});
						}
					}
				}
			}
			// get file source
			this.source = data?.data?.[ns('FileId__r')]?.[ns('Source__c')]
				? data?.data?.[ns('FileId__r')]?.[ns('Source__c')]
				: 'salesforce';
			if (this.source === 'salesforce') {
				if (data?.data?.[ns('FileId__r')]?.[ns('ContentDocumentId__c')]) {
					// assuming salesforce files will have the document attached still
					this.fileLink =
						instanceUrl +
						'/' +
						data?.data?.[ns('FileId__r')]?.[ns('ContentDocumentId__c')];
				} else {
					this.fileLink = this.fileId !== null ? instanceUrl + '/' + this.fileId : null;
				}
			} else {
				// use FileDetails__c if exist
				if (data?.data?.[ns('FileId__r')]?.[ns('FileDetails__c')]) {
					try {
						var fileDetail = JSON.parse(
							data?.data?.[ns('FileId__r')]?.[ns('FileDetails__c')],
						);
						this.fileLink =
							this.source && this.source.toLowerCase() === 'mindtickle'
								? fileDetail.link
								: fileDetail.url;
						this.fileName = fileDetail.fileName;
					} catch (err) {
						console.warn('Error parsing file details. ', err);
						this.fileLink = null;
						this.fileName = null;
					}
				} else {
					this.fileLink = null;
				}
			}
		} else {
			this.source = 'salesforce';
			this.fileLink = null;
		}
	}
}
