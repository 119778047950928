import ApiGateway from './ApiGateway';
import { SFDCFiles } from '../models/SFDCFile';
import SalesforceService from '../services/SalesforceService';
import axios from 'axios';
import { FWFiles } from '../models/FWFile';
import { ns } from '../utils/Utils';

const FileApi = {};

FileApi.getFiles = async (recordId, objectType, filterStr) => {
	var queryStr =
		'SELECT id, name, (SELECT Title, ContentDocumentId FROM AttachedContentDocuments ';
	if (filterStr) {
		queryStr += 'WHERE ' + filterStr + ') ';
	} else {
		queryStr += ') ';
	}
	queryStr += 'FROM ' + objectType + " WHERE id = '" + recordId + "' Limit 1";
	var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	if (resultData.length > 0) {
		if (resultData[0].AttachedContentDocuments) {
			// return document record
			return new SFDCFiles(resultData[0].AttachedContentDocuments);
		}
	}
	return null;
};

const FW_FILES_BASE_FIELDS = [
	'Id',
	'Name',
	ns('ExternalId__c'),
	ns('Source__c'),
	ns('Status__c'),
	ns('Title__c'),
	ns('ContentDocumentId__c'),
	ns('ContentVersionId__c'),
	ns('FileType__c'),
];

const FW_TAGS_FIELDS = ['Name', ns('Description__c'), ns('SystemTag__c')];

const FW_FILE_TAGS_FIELDS = ['Id', ns('TagId__c'), FW_TAGS_FIELDS.map((f) => ns('TagId__r.' + f))];

FileApi.getFeaturewaveFiles = async ({ recordIds, includeFileTags, filterString }) => {
	let allFields = FW_FILES_BASE_FIELDS.join(', ');
	if (includeFileTags)
		allFields += `, (SELECT ${FW_FILE_TAGS_FIELDS.join(', ')} FROM ${ns('FileTags__r')})`;

	let query = `SELECT ${allFields} FROM ${ns('File__c')}`;
	if (Array.isArray(recordIds) && recordIds.length > 0) {
		query += ` WHERE Id IN ('${recordIds.join("', '")}')`;
		if (filterString) query += ' AND ' + filterString;
	} else if (filterString) query += ' WHERE ' + filterString;

	const resultData = await ApiGateway.invoke('sf', 'query', {}, { query });
	if (!Array.isArray(resultData) || resultData.length === 0) return undefined;
	return new FWFiles(resultData);
};

FileApi.getFileContent = async (recordId) => {
	var queryStr = 'SELECT VersionData FROM ContentVersion WHERE ';
	queryStr += "ContentDocumentId = '" + recordId + "' AND IsLatest = true";
	var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	if (resultData.length > 0) {
		var contentUrl = resultData[0].VersionData;
		var instanceUrl = SalesforceService.getInstanceUrl();
		try {
			const fileContentResponse = await axios.get(instanceUrl + contentUrl, {
				headers: {
					Authorization: 'Bearer ' + SalesforceService.getSessionId(),
					'Content-Type': 'application/json',
				},
			});
			return fileContentResponse;
		} catch (err) {
			console.error('Error fetching file content. ', err);
		}
	}
};

FileApi.getFileTextContent = async (contentVersionId) => {
	var reqBody = {
		externalId: contentVersionId,
		includeHtml: true,
	};

	var header = {
		token: SalesforceService.getSessionId(),
		'instance-url': SalesforceService.getInstanceUrl(),
		'Access-Control-Allow-Origin': '*',
		'org-id': SalesforceService.getSfOrgId(),
	};
	try {
		var resultData = await ApiGateway.invoke('es', 'file', {}, header, JSON.stringify(reqBody));
		return resultData;
	} catch (err) {
		console.error('Error fetching file text content. ', err);
	}
};

FileApi.getFileInfo = async (recordId) => {
	var queryStr = 'SELECT Id, Title FROM ContentDocument WHERE ';
	queryStr += "Id = '" + recordId + "'";
	var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	if (resultData.length > 0) {
		try {
			if (resultData[0]) {
				return resultData[0];
			}
		} catch (err) {
			console.error('Error fetching file info. ', err);
		}
	}
};

FileApi.uploadWebpageUrl = async (url) => await ApiGateway.invoke('sf', 'uploadurl', {}, { url });

FileApi.getFileTagsByFileIdMapFromFwFiles = (files) => {
	const tagResult = new Map();
	if (!Array.isArray(files) || files.length < 1) return tagResult;
	files.forEach((f) => {
		if (!f?.Id) return;
		tagResult.set(f.Id, f.fileTags || []);
	});
	return tagResult;
};

export default FileApi;
