import AnthropicService from '../services/AnthropicService';
import ApiGateway from './ApiGateway';

const AnthropicApi = {};

AnthropicApi.generate = async (prompts, prevPrompts, flow) => {
	var response = [];
	let systemPrompts = [];
	let systemPromptContents = [];
	let userPrompts = [];
	prevPrompts = prevPrompts || [];
	prompts = prompts ? prompts.concat(prevPrompts) : [].concat(prevPrompts);
	if (prompts && prompts.length > 0) {
		for (let prompt of prompts) {
			if (prompt.role === 'system') {
				systemPrompts.push(prompt);
			} else {
				userPrompts.push(prompt);
				systemPromptContents.push(prompt.content);
			}
		}
	}
	userPrompts = AnthropicService.processPrompt(userPrompts);
	let reqBody = {
		model: AnthropicService.getModel(),
		max_tokens: 300,
		system: systemPromptContents.join(', ') || [],
		messages: userPrompts || [],
	};
	try {
		var resultData = await ApiGateway.invoke('ai', 'claude', {}, {}, JSON.stringify(reqBody));
		return resultData;
	} catch (error) {
		console.error('An error occurred during Anthropic request', error);
	}
	return response;
};
AnthropicApi.getSearchResults = async (searchText, prevResponse, flow) => {
	let response = [];
	let promptPrefix = AnthropicService.getPromptPreix(flow);
	let prevQuestion = prevResponse ? prevResponse : [];
	prevQuestion.push({ role: 'user', content: searchText });
	prevQuestion = AnthropicService.processPrompt(prevResponse);
	let reqBody = {
		model: AnthropicService.getModel(),
		max_tokens: 300,
		system: promptPrefix,
		messages: prevQuestion,
	};
	try {
		var resultData = await ApiGateway.invoke('ai', 'claude', {}, {}, JSON.stringify(reqBody));
		return resultData;
	} catch (error) {
		console.error('An error occurred during Anthropic request', error);
	}
	return response;

	// anthropic doesn't support client side calls right now
	/*if (searchText) {
		try {
			var model = AnthropicService.getModel();
			if (model) {
                const anthropicClient = new Client({apiKey: AnthropicService.getApiKey()})

                const stream = await anthropicClient.complete(
					{
						model: model,
						max_token: 300, // keeping it relatively low for chat bot
						temperature: 0.5,
                        system: promptPrefix,
						messages: prevQuestion,
                        stream: true,
					}
				);
				return stream;
			} else {
				return null;
			}
		} catch (error) {
			console.error('An error occurred during Anthropic request', error);
		}
	}
	return response;*/
};

AnthropicApi.generateNew = async (prompts, config) => {
	let configModel = config?.model || AnthropicService.getModel();
	let configMaxToken = config?.maxToken || 300;
	var response = [];
	let systemPrompts = [];
	let systemPromptContents = [];
	let userPrompts = [];
	if (prompts && prompts.length > 0) {
		for (let prompt of prompts) {
			if (prompt.role === 'system') {
				systemPrompts.push(prompt);
			} else {
				userPrompts.push(prompt);
				systemPromptContents.push(prompt.content);
			}
		}
	}
	userPrompts = AnthropicService.processPrompt(userPrompts);
	let reqBody = {
		model: configModel,
		max_tokens: configMaxToken,
		system: systemPromptContents.join(', ') || [],
		messages: userPrompts || [],
	};
	try {
		var resultData = await ApiGateway.invoke('ai', 'claude', {}, {}, JSON.stringify(reqBody));
		return resultData;
	} catch (error) {
		console.error('An error occurred during Anthropic request', error);
	}
	return response;
};

export default AnthropicApi;
