import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SalesforceCommonApi from '../../../apis/SalesforceCommonApi';
import { AddToPhotosOutlined } from '@mui/icons-material';

export default function SObjectFieldCopyCmp({ sObjects, fieldFilterMap, onAppend }) {
	const [selectedSObject, setSelectedSObject] = useState('Account');
	const [selectedField, setSelectedField] = useState('Name');
	const [fieldMap, setFieldMap] = useState({});
	const [isFieldLoading, setIsFieldLoading] = useState(true);

	const loadFields = (objectApiName) => {
		setIsFieldLoading(true);
		if (!fieldMap[objectApiName]) {
			SalesforceCommonApi.getMetadata({
				sobjectApiName: objectApiName,
			}).then((metadata) => {
				fieldMap[objectApiName] = metadata.fields;
				setFieldMap(fieldMap);
				setIsFieldLoading(false);
				//setSelectedField('Name');
			});
		} else {
			setIsFieldLoading(false);
		}
	};
	useEffect(() => {
		loadFields('Account');
	}, []);

	const handleSObjectChange = (event) => {
		setSelectedSObject(event.target.value);
		loadFields(event.target.value);
	};
	const handleSObjectFieldChange = (event) => {
		setSelectedField(event.target.value);
	};

	/*
	const copyToClipboard = () => {
		let selectedSObjectObjs = sObjects.filter((sobject) => sobject.name === selectedSObject);
		let selectedSObjectObj =
			selectedSObjectObjs && selectedSObjectObjs.length > 0
				? selectedSObjectObjs[0]
				: undefined;
		let objectTxt = selectedSObjectObj?.label;
		let fieldObjs = fieldMap[selectedSObject].filter((field) => field.name === selectedField);
		let fieldObj = fieldObjs && fieldObjs.length > 0 ? fieldObjs[0] : undefined;
		let fieldTxt = fieldObj.label?.startsWith(objectTxt)
			? fieldObj.label + ' (' + fieldObj.name + ')'
			: objectTxt + ' ' + fieldObj.label + ' (' + fieldObj.name + ')';
		navigator.clipboard.writeText(`${fieldTxt}`);
	};
	*/

	const appendInLast = () => {
		let selectedSObjectObjs = sObjects.filter((sobject) => sobject.name === selectedSObject);
		let selectedSObjectObj =
			selectedSObjectObjs && selectedSObjectObjs.length > 0
				? selectedSObjectObjs[0]
				: undefined;
		let objectTxt = selectedSObjectObj?.label;
		let fieldObjs = fieldMap[selectedSObject].filter((field) => field.name === selectedField);
		let fieldObj = fieldObjs && fieldObjs.length > 0 ? fieldObjs[0] : undefined;
		let fieldTxt = fieldObj.label?.startsWith(objectTxt)
			? fieldObj.label + ' (' + fieldObj.name + ')'
			: objectTxt + ' ' + fieldObj.label + ' (' + fieldObj.name + ')';
		onAppend?.(`"${fieldTxt}"`);
	};
	return (
		<div className="SObjectFieldCopyCmp_container">
			<Grid container>
				<Grid item style={{ paddingRight: '10px' }}>
					<FormControl size="small" style={{ width: '300px' }}>
						<InputLabel id="demo-simple-select-label">Object</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedSObject}
							// defaultValue={selectedSObject.name}
							label="SObject"
							onChange={handleSObjectChange}
						>
							{(sObjects || [{ name: 'Account', label: 'Account' }]).map(
								(sObject) => {
									return (
										<MenuItem key={sObject.name} value={sObject.name}>
											{sObject.label} ({sObject.name})
										</MenuItem>
									);
								},
							)}
						</Select>
					</FormControl>
				</Grid>
				<Grid item>
					<FormControl size="small" style={{ width: '300px' }}>
						<InputLabel id="demo-simple-select-label">Field</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedField}
							disabled={isFieldLoading}
							label="SObject"
							onChange={handleSObjectFieldChange}
						>
							{fieldMap[selectedSObject]
								?.filter((fieldObj) => {
									let _fields = fieldFilterMap[selectedSObject];
									if (!_fields || _fields.length === 0) {
										return true;
									} else {
										return _fields.includes(fieldObj.name);
									}
								})
								.map((fieldObj) => {
									return (
										<MenuItem key={fieldObj.name} value={fieldObj.name}>
											{fieldObj.label} ({fieldObj.name})
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</Grid>
				<Grid item>
					{/* <IconButton
						title="Copy to Clipboard"
						aria-label="Copy to Clipboard"
						onClick={copyToClipboard}
					>
						<ContentCopyOutlined />
					</IconButton> */}
					<IconButton title="Append" aria-label="Append" onClick={appendInLast}>
						<AddToPhotosOutlined />
					</IconButton>
				</Grid>
			</Grid>
		</div>
	);
}
