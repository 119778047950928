import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

function FWTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`oppty-tabpanel-${index}`}
			aria-labelledby={`oppty-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

FWTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export default FWTabPanel;
