import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const currencyFormatter = (num) => {
	return num.toLocaleString('en-US');
};
const FWCurrency = styled((props) => (
	<Box {...props}>{props.prefix + currencyFormatter(props.value ? props.value : 0)}</Box>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));
FWCurrency.displayName = 'FWCurrency';
export default FWCurrency;
