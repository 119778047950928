import {
	DEFAULT_CLOSE_DATE_FIELD,
	DEFAULT_EXPECTED_REVENUE_FIELD,
	NAMESPACE_PREFIX,
} from '../Constants';
import { Owner } from './Owner';
import { Account } from './Account';
import moment from 'moment';
import { Requirements } from './Requirement';
import SalesforceService from '../services/SalesforceService';
import { SalesPlaySupplementFiles } from './SalesPlaySupplementFile';

export class Opportunities {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Opportunity(item, false, false));
		}
	}
}

export class Opportunity {
	constructor(data, salesPayBookData, requirementData, accountData) {
		// get config mapping
		var mappingConfig = SalesforceService.getMappingConfig();

		// get default field mapping
		var expectedRevenueField = DEFAULT_EXPECTED_REVENUE_FIELD;
		var closeDateField = DEFAULT_CLOSE_DATE_FIELD;
		if (mappingConfig) {
			if (mappingConfig?.ExpectedRevenue__c) {
				expectedRevenueField = mappingConfig?.ExpectedRevenue__c;
			}
			if (mappingConfig?.CloseDate__c) {
				closeDateField = mappingConfig?.CloseDate__c;
			}
		}

		// set data
		this.Id = data.Id;
		this.LastModifiedDate = data.LastModifiedDate ? moment(data.LastModifiedDate) : null;
		this.Name = data.Name;
		this.ExpectedRevenue = data[expectedRevenueField];
		this.CloseDate = data[closeDateField];
		this.StageName = data.StageName;
		this.Type = data.Type;
		this.Probability = data.Probability;
		this.AccountId = data.AccountId || data.Account?.Id;
		this.SalesPlaybook__c = data[NAMESPACE_PREFIX + 'SalesPlaybook__c']
			? data[NAMESPACE_PREFIX + 'SalesPlaybook__c']
			: null;

		// related parent
		if (data.Owner !== null && data.Owner !== undefined) {
			this.Owner = new Owner(data.Owner);
		}
		if (data.Account !== null && data.Account !== undefined) {
			this.Account = new Account(data.Account);
		}

		this.ContactRoles = data.OpportunityContactRoles?.records;
		if (data[NAMESPACE_PREFIX + 'Requirements__r']) {
			this['Requirements__r'] = new Requirements(data[NAMESPACE_PREFIX + 'Requirements__r']);
		}
		if (data[NAMESPACE_PREFIX + 'SalesPlaySupplementFiles__r']) {
			this['SalesPlaySupplementFiles__r'] = new SalesPlaySupplementFiles(
				data[NAMESPACE_PREFIX + 'SalesPlaySupplementFiles__r'],
			);
		}
		if (salesPayBookData) {
			// we assume salesPayBookData is already in the fwave model format and not in sfdc format
			this.SalesPlayBook = salesPayBookData;
		}
		if (requirementData) {
			// we assume requirementData is already in the fwave model format and not in sfdc format
			this.Requirements = requirementData;
		}
		if (accountData) {
			this.Contacts = accountData.Contacts?.records || [];
		}
		this.Personas = [];
		//If there is contact role use that otherwise use Account Contacts
		//ENG-349 [Sales Play Runtime and Admin] Persona based sales play for each buyer type
		let roleAndTitles = new Set();
		if (this.ContactRoles?.length > 0) {
			for (let contactRoleSO of this.ContactRoles || []) {
				let title = contactRoleSO?.Contact?.Title;
				let role = contactRoleSO?.Role;
				let promptRoleArr = [];
				let displayRoleArr = [];
				if (title) {
					displayRoleArr.push(title);
					promptRoleArr.push(title);
				}
				if (role) {
					promptRoleArr.push(role);
					if (title) {
						displayRoleArr.push(role);
					} else {
						displayRoleArr.push(role);
					}
				}
				let roleDisplayName = displayRoleArr.join(' | ');
				if (roleAndTitles.has(roleDisplayName)) continue;
				this.Personas.push({
					type: 'ContactRole',
					id: contactRoleSO.Id,
					//contactId: contactRoleSO.Contact?.Id,
					role: promptRoleArr.join(' & '),
					roleDisplayName: roleDisplayName,
					//title : title,
					//contactName: contactRoleSO?.Contact?.Name,
				});
				roleAndTitles.add(roleDisplayName);
			}
		} else {
			for (let contactSO of this.Contacts || []) {
				if (roleAndTitles.has(contactSO.Title)) continue;
				this.Personas.push({
					type: 'Contact',
					id: contactSO.Id,
					//contactId: contactSO.Id,
					role: contactSO.Title,
					roleDisplayName: contactSO.Title,
					//contactName: contactSO.Name,
				});
				roleAndTitles.add(contactSO.Title);
			}
		}
	}
}
