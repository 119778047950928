import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import SmallLoadingCmp from '../SmallLoadingCmp';

import { GPTSummarySO } from '../../models/GPTSummary';

import AnthropicService from '../../services/AnthropicService';
import CustomSettingService from '../../services/CustomSettingService';
import GPTSummaryService from '../../services/GPTSummaryService';
import SalesforceCommonApi from '../../apis/SalesforceCommonApi';
import SalesPlaybookService from '../../services/SalesPlaybookService';

import { OBJECT_TYPE_GPT_SUMMARY, OPPTY_OVERVIEW_ABOUT_COMPANY } from '../../Constants';
import { QKEY_GPT_SUMMARY } from '../../utils/QueryKeys';

const AboutCompanyCmp = ({ companyName, companyUrl }) => {
	const [aboutCompany, setAboutCompany] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const queryClient = useQueryClient();

	const {
		data: gptSummaryRecord,
		isPending: isCachePending,
		isLoading: gptSummaryRecordLoading,
	} = useQuery({
		queryKey: [QKEY_GPT_SUMMARY, companyName + '-' + companyUrl],
		queryFn: async () =>
			await GPTSummaryService.getGPTSummary({
				summaryId: companyName + '-' + companyUrl,
			}),
	});

	useEffect(() => {
		if (isCachePending || gptSummaryRecordLoading) return;

		// Check first if there is record exist in GPTSummary
		if (gptSummaryRecord) {
			//setGenerationStatus('Initializing');
			setAboutCompany(gptSummaryRecord.GPTSummary__c);
			setIsLoading(false);
			return;
		}

		let allPromises = [];
		let hasUrl = false;
		let promptPromise = CustomSettingService.getGPTPrompt({
			promptConfigName: OPPTY_OVERVIEW_ABOUT_COMPANY,
		});
		allPromises.push(promptPromise);
		if (companyUrl) {
			hasUrl = true;
			let aboutCompanyPromise = SalesPlaybookService.getWebsiteText(companyUrl);
			allPromises.push(aboutCompanyPromise);
		}

		Promise.all(allPromises).then((results) => {
			let _promptConfig = results[0];
			let _aboutCompany = hasUrl ? results[1] : '';

			let _finalPrompts = [];
			for (let _prompt of _promptConfig.prompts || []) {
				let _content = _prompt.content;
				_content = _content.replace('{!companyName}', companyName);
				_content = _content.replace(
					'{!aboutCompany}',
					_aboutCompany
						? 'More information about company ' + companyName + ': ' + _aboutCompany
						: '',
				);
				_finalPrompts.push({
					role: _prompt.role,
					content: _content,
				});
			}

			AnthropicService.generate(_finalPrompts).then((_genResult) => {
				let outputMsgs = [];
				if (_genResult) {
					let contents = _genResult.content || [];
					for (let content of contents) {
						outputMsgs.push(content.text);
					}
				}
				let finalSummary = outputMsgs.join(',');
				setAboutCompany(finalSummary);
				setIsLoading(false);
				const gptSumarySORecord = new GPTSummarySO({
					gptSummary: finalSummary,
					recordSummary: JSON.stringify(_finalPrompts),
					recordId: companyName + '-' + companyUrl,
				});
				SalesforceCommonApi.createRecord(gptSumarySORecord, OBJECT_TYPE_GPT_SUMMARY).then(
					() =>
						queryClient.invalidateQueries({
							queryKey: [QKEY_GPT_SUMMARY, companyName + '-' + companyUrl],
						}),
				);
			});
		});
	}, [companyName, companyUrl, isCachePending, gptSummaryRecordLoading, gptSummaryRecord]);

	if (isLoading) return <SmallLoadingCmp />;

	return <Markdown remarkPlugins={[remarkGfm]}>{aboutCompany}</Markdown>;
};
export default AboutCompanyCmp;
