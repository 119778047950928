import {
	NAMESPACE_PREFIX,
	NAMESPACE_CLASS,
	OBJECT_TYPE_File,
	OBJECT_TYPE_SOBJECT_EXPORT,
	ACCOUNT_SUMMARY_CHATGPT_PROMPT,
	PERSONA_INSIGHT_CHATGPT_PROMPT,
} from '../Constants';
import SalesforceService from '../services/SalesforceService';
import FileService from '../services/common/FileService';
import Utils, { ns } from '../utils/Utils';
import ApiGateway from './ApiGateway';
import SalesforceCommonApi from './SalesforceCommonApi';
import ConfigureDocumentApi from './ConfigureDocumentApi';

const AdminApi = {};

AdminApi.getCollaborationGroups = () => {
	var queryStr = `SELECT Id, Name, MemberCount, OwnerId, CollaborationType, Description, CreatedDate, CreatedById, LastModifiedDate, LastModifiedById, SystemModstamp, FullPhotoUrl, MediumPhotoUrl, SmallPhotoUrl, LastFeedModifiedDate, InformationTitle, InformationBody, HasPrivateFieldsAccess, CanHaveGuests, LastViewedDate, LastReferencedDate, IsArchived, IsAutoArchiveDisabled, AnnouncementId, GroupEmail, BannerPhotoUrl, IsBroadcast FROM CollaborationGroup WHERE IsArchived = false`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

AdminApi.getLogoDocument = (documentName) => {
	var queryStr = `SELECT Id FROM Document WHERE DeveloperName = '${documentName}'`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

//SELECT ContentDocumentId, Id, IsDeleted, LinkedEntity.Type, ShareType, SystemModstamp, Visibility FROM ContentDocumentLink WHERE ContentDocumentId = '0698B0000019ooV'
AdminApi.getDocumentLinks = (contentDocumentIds) => {
	let contentDocIdStr = Utils.convertToWhereCondition(contentDocumentIds);
	var queryStr = `SELECT Id, LinkedEntityId, LinkedEntity.Name, LinkedEntity.Type, ContentDocumentId, ContentDocument.Title, IsDeleted, SystemModstamp, ShareType, Visibility FROM ContentDocumentLink WHERE ContentDocumentId IN (${contentDocIdStr}) ORDER BY SystemModstamp DESC`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

AdminApi.saveGroupAccess = async (groups, access, contentDocumentIds) => {
	let contentDocumentLinks = [];

	for (let group of groups) {
		for (let contentDocumentId of contentDocumentIds) {
			contentDocumentLinks.push({
				LinkedEntityId: group,
				ContentDocumentId: contentDocumentId,
				Visibility: 'AllUsers',
				ShareType: access,
			});
		}
	}
	var body = {
		objectName: 'ContentDocumentLink',
		records: contentDocumentLinks,
	};
	await ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

AdminApi.updateAccess = (updateReq) => {
	return ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(updateReq));
};

AdminApi.removeAccess = (linkIds, objectType = 'unknown') => {
	let deletePromise = ApiGateway.invoke(
		'sf',
		'delete',
		{},
		{},
		JSON.stringify({ objectType: objectType, recordIds: linkIds }),
	);

	return deletePromise;
};

AdminApi.getOrgInfo = () => {
	let orgId = SalesforceService.getSfOrgId();
	var queryStr = `SELECT Id, ${NAMESPACE_PREFIX}Admin_Email__c, ${NAMESPACE_PREFIX}CompanyPhone__c, ${NAMESPACE_PREFIX}CompanyWebsite__c, ${NAMESPACE_PREFIX}CompanyName__c, ${NAMESPACE_PREFIX}CompanyAbout__c, ${NAMESPACE_PREFIX}CompanyLogo__c FROM ${NAMESPACE_PREFIX}Configuration__c WHERE SetupOwnerId = '${orgId}'`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

AdminApi.getApexJogDetails = () => {
	var queryStr = `SELECT Id, CreatedDate, CreatedById, JobType, ApexClass.Name, Status, JobItemsProcessed, TotalJobItems, NumberOfErrors, CompletedDate, MethodName, ExtendedStatus, ParentJobId, LastProcessed, LastProcessedOffset,CronTriggerId,  CronTrigger.NextFireTime, CronTrigger.PreviousFireTime, CronTrigger.State, CronTrigger.StartTime, CronTrigger.EndTime, CronTrigger.CronExpression, CronTrigger.TimeZoneSidKey, CronTrigger.OwnerId, CronTrigger.LastModifiedById, CronTrigger.CreatedById, CronTrigger.CreatedDate, CronTrigger.TimesTriggered  FROM AsyncApexJob WHERE ApexClass.Name = 'SObjectExportJob' AND Status = 'Queued'`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

AdminApi.getSObjectExports = ({ sObjectApiName }) => {
	let whereCondition = ``;
	if (sObjectApiName) {
		whereCondition = ` WHERE ${NAMESPACE_PREFIX}SObjectName__c = '${sObjectApiName}' AND ${NAMESPACE_PREFIX}ParentSObjectExport__c = NULL`;
	}
	var queryStr = `SELECT Id, Name, ${NAMESPACE_PREFIX}SObjectLabel__c, ${NAMESPACE_PREFIX}SObjectName__c, ${NAMESPACE_PREFIX}RelationshipLabel__c, ${NAMESPACE_PREFIX}RelationshipName__c, ${NAMESPACE_PREFIX}ParentSObjectExport__c, ${NAMESPACE_PREFIX}Fields__c, ${NAMESPACE_PREFIX}DefaultFields__c FROM ${NAMESPACE_PREFIX}SObjectExport__c${whereCondition}  ORDER By ${NAMESPACE_PREFIX}ParentSObjectExport__c`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

AdminApi.getAccountSummaryPrompt = async () => {
	try {
		var queryStr = `SELECT Id, DeveloperName, ${NAMESPACE_PREFIX}Prompt__c FROM ${NAMESPACE_PREFIX}GPTPrompt__mdt WHERE DeveloperName = 'AccountSummary' AND NamespacePrefix = 'fwave'`;
		let promptRecords = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
		if (promptRecords && promptRecords.length > 0) {
			let promptStr = promptRecords[0][NAMESPACE_PREFIX + 'Prompt__c'];
			promptStr = promptStr.replaceAll('\\\\', '\\');
			let prompts = JSON.parse(promptStr);
			for (let prompt of prompts) {
				let content = prompt.content;
				if (content && content.toLowerCase().indexOf('summarize') !== -1) {
					content = content.replace('{summary} ', '');
					return {
						content: content,
						record: promptRecords[0],
					};
				}
			}
		}
	} catch (e) {
		console.error('Error while parsing Account Summary Prompt:', e);
	}
	return {
		content: ACCOUNT_SUMMARY_CHATGPT_PROMPT,
		record: undefined,
	};
};

AdminApi.getPersonaInsightPrompt = async () => {
	try {
		var queryStr = `SELECT Id, DeveloperName, ${NAMESPACE_PREFIX}Prompt__c FROM ${NAMESPACE_PREFIX}GPTPrompt__mdt WHERE DeveloperName = 'OpportunityOverviewPersonaInsight'`;
		let promptRecords = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
		if (promptRecords && promptRecords.length > 0) {
			let promptStr = promptRecords[0][NAMESPACE_PREFIX + 'Prompt__c'];
			let prompts = JSON.parse(promptStr);
			for (let prompt of prompts) {
				if (prompt.role === 'user') {
					let content = prompt.content;
					return {
						content: content,
						record: promptRecords[0],
					};
				}
			}
		}
	} catch (e) {
		console.error('Error while parsing Account Summary Prompt:', e);
	}
	return {
		content: PERSONA_INSIGHT_CHATGPT_PROMPT,
		record: undefined,
	};
};

AdminApi.updatePrompt = ({ developerName, masterLabel, prompt, record }) => {
	var abortCode = `${NAMESPACE_CLASS}ExtensionController.execute('updatePrompt', new Map<String, Object>{'developerName'=> '${developerName}', 'masterLabel'=>'${masterLabel}', 'prompt'=>'${prompt}'});`; //, 'masterLabel'=>'${masterLabel}'
	return ApiGateway.invoke('sf', 'executeApex', {}, { query: abortCode });
};

// AdminApi.getSObjectExportFile = ({recordId}) => {
// 	var queryStr = `SELECT Id, Name, ${NAMESPACE_PREFIX}SObjectLabel__c, ${NAMESPACE_PREFIX}SObjectName__c, ${NAMESPACE_PREFIX}RelationshipLabel__c, ${NAMESPACE_PREFIX}RelationshipName__c, ${NAMESPACE_PREFIX}ParentSObjectExport__c, ${NAMESPACE_PREFIX}Fields__c FROM ${NAMESPACE_PREFIX}SObjectExport__c  ORDER By ${NAMESPACE_PREFIX}ParentSObjectExport__c${whereCondition}`;
// 	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
// };

AdminApi.createSObjectExport = async (parentId, fields) => {
	let records = [];

	let record = {};
	record[NAMESPACE_PREFIX + 'ParentSObjectExport__c'] = parentId;
	if (fields) {
		for (let field in fields) {
			record[field] = fields[field];
		}
	}
	records.push(record);

	var body = {
		objectName: NAMESPACE_PREFIX + 'SObjectExport__c',
		records: records,
	};
	return await ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

AdminApi.createSObjectExports = async (parentId, exports) => {
	let records = [];

	for (let exportObj of exports) {
		if (exportObj) {
			let record = {};
			for (let field in exportObj) {
				record[field] = exportObj[field];
				if (parentId) record[NAMESPACE_PREFIX + 'ParentSObjectExport__c'] = parentId;
			}
			records.push(record);
		}
	}

	var body = {
		objectName: NAMESPACE_PREFIX + 'SObjectExport__c',
		records: records,
	};
	return await ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

AdminApi.updateSObjectExport = async (recordId, fields) => {
	let records = [];

	let record = { Id: recordId };
	for (let property in fields) {
		record[property] = fields[property];
	}
	records.push(record);

	var body = {
		objectName: NAMESPACE_PREFIX + 'SObjectExport__c',
		records: records,
	};
	await ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body));
};

// AdminApi.getCronJogDetails = ()=>{
//     var queryStr = `SELECT Id, CronJobDetailId, NextFireTime, PreviousFireTime, State, StartTime, EndTime, CronExpression, TimeZoneSidKey, OwnerId, LastModifiedById, CreatedById, CreatedDate, TimesTriggered FROM CronTrigger`;
//     return ApiGateway.invoke('sf', 'query', {}, { query : queryStr});
// }

AdminApi.scheduleJob = (cronExp) => {
	var scheduleCode = `System.schedule('Featurewave Export', '${cronExp}', new ${NAMESPACE_CLASS}SObjectExportJob() );`;
	return ApiGateway.invoke('sf', 'executeApex', {}, { query: scheduleCode });
};

AdminApi.abortJob = (jobId) => {
	var abortCode = `System.abortJob('${jobId}');`;
	return ApiGateway.invoke('sf', 'executeApex', {}, { query: abortCode });
};

AdminApi.exportNow = () => {
	var abortCode = `${NAMESPACE_CLASS}SObjectExportUtils.runExport();`;
	return ApiGateway.invoke('sf', 'executeApex', {}, { query: abortCode });
};

AdminApi.exportSingleRecord = ({ recordId, exportId }) => {
	var abortCode = `${NAMESPACE_CLASS}SObjectExportUtils.exportData(new Set<String>{'${recordId}'}, 'Account', '${exportId}');`;
	return ApiGateway.invoke('sf', 'executeApex', {}, { query: abortCode });
};

AdminApi.getSummary = ({ recordId, documentId, promptValue }) => {
	return SalesforceCommonApi.invokeSalesforceCustomApi({
		method: 'getSummary',
		parameters: {
			recordId,
			documentId,
			promptValue,
		},
	});
};

let isCachedSObject = false;
let isCachedRelationships = {};
let cacheSObject = undefined;
let cacheRelationship = {};
AdminApi.getSObjects = async () => {
	return new Promise((resolve, reject) => {
		if (isCachedSObject) {
			resolve(cacheSObject);
		} else {
			let apiVersion = SalesforceService.getApiVersion();
			var query = `/data/${apiVersion}/sobjects/`;
			ApiGateway.invoke('sf', 'executeSalesforceRest', {}, { query: query }).then(
				(response) => {
					let sobjects = response.sobjects;
					let sobjectOptions = [];
					for (let sobject of sobjects) {
						sobjectOptions.push({
							Id: sobject.name,
							text: sobject.label,
						});
					}
					isCachedSObject = true;
					cacheSObject = sobjectOptions;
					resolve(sobjectOptions);
				},
			);
		}
	});
};

AdminApi.getRelationships = async (sObjectApiName) => {
	let apiVersion = SalesforceService.getApiVersion();
	return new Promise((resolve, reject) => {
		if (isCachedRelationships[sObjectApiName]) {
			resolve(cacheRelationship[sObjectApiName]);
		} else {
			var query = `/data/${apiVersion}/sobjects/${sObjectApiName}/describe/`;
			ApiGateway.invoke('sf', 'executeSalesforceRest', {}, { query: query }).then(
				(relationshipResponse) => {
					//childSObject = Opportunity, relationshipName = "Opportunities"
					let relationships = relationshipResponse.childRelationships;
					let sobjectOptions = [];
					for (let sobject of relationships) {
						if (sobject.relationshipName)
							sobjectOptions.push({
								Id: sobject.relationshipName,
								text: sobject.relationshipName,
								object: sobject.childSObject,
							});
					}
					isCachedRelationships[sObjectApiName] = true;
					cacheRelationship[sObjectApiName] = sobjectOptions;
					resolve(sobjectOptions);
				},
			);
		}
	});
};

AdminApi.isDefaultSObjectExportSetup = () => {
	return false;
};

AdminApi.updateCompanyInfo = async (recordId, fields) => {
	let records = [];
	let record = { Id: recordId };
	for (let property in fields) {
		record[property] = fields[property];
	}
	records.push(record);

	var body = {
		objectName: NAMESPACE_PREFIX + 'Configuration__c',
		records: records,
	};
	await ApiGateway.invoke('sf', recordId ? 'update' : 'create', {}, {}, JSON.stringify(body));
};

AdminApi.createSalesPlayBookRelated = async (
	values,
	baseObjectApiName,
	objectApiName,
	relatedLookupApiName,
	salesPlayApiName,
	salesPlayBookId,
	objectDescribe,
) => {
	return new Promise((resolve, reject) => {
		//Create Base Record
		let isUpdate = false;
		if (values.Id) {
			isUpdate = true;
		}
		//Skip file
		let finalObjectValues = {};
		for (let fieldName in values) {
			if (fieldName !== 'File' && fieldName !== 'ProductSelection')
				finalObjectValues[fieldName] = values[fieldName];
		}

		let baseRecordPromise;
		let hasFile = false;
		if (objectDescribe.hasFile && !objectDescribe.hasMultipleFile) {
			let fileObject = values.File;
			let fileName = fileObject.name || values.Name;
			let fileTitle = values.Name || fileObject.name;
			let fileType = objectDescribe.fileType;
			let uploadId = 'uploadId' + Utils.generateId(10);
			baseRecordPromise = FileService.uploadFile(
				fileObject,
				fileName,
				fileTitle,
				fileType,
				uploadId,
			);
			hasFile = true;
		} else {
			let baseRecords = [];
			baseRecords.push(finalObjectValues);

			var baseBody = {
				objectName: baseObjectApiName,
				records: baseRecords,
			};
			baseRecordPromise = ApiGateway.invoke(
				'sf',
				isUpdate ? 'update' : 'create',
				{},
				{},
				JSON.stringify(baseBody),
			);
		}

		baseRecordPromise.then((baseResponse) => {
			if (baseResponse && baseResponse.length > 0 && !baseResponse[0].success) {
				reject(baseResponse);
				return;
			}
			let baseRecordId = hasFile
				? baseResponse.featurewaveFileId
				: isUpdate
					? salesPlayBookId
					: baseResponse[0].id;
			if (objectApiName) {
				let records = [];
				let record = {};
				record[relatedLookupApiName] = baseRecordId;
				record[salesPlayApiName] = salesPlayBookId;
				records.push(record);

				var body = {
					objectName: objectApiName,
					records: records,
				};
				ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body)).then(
					(baseResponse) => {
						let recordId = baseResponse[0].id;
						if (objectDescribe.hasMultipleFile) {
							if (!values.File) {
								resolve([baseRecordId, recordId]);
								return;
							}
							let files = values.File;
							let filesPromises = [];
							for (let fileObject of files) {
								let fileName = fileObject.name;
								let fileType = objectDescribe.fileType;
								let uploadId = recordId;
								filesPromises.push(
									FileService.uploadFile(
										fileObject,
										fileName,
										fileName,
										fileType,
										uploadId,
									),
								);
							}
							Promise.all(filesPromises).then((recordsList) => {
								//let fileResponse = recordsList && recordsList.length>0 ? recordsList[0] : undefined;
								let salesPlayFiles = [];
								for (let fileResponse of recordsList) {
									if (fileResponse) {
										let featurewaveFileId = fileResponse.featurewaveFileId;
										if (featurewaveFileId) {
											let salesPlayFile = {};
											salesPlayFile[NAMESPACE_PREFIX + 'SalesPlaybook__c'] =
												salesPlayBookId;
											salesPlayFile[NAMESPACE_PREFIX + 'File__c'] =
												featurewaveFileId;
											salesPlayFiles.push(salesPlayFile);
										}
									}
								}
								var salesPlayFileBody = {
									objectName: NAMESPACE_PREFIX + 'SpbFiles__c',
									records: salesPlayFiles,
								};
								let salesPlayFilePromise = ApiGateway.invoke(
									'sf',
									'create',
									{},
									{},
									JSON.stringify(salesPlayFileBody),
								);
								salesPlayFilePromise.then((salesPlayFileResponse) => {
									resolve([baseRecordId, recordId]);
								});
							});
						} else {
							resolve([baseRecordId, recordId]);
						}
					},
				);
			} else {
				if (objectDescribe.hasProductSelection) {
					let products = values.ProductSelection;
					if (products && products.length > 0) {
						ConfigureDocumentApi.saveSalesPlayTags([baseRecordId], products).then(
							(a, b, c) => {
								resolve([baseRecordId]);
							},
						);
					}
				}
				resolve([baseRecordId]);
			}
		});

		// ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(baseBody)).then((baseResponse)=>{
		//     let baseRecordId = baseResponse[0].id;
		//     if(objectApiName){
		//         let records = [];
		//         let record = {};
		//         record[relatedLookupApiName] = baseRecordId;
		//         record[salesPlayApiName] = salesPlayBookId;
		//         records.push(record);

		//         var body = {
		//             objectName:  objectApiName,
		//             records: records,
		//         }
		//         ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body)).then((baseResponse)=>{
		//             let recordId = baseResponse[0].id;
		//             resolve([baseRecordId, recordId])
		//         })
		//     }else{
		//         resolve([baseRecordId])
		//     }

		// })
	});
};

AdminApi.updateSalesPlayBookRelated = async (
	values,
	spbRecordId,
	baseObjectApiName,
	objectApiName,
	relatedLookupApiName,
	salesPlayApiName,
	salesPlayBookId,
	objectDescribe,
	fwFileId,
) => {
	return new Promise((resolve, reject) => {
		//Create Base Record
		let isUpdate = false;
		let isUpdateFile = false;
		if (values.Id) {
			isUpdate = true;
			if (objectDescribe.hasFile) {
				if (objectDescribe.hasMultipleFile) {
					if (values.File) {
						isUpdateFile = true;
					}
				} else {
					if (values.File) {
						isUpdateFile = true;
					}
				}
			}
		}
		//Skip file
		let finalObjectValues = {};
		for (let field of objectDescribe.form.fields) {
			if (field.type !== 'File' && field.type !== 'ProductSelection' && !field.notEditable) {
				finalObjectValues[field.name] = values[field.name];
			}
		}
		if (isUpdate) {
			finalObjectValues['Id'] = values.Id;
		}

		let baseRecordPromise;
		let hasFile = false;
		if (objectDescribe.hasFile && !objectDescribe.hasMultipleFile) {
			if (fwFileId) {
				let baseRecords = [];
				baseRecords.push(finalObjectValues);
				const baseBody = {
					objectName: baseObjectApiName,
					records: baseRecords,
				};
				ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(baseBody)).then(() => {
					resolve([spbRecordId, fwFileId]);
				});
				return;
			} else {
				if (values?.File?.name) {
					let fileObject = values.File;
					let fileName = fileObject.name || values.Name;
					let fileType = objectDescribe.fileType;
					let fileTitle = values.Name || fileObject.name;
					let uploadId = 'uploadId' + Utils.generateId(10);
					baseRecordPromise = FileService.uploadFile(
						fileObject,
						fileName,
						fileTitle,
						fileType,
						uploadId,
					);
					hasFile = true;
				}
			}
		} else {
			let baseRecords = [];
			baseRecords.push(finalObjectValues);

			const baseBody = {
				objectName: baseObjectApiName,
				records: baseRecords,
			};
			baseRecordPromise = ApiGateway.invoke(
				'sf',
				isUpdate ? 'update' : 'create',
				{},
				{},
				JSON.stringify(baseBody),
			);
		}

		baseRecordPromise.then((baseResponse) => {
			if (baseResponse && baseResponse.length > 0 && !baseResponse[0].success) {
				reject(baseResponse);
				return;
			}
			let baseRecordId = hasFile
				? baseResponse.featurewaveFileId
				: isUpdate
					? salesPlayBookId
					: baseResponse[0].id;
			if (objectApiName) {
				if (objectDescribe.hasMultipleFile) {
					if (!values.File) {
						resolve([baseRecordId, spbRecordId]);
						return;
					}
					let files = values.File;
					let filesPromises = [];
					for (let fileObject of files) {
						let fileName = fileObject.name;
						if (!fileName) continue;
						let fileType = objectDescribe.fileType;
						let uploadId = spbRecordId;
						filesPromises.push(
							FileService.uploadFile(
								fileObject,
								fileName,
								fileName,
								fileType,
								uploadId,
							),
						);
					}
					Promise.all(filesPromises).then((recordsList) => {
						//let fileResponse = recordsList && recordsList.length>0 ? recordsList[0] : undefined;
						let salesPlayFiles = [];
						for (let fileResponse of recordsList) {
							if (fileResponse) {
								let featurewaveFileId = fileResponse.featurewaveFileId;
								if (featurewaveFileId) {
									let salesPlayFile = {};
									salesPlayFile[NAMESPACE_PREFIX + 'SalesPlaybook__c'] =
										salesPlayBookId;
									salesPlayFile[NAMESPACE_PREFIX + 'File__c'] = featurewaveFileId;
									salesPlayFiles.push(salesPlayFile);
								}
							}
						}
						var salesPlayFileBody = {
							objectName: NAMESPACE_PREFIX + 'SpbFiles__c',
							records: salesPlayFiles,
						};
						let salesPlayFilePromise = ApiGateway.invoke(
							'sf',
							'create',
							{},
							{},
							JSON.stringify(salesPlayFileBody),
						);
						salesPlayFilePromise.then((salesPlayFileResponse) => {
							resolve([baseRecordId, spbRecordId]);
						});
					});
				} else {
					if (isUpdateFile) {
						let records = [];
						let record = {};
						record['Id'] = spbRecordId;
						record[relatedLookupApiName] = baseRecordId;
						//record[salesPlayApiName] = salesPlayBookId;
						records.push(record);

						var body = {
							objectName: objectApiName,
							records: records,
						};

						let oldFwFilePromise = ApiGateway.invoke(
							'sf',
							'query',
							{},
							{
								query: `SELECT Id, ${NAMESPACE_PREFIX}File__c  FROM ${NAMESPACE_PREFIX}SpbFiles__c WHERE Id='${spbRecordId}'`,
							},
						);
						oldFwFilePromise.then((spbFiles) => {
							ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body)).then(
								(baseResponse) => {
									//let recordId = baseResponse[0].id;
									//Get Old Fw File and Delete it
									if (spbFiles && spbFiles.length > 0) {
										let spbFile = spbFiles[0];
										let oldFwFileId = spbFile[NAMESPACE_PREFIX + 'File__c'];
										if (oldFwFileId) {
											SalesforceCommonApi.deleteRecords(
												[oldFwFileId],
												ns(OBJECT_TYPE_File),
											).then((response) => {
												// console.log(
												// 	'file deleted for single file replace : ',
												// 	response,
												// );
											});
										}
									}
									resolve([baseRecordId, spbRecordId]);
								},
							);
						});
					} else {
						resolve([baseRecordId, spbRecordId]);
					}
				}
			} else {
				resolve([baseRecordId]);
			}
		});

		// ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(baseBody)).then((baseResponse)=>{
		//     let baseRecordId = baseResponse[0].id;
		//     if(objectApiName){
		//         let records = [];
		//         let record = {};
		//         record[relatedLookupApiName] = baseRecordId;
		//         record[salesPlayApiName] = salesPlayBookId;
		//         records.push(record);

		//         var body = {
		//             objectName:  objectApiName,
		//             records: records,
		//         }
		//         ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body)).then((baseResponse)=>{
		//             let recordId = baseResponse[0].id;
		//             resolve([baseRecordId, recordId])
		//         })
		//     }else{
		//         resolve([baseRecordId])
		//     }

		// })
	});
};

AdminApi.saveSObjectFields = async ({ recordId, fields }) => {
	let fieldNames = [];
	for (let field of fields) {
		fieldNames.push(field.name);
	}
	let sobjectExportRecord = {};
	sobjectExportRecord[ns('Fields__c')] = JSON.stringify(fieldNames);

	await SalesforceCommonApi.updateRecord(
		sobjectExportRecord,
		recordId,
		OBJECT_TYPE_SOBJECT_EXPORT,
	);
};

export default AdminApi;
