import React from 'react';

import { Grid } from '@mui/material';

function TitleBar({ icon, title, subtitle }) {
	return (
		<div>
			<Grid container alignItems="center" justifyContent="center">
				<Grid item xs={1}>
					<Grid container alignItems="center" justifyContent="center" direction="column">
						<Grid item />
						<Grid item> {icon}</Grid>
						<Grid item />
					</Grid>
				</Grid>
				{subtitle && (
					<Grid item xs={11}>
						<Grid className="main-title"> {title?.label}</Grid>
						{subtitle && (
							<Grid
								className="sub-title"
								style={{ color: subtitle.color, fontWeight: subtitle.fontWeight }}
							>
								{subtitle.label}
							</Grid>
						)}
					</Grid>
				)}
			</Grid>
		</div>
	);
}
export default TitleBar;
