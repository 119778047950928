import ApiGateway from './ApiGateway';
import FileApi from './FileApi';
import SalesforceService from '../services/SalesforceService';

import { SearchResults } from '../models/SearchResult';
import { SFDCSearchHistory, SearchHistories } from '../models/SearchHistory';

import Utils, { ns } from '../utils/Utils';

const SearchApi = {};

SearchApi.getSearchResults = async (
	searchText,
	numOfResults,
	skipRerank,
	saveSearch,
	excludeIdsList,
) => {
	// we are not searching if there's no search text
	if (!searchText) return [];

	const reqBody = [
		{
			id: '1', // not sure why it's 1, but it's in the sample api, so we're going to keep it like this
			query: searchText,
			top: numOfResults ?? 3,
			skiprerank: !!skipRerank,
		},
	];
	if (Array.isArray(excludeIdsList) && excludeIdsList.length > 0)
		reqBody[0].excludeIdsList = excludeIdsList;

	const headers = {
		token: SalesforceService.getSessionId(),
		'instance-url': SalesforceService.getInstanceUrl(),
		'Access-Control-Allow-Origin': '*',
		'org-id': SalesforceService.getSfOrgId() + '-content-auto',
	};
	const resultData = await ApiGateway.invoke(
		'es',
		'search',
		{},
		headers,
		JSON.stringify(reqBody),
	);

	// get all the tag data too
	// get feature list
	const data = resultData[0];
	const fileIds = new Set();
	if (Array.isArray(data.featureRecords) && data.featureRecords.length > 0) {
		data.featureRecords.forEach((item) => {
			let fileId = Utils.getFileId(item);
			if (fileId) fileIds.add(fileId);
		});
	}
	const files = await FileApi.getFeaturewaveFiles({
		recordIds: Array.from(fileIds),
		includeFileTags: true,
	});
	const tagResult = FileApi.getFileTagsByFileIdMapFromFwFiles(files);

	// save search
	if (saveSearch) {
		try {
			// save the search key
			SearchApi.createSearchHistory(resultData[0]);
		} catch (err) {
			console.error('Error saving search history. ', err);
		}
	}
	return new SearchResults(resultData[0], tagResult);
};

SearchApi.getHtmlResult = async (externalId, range) => {
	var defaultRange = [0];
	if (range) {
		defaultRange = range;
	}
	var reqBody = {
		externalId: externalId,
		range: defaultRange,
	};
	var header = {
		token: SalesforceService.getSessionId(),
		'instance-url': SalesforceService.getInstanceUrl(),
		'Access-Control-Allow-Origin': '*',
		'org-id': SalesforceService.getSfOrgId(),
	};
	try {
		var resultData = await ApiGateway.invoke('es', 'html', {}, header, JSON.stringify(reqBody));
		return resultData;
	} catch (err) {
		console.error('Error fetching html content. ', err);
	}
};

SearchApi.getSearchHistory = async (ownerId, isSFDC) => {
	let filterStr = '';
	let query = 'SELECT Id FROM ' + ns('SearchHistory__c');
	if (ownerId) {
		filterStr = " WHERE ownerId = '" + ownerId + "'";
		query += filterStr;
	}
	const records = await ApiGateway.invoke('sf', 'query', {}, { query });
	if (isSFDC) {
		return records;
	}
	return new SearchHistories({ records });
};

SearchApi.createSearchHistory = async (searchResult) => {
	const records = [];
	if (searchResult) {
		records.push(new SFDCSearchHistory(searchResult));
	}
	return await ApiGateway.invoke(
		'sf',
		'create',
		{},
		{},
		JSON.stringify({
			objectName: ns('SearchHistory__c'),
			records,
		}),
	);
};

SearchApi.clearSearchHistory = async () => {
	try {
		let seachHistories = await SearchApi.getSearchHistory(null, true);
		if (Array.isArray(seachHistories) && seachHistories?.length > 0) {
			// delete records
			return await ApiGateway.invoke(
				'sf',
				'delete',
				{},
				{},
				JSON.stringify({
					objectType: ns('SearchHistory__c'),
					recordIds: seachHistories.map((item) => item.Id),
				}),
			);
		} else {
			return [];
		}
	} catch (err) {
		console.error('error clearing search history', err);
		return err;
	}
};

export default SearchApi;
