import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

const FWGradiantIcon = styled((props) => (
	<Box {...props}>
		<svg width={0} height={0}>
			<linearGradient id={'fwGradiantIcon' + props.iconKey} x1={0} y1={0} x2={1} y2={0}>
				<stop offset={0} stopColor={props.color1} />
				<stop offset={1} stopColor={props.color2} />
			</linearGradient>
		</svg>
		{React.cloneElement(props.children, {
			sx: { fill: 'url(#fwGradiantIcon' + props.iconKey + ')' },
		})}
	</Box>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

export default FWGradiantIcon;
