import { NAMESPACE_PREFIX } from '../Constants';
import { Opportunity } from './Opportunity';
import { RequirementTags } from './RequirementTag';
import { Solution } from './Solution';

export class Requirements {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Requirement(item));
		}
	}
}

export class Requirement {
	constructor(data, isPlaceholder) {
		if (!isPlaceholder) {
			this.Id = data.Id;
			this.Name = data.Name;
			this.Priority__c = data[NAMESPACE_PREFIX + 'Priority__c'];
			this.CustomerAsk__c = data[NAMESPACE_PREFIX + 'CustomerAsk__c'];
			this.FeatureStatus__c = data[NAMESPACE_PREFIX + 'FeatureStatus__c'];
			this.Requirement__c = data[NAMESPACE_PREFIX + 'Requirement__c'];
			this.Opportunity__c = data[NAMESPACE_PREFIX + 'Opportunity__c'];
			this.RequirementId__c = data[NAMESPACE_PREFIX + 'RequirementId__c'];

			// init rfp flag
			this.HasRFP = false;

			// related
			const solutions = data[NAMESPACE_PREFIX + 'Solutions__r'];
			const solutionRecs = solutions?.records;
			if (solutions) {
				this.Solutions__r = { records: [] };
				if (solutionRecs[0]) {
					this.Solutions__r.records.push(solutionRecs[0].map((sol) => new Solution(sol)));
				} else {
					this.Solutions__r.records.push([]);
				}
				if (solutionRecs[1]) {
					this.Solutions__r.records.push(solutionRecs[1].map((sol) => new Solution(sol)));
				} else {
					this.Solutions__r.records.push([]);
				}
			}

			const opptys = data[NAMESPACE_PREFIX + 'Opportunity__r'];
			if (opptys) {
				this.Opportunity__r = new Opportunity(opptys);
			}

			const reqProducts = data[NAMESPACE_PREFIX + 'RequirementProducts__r'];
			if (reqProducts) {
				this.RequirementProducts__r = new RequirementTags(reqProducts);
			}
		} else {
			this.Priority__c = null;
			this.CustomerAsk__c = 'Must Have';
			this.FeatureStatus__c = 'Available';
			this.Requirement__c = '';
			this.Opportunity__c = data?.Opportunity__c;
			this.RequirementId__c = '';

			// init rfp flag
			this.HasRFP = false;
		}
	}
}

export class SFDCRequirement {
	constructor(data) {
		if (data) {
			this.Id = data?.Id;
			this.Name = data?.Name;
			this[NAMESPACE_PREFIX + 'Priority__c'] = data?.Priority__c;
			this[NAMESPACE_PREFIX + 'CustomerAsk__c'] = data?.CustomerAsk__c;
			this[NAMESPACE_PREFIX + 'FeatureStatus__c'] = data?.FeatureStatus__c;
			this[NAMESPACE_PREFIX + 'Requirement__c'] = data?.Requirement__c;
			this[NAMESPACE_PREFIX + 'Opportunity__c'] = data?.Opportunity__c;
			this[NAMESPACE_PREFIX + 'RequirementId__c'] = data?.RequirementId__c;
		}
	}
}
