import { getUser, getUsers } from '../apis/UserApi';

const UserService = {};

UserService.getUser = async (userId) => {
	return await getUser(userId);
};

UserService.getUsers = () => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			getUsers()
				.then((_users) => {
					let userOptions = _users?.map((groupItem) => ({
						Id: groupItem.Id,
						text:
							(groupItem.FirstName ? groupItem.FirstName + ' ' : '') +
							groupItem.LastName,
					}));
					resolve(userOptions);
				})
				.catch((err) => reject(err));
		}, 3000);
	});
};

export default UserService;
