import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
	Accordion as MuiAccordion,
	AccordionDetails as MuiAccordionDetails,
	AccordionSummary as MuiAccordionSummary,
	Box,
	Grid,
	Tab,
	Tabs,
	Typography,
	tabsClasses,
	styled,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FWTabPanel from './mui/FWTabPanel';

import GptCommonCmp from './common/GptCommonCmp';
import NoSearchResultCmp from './common/NoSearchResultCmp';
import SmallLoadingCmp from './SmallLoadingCmp';

import AdminApi from '../apis/AdminApi';
import ConfigureDocumentService from '../services/ConfigureDocumentService';

import { ns } from '../utils/Utils';
import { QKEY_ORG_INFO, QKEY_SALESPLAY_TAGS, QKEY_TAGS } from '../utils/QueryKeys';
import { GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT } from '../Constants';

function a11yProps(index) {
	return {
		id: `oppty-tab-${index}`,
		'aria-controls': `oppty-tabpanel-${index}`,
	};
}

const Accordion = styled((props) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		sx={{
			'&': {
				paddingLeft: 'unset !important',
				marginLeft: 'unset !important',
				boxShadow: 'unset !important',
			},
		}}
		square
		{...props}
	/>
))(({ theme }) => ({
	border: `0px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		sx={{
			'&': {
				paddingLeft: '0px',
				marginLeft: '0px',
				boxShadow: 'unset !important',
			},
		}}
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: 'rgba(255, 255, 255)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AntTabs = styled(Tabs)({
	borderBottom: '1px solid #e8e8e8',
	'& .MuiTabs-indicator': {
		backgroundColor: '#1890ff',
	},
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	color: 'rgba(0, 0, 0, 0.85)',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		color: '#40a9ff',
		opacity: 1,
	},
	'&.Mui-selected': {
		color: '#1890ff',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: '#d1eaff',
	},
}));

function PersonaInsightItem({
	companyName,
	accountName,
	opportunity,
	role,
	roleDisplayName,
	products,
	prompt,
}) {
	const [value, setValue] = useState(0);

	const getPrompts = (productObj, _prompts) => {
		let productName = productObj.text;
		let productDescription = productObj.description;

		return new Promise((resolve, reject) => {
			let prompts = _prompts || [];
			let _finalPrompts = [];
			for (let prompt of prompts) {
				let content = prompt.content || '';
				content = content.replaceAll('{!role}', role);
				//content = content.replaceAll('{!contactName}', contactName);
				content = content.replaceAll('{!accountName}', accountName || '');
				content = content.replaceAll('{!companyName}', companyName || '');
				content = content.replaceAll('{!productName}', productName ? productName : '');
				content = content.replaceAll('{!productDescription}', productDescription || '');
				// var rx = /(\{!opportunity[a-z A-Z]\w*})/gi;
				// var s = "You are assistant to company called 'Featurewave', which is working with company 'ABC Corp' on Opportunity called '{!opportunityName}' with 'VP of Sales' from {!opportunity.Account.Name}. Explain how Featurewave company should approach VP of Sales working at ABC Corp to sell product '{!productName}'. Here is some more details about '{!productName}': {!productDescription}";
				// var m, res=[];
				// while (m = rx.exec(s)) {
				//   res.push(m[1]);
				// }
				var rx = /(\{!opportunity[a-z A-Z]\w*})/gi;
				var ms = rx.exec(content) || [];
				for (let opprtyField of ms) {
					//let opprtyField = m[1] || '';
					let fieldName = opprtyField.substring(13, opprtyField.lastIndexOf('}'));
					if (fieldName.indexOf('.') === -1) {
						let fieldVal = opportunity[fieldName];
						content = content.replaceAll(opprtyField, fieldVal || '');
					} else {
						let fieldObj = opportunity;
						for (let relatedFieldName of fieldName.split('.')) {
							fieldObj = fieldObj[relatedFieldName];
						}
						content = content.replaceAll(opprtyField, fieldObj || '');
					}
				}

				_finalPrompts.push({
					content: content,
					role: prompt.role,
				});
			}
			resolve(_finalPrompts);
		});
	};

	return (
		// <Card variant="outlined" style={{ margin: '5px' }}>
		// 	<CardContent>
		<Grid container direction={'column'}>
			<Grid item>
				<AntTabs
					value={value}
					onChange={(e, val) => setValue(val)}
					aria-label="Products"
					sx={{
						[`& .${tabsClasses.indicator}`]: {
							display: 'none',
						},
					}}
				>
					{(products || []).map((product, index) => (
						<AntTab key={product.text} label={product.text} {...a11yProps(index)} />
					))}
				</AntTabs>
				{(products || []).map((product, index) => (
					<FWTabPanel
						key={product.text}
						value={value}
						index={index}
						style={{ paddingLeft: '15px' }}
					>
						<GptCommonCmp
							recordId={`${opportunity?.Id}-${roleDisplayName}-${product.text}`}
							flow={GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT}
							resolveMergeFields={(inputPrompts) => getPrompts(product, inputPrompts)}
							displayShowMore={true}
							stream={false}
							prompt={prompt}
							//getPrompts={() => getPrompts(product)}
						/>
					</FWTabPanel>
				))}
			</Grid>
		</Grid>
		// 	</CardContent>
		// </Card>
	);
}

function PersonaInsight({
	account,
	opportunity,
	salesPlayBook,
	setShowPersonaInsight,
	showEmptyScreen,
	prompt,
}) {
	const [expanded, setExpanded] = useState(0);

	const { data: salesplayTags, isLoading: salesplayTagsLoading } = useQuery({
		enabled: salesPlayBook?.IsActive__c === true,
		queryKey: [QKEY_SALESPLAY_TAGS, salesPlayBook?.Id],
		queryFn: async () => await ConfigureDocumentService.getSalesplayTags(salesPlayBook?.Id),
	});

	const { data: tags, isLoading: tagsLoading } = useQuery({
		enabled: !salesPlayBook || salesPlayBook?.IsActive__c === false,
		queryKey: [QKEY_TAGS],
		queryFn: () => ConfigureDocumentService.getTags({ onlyProducts: true }),
	});

	const { data: configurations } = useQuery({
		queryKey: [QKEY_ORG_INFO],
		queryFn: AdminApi.getOrgInfo,
	});
	const companyName = useMemo(
		() => configurations?.[0]?.[ns('CompanyName__c')],
		[configurations],
	);

	const hasSalesPlayTags =
		!salesplayTagsLoading && Array.isArray(salesplayTags) && salesplayTags.length > 0;
	const products = useMemo(
		() => (hasSalesPlayTags ? salesplayTags : tags || []),
		[hasSalesPlayTags, salesplayTags, tags],
	);

	const hasProducts = products && products.length > 0;
	const hasPersonas = opportunity?.Personas?.length > 0 || account?.Personas?.length > 0;

	// Use account contacts as personas if no opportunity personas found
	const personas = useMemo(
		() => [...(opportunity?.Personas || account?.Personas || [])],
		[opportunity?.Personas, account?.Personas],
	);

	useEffect(() => {
		if (salesplayTagsLoading || tagsLoading) return;
		setShowPersonaInsight?.(hasPersonas && hasProducts);
	}, [hasPersonas, hasProducts, setShowPersonaInsight, salesplayTagsLoading, tagsLoading]);

	if (salesplayTagsLoading || tagsLoading) return <SmallLoadingCmp />;

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={0}
			flex={1}
			sx={{ paddingTop: '10px', width: '100%' }}
		>
			{(hasPersonas && hasProducts && (
				<Grid item container flex={1}>
					{personas.map((persona, index) => (
						<Accordion
							key={index}
							expanded={expanded === 'panel' + index}
							sx={{ width: '100%' }}
							onChange={(e, isExpanded) =>
								setExpanded(isExpanded ? 'panel' + index : false)
							}
						>
							<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
								<Typography>
									<b>{persona?.roleDisplayName}</b>
								</Typography>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									'&': {
										padding: 'unset !important',
									},
								}}
							>
								{expanded === 'panel' + index && (
									<PersonaInsightItem
										key={index}
										role={persona?.role}
										roleDisplayName={persona.roleDisplayName}
										products={products}
										//contactName={persona?.contactName}
										companyName={companyName}
										accountName={opportunity?.Account?.Name}
										opportunity={opportunity}
										prompt={prompt}
									/>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Grid>
			)) ||
				(showEmptyScreen && (
					<Box>
						<NoSearchResultCmp msg="No Persona Insights Found..." />
					</Box>
				))}
		</Grid>
	);
}

export default PersonaInsight;
