import RequirementApi from '../apis/RequirementApi';

const RequirementService = {};

RequirementService.getRequirements = async (featureId) =>
	await RequirementApi.getRequirements(featureId);

RequirementService.createRequirement = async (newReq, selectedTags) =>
	await RequirementApi.createRequirement(newReq, selectedTags);

RequirementService.updateRequirement = async (newReq) =>
	await RequirementApi.updateRequirement(newReq);

RequirementService.deleteRequirement = async (newReq) =>
	await RequirementApi.deleteRequirement(newReq);

RequirementService.queryCluster = async (startDate, endDate, status, accuracy) =>
	await RequirementApi.queryCluster(startDate, endDate, status, accuracy);

RequirementService.getRelatedMaterials = async (featureList) =>
	await RequirementApi.getRelatedMaterials(featureList);

export default RequirementService;
