import CustomSettingApi from '../apis/CustomSettingApi';

const CustomSettingService = {};

CustomSettingService.getCustomSettings = async () => {
	return await CustomSettingApi.getCustomSettings();
};

CustomSettingService.updateCustomSettings = async (req) => {
	return await CustomSettingApi.updateCustomSettings(req);
};

CustomSettingService.getGPTPrompt = async (req) => {
	let promptObj = await CustomSettingApi.getGPTPrompt(req);
	return promptObj;
};

export default CustomSettingService;
