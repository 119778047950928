import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiGateway from '../../apis/ApiGateway';

function FWSObjectTextAutoCompleteCmp({
	onChange,
	existingVal,
	label,
	sobjectApiName,
	fieldApiName,
	defaultOptions,
}) {
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		ApiGateway.invoke(
			'sf',
			'query',
			{},
			{ query: `SELECT ${fieldApiName} FROM ${sobjectApiName}` },
		).then((records) => {
			let _options = defaultOptions || [];
			for (let record of records) {
				let fieldVal = record[fieldApiName];
				if (fieldVal && !_options.includes(fieldVal)) _options.push(fieldVal);
			}
			setOptions(_options);
			setLoading(false);
		});
	}, [sobjectApiName, fieldApiName]);

	const onChangeInput = (evt) => {
		// let val = evt.target.value;
		// console.log({val})
		if (onChange) onChange(evt.target.value);
	};
	const onChangeMenu = (evt) => {
		// let val = evt.target.value;
		// console.log({val})
		if (onChange) onChange(evt.target.textContent);
	};
	return (
		<div>
			{loading ? (
				<TextField
					id="outlined-basic"
					label={label}
					value={'Loading...'}
					disabled
					variant="outlined"
				/>
			) : (
				<Autocomplete
					id="fwSObjectTextFieldAutoComplete"
					freeSolo
					defaultValue={existingVal}
					options={options || []}
					onChange={onChangeMenu}
					renderInput={(params) => (
						<TextField {...params} label={label} onChange={onChangeInput} />
					)}
				/>
			)}
		</div>
	);
}

export default FWSObjectTextAutoCompleteCmp;
