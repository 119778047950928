import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { COMPANY_NAME } from '../../Constants';

const SIZE_HEIGHT_MAPPING = {
	lg: '80px',
	md: '60px',
	sm: '34px',
};

const FWLogo = styled(({ size, clickable, onClickHandler }) => (
	<Box
		sx={{
			height: SIZE_HEIGHT_MAPPING[size] || '34px',
			cursor: clickable ? 'pointer' : null,
		}}
		component="img"
		src={require('../../assets/company-logo-sm.png')}
		alt={`${COMPANY_NAME} Logo`}
		onClick={onClickHandler || null}
	/>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));
FWLogo.displayName = 'FWLogo';
export default FWLogo;
