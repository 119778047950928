import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FileListCmp from './FileListCmp';

import ConfigureDocumentService from '../../../services/ConfigureDocumentService';

/*
Properties:
open
handleClose
*/
function FileImportCmp({ open, handleClose }) {
	const [isOpen, setOpen] = useState(open || false);
	const [showProgress, setShowProgress] = useState(false);
	const [selectedFileIds, setSelectedFileIds] = useState([]);

	const handleCloseFn = () => {
		setOpen(false);
		if (handleClose) handleClose();
	};

	const handleAction = () => {
		setShowProgress(true);
		ConfigureDocumentService.importExistingFiles(selectedFileIds).then(() => {
			setShowProgress(false);
			setOpen(false);
			if (handleClose) handleClose();
		});
	};

	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={handleClose}
				scroll="paper"
				maxWidth="xl"
				fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">Import Salesforce Files</DialogTitle>
				<DialogContent dividers={true}>
					<FileListCmp
						fileType={''}
						recordSelection="Multiple"
						onSelectionChange={(selectedFileIds, selectedFileItems) => {
							setSelectedFileIds(selectedFileIds);
						}}
						isContentDocumentsOnly={true}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseFn}>Cancel</Button>
					<Button
						onClick={handleAction}
						disabled={!selectedFileIds || selectedFileIds.length === 0 || showProgress}
						autoFocus
					>
						Import
					</Button>
					{showProgress && <CircularProgress size={20} />}
				</DialogActions>
			</Dialog>
		</div>
	);
}
FileImportCmp.displayName = 'FileImportCmp';
export default FileImportCmp;
