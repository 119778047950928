import SalesPlaybookApi from '../apis/SalesPlaybookApi';

const SalesPlaybookService = {};

SalesPlaybookService.getSalesPlaybookDetail = async (featureId) => {
	return await SalesPlaybookApi.getSalesPlaybookDetail(featureId);
};

SalesPlaybookService.getWebsiteText = (websiteUrl) => {
	return SalesPlaybookApi.getWebsiteText(websiteUrl);
};

export default SalesPlaybookService;
