import React, { lazy, Suspense } from 'react';

import MainLoadingCmp from '../MainLoadingCmp';

export const withSuspense = (WrappedComponent, FallbackComponent = MainLoadingCmp) => {
	const WithSuspenseComponent = (props) => (
		<Suspense fallback={FallbackComponent ? <FallbackComponent /> : null}>
			<WrappedComponent {...props} />
		</Suspense>
	);
	WithSuspenseComponent.displayName = `WithSuspense(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
	return WithSuspenseComponent;
};

export const withLazySuspense = (importPromise, FallbackComponent) =>
	withSuspense(lazy(importPromise), FallbackComponent);
