import ApiGateway from './ApiGateway';
import { CONFIG_GPT_PROMPTS, NAMESPACE_PREFIX } from '../Constants';
import SalesforceService from '../services/SalesforceService';
import { Configuration } from '../models/Configuration';
import SalesforceCommonApi from './SalesforceCommonApi';

const CustomSettingApi = {};

CustomSettingApi.getCustomSettings = async () => {
	var queryStr =
		'SELECT id, ' +
		NAMESPACE_PREFIX +
		'EnableSalesPlay__c, ' +
		NAMESPACE_PREFIX +
		'EnableRequirements__c, ' +
		NAMESPACE_PREFIX +
		'AccountSummaryPrompt__c ' +
		'FROM ' +
		NAMESPACE_PREFIX +
		"Configuration__c WHERE SetupOwnerId = '" +
		SalesforceService.getSfOrgId() +
		"' Limit 1";
	try {
		var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
		if (resultData) {
			return new Configuration(resultData[0]);
		} else {
			return null;
		}
	} catch (err) {
		console.error('Error fetching custom settings. ', err);
		return null;
	}
};

CustomSettingApi.updateCustomSettings = async (req) => {
	// send the rest out for update
	var body = {
		objectName: NAMESPACE_PREFIX + 'Configuration__c',
		records: [req],
	};
	var resultData = await ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body));

	// look for solutions after requirement is created
	return resultData;
};

CustomSettingApi.getGPTPrompt = async ({ promptConfigName }) => {
	return new Promise((resolve, reject) => {
		try {
			SalesforceCommonApi.getMetadataRecord({
				recordDevName: promptConfigName,
				objectApiName: NAMESPACE_PREFIX + 'GPTPrompt__mdt',
				fields: [
					'MasterLabel',
					'DeveloperName',
					NAMESPACE_PREFIX + 'Fields__c',
					NAMESPACE_PREFIX + 'Prompt__c',
				],
			}).then((mdtRecord) => {
				if (!mdtRecord) {
					mdtRecord = CONFIG_GPT_PROMPTS[promptConfigName];
				}
				let prompts = mdtRecord[NAMESPACE_PREFIX + 'Prompt__c'];
				let fields = mdtRecord[NAMESPACE_PREFIX + 'Fields__c'];
				resolve({
					prompts: !prompts || prompts === '' ? undefined : JSON.parse(prompts),
					fields: !fields || fields === '' ? undefined : JSON.parse(fields),
				});
			});
		} catch (e) {
			console.error('Error retrieving GptPrompt metadata. ', e);
			resolve(CONFIG_GPT_PROMPTS[promptConfigName]);
		}
	});
};

export default CustomSettingApi;
