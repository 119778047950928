import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => <MuiAccordion disableGutters={true} {...props} />)(
	({ theme }) => ({
		// border: `1px solid ${theme.palette.divider}`,
		// '&:not(:last-child)': {
		//   borderBottom: 0,
		// },
		// '&:before': {
		//   display: 'none',
		// },
		cursor: 'pointer',
	}),
);
Accordion.displayName = 'Accordion';
export default Accordion;
