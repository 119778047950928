import React from 'react';

import { Box, Fade, Grid, LinearProgress } from '@mui/material';

import { COMPANY_NAME } from '../Constants';

function MainLoadingCmp(props) {
	return (
		<Box sx={{ height: '100vh' }}>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				sx={{ height: '100%' }}
			>
				<Fade in={props.showIcon} timeout={1000} mountOnEnter unmountOnExit>
					<Grid item>
						<Box
							sx={{
								height: '90px',
								borderRadius: '5px',
							}}
							component="img"
							src={require('../assets/fwlogosq-sm.png')}
							alt={`${COMPANY_NAME} Logo`}
						/>
					</Grid>
				</Fade>
				<Fade in={props.showIcon && props.isLoading} timeout={1000}>
					<Grid item>
						<Box sx={{ width: '404px' }}>
							<LinearProgress />
						</Box>
					</Grid>
				</Fade>
				<Fade in={props.showIcon && props.isLoading} timeout={1000}>
					<Grid item>
						<Box sx={{ fontSize: '24px', fontWeight: '400' }}>
							{props.loadingMsg ? props.loadingMsg : COMPANY_NAME}
						</Box>
					</Grid>
				</Fade>
			</Grid>
		</Box>
	);
}

export default MainLoadingCmp;
