import { Opportunity, Opportunities } from '../models/Opportunity';

import ApiGateway from './ApiGateway';
import SalesPlaybookService from '../services/SalesPlaybookService';
import RequirementService from '../services/RequirementService';
import SalesforceService from '../services/SalesforceService';

import { ACCOUNT_BASE_FIELDS } from './AccountApi';
import {
	DEFAULT_CLOSE_DATE_FIELD,
	DEFAULT_EXPECTED_REVENUE_FIELD,
	DEFAULT_OPP_OBJ,
	DEFAULT_STAGE_NAME_FIELD,
} from '../Constants';
import { ns } from '../utils/Utils';

export const OPPTY_BASE_FIELDS = [
	'Id',
	'Name',
	'LastModifiedDate',
	'Owner.Name',
	'Owner.SmallPhotoUrl',
	'StageName',
	'Type',
	'Probability',
	'AccountId',
	ns('SalesPlaybook__c'),
];

const opptyContactRolesFields = [
	'Id',
	'Contact.Name',
	'Contact.Email',
	'Contact.Title',
	'Contact.Phone',
	'IsPrimary',
	'Role',
];

const OpportunityApi = {};

OpportunityApi.getOpps = async (queryFilter) => {
	// get default field mapping
	let opptyObj = DEFAULT_OPP_OBJ;
	let expectedRevenueField = DEFAULT_EXPECTED_REVENUE_FIELD;
	let closeDateField = DEFAULT_CLOSE_DATE_FIELD;
	let stageNameField = DEFAULT_STAGE_NAME_FIELD;

	// get config mapping
	const mappingConfig = SalesforceService.getMappingConfig();
	if (mappingConfig) {
		if (mappingConfig?.OpportunityMapping__c) {
			opptyObj = mappingConfig?.OpportunityMapping__c;
		}
		if (mappingConfig?.ExpectedRevenue__c) {
			expectedRevenueField = mappingConfig?.ExpectedRevenue__c;
		}
		if (mappingConfig?.CloseDate__c) {
			closeDateField = mappingConfig?.CloseDate__c;
		}
	}

	queryFilter = queryFilter !== null && queryFilter !== undefined ? 'WHERE ' + queryFilter : '';

	const opptyFields = [
		'Id',
		'Name',
		'Account.Id',
		'Account.Name',
		stageNameField,
		expectedRevenueField,
		closeDateField,
		'Owner.Name',
		'Owner.SmallPhotoUrl',
	];

	const opptyRequirementsFields = [
		'Id',
		'Name',
		ns('CustomerAsk__c'),
		ns('FeatureStatus__c'),
		ns('Priority__c'),
		ns('Requirement__c'),
	];

	const opptyFieldsJoined =
		opptyFields.join(',') +
		`, (SELECT ${opptyRequirementsFields.join(',')} FROM ${ns('Requirements__r')})`;

	const query = `SELECT ${opptyFieldsJoined} FROM ${opptyObj} ${queryFilter}`;
	const records = await ApiGateway.invoke('sf', 'query', {}, { query });
	return new Opportunities({ records });
};

OpportunityApi.getOppDetail = async (
	opptyId,
	includeSalesPlaybook,
	includeRequirement,
	includeAccount,
) => {
	if (!opptyId) return null;
	// get config mapping
	const mappingConfig = SalesforceService.getMappingConfig();

	// get default field mapping
	let opptyObj = DEFAULT_OPP_OBJ;
	let expectedRevenueField = DEFAULT_EXPECTED_REVENUE_FIELD;
	let closeDateField = DEFAULT_CLOSE_DATE_FIELD;
	if (mappingConfig) {
		if (mappingConfig?.OpportunityMapping__c) {
			opptyObj = mappingConfig?.OpportunityMapping__c;
		}
		if (mappingConfig?.ExpectedRevenue__c) {
			expectedRevenueField = mappingConfig?.ExpectedRevenue__c;
		}
		if (mappingConfig?.CloseDate__c) {
			closeDateField = mappingConfig?.CloseDate__c;
		}
	}

	const opptyFields = OPPTY_BASE_FIELDS.concat(ACCOUNT_BASE_FIELDS.map((f) => `Account.${f}`));
	if (!opptyFields.includes(expectedRevenueField)) opptyFields.push(expectedRevenueField);
	if (!opptyFields.includes(closeDateField)) opptyFields.push(closeDateField);

	const opptyRequirementsFields = [
		'Id',
		'Name',
		ns('FeatureStatus__c'),
		ns('CustomerAsk__c'),
		ns('RequirementId__c'),
		ns('Priority__c'),
	];

	const supplementFilesFields = [
		'Id',
		'Name',
		ns('SalesPlayType__c'),
		ns('OpportunityId__c'),
		ns('ContentVersionIds__c'),
		ns('SalesPlaybookId__c'),
	];

	const opptyFieldsJoined =
		opptyFields.join(', ') +
		`, (SELECT ${opptyContactRolesFields.join(', ')} FROM OpportunityContactRoles)` +
		`, (SELECT ${opptyRequirementsFields.join(', ')} FROM ${ns('Requirements__r')})` +
		`, (SELECT ${supplementFilesFields.join(',')} FROM ${ns('SalesPlaySupplementFiles__r')})`;
	const query = `SELECT ${opptyFieldsJoined} FROM ${opptyObj} WHERE Id = '${opptyId}' LIMIT 1`;

	let opptyData, reqData, salesPlaybookData, accountData;
	const promises = [
		ApiGateway.invoke('sf', 'query', {}, { query }).then((data) => (opptyData = data)),
	];
	// get related requirements and related items
	if (includeRequirement)
		promises.push(RequirementService.getRequirements(opptyId).then((data) => (reqData = data)));
	await Promise.all(promises);

	if (includeAccount) {
		const accId = opptyData?.[0]?.AccountId;
		let accounts = await ApiGateway.invoke(
			'sf',
			'query',
			{},
			{
				query: `SELECT Id, Name, (SELECT Id, Name, Title FROM Contacts) FROM Account WHERE Id='${accId}'`,
			},
		);//.then((data) => (accountData = data?.length > 0 ? data[0] : undefined));
		accountData = accounts?.length > 0 ? accounts[0] : undefined;
	}

	if (includeSalesPlaybook && opptyData[0][ns('SalesPlaybook__c')]) {
		// get salesplay data
		salesPlaybookData = await SalesPlaybookService.getSalesPlaybookDetail(
			opptyData[0][ns('SalesPlaybook__c')],
		);
	}

	return new Opportunity(opptyData[0], salesPlaybookData, reqData, accountData);
};

OpportunityApi.getAccountOpportunities = async (accountId) => {
	if (!accountId) return null;

	const query = `SELECT ${OPPTY_BASE_FIELDS.join(',')}, (SELECT ${opptyContactRolesFields.join(', ')} FROM OpportunityContactRoles) FROM Opportunity WHERE AccountId = '${accountId}'`;
	const records = await ApiGateway.invoke('sf', 'query', {}, { query });
	return new Opportunities({ records });
};

export default OpportunityApi;
