import ApiGateway from './ApiGateway';
import {
	FILE_SOURCE_SALESFORCE,
	OBJECT_TYPE_REQUIREMENT,
	OBJECT_TYPE_SOLUTION,
	RFP_PRODUCT_TAG,
} from '../Constants';
import { Requirements, SFDCRequirement } from '../models/Requirement';
import SolutionService from '../services/SolutionService';
import { SFDCRequirementTag } from '../models/RequirementTag';
import moment from 'moment';
import Utils, { ns } from '../utils/Utils';
import FileService from '../services/FileService';

const RequirementApi = {};

RequirementApi.getRequirements = async (opportunityId) => {
	const requirementFields = [
		'Id',
		'Name',
		ns('CustomerAsk__c'),
		ns('FeatureStatus__c'),
		ns('Priority__c'),
		ns('Requirement__c'),
		ns('RequirementId__c'),
	];
	const featureImportFields = ['Name', ns('DocumentId__c'), ns('DocumentVersionId__c')];
	const featureFields = [
		'Id',
		'Name',
		ns('Description__c'),
		ns('DescriptionRichText__c'),
		ns('FeatureName__c'),
		ns('Product__c'),
		ns('FileId__c'),
		ns('ExternalId__c'),
		ns('Video_Links__c'),
		...featureImportFields.map((f) => `${ns('FeatureImport__r')}.${f}`),
	];
	const solutionsFields = [
		'Id',
		'Name',
		ns('RelevanceScore__c'),
		ns('IsFavorite__c'),
		...featureFields.map((f) => `${ns('Feature__r')}.${f}`),
	];
	const requirementTagsFields = [
		'Id',
		'Name',
		ns('RequirementId__c'),
		ns('TagId__c'),
		ns('TagId__r.Name'),
	];

	const solutionsSubQuery = `(SELECT ${solutionsFields.join(', ')} FROM ${ns('Solutions__r')} ORDER BY ${ns('RelevanceScore__c')} DESC)`;

	const requirementTagsSubQuery = `(SELECT ${requirementTagsFields.join(', ')} FROM ${ns('RequirementTags__r')})`;

	const reqFieldsJoined = `${requirementFields.join(', ')}, ${solutionsSubQuery}, ${requirementTagsSubQuery}`;

	const queryStr =
		`SELECT ${reqFieldsJoined} FROM ${ns('Requirement__c')}` +
		` WHERE ${ns('Opportunity__c')} = '${opportunityId}'` +
		` ORDER BY Name ASC`;
	const records = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });

	if (!records || records.length < 0) return new Requirements({ records: [] });

	//Associate file tag with features
	const fileIdSolutionMap = new Map();
	const fileIdSet = new Set();
	records.forEach((req) =>
		req?.[ns('Solutions__r')]?.records?.forEach?.((sol) => {
			const feature = sol?.[ns('Feature__r')];
			if (!feature) return;

			const fileId = feature[ns('FileId__c')];
			fileIdSet.add(fileId);

			const tempList = fileIdSolutionMap.get(fileId) || [];
			fileIdSolutionMap.set(fileId, [...tempList, sol.Id]);
		}),
	);

	if (fileIdSet.size < 1) {
		// no tags, just need to reformat the solution model
		records.forEach((req) => {
			const solutions = req?.[ns('Solutions__r')];
			if (solutions) solutions.records = [solutions.records, []];
		});
		return new Requirements({ records });
	}

	// get the tags and add back to solution
	const fileTagFields = ['Id', ns('FileId__c'), ns('TagId__r.Name')];
	const fileIdsJoined = '(' + Utils.convertToWhereCondition(Array.from(fileIdSet)) + ')';
	const fileTagQueryStr =
		`SELECT ${fileTagFields.join(', ')} FROM ${ns('FileTag__c')}` +
		` WHERE ${ns('FileId__c')} IN ${fileIdsJoined}`;
	const fileTagsData = await ApiGateway.invoke('sf', 'query', {}, { query: fileTagQueryStr });

	const solutionFileTagMap = new Map();
	fileTagsData.forEach((fileTag) => {
		fileIdSolutionMap.get(fileTag[ns('FileId__c')])?.forEach?.((solId) => {
			const tagName = fileTag[ns('TagId__r')].Name;
			const tempTags = solutionFileTagMap.get(solId) || [];
			solutionFileTagMap.set(solId, [...tempTags, tagName]);
		});
	});

	// add the tag parameter back to solutions
	records.forEach((req) => {
		const solutions = req[ns('Solutions__r')];
		if (!solutions?.records) return;

		// we are assuming the solutions are ranked by relevancy, need to double check
		const nonRfpList = [],
			rfpList = [];
		solutions.records.forEach((sol) => {
			const fileTags = solutionFileTagMap.get(sol.Id);
			if (!fileTags) {
				// no tag, add to nonRpfList
				// only push if less than 3
				if (nonRfpList.length < 3) nonRfpList.push(sol);
				return;
			}

			// add tags
			sol.FileTags = solutionFileTagMap.get(sol.Id);
			const matchIdx = sol.FileTags.findIndex((tag) => tag.toLowerCase() === RFP_PRODUCT_TAG);
			if (matchIdx === -1) {
				// only push if less than 3
				if (nonRfpList.length < 3) nonRfpList.push(sol);
				return;
			}

			// add contains rfp flag
			req.HasRFP = true;

			// reorganize solutions
			rfpList.push(sol);

			// check if multiple tags
			// only push if less than 3
			if (sol.FileTags.length > 1 && nonRfpList.length < 3) nonRfpList.push(sol);
		});
		solutions.records = [nonRfpList, rfpList];
	});

	return new Requirements({ records });
};

RequirementApi.createRequirement = async (newReq, selectedTags) => {
	const records = [];
	let opptyId = newReq.Opportunity__c;
	if (newReq.length > 0) {
		opptyId = newReq[0][ns('Opportunity__c')];

		// update list
		newReq.forEach((req) => {
			// remove id as needed
			delete req.id;
			records.push(req);
		});
	} else records.push(new SFDCRequirement(newReq));

	const resultData = await ApiGateway.invoke(
		'sf',
		'create',
		{},
		{},
		JSON.stringify({
			objectName: ns('Requirement__c'),
			records,
		}),
	);

	// create junction objects for tags if needed
	if (selectedTags?.length > 0) {
		let newReqTags = [];
		resultData.forEach((req) => {
			if (!req.success) return;
			newReqTags = selectedTags.map(
				(tag) => new SFDCRequirementTag({ RequirementId__c: req.id, TagId__c: tag.Id }),
			);
		});
		await ApiGateway.invoke(
			'sf',
			'create',
			{},
			{},
			JSON.stringify({
				objectName: ns('RequirementTag__c'),
				records: newReqTags,
			}),
		);
	}

	// look for solutions after requirement is created
	try {
		await SolutionService.findSolution(opptyId);
	} catch (err) {
		// roll back changes
		await ApiGateway.invoke(
			'sf',
			'delete',
			{},
			{},
			JSON.stringify({
				objectType: ns(OBJECT_TYPE_SOLUTION),
				recordIds: resultData.map((item) => item.id),
			}),
		);

		// send error
		throw new Error(
			'Import is taking too long, please split up the upload file and try again.',
		);
	}

	return resultData;
};

RequirementApi.updateRequirement = async (newReq) => {
	// remove name as it's not updatable
	delete newReq.Name;
	delete newReq.id;

	// send the rest out for update
	const resultData = await ApiGateway.invoke(
		'sf',
		'update',
		{},
		{},
		JSON.stringify({
			objectName: ns('Requirement__c'),
			records: [new SFDCRequirement(newReq)],
		}),
	);

	// look for solutions after requirement is created
	await SolutionService.findSolution(newReq.Opportunity__c);

	return resultData;
};

RequirementApi.deleteRequirement = async (deleteList) =>
	await ApiGateway.invoke(
		'sf',
		'delete',
		{},
		{},
		JSON.stringify({
			objectType: ns(OBJECT_TYPE_REQUIREMENT),
			recordIds: deleteList || [],
		}),
	);

RequirementApi.queryCluster = async (startDate, endDate, status, accuracy) =>
	await ApiGateway.invoke(
		'req',
		'clusterquery',
		{},
		{},
		JSON.stringify({
			startDate: startDate ? startDate : '07-March-2023', //moment().subtract(90, 'days').format("DD-MMM-YYYY"),
			endDate: endDate ? endDate : moment().format('DD-MMM-YYYY'),
			accuracy: accuracy ? accuracy : 90,
			//"status" : status ? status : "Gap"
		}),
	);

RequirementApi.getRelatedMaterials = async (featureList) => {
	// check if similar results come back with items
	if (featureList?.length < 1) return [];

	const fileInfoPendingDocIds = new Set();

	featureList.forEach((feature) => {
		if (feature.source !== FILE_SOURCE_SALESFORCE) return;

		if (!feature.fileLink) return;

		let contentDocId = feature.fileLink?.split?.('/');
		if (contentDocId?.length < 2) return;

		contentDocId = contentDocId[contentDocId.length - 1];
		fileInfoPendingDocIds.add(contentDocId);
	});

	const fileInfoList = await Promise.all(
		Array.from(fileInfoPendingDocIds).map((id) => FileService.getFileInfo(id)),
	);
	const fileInfoMap = new Map();
	fileInfoList.map((fileInfo) => {
		if (fileInfo) {
			fileInfoMap.set(fileInfo.Id, fileInfo);
		}
		return fileInfo;
	});

	const tempMaterialsList = [];
	const contentDocIdMap = new Map();
	for (let i = 0; i < featureList.length; i++) {
		if (!featureList[i].fileLink) continue;

		if (featureList[i].source === FILE_SOURCE_SALESFORCE) {
			// parse content document id, if id is not there we don't process
			let contentDocId = featureList[i].fileLink?.split('/');
			if (contentDocId.length < 2) continue;

			contentDocId = contentDocId[contentDocId.length - 1];
			if (contentDocIdMap.has(contentDocId)) continue;

			// fetch file name
			const fileInfo = fileInfoMap.get(contentDocId);
			featureList[i].fileName = fileInfo?.Title;
			contentDocIdMap.set(contentDocId, fileInfo?.Title);

			// add as an item
			tempMaterialsList.push(featureList[i]);
		} else if (!contentDocIdMap.has(featureList[i].fileLink)) {
			// assuming sharepoint and mindtickle search results are the same here
			contentDocIdMap.set(featureList[i].fileLink, featureList[i].fileName);

			// add as an item
			tempMaterialsList.push(featureList[i]);
		}
	}
	return tempMaterialsList?.slice(0, 5);
};

export default RequirementApi;
