import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Avatar, Box, Chip, Grid, LinearProgress, Radio } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import ConfigureDocumentService from '../../../services/ConfigureDocumentService';
import SalesforceService from '../../../services/SalesforceService';

import Utils from '../../../utils/Utils';

import { QKEY_FWAVE_FILES } from '../../../utils/QueryKeys';

const LASTMOD_DATE_WIDTH = 190;
const LASTMODBY_CHIP_WIDTH = 35;
const LASTMODBY_MULTIPLIER = 8;
const LASTMODBY_DEFAULT_CHAR = 10;
const LASTMOD_DEFAULT_WIDTH = LASTMODBY_CHIP_WIDTH + LASTMODBY_DEFAULT_CHAR * LASTMODBY_MULTIPLIER;

const FILES_DEFAULT_SORTMODEL = [{ field: 'LastModifiedDate', sort: 'desc' }];

/**
 * fileType = User Guides | Supporting Documents
 * recordSelection = Single | Multiple
 * */
const FileListCmp = forwardRef(
	(
		{
			fileType,
			recordSelection,
			onSelectionChange,
			isContentDocumentsOnly = false,
			onFilesLoad,
		},
		ref,
	) => {
		const instanceUrl = SalesforceService.getInstanceUrl();
		const { recordId } = useParams();
		const [pageSize, setPageSize] = useState(10);
		const [selectionModel, setSelectionModel] = useState([]);
		const [fileId, setFileId] = useState();
		const [lastModifiedByWidth, setLastModifiedByWidth] = useState(LASTMOD_DEFAULT_WIDTH);
		const [sortModel, setSortModel] = useState(FILES_DEFAULT_SORTMODEL);

		const [columns, setColumns] = useState([]);

		//To initialize the sort model, if directly added in useState it won't be reflected in the data grid.
		useEffect(() => {
			setTimeout(() => setSortModel(FILES_DEFAULT_SORTMODEL), 100);
		}, []);

		useEffect(() => {
			const _columns = [
				//Col - Title
				{
					field: 'Title',
					headerName: 'File Name',
					flex: 1,
					renderCell: (params) => (
						<div>
							{!params.row.Source ||
							params.row.Source === '' ||
							params.row.Source === 'Salesforce' ? (
								<a
									href={`${instanceUrl}/${params.row.ContentDocumentId}`}
									target="_blank"
									rel="noreferrer"
								>
									{params.row.Title || ''}
								</a>
							) : (
								<a
									href={
										params.row.fileUrl
											? params.row.fileUrl
											: params.row.fileLink ||
												`${instanceUrl}/${params.row.Id}`
									}
									target="_blank"
									rel="noreferrer"
								>
									{params.row.Title || ''}
								</a>
							)}
						</div>
					),
					// valueGetter: (params) => {
					//   return `${params.row.Title || ''}`;
					// }
				},

				//Col - FileType
				{
					field: 'FileType',
					headerName: 'File Type',
					width: 230,
					valueGetter: (value, row) => `${row.FileType || 'Salesforce File'}`,
				},

				//Col - Source
				{
					field: 'Source',
					headerName: 'Source',
					width: 100,
					valueGetter: (value, row) => `${row.Source || 'Salesforce'}`,
				},

				//Col - Status
				{
					field: 'Status',
					headerName: 'Status',
					width: 100,
					valueGetter: (value, row) => `${row.Status || 'Uploaded'}`,
				},

				// {
				// 	field: 'VersionNumber',
				// 	headerName: 'Version Number',
				// 	width: 125,
				// },

				//Col - ContentSize
				{
					field: 'ContentSize',
					headerName: 'File Size',
					width: 125,
					valueGetter: (value, row) =>
						row.ContentSize ? Utils.formatBytes(row.ContentSize) : '-',
				},

				//Col - LastModifiedById
				{
					field: 'LastModifiedById',
					headerName: 'Last Modified By',
					width: lastModifiedByWidth,
					renderCell: (params) => (
						<Grid container direction={'row'} spacing={1}>
							<Grid item>
								{/* <Avatar sx={{ width: 40, height: 40 }} alt="Remy Sharp" src={params.row.LastModifiedBy.FullPhotoUrl} />  */}
								<Chip
									size="medium"
									avatar={
										<Avatar
											sx={{ width: 60, height: 60 }}
											alt={params.row.LastModifiedBy.Name}
											src={params.row.LastModifiedBy.FullPhotoUrl}
										/>
									}
									label={
										<span
											title={params.row.LastModifiedBy.Name}
											style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
										>
											{params.row.LastModifiedBy.Name}
										</span>
									}
								/>
							</Grid>
						</Grid>
					),
					// valueGetter: (params) => {
					//   return params.row.LastModifiedDate ? new Date(params.row.LastModifiedDate).toLocaleString() : '';
					// }
				},

				//Col - LastModifiedDate
				{
					field: 'LastModifiedDate',
					headerName: 'Last Modified Date',
					width: LASTMOD_DATE_WIDTH,
					renderCell: (params) => {
						const dateStr = new Date(params.row.LastModifiedDate).toLocaleString();

						return (
							<div title={dateStr} style={{ marginTop: '5px' }}>
								{dateStr}
							</div>
						);
					},
				},
			];

			//Col - radiobutton
			if (recordSelection === 'Single')
				_columns.unshift({
					field: 'radiobutton',
					headerName: '',
					width: 60,
					sortable: false,
					renderCell: (params) => (
						<Radio
							checked={params.id === fileId}
							value={params.id}
							onChange={(event) => {
								if (event.target.checked) {
									setFileId(params.id);
									onSelectionChange([params.id], [params.row]);
								}
							}}
						/>
					),
				});

			setColumns(_columns);
		}, [recordSelection, fileId, onSelectionChange, lastModifiedByWidth, instanceUrl]);

		const {
			data: files = [],
			isFetching: isLoading,
			refetch,
		} = useQuery({
			queryKey: [QKEY_FWAVE_FILES, recordId, { isContentDocumentsOnly }],
			queryFn: async () =>
				await ConfigureDocumentService.getFiles({ recordId, isContentDocumentsOnly }),
			refetchOnMount: true,
			staleTime: 1000,
		});

		useEffect(() => {
			if (isLoading) return;
			onFilesLoad?.(files);
		}, [onFilesLoad, files, isLoading]);

		useEffect(() => {
			if (isLoading || !Array.isArray(files)) return;

			const maxChars = files.reduce((prevMaxChars, file) => {
				const length = (file?.LastModifiedBy?.Name || '').length;
				return Math.max(prevMaxChars, length);
			}, LASTMODBY_DEFAULT_CHAR);
			setLastModifiedByWidth(LASTMODBY_CHIP_WIDTH + maxChars * LASTMODBY_MULTIPLIER);

			//let _fileList = _result.files || [];
			// let _fileImportCountList = _result.fileImportCountMap;
			// let _fileImportCountMap = {};
			// if (_fileImportCountList) {
			//   for (let fileImportCount of _fileImportCountList) {
			//     _fileImportCountMap[fileImportCount.documentVersionId] = fileImportCount.featureImportsCount;
			//   }
			// }
		}, [files, isLoading, sortModel]);

		useImperativeHandle(ref, () => ({
			refreshTable() {
				refetch();
				setFileId(undefined);
			},
		}));

		return (
			<div style={{ height: '100%', width: '100%' }}>
				<DataGrid
					sortModel={sortModel}
					onSortModelChange={(newSortModel, e) => setSortModel(newSortModel)}
					columns={columns}
					rows={files}
					autoHeight={pageSize === 10}
					rowSelectionModel={selectionModel}
					disableSelectionOnClick={false}
					checkboxSelection={recordSelection === 'Multiple'}
					onRowSelectionModelChange={(selectedIds) => {
						const selectedItems = [];
						setSelectionModel(selectedIds);
						if (selectedIds && selectedIds.length > 0) {
							setFileId(selectedIds[0]);
							selectedIds.forEach((fileId) => {
								const fileObj = files.find((file) => file.Id === fileId);
								selectedItems.push(fileObj);
							});
							onSelectionChange?.(selectedIds, selectedItems);
						} else onSelectionChange?.([], []);
					}}
					slots={{
						noRowsOverlay: CustomNoRowsOverlay,
						loadingOverlay: LinearProgress,
					}}
					// checkboxSelection={true}
					density="compact"
					getRowId={(row) => row.Id}
					loading={isLoading}
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[10, 20, 50]}
					pagination
					disableColumnMenu={false}
				/>
			</div>
		);
	},
);
FileListCmp.displayName = 'FileListCmp';
export default FileListCmp;

const StyledGridOverlay = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	'& .ant-empty-img-1': {
		fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
	},
	'& .ant-empty-img-2': {
		fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
	},
	'& .ant-empty-img-3': {
		fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
	},
	'& .ant-empty-img-4': {
		fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
	},
	'& .ant-empty-img-5': {
		fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
		fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
	},
}));

function CustomNoRowsOverlay() {
	return (
		<StyledGridOverlay>
			<svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
				<g fill="none" fillRule="evenodd">
					<g transform="translate(24 31.67)">
						<ellipse
							className="ant-empty-img-5"
							cx="67.797"
							cy="106.89"
							rx="67.797"
							ry="12.668"
						/>
						<path
							className="ant-empty-img-1"
							d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
						/>
						<path
							className="ant-empty-img-2"
							d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
						/>
						<path
							className="ant-empty-img-3"
							d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
						/>
					</g>
					<path
						className="ant-empty-img-3"
						d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
					/>
					<g className="ant-empty-img-4" transform="translate(149.65 15.383)">
						<ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
						<path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
					</g>
				</g>
			</svg>
			<Box sx={{ mt: 1 }}>No Rows</Box>
		</StyledGridOverlay>
	);
}
