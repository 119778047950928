import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { ClickAwayListener, Fab, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react';

const FWButton = styled((props) => (
	<Button
		{...props}
		variant="outlined"
		sx={{
			textTransform: 'none',
			border: props.buttonColor ? '1px solid ' + props.buttonColor : '1px solid #0B5FDE',
			borderRadius: '100px',
			fontWeight: '600',
			fontSize: '14px',
			color: props.buttonColor ? props.buttonColor : '#0B5FDE',
			letterSpacing: '0.46px',
			'&:hover': {
				background: props.buttonBackground
					? props.buttonBackground
					: 'linear-gradient(100.05deg, #0FA3E2 0%, #0BDE79 100%)',
				color: '#FFFFFF',
			},
		}}
	>
		{props.children}
	</Button>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const GradientText = styled('div')(({ theme }) => ({
	':hover': {
		background: 'linear-gradient(100.05deg, #0FA3E2 0%, #0BDE79 100%)',
		backgroundClip: 'text',
		color: 'transparent',
		transition: 'color .2s ease-in-out',
	},
}));

const FWTextButton = styled((props) => (
	<Button
		{...props}
		variant="text"
		sx={{
			textTransform: 'none',
			fontWeight: '600',
			fontSize: '14px',
			color: 'rgb(81, 105, 119)',
			letterSpacing: '0.46px',
		}}
	>
		<GradientText>{props.children}</GradientText>
	</Button>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWButtonCard = styled((props) => (
	<Box
		padding={1}
		sx={{
			textTransform: 'none',
			borderRadius: '25px',
			border: props.color ? '1px solid ' + props.color : '1px solid #0B5FDE',
			fontWeight: '600',
			fontSize: '14px',
			color: props.color ? props.color : '#0B5FDE',
			background: props.background ? props.background : 'white',
			letterSpacing: '0.46px',
			cursor: 'default',
			pointerEvents: 'none',
		}}
	>
		{props.children}
	</Box>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWLinkButton = styled((props) => (
	<Button
		endIcon={props.endIcon || <ArrowForwardOutlinedIcon sx={{ color: '#c5cdd2' }} />}
		{...props}
		variant="contained"
		sx={{
			textTransform: 'none',
			border: 'none',
			fontWeight: '600',
			fontSize: '14px',
			color: '#387ce3',
			letterSpacing: '0.46px',
			background: '#f8f8f8',
			boxShadow: 'none',
			'&:hover': {
				background: '#d9d9d9',
				boxShadow: 'none',
			},
		}}
	>
		{props.children}
	</Button>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWIconButton = styled((props) => (
	<IconButton
		{...props}
		sx={{
			'&.MuiButtonBase-root:hover': {
				backgroundColor: 'transparent',
			},
		}}
	>
		{React.cloneElement(props.children, {
			sx: {
				color: '#2220A7',
				...(props.sx || {}),
			},
		})}
	</IconButton>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWGradiantButton = styled((props) => (
	<Button
		{...props}
		variant="contained"
		sx={{
			textTransform: 'none',
			fontWeight: '700',
			fontSize: '14px',
			borderRadius: '100px',
			background:
				props.disabled === true
					? 'lightgrey'
					: 'linear-gradient(100.05deg, #0FA3E2 0%, #0BDE79 100%)',
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.2)',
			},
		}}
	>
		{props.children}
	</Button>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWFabButton = styled((props) => (
	<Fab
		{...props}
		sx={{
			background: 'linear-gradient(100.05deg, #0FA3E2 0%, #0BDE79 100%)',
			color: 'white',
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.2)',
			},
		}}
	>
		{props.children}
	</Fab>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

const FWRefreshButton = styled((props) => (
	<ClickAwayListener onClickAway={props.closeHandler}>
		<div>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={props.closeHandler}
				open={props.open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				placement={props.placement}
				title={props.title}
			>
				<IconButton onClick={props.openHandler}>
					<RefreshOutlinedIcon />
				</IconButton>
			</Tooltip>
		</div>
	</ClickAwayListener>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));

export {
	FWButton,
	FWTextButton,
	FWButtonCard,
	FWIconButton,
	FWGradiantButton,
	FWLinkButton,
	FWFabButton,
	FWRefreshButton,
};
