import ApiGateway from '../../apis/ApiGateway';
import Utils from '../../utils/Utils';
import SalesforceService from '../SalesforceService';
import axios from 'axios';

const FileService = {};

FileService.uploadFile = (fileObject, fileName, fileTitle, fileType, uploadId) => {
	return new Promise((resolve, reject) => {
		var formdata = new FormData();
		formdata.append('file1', fileObject, fileName);
		//TODO : Add FileType here like User Guide or Supporting Document
		// ConfigureDocumentService.uploadFile(formdata).then((result)=>{
		//   console.log({result})
		// })
		let token = SalesforceService.getSessionId();
		const defaultHeaders = {
			token: token,
			'instance-url': SalesforceService.getInstanceUrl(),
			'org-id': SalesforceService.getSfOrgId(),
			'Access-Control-Allow-Origin': '*',
		};
		defaultHeaders['fileType'] = fileType;
		defaultHeaders['uploadId'] = uploadId;
		defaultHeaders['fileTitle'] = fileTitle;
		const axiosInstance = axios.create({
			baseURL: Utils.isLocalhost() ? '' : ApiGateway.getApiEndPoint(),
		});
		let fileEndPoint = Utils.isLocalhost() ? '/api/uploadfiles' : '/sf/uploadfiles';

		axiosInstance
			.post(fileEndPoint, formdata, {
				headers: defaultHeaders,
				onUploadProgress: (data) => {
					//Set the progress value to show the progress bar
					//let percent = Math.round((100 * data.loaded) / data.total);
					//setProgress(percent)
				},
				onDownloadProgress: (progressEvent) => {
					// const total = parseFloat(progressEvent.currentTarget?.responseHeaders['Content-Length'])
					// const current = progressEvent.currentTarget.response.length
					// let percentCompleted = Math.floor(current / total * 100)
					// console.log({percentCompleted})
					// setProgress(percentCompleted)
					// if(percentCompleted===100) setUploadingStatus("Uploading");
				},
				//cancelToken: CancelTokenObj.token,
			})
			.then((response) => {
				let fileResults = response.data;
				let fileResult = fileResults && fileResults.length > 0 ? fileResults[0] : undefined;

				resolve({
					fileId: fileResult.fileId,
					featurewaveFileId: fileResult.featurewaveFileId,
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default FileService;
