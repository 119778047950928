import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import {
	Autocomplete,
	CircularProgress,
	//createFilterOptions,
	IconButton,
	TextField,
} from '@mui/material';
import { Stack } from '@mui/system';

import ConfigureDocumentService from '../../services/ConfigureDocumentService';

//const filter = createFilterOptions();

const FWAutocomplete = forwardRef(
	(
		{
			existingVal,
			recordIds,
			dataPromise,
			options,
			allowToAddNewValues = true,
			label = 'Products / Tags',
			saveFunction,
			provideSaveButton,
			onChangeHandler,
			disableAdd,
			multiple = false,
			onValueChange,
			width,
			disabledValues,
			textVariant,
		},
		ref,
	) => {
		const [dataOptions, setDataOptions] = useState([]);
		const [loading, setLoading] = useState(true);
		const [value, setValue] = useState(existingVal);
		const [oldValue, setOldValue] = useState(undefined);
		const [saveStatus, setSaveStatus] = useState('None'); //InProgress, Completed

		useImperativeHandle(ref, () => ({
			reset() {
				setValue(undefined);
				onValueChange(undefined);
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				});
			},
		}));

		useEffect(() => {
			if (dataPromise?.then) {
				setLoading(true);
				dataPromise.then((_options) => {
					setDataOptions(_options);
					if (existingVal) {
						let _valueLabels = [];
						for (let existingValItem of existingVal) {
							_valueLabels.push(existingValItem.text);
						}
						setValue(existingVal);
					}
					setLoading(false);
				});
			} else if (options?.length > 0) {
				setDataOptions(options);
				setLoading(false);
			}
		}, [dataPromise, options]);

		const saveTags = () => {
			setSaveStatus('InProgress');
			saveFunction(recordIds, value).then(() => {
				setSaveStatus('Completed');
			});
		};

		return (
			<div>
				{!loading ? (
					<div style={{ width: width ? width : '100%' }}>
						<Stack direction="row">
							<Autocomplete
								value={value}
								isOptionEqualToValue={(option, value) =>
									option?.text === value?.text
								}
								multiple={multiple}
								id="tags-standard"
								options={dataOptions}
								getOptionLabel={(option) => option?.text}
								//defaultValue={[top100Films[1]]}
								style={{ width: '100%  ' }}
								renderInput={(params) => (
									<TextField
										{...params}
										//variant={'standard'}
										//hiddenLabel = {textVariant === "filled"}
										label={label}
										size="small"
										placeholder="Search"
										sx={{ width: '100%' }}
									/>
								)}
								getOptionDisabled={(option) =>
									(disabledValues || []).includes(option.Id)
								}
								onChange={async (event, newValue) => {
									let newValArr = [];
									setOldValue(value);
									setValue(undefined);
									setSaveStatus('Pending');
									if (!multiple) {
										if (newValue) newValue = [newValue];
										else newValue = [];
									}
									for (let item of newValue) {
										if (item.text?.startsWith('Add ')) {
											item.text = item.text
												.substring(4)
												.replaceAll('"', '')
												.replaceAll('"', '');
											let saveResults =
												await ConfigureDocumentService.saveOrUpdateTags([
													{ name: item.text },
												]);

											let _dataOptions = dataOptions;
											for (let saveResult of saveResults) {
												_dataOptions.push({
													text: item.text,
													Id: saveResult.id,
												});
												item.Id = saveResult.id;
											}
											setDataOptions(_dataOptions);
											// .then((saveResults)=>{
											//     let _dataOptions = dataOptions;
											//     for(let saveResult of saveResults){
											//         _dataOptions.push({
											//             label : item.label,
											//             Id : saveResult.id
											//         });
											//     }
											//     setDataOptions(_dataOptions);
											// })
										}
										// if (typeof item === 'string') {
										//     item = {label : item};
										// }
										newValArr.push(item);
									}
									setOldValue(value);
									setValue(newValArr);
									if (onValueChange) {
										onValueChange(newValArr, oldValue);
									}
									if (onChangeHandler) {
										onChangeHandler(newValArr, oldValue);
									}
								}}
								// filterOptions={(options, params) => {
								// 	const filtered = filter(options, params);
								// 	const { inputValue } = params;
								// 	// Suggest the creation of a new value
								// 	const isExisting = options.some(
								// 		(option) => inputValue === option.text,
								// 	);
								// 	if (inputValue !== '' && !isExisting) {
								// 		if (allowToAddNewValues)
								// 			filtered.push({
								// 				text: `Add "${inputValue}"`,
								// 			});
								// 	}
								// 	return filtered;
								// }}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								renderOption={(props, option) => <li {...props}>{option.text}</li>}
								sx={{ width: 300 }}
							/>
							{provideSaveButton ? (
								<div>
									<Stack>
										{saveStatus === 'Pending' && (
											<div style={{ paddingTop: '0px' }}>
												<IconButton
													disabled={disableAdd}
													aria-label="save"
													size="large"
													onClick={saveTags}
												>
													<CheckCircleOutline
														color="primary"
														fontSize="inherit"
													/>
												</IconButton>
											</div>
										)}

										{saveStatus === 'InProgress' && (
											<div style={{ paddingTop: '10px' }}>
												<CircularProgress size={20} color="success" />
											</div>
										)}
										{saveStatus === 'Completed' && (
											<div style={{ paddingTop: '10px' }}>
												<CheckCircle color="success" />
											</div>
										)}
									</Stack>
								</div>
							) : (
								<div></div>
							)}
						</Stack>{' '}
					</div>
				) : (
					<div>
						<TextField
							id="outlined-basic"
							label={label}
							value={'Loading...'}
							disabled
							variant="outlined"
						/>
					</div>
				)}
			</div>
		);
	},
);
FWAutocomplete.displayName = 'FWAutocomplete';
export default FWAutocomplete;
