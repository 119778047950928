import AnthropicService from '../services/AnthropicService';
import OpenAIService from '../services/OpenAIService';
import { OpenAI } from 'openai-streams';

const OpenAISearchApi = {};

OpenAISearchApi.getSearchResults = async (searchText, prevResponse, flow) => {
	var response = [];
	let promptPrefix = OpenAIService.getPromptPreix(flow);
	var prevQuestion = prevResponse ? [promptPrefix].concat(prevResponse) : [promptPrefix];
	prevQuestion.push({ role: 'user', content: searchText });

	// call api
	if (searchText) {
		try {
			var model = OpenAIService.getModel(prevQuestion);
			if (model) {
				const stream = await OpenAI(
					'chat',
					{
						model: model,
						top_p: 0.5, // previous value: 1
						temperature: 0.5, // previous value: did not exist
						stop: null,
						messages: prevQuestion,
					},
					{ apiKey: OpenAIService.getApiKey() },
				);
				return stream;
				//throw new Error('testing anthropic backup...');
			} else {
				return null;
			}
		} catch (error) {
			console.error('An error occurred during OpenAI request', error);
			console.error('Calling secondary api instead');
			return await AnthropicService.getSearchResults(searchText, prevResponse, flow);
		}
	}
	return response;
};

OpenAISearchApi.getSearchResultsNew = async (prompts, prevPrompts) => {
	var response = [];
	let inputPrompts = [];
	inputPrompts = prevPrompts ? [].concat(prevPrompts) : [];
	if (prompts && prompts.length > 0) {
		for (let prompt of prompts) {
			inputPrompts.push(prompt);
		}
	}

	// call api
	if (inputPrompts && inputPrompts.length > 0) {
		try {
			var model = OpenAIService.getModel(inputPrompts);
			if (model) {
				const stream = await OpenAI(
					'chat',
					{
						model: model,
						top_p: 1,
						stop: null,
						messages: inputPrompts,
					},
					{ apiKey: OpenAIService.getApiKey() },
				);
				return stream;
			} else {
				return null;
			}
		} catch (error) {
			console.error('An error occurred during OpenAI request', error);
		}
	}
	return response;
};

OpenAISearchApi.complete = async (prompts, config) => {
	var response = [];
	let configTopP = config.topP || 0.5;
	let configTemperature = config.temperature || 0.5;
	let configStop = config.stop || null;
	let configModel = config.model || OpenAIService.getModel(prompts);

	if (prompts && prompts.length > 0) {
		try {
			var model = OpenAIService.getModel(prompts);
			if (model) {
				let stream;
				try {
					stream = await OpenAI(
						'chat',
						{
							model: configModel,
							top_p: configTopP, // previous value: 1
							temperature: configTemperature, // previous value: did not exist
							stop: configStop,
							messages: prompts,
						},
						{ apiKey: OpenAIService.getApiKey() },
					);
				} catch (e) {
					console.error('Error in OpenAI chat request', e);
				}
				return stream;
			} else {
				return null;
			}
		} catch (error) {
			console.error('An error occurred during OpenAI request. ', error);
		}
	}
	return response;
};

export default OpenAISearchApi;
