import { NAMESPACE_PREFIX } from '../Constants';

export class SalesPlaySupplementFiles {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new SalesPlaySupplementFile(item));
		}
	}
}

export class SalesPlaySupplementFile {
	constructor(data) {
		try {
			this.Id = data?.Id;
			this.Name = data?.Name;
			this.SalesPlayType__c = data[NAMESPACE_PREFIX + 'SalesPlayType__c'];
			this.OpportunityId__c = data[NAMESPACE_PREFIX + 'OpportunityId__c'];
			this.SalesPlaybookId__c = data[NAMESPACE_PREFIX + 'SalesPlaybookId__c'];
			this.ContentVersionIds__c = data[NAMESPACE_PREFIX + 'ContentVersionIds__c']
				? JSON.parse(data[NAMESPACE_PREFIX + 'ContentVersionIds__c'])
				: null;
		} catch (err) {
			console.error('Error parsing SalesPlaySupplementFile. ', err);
		}
	}
}
