import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Box, Grid } from '@mui/material';

import NoSearchResultCmp from '../common/NoSearchResultCmp';
import SmallLoadingCmp from '../SmallLoadingCmp';

import GPTSummaryService from '../../services/GPTSummaryService';

import { QKEY_GPT_SUMMARY } from '../../utils/QueryKeys';

function AccountSummary({
	accountId,
	showAccountSummary,
	updateAcctSummaryRefreshTime,
	showEmptyScreen,
}) {
	const [chatGPTSearchResult, setChatGPTSearchResult] = useState(null);

	const { data: cachedAccountSummary, isLoading: loadingChatGPT } = useQuery({
		enabled: !!accountId,
		queryKey: [QKEY_GPT_SUMMARY, accountId],
		queryFn: async () => await GPTSummaryService.getGPTSummary({ summaryId: accountId }),
	});

	useEffect(() => {
		if (loadingChatGPT) return;

		// use this logic for env with updated package to allow gpt summary records
		if (!cachedAccountSummary?.GPTSummary__c) {
			// hide account summary
			showAccountSummary?.(false);
			return;
		}

		let tempAccountSummary = JSON.parse(cachedAccountSummary?.GPTSummary__c);
		if (!tempAccountSummary?.content) {
			// hide account summary
			showAccountSummary?.(false);
			return;
		}
		tempAccountSummary = tempAccountSummary?.content[0].text;

		// show account summary
		showAccountSummary?.(true);

		// load cached account summary
		setChatGPTSearchResult({
			data: tempAccountSummary,
			isDone: true,
		});

		// update refresh date
		if (cachedAccountSummary?.GPTSummaryModifiedDate__c) {
			// temporaily remove due to infinite loop issue
			const refreshDate = new Date(cachedAccountSummary.GPTSummaryModifiedDate__c);
			updateAcctSummaryRefreshTime?.(refreshDate);
		}
	}, [loadingChatGPT, cachedAccountSummary, showAccountSummary, updateAcctSummaryRefreshTime]);

	if (loadingChatGPT) return <SmallLoadingCmp />;

	return (
		<Grid container direction="column" alignItems="center">
			<Grid item>
				{chatGPTSearchResult?.data ? (
					<Markdown remarkPlugins={[remarkGfm]}>{chatGPTSearchResult?.data}</Markdown>
				) : (
					showEmptyScreen && (
						<Box>
							<NoSearchResultCmp msg="No Account Summary Found..." />
						</Box>
					)
				)}
			</Grid>
		</Grid>
	);
}

export default AccountSummary;
