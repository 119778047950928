import SearchApi from '../apis/SearchApi';
import CustomSettingApi from '../apis/CustomSettingApi';
import { GEN_FLOW_SUMMARIZE_SEARCH_RESULT, OBJECT_TYPE_GPT_SUMMARY } from '../Constants';
import AnthropicService from './AnthropicService';
import { GPTSummarySO } from '../models/GPTSummary';
import SalesforceCommonApi from '../apis/SalesforceCommonApi';

const SearchService = {};

SearchService.getSearchResults = async (
	searchText,
	numOfResults,
	skipRerank,
	saveSearch,
	excludeIdsList,
) => {
	return await SearchApi.getSearchResults(
		searchText,
		numOfResults,
		skipRerank,
		saveSearch,
		excludeIdsList,
	);
};

SearchService.getHtmlResults = async (externalId, range) => {
	return await SearchApi.getHtmlResult(externalId, range);
};

SearchService.summarizeSearchResult = async (searchResult, recordId, flow) => {
	let gptPrompt = await CustomSettingApi.getGPTPrompt({
		promptConfigName: flow || GEN_FLOW_SUMMARIZE_SEARCH_RESULT,
	});
	let _prompts = [];
	for (let prompt of gptPrompt.prompts || []) {
		_prompts.push({
			role: prompt.role,
			content: prompt.content?.replaceAll('{!content}', searchResult),
		});
	}
	let _gptSummary = await AnthropicService.generate(_prompts, []);
	let outputMsgs = [];
	if (_gptSummary) {
		for (let content of _gptSummary.content || []) {
			outputMsgs.push(content.text);
		}
	}
	let finalSummary = outputMsgs.join(',');
	let gptSumarySORecord = new GPTSummarySO({
		gptSummary: finalSummary,
		recordSummary: JSON.stringify(_prompts),
		recordId: recordId,
	});
	await SalesforceCommonApi.createRecord(gptSumarySORecord, OBJECT_TYPE_GPT_SUMMARY);

	// let outputMsgs = [];
	// if (_genResult) {
	// 	let contents = _genResult.content || [];
	// 	for (let content of contents) {
	// 		outputMsgs.push(content.text);
	// 	}
	// }
	// let finalSummary = outputMsgs.join(',');

	return finalSummary;
};

SearchService.getSearchHistory = async (ownerId) => {
	return await SearchApi.getSearchHistory(ownerId);
};

SearchService.createSearchHistory = async (searchResult) => {
	return await SearchApi.createSearchHistory(searchResult);
};

SearchService.clearSearchHistory = async () => {
	return await SearchApi.clearSearchHistory();
};

export default SearchService;
