import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material';
import SmallLoadingCmp from '../SmallLoadingCmp';

const ConfirmationDialog = forwardRef((props, ref) => (
	<Dialog maxWidth="xs" open={props?.open} {...props}>
		<DialogTitle>{props?.title}</DialogTitle>
		{props?.isLoading ? (
			<DialogContent>
				<SmallLoadingCmp />
			</DialogContent>
		) : (
			<>
				<DialogContent dividers>{props?.content}</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={props?.handleCancel}>
						Cancel
					</Button>
					<Button onClick={props?.handleOk}>Ok</Button>
				</DialogActions>
			</>
		)}
	</Dialog>
));
ConfirmationDialog.displayName = 'Confirmation Dialog';
export default styled(ConfirmationDialog)(({ theme }) => ({}));
