import { NAMESPACE_PREFIX } from '../Constants';

export class GPTSummaries {
	constructor(data) {
		this.records = [];
		if (data.records !== null && data.records !== undefined && data.records.length > 0) {
			data.records.map((item) => {
				this.records.push(new GPTSummary(item));
			});
		}
	}
}

export class GPTSummary {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.GPTSummary__c = data[NAMESPACE_PREFIX + 'GPTSummary__c'];
		this.RecordSummary__c = data[NAMESPACE_PREFIX + 'RecordSummary__c'];
		this.GPTSummaryModifiedDate__c = data[NAMESPACE_PREFIX + 'GPTSummaryModifiedDate__c'];
		this.RecordId__c = data[NAMESPACE_PREFIX + 'RecordId__c'];
	}
}
export class GPTSummarySO {
	constructor(data) {
		this[NAMESPACE_PREFIX + 'RecordSummary__c'] = data.recordSummary;
		this[NAMESPACE_PREFIX + 'GPTSummary__c'] = data.gptSummary;
		this[NAMESPACE_PREFIX + 'GPTSummaryModifiedDate__c'] = new Date().toISOString();
		this[NAMESPACE_PREFIX + 'RecordId__c'] = data.recordId;
		this[NAMESPACE_PREFIX + 'RecordSummaryModifiedDate__c'] = new Date().toISOString();
	}
}
