import SalesforceService from '../services/SalesforceService';
import Utils from './Utils';

const CacheUtils = {};

var staticCache = {};
var StaticCacheImpl = function () {};
StaticCacheImpl.prototype.getItem = function (key) {
	return staticCache[key];
};
StaticCacheImpl.prototype.setItem = function (key, val) {
	let replaced = !Utils.isBlankString(StaticCacheImpl.getItem(key));
	staticCache[key] = val;
	return replaced;
};

var LocalStorageCacheImpl = function () {};
LocalStorageCacheImpl.prototype.getItem = function (key) {
	return localStorage.getItem(key);
};
LocalStorageCacheImpl.prototype.setItem = function (key, val) {
	let replaced = !Utils.isBlankString(LocalStorageCacheImpl.prototype.getItem(key));
	localStorage.setItem(key, val);
	return replaced;
};

var cacheConfig = {};
cacheConfig['fileHtml'] = {
	staticCache: false,
	localStorageCache: true,
	expiry: undefined,
};

cacheConfig['companyInfo'] = {
	staticCache: false,
	localStorageCache: true,
	expiry: undefined,
};

CacheUtils.setItem = (partitionKey, key, val, mergeProps, itemKey) => {
	let keyArr = [];

	if (Utils.isBlankString(key)) return undefined;
	keyArr.push(key);
	let orgId = SalesforceService.getSfOrgId();
	if (!Utils.isBlankString(orgId)) keyArr.push(orgId);
	if (!Utils.isBlankString(partitionKey)) keyArr.push(partitionKey);

	let finalKey = keyArr.join('-');

	let config = cacheConfig[partitionKey];
	let cachedValue;
	if (config.staticCache === true) {
		let cacheImpl = new StaticCacheImpl();
		cachedValue = cacheImpl.getItem(finalKey);
	}
	if (Utils.isBlankString(cachedValue) && config.localStorageCache === true) {
		let cacheImpl = new LocalStorageCacheImpl();
		cachedValue = cacheImpl.getItem(finalKey);
	}

	let finalVal;
	if (mergeProps === true) {
		cachedValue = cachedValue || {};
		cachedValue[itemKey] = val;
		finalVal = cachedValue;
	} else {
		finalVal = val;
	}

	if (config.staticCache === true) {
		let cacheImpl = new StaticCacheImpl();
		return cacheImpl.setItem(finalKey, finalVal);
	} else if (config.localStorageCache === true) {
		let cacheImpl = new LocalStorageCacheImpl();
		return cacheImpl.setItem(finalKey, finalVal);
	}
};

CacheUtils.getItem = (partitionKey, key) => {
	if (Utils.isBlankString(key)) return undefined;

	let config = cacheConfig[partitionKey];
	let keyArr = [];
	keyArr.push(key);
	let orgId = SalesforceService.getSfOrgId();
	if (!Utils.isBlankString(orgId)) keyArr.push(orgId);
	if (!Utils.isBlankString(partitionKey)) keyArr.push(partitionKey);

	let finalKey = keyArr.join('-');

	if (config.staticCache === true) {
		let cacheImpl = new StaticCacheImpl();
		return cacheImpl.getItem(finalKey);
	} else if (config.localStorageCache === true) {
		let cacheImpl = new LocalStorageCacheImpl();
		return cacheImpl.getItem(finalKey);
	}
};

export default CacheUtils;
