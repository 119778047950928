import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FWTab, FWTabs } from '../mui/FWTabs';
import FWTabPanel from '../mui/FWTabPanel';

function TabPanel({ children, value, index, ...other }) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default function AdminSettingsScheduleCmp() {
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const params = useLocation();
	const pathname = params.pathname;

	const getTabIndex = (pathname) => {
		if (!pathname) {
			return 0;
		} else if (pathname?.indexOf('preview') !== -1) {
			return 1;
		} else if (pathname?.indexOf('execute') !== -1) {
			return 2;
		} else {
			return 0;
		}
	};

	const [value, setValue] = useState(getTabIndex(pathname));

	return (
		<Paper
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				padding: '15px',
			}}
		>
			<FWTabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: 'divider', width: '250px', padding: '5px' }}
			>
				<FWTab
					style={{ alignItems: 'flex-start' }}
					label="&nbsp;1. Select and Configure Objects"
					{...a11yProps(0)}
					to="/admin/setup/scheduleInsight/sobject"
					component={Link}
				/>
				<FWTab
					style={{ alignItems: 'flex-start' }}
					label="&nbsp;2. Account Summary Preview"
					to="/admin/setup/scheduleInsight/preview"
					component={Link}
					{...a11yProps(1)}
				/>
				<FWTab
					style={{ alignItems: 'flex-start' }}
					label="&nbsp;3. Schedule Data Update"
					to="/admin/setup/scheduleInsight/execute"
					component={Link}
					{...a11yProps(2)}
				/>
			</FWTabs>

			<FWTabPanel>
				<Outlet />
			</FWTabPanel>
		</Paper>
	);
}
