import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Alert, Snackbar } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import FileUploadWithListCmp from './files/FileUploadWithListCmp';
import FileImportCmp from './files/FileImportCmp';
import FileSharingSettingCmp from './files/FileSharingSettingsCmp';
import SimpleTooltipCmp from '../common/SimpleTooltipCmp';
import FileDeleteConfirmationCmp from './files/FileDeleteConfirmationCmp';

function AdminSettingsFilesTabCmp() {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [showImport, setShowImport] = useState(false);
	const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
	const [showSharingSetting, setShowSharingSetting] = useState(false);
	const fileUploadRef1 = useRef();
	const [showError, setShowError] = useState(false);
	const [errorCmp, setErrorCmp] = useState(undefined);

	return (
		<div id="AdminSettingsFilesTabCmp">
			<div style={{ height: '40px' }} />
			<Box
				sx={{
					width: '100%',
					bgcolor: 'background.paper',
				}}
			>
				<FileUploadWithListCmp
					showGuidance={true}
					ref={fileUploadRef1}
					fileType="User Guides"
					title="Please upload any product related materials - product guides, user guides, RFP/RFI library, API Documentation, release notes."
					supportedFiles="all"
					recordSelection="Multiple"
					actions={[
						{
							id: 'share',
							label: 'Share',
							isApplicableFn: (files) => {
								return files && files.length === 1 && files[0].ContentDocumentId;
							},
							getApplicableTooltip: (files) => {
								return (
									<SimpleTooltipCmp
										title={
											files &&
											files.length === 1 &&
											!files[0].ContentDocumentId
												? 'Sharing is not available for external files'
												: 'Sharing is only available for single file.'
										}
									>
										<InfoOutlined color="secondary" />
									</SimpleTooltipCmp>
								);
							},
							actionFn: (files) => {
								//setFileId(files[0].featurewaveFileId);
								//setContentDocumentId(files[0].ContentDocumentId);
								setSelectedFiles(files);
								setShowSharingSetting(true);
							},
						},
						{
							id: 'delete',
							label: 'Delete',
							isApplicableFn: (files) => {
								return files && files.length > 0;
							},
							actionFn: (files) => {
								//setFileId(file.featurewaveFileId);
								//setContentDocumentId(file.ContentDocumentId);
								setSelectedFiles(files);
								setDeleteConfirmation(true);
							},
						},
					]}
					primaryActions={[
						{
							id: 'import',
							label: 'Select Files',
							actionFn: () => {
								setShowImport(true);
							},
						},
					]}
				/>
			</Box>

			{showSharingSetting && (
				<FileSharingSettingCmp
					recordIds={[selectedFiles[0].ContentDocumentId]}
					onClose={() => {
						setShowSharingSetting(false);
					}}
				/>
			)}
			{showImport && (
				<FileImportCmp
					open={showImport}
					handleClose={() => {
						setTimeout(() => {
							fileUploadRef1?.current?.refresh();
							setShowImport(false);
						}, 1000);
					}}
				/>
			)}
			{showDeleteConfirmation && (
				<FileDeleteConfirmationCmp
					setDeleteConfirmation={setDeleteConfirmation}
					showDeleteConfirmation={showDeleteConfirmation}
					selectedFiles={selectedFiles}
					onDeleteFn={(errorMsg) => {
						if (errorMsg) {
							setShowError(true);
							setErrorCmp(errorMsg);
						}
						setDeleteConfirmation(false);
					}}
				/>
			)}
			<Snackbar
				open={showError}
				autoHideDuration={6000}
				onClose={() => setShowError(false)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity="info" sx={{ width: '100%' }} onClose={() => setShowError(false)}>
					{errorCmp}
				</Alert>
			</Snackbar>
		</div>
	);
}
export default AdminSettingsFilesTabCmp;
