import React, { useEffect } from 'react';
import { Outlet, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import '@salesforce/canvas-js-sdk';

import './App.css';
import './AppStyle.scss';

import { withLazySuspense } from './components/common/SuspenseUtils';
import Utils from './utils/Utils';

import SalesforceService from './services/SalesforceService';
import ApplicationService from './services/ApplicationService';
import AccountInsightsStandaloneContainerCmp from './components/account/AccountInsightsStandaloneContainerCmp';
import AdminSettingsSetupCmp from './components/admin/AdminSettingsSetupCmp';
import AdminSettingsScheduleCmp from './components/admin/AdminSettingsScheduleCmp';
import AdminSettingsScheduleSObjectCmp from './components/admin/AdminSettingsScheduleSObjectCmp';
import AdminSettingsScheduleExecuteCmp from './components/admin/AdminSettingsScheduleExecuteCmp';
import AdminSettingsOtherCmp from './components/admin/AdminSettingsOtherCmp';
import CompanyProfileCmpNew from './components/admin/CompanyProfileCmpNew';
import SalesPlayAdminHome from './components/admin/salesplay/SalesPlayAdminHome';
import AdminSettingsFilesTabCmp from './components/admin/AdminSettingsFilesTabCmp';
import GPTPlaygroundCmp from './components/GPTPlaygroundCmp';
import AdminSettingsSummaryPreviewCmp from './components/admin/AdminSettingsSummaryPreviewCmp';
import AdminSettingsPersonaInsightCmp from './components/admin/AdminSettingsPersonaInsightCmp';
const DashboardCmp = withLazySuspense(() => import('./components/DashboardCmp'));
const AdminSettingsMainCmp = withLazySuspense(
	() => import('./components/admin/AdminSettingsMainCmp'),
);
const FilesOtherSourcesCmp = withLazySuspense(
	() => import('./components/admin/files/FilesOtherSourcesCmp'),
);
const OpportunityViewCmp = withLazySuspense(
	() => import('./components/opportunity/OpportunityViewCmp'),
);
const RequirementStandaloneCmp = withLazySuspense(
	() => import('./components/RequirementStandaloneCmp'),
);
const SearchCmp = withLazySuspense(() => import('./components/SearchCmp'));
const SearchResult = withLazySuspense(() => import('./components/search/SearchResult'));

function App() {
	const navigate = useNavigate();

	let _url = ApplicationService.getNavigationUrl();
	if (Utils.isLocalhost() && _url !== '') {
		// console.log(_url);
		navigate(_url);
	}

	useEffect(() => {
		global.Sfdc.canvas.client.refreshSignedRequest(function (data) {
			var signedRequest = data.payload.response;
			var part = signedRequest.split('.')[1];
			var obj = JSON.parse(global.Sfdc.canvas.decode(part));
			// console.log(obj);
			SalesforceService.setSalesforceInfo({
				sessionId: obj.client.oauthToken,
				salesforceEndPoint: obj.client.instanceUrl,
				instanceId: obj.client.instanceId,
				client: obj.client,
				organizationId: obj.context.organization.organizationId,
				params: obj.context.environment.parameters,
			});
			let _url = ApplicationService.getNavigationUrl();
			// console.log(_url);
			if (_url !== '') {
				// console.log(_url);
				navigate(_url);
			}
		});
	}, []);

	return (
		<Routes>
			<Route path="/" element={<Outlet />}>
				<Route path="/dashboard/:currentUserId">
					<Route path="" element={<DashboardCmp />} />
					<Route path=":recordType/:primaryRecordId" element={<DashboardCmp />} />
				</Route>
				<Route path="/account/:accountId" element={<OpportunityViewCmp />} />
				<Route path="/opportunity/:opportunityId" element={<OpportunityViewCmp />} />
				{/* <Route path="/admin">
					<Route path="" element={<AdminSettingsMainCmp />} />
					<Route path=":defaultTab" element={<AdminSettingsMainCmp />} />
					<Route path=":defaultTab/:defaultSubTab" element={<AdminSettingsMainCmp />} />
				</Route> */}
				<Route path="/admin" element={<AdminSettingsMainCmp />}>
					<Route exact path="/admin" element={<Navigate to="companyprofile" />} />
					<Route path="companyprofile" element={<CompanyProfileCmpNew />} />
					<Route path="salesplay" element={<SalesPlayAdminHome />}></Route>
					<Route
						path="salesplay/:salesPlayId/:relatedList"
						element={<SalesPlayAdminHome />}
					/>
					<Route path="files" element={<AdminSettingsFilesTabCmp />} />
				</Route>
				<Route path="/admin/setup" element={<AdminSettingsSetupCmp />}>
					<Route exact path="/admin/setup/" element={<Navigate to="scheduleInsight" />} />
					<Route path="scheduleInsight" element={<AdminSettingsScheduleCmp />}>
						<Route
							exact
							path="/admin/setup/scheduleInsight/"
							element={<Navigate to="sobject" />}
						/>
						<Route path="sobject" element={<AdminSettingsScheduleSObjectCmp />} />
						<Route path="execute" element={<AdminSettingsScheduleExecuteCmp />} />
						<Route path="preview" element={<AdminSettingsSummaryPreviewCmp />} />
					</Route>
					<Route path="personaInsight" element={<AdminSettingsPersonaInsightCmp />} />
					<Route path="otherSettings" element={<AdminSettingsOtherCmp />} />
				</Route>
				<Route path="/filesOtherSources" element={<FilesOtherSourcesCmp />} />
				<Route path="/requirement/:opptyId" element={<RequirementStandaloneCmp />} />
				<Route
					path="/accountSummary/:accountId"
					element={<AccountInsightsStandaloneContainerCmp />}
				/>
				<Route path="/selectFile" element={<AdminSettingsMainCmp />} errorElement />
				<Route path="/selectFile/:defaultTab" element={<AdminSettingsMainCmp />} />
				<Route path="/playground" element={<GPTPlaygroundCmp />} />
				<Route path="/search">
					<Route path="" element={<SearchCmp />} />
					<Route path=":featureId" element={<SearchResult />} />
				</Route>
			</Route>
		</Routes>
	);
}
export default App;
