import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Grid } from '@mui/material';
import { FWTab, FWTabs } from '../mui/FWTabs';

import { getAccount } from '../../apis/AccountApi';

import {
	QKEY_ACCOUNT,
	QKEY_ACCOUNT_OPPORTUNITIES,
	QKEY_OPPTY_DETAIL,
	QKEY_OPPTY_SALESPLAYBOOK,
} from '../../utils/QueryKeys';
import SmallLoadingCmp from '../SmallLoadingCmp';
import OpportunityApi from '../../apis/OpportunityApi';
import SalesPlaybookService from '../../services/SalesPlaybookService';
import AccountInsightsStandaloneContentCmp from './AccountInsightsStandaloneContentCmp';

const AccountInsightsStandaloneContainerCmp = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { accountId } = useParams();
	const tabId = searchParams.get('tab') || 'aboutCompany';
	const opptyId = searchParams.get('opportunityId');

	const { data: account, isLoading: isAccountLoading } = useQuery({
		enabled: !!accountId,
		queryKey: [QKEY_ACCOUNT, accountId],
		queryFn: async () => await getAccount(accountId, true),
	});

	const { data: accountOppties, isLoading: isAccountOpptiesLoading } = useQuery({
		enabled: !!accountId,
		queryKey: [QKEY_ACCOUNT_OPPORTUNITIES, accountId],
		queryFn: async () => await OpportunityApi.getAccountOpportunities(accountId),
	});
	const accountOppty = useMemo(
		() =>
			Array.isArray(accountOppties?.records) &&
			accountOppties?.records?.length > 0 &&
			((accountOppties?.records?.length === 1 && accountOppties?.records?.[0]) || // If only one opportunity, just use that one
				accountOppties?.records?.find((o) => o?.Id === opptyId) || // If we have an opportunity id, use that one
				accountOppties?.records?.find(
					(o) =>
						Array.isArray(o?.Personas) &&
						o?.Personas?.length > 0 &&
						o?.StageName !== 'Closed Won' &&
						o?.StageName !== 'Closed Lost',
				)), // If we have opportunity personas, use the first one that isn't closed won or closed lost
		[accountOppties, opptyId],
	);

	const { data: oppty, isLoading: isOpptyLoading } = useQuery({
		enabled: !!accountOppty?.Id,
		queryKey: [
			QKEY_OPPTY_DETAIL,
			accountOppty?.Id,
			{ includeSalesPlaybook: false, includeRequirement: false, includeAccount: true },
		],
		queryFn: async () =>
			await OpportunityApi.getOppDetail(accountOppty?.Id, false, false, true),
	});

	const { data: salesPlayBook, isLoading: isSalesplayBookLoading } = useQuery({
		enabled: !!accountOppty?.Id && !isAccountOpptiesLoading && !!accountOppty?.SalesPlaybook__c,
		queryKey: [QKEY_OPPTY_SALESPLAYBOOK, accountOppty?.SalesPlaybook__c, accountOppty?.Id],
		queryFn: async () =>
			await SalesPlaybookService.getSalesPlaybookDetail(accountOppty?.SalesPlaybook__c),
	});

	const setTabId = (e, value) => {
		if (tabId === value) return;
		searchParams.set('tab', value);
		setSearchParams(searchParams, { replace: true });
	};

	return (
		<Grid column direction="column" padding="10px">
			<Grid item>
				<FWTabs centered value={tabId} onChange={setTabId}>
					<FWTab value="aboutCompany" label={`About`} />
					<FWTab value="accountSummary" label="Account Summary" />
					<FWTab value="personaInsights" label="Persona Insights" />
				</FWTabs>
			</Grid>
			{((isAccountLoading ||
				isAccountOpptiesLoading ||
				isOpptyLoading ||
				isSalesplayBookLoading) && (
				<Grid item padding="50px">
					<SmallLoadingCmp />
				</Grid>
			)) || (
				<Grid item>
					<AccountInsightsStandaloneContentCmp
						tabId={tabId}
						account={account}
						oppty={oppty}
						salesPlayBook={salesPlayBook}
						accountId={accountId}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default AccountInsightsStandaloneContainerCmp;
