import React from 'react';
import { useNavigate } from 'react-router';

import { AppBar, Toolbar, Typography } from '@mui/material';

import FWLogo from '../mui/FWLogo';

import TitleBar from './TitleBar';

import SalesforceService from '../../services/SalesforceService';

const FwAppHeader = ({
	title: label = 'Admin',
	navigationTitle = 'Dashboard',
	navigationPath = `/dashboard/${SalesforceService.getCurrentUserId()}`,
}) => {
	const navigate = useNavigate();

	return (
		<AppBar
			style={{
				background: 'linear-gradient(23deg, #DBDBF4, #DBDBF4, #CFD7E2, #DBDBF4, #CFD7E2)',
			}}
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			position="sticky"
		>
			<Toolbar>
				<div
					title={navigationTitle}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						if (navigationPath) navigate(navigationPath);
					}}
				>
					<FWLogo size="sm" />
				</div>
				<Typography
					variant="h6"
					noWrap
					component="div"
					style={{ width: '400px !important' }}
					sx={{ display: 'block', width: '300px !important' }}
				>
					<TitleBar subtitle={{ label, color: 'black', fontWeight: 'bold' }} />
				</Typography>
			</Toolbar>
		</AppBar>
	);
};
export default FwAppHeader;
