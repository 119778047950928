import {
	GEN_AI_SERVICE_CLAUDE,
	GEN_AI_SERVICE_OPENAI,
	OBJECT_TYPE_GPT_SUMMARY,
} from '../../Constants';
import AnthropicApi from '../../apis/AnthropicApi';
import CustomSettingApi from '../../apis/CustomSettingApi';
import SalesforceCommonApi from '../../apis/SalesforceCommonApi';
import { GPTSummarySO } from '../../models/GPTSummary';
import GPTSummaryService from '../GPTSummaryService';
import OpenAIService from '../OpenAIService';

const GenAiService = {};
const defaultService = GEN_AI_SERVICE_OPENAI;

GenAiService.generate = async ({ input, config }) => {
	let response;
	let service = config?.service || defaultService;
	let resolveMergeFields = input?.resolveMergeFields;
	let prompts = input.prompts || [];
	let stream = config?.stream || false;
	let flow = input?.flow;
	let useCache = config?.useCache === true && prompts.length === 0;
	let useFallback = true; // config?.useFallback === false;
	let summaryId = input?.summaryId;

	if (useCache === true) {
		if (summaryId) {
			let gptSummaryRecord = await GPTSummaryService.getGPTSummary({
				summaryId: summaryId,
			});
			if (gptSummaryRecord) {
				return {
					response: gptSummaryRecord.GPTSummary__c,
				};
			}
		}
	}

	if (flow) {
		let gptPrompt = await CustomSettingApi.getGPTPrompt({
			promptConfigName: flow,
		});
		prompts = gptPrompt.prompts;
	}

	let resolvedPrompts = [];
	if (resolveMergeFields) {
		resolvedPrompts = await resolveMergeFields(prompts);
	} else {
		resolvedPrompts = prompts;
	}
	let finalSummary;
	if (stream === true) {
		if (service === GEN_AI_SERVICE_OPENAI) {
			response = await OpenAIService.complete(resolvedPrompts, config);
			if (response) {
				return {
					response: response,
					resolvedPrompts: resolvedPrompts,
				};
			}
			if ((!response || response === null) && useFallback === true) {
				stream = false;
				service = GEN_AI_SERVICE_CLAUDE;
			}
		} else {
			throw new Error(
				'Streaming API is not yet implemented for Claude. Use Open AI Services.',
			);
		}
		//In Case of Stream API Response, Summary need to be stored by Individual method

		if (!useFallback) {
			return {
				response: response,
				resolvedPrompts: resolvedPrompts,
			};
		}
	}

	if (stream !== true) {
		if (service === GEN_AI_SERVICE_OPENAI) {
			throw new Error(
				'Only streaming API is available for OpenAI. Use claude instead for non streaming APIs.',
			);
		} else {
			response = await AnthropicApi.generateNew(resolvedPrompts, config);
			let outputMsgs = [];
			if (response) {
				let contents = response.content || [];
				for (let content of contents) {
					outputMsgs.push(content.text);
				}
			}
			finalSummary = outputMsgs.join(',');
			if (useCache === true) {
				GenAiService.saveGptSummary(summaryId, resolvedPrompts, finalSummary);
			}
		}
		return {
			response: finalSummary,
			resolvedPrompts: resolvedPrompts,
		};
	}
};

GenAiService.saveGptSummary = (summaryId, prompts, finalSummary) => {
	// if(finalSummary){
	// 	finalSummary = finalSummary.replaceAll("'","\\'");
	// }
	let gptSumarySORecord = new GPTSummarySO({
		gptSummary: finalSummary,
		recordSummary: JSON.stringify(prompts),
		recordId: summaryId,
	});
	return SalesforceCommonApi.createRecord(gptSumarySORecord, OBJECT_TYPE_GPT_SUMMARY);
};

export default GenAiService;
