import ApiGateway from './ApiGateway';
import { NAMESPACE_PREFIX } from '../Constants';
import { SalesPlaybook } from '../models/SalesPlaybook';
import { ns } from '../utils/Utils';

const SalesPlaybookApi = {};

SalesPlaybookApi.getSalesPlaybookDetail = async (salesPlaybookId) => {
	const salesPlayBookFields = [
		'Id',
		'Name',
		ns('Description__c'),
		ns('IsActive__c'),
		ns('CriticalMessaging__c'),
	];

	const objectionHandlingsObjectionHandlingFields = [
		'Name',
		ns('Objection__c'),
		ns('Response__c'),
	];

	const objectionHandlingsFields = [
		'Id',
		'Name',
		...objectionHandlingsObjectionHandlingFields.map(
			(f) => `${ns('ObjectionHandling__r')}.${f}`,
		),
	];

	const discoveryIndustryTrendFields = ['Name', ns('Description__c')];

	const salesPlayBookDiscoveryIndustryTrendsFields = [
		'Id',
		'Name',
		...discoveryIndustryTrendFields.map((f) => `${ns('DiscoveryIndustryTrendId__r')}.${f}`),
	];

	const personaMessagingFields = ['Name', ns('Message__c')];

	const salesPlayBookPersonaMessagingFields = [
		'Id',
		'Name',
		...personaMessagingFields.map((f) => `${ns('PersonaMessagingId__r')}.${f}`),
	];

	const demoFields = ['Name', ns('Description__c'), ns('Url__c')];

	const salesPlayBookDemosFields = [
		'Id',
		'Name',
		...demoFields.map((f) => `${ns('Demo__r')}.${f}`),
	];

	const competitionFields = [
		'Name',
		ns('CompetitorStrengths__c'),
		ns('HowWeBeatThisCompetitor__c'),
		ns('Url__c'),
		ns('RecentWins__c'),
	];

	const salesPlayBookCompetitionsFields = [
		'Id',
		'Name',
		...competitionFields.map((f) => `${ns('Competition__r')}.${f}`),
	];

	const fileFields = [
		'Name',
		ns('FileType__c'),
		ns('ExternalId__c'),
		ns('ContentDocumentId__c'),
		ns('ContentVersionId__c'),
		ns('Title__c'),
	];

	const salesPlaybookFilesFields = [
		'Id',
		'Name',
		...fileFields.map((f) => `${ns('File__r')}.${f}`),
	];

	const contactFields = ['Name', 'Email', 'Phone'];

	const salesPlayBookContactsFields = [
		'Id',
		'Name',
		...contactFields.map((f) => `${ns('Contact__r')}.${f}`),
	];

	/*const elevatorPitchFields = [
        'Name',
        ns('Description__c'),
    ];

    const salesPlayBookElevatorPitchesFields = [
        'Id',
        'Name',
        ...elevatorPitchFields.map(f => `${ns('ElevatorPitch__r')}.${f}`),
    ];*/

	const fieldsJoined =
		salesPlayBookFields.join(', ') +
		`, (SELECT ${objectionHandlingsFields.join(', ')} FROM ${ns('Objection_Handlings__r')})` +
		`, (SELECT ${salesPlayBookPersonaMessagingFields.join(', ')} FROM ${ns('SalesPlayPersonaMessagings__r')})` +
		`, (SELECT ${salesPlayBookDiscoveryIndustryTrendsFields.join(', ')} FROM ${ns('SalesPlaybookDiscoveryIndustryTrends__r')})` +
		`, (SELECT ${salesPlayBookDemosFields.join(', ')} FROM ${ns('Sales_Playbook_Demos__r')})` +
		`, (SELECT ${salesPlayBookCompetitionsFields.join(', ')} FROM ${ns('Sales_Playbook_Competitions__r')})` +
		`, (SELECT ${salesPlaybookFilesFields.join(', ')} FROM ${ns('Sales_Playbook_Files__r')} ORDER BY ${ns('File__r')}.CreatedDate DESC)` +
		`, (SELECT ${salesPlayBookContactsFields.join(', ')} FROM ${ns('Sales_Playbook_Contacts__r')})`;
	//`, (SELECT ${salesPlayBookElevatorPitchesFields.join(', ')} FROM ${ns('Sales_Playbook_Elevator_Pitches__r')})`;

	const query = `SELECT ${fieldsJoined} FROM ${ns('SalesPlaybook__c')} WHERE Id='${salesPlaybookId}' LIMIT 1`;

	const resultData = await ApiGateway.invoke('sf', 'query', {}, { query });
	return new SalesPlaybook(resultData[0]);
};

SalesPlaybookApi.getSalesPlays = async () => {
	const salesPlayBookFields = [
		'Id',
		'Name',
		ns('Description__c'),
		ns('IsActive__c'),
		ns('CriticalMessaging__c'),
		ns('Industry__c'),
	];

	const salesPlayProductsFields = ['Id', ns('TagId__c'), ns('TagId__r.Name')];

	const fieldsJoined =
		salesPlayBookFields.join(', ') +
		`, (SELECT ${salesPlayProductsFields.join(', ')} FROM ${ns('SalesPlayProducts__r')})`;

	const query = `SELECT ${fieldsJoined} FROM ${ns('SalesPlaybook__c')} ORDER BY CreatedDate DESC`; //WHERE ${ns('IsActive__c')}=true

	const _salesPlays = await ApiGateway.invoke('sf', 'query', {}, { query });
	_salesPlays.forEach((_salesPlay) => {
		const products = _salesPlay[ns('SalesPlayProducts__r')]?.records || [];
		_salesPlay.ProductSelection =
			products.map((salesPlayProduct) => ({
				Id: salesPlayProduct[ns('TagId__c')],
				text: salesPlayProduct[ns('TagId__r')]['Name'],
			})) || [];
	});
	return _salesPlays;
};

SalesPlaybookApi.getRelatedList = async (salesPlayId, relatedListType, objectDescribe) => {
	return new Promise((resolve, reject) => {
		let fields = ['Id'];
		let isSalesPlay = !relatedListType || relatedListType === 'salesPlayBook';
		let queryStr;

		if (objectDescribe) {
			if (isSalesPlay) {
				for (let formField of objectDescribe.form.fields || []) {
					if (formField.type !== 'File' && formField.nonObjectField !== true)
						fields.push(formField.name);
				}
				queryStr =
					'SELECT ' + fields.join(', ') + ' FROM ' + objectDescribe.baseObjectApiName;
				queryStr += ' ORDER BY CreatedDate DESC';
			} else {
				fields.push('Name');
				//fields.push(objectDescribe.relatedLookupApiName);
				let relationshipName = objectDescribe.relationshipName;
				for (let formField of objectDescribe.form.fields || []) {
					if (formField.type !== 'File' && formField.nonObjectField !== true)
						fields.push(relationshipName + '.' + formField.name);
				}
				fields.push(relationshipName + '.Id');

				fields.push(objectDescribe.relatedLookupApiName);
				fields.push(objectDescribe.salesPlayApiName);
				let fileFilter = '';
				if (objectDescribe.fileType && !objectDescribe.hasMultipleFile) {
					fileFilter =
						' AND ' +
						relationshipName +
						'.' +
						NAMESPACE_PREFIX +
						"FileType__c='" +
						objectDescribe.fileType +
						"'";
				}

				queryStr =
					'SELECT ' +
					fields.join(', ') +
					' FROM ' +
					objectDescribe.objectApiName +
					' WHERE ' +
					objectDescribe.salesPlayApiName +
					" = '" +
					salesPlayId +
					"'" +
					fileFilter;
				if (objectDescribe)
					queryStr += ' ORDER BY ' + objectDescribe.relationshipName + '.Name';
			}
		}
		let fileType = objectDescribe.fileType;
		let whereCondition = `${ns('File__r')}.${ns('FileType__c')}='${fileType}'`;
		let fileQuery = `SELECT Id, ${ns('File__r')}.Id, ${ns('File__r')}.Name, ${ns('File__r')}.${ns('Title__c')}, ${ns('File__r')}.${ns('FileType__c')}, ${ns('File__r')}.${ns('Status__c')}, ${ns('File__r')}.${ns('ExternalId__c')} FROM ${ns('SpbFiles__c')} WHERE ${ns('SalesPlaybook__c')}='${salesPlayId}' AND  ${whereCondition} ORDER BY CreatedDate DESC`;
		let promiseList = [];
		let recordPromise = ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
		promiseList.push(recordPromise);
		if (objectDescribe.hasFile) {
			let filePromise = ApiGateway.invoke('sf', 'query', {}, { query: fileQuery });
			promiseList.push(filePromise);
		}
		//var queryStr = "SELECT "+SALESPLAY_OBJECT_FIELDS + " FROM "+NAMESPACE_PREFIX+"SpbObjectionHandling__c WHERE "+NAMESPACE_PREFIX+"SalesPlaybook__c = '"+salesPlayId+"'";
		let convertToFileObj = (spbFile) => {
			let fileObj = {};
			fileObj.featurewaveFileId = spbFile[NAMESPACE_PREFIX + 'File__r']['Id'];
			fileObj.fileName = spbFile[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'Title__c'];
			if (!fileObj.fileName) fileObj.fileName = spbFile[NAMESPACE_PREFIX + 'File__r']['Name'];
			fileObj.spbFileId = spbFile.Id;
			return fileObj;
		};

		Promise.all(promiseList).then((response) => {
			let records = response[0];
			let files = response.length > 1 ? response[1] : [];

			let _finalRecords = [];
			let _recordFileMap = {};
			if (objectDescribe.hasFile) {
				for (let spbFile of files || []) {
					let _referenceId = spbFile.Id;
					if (objectDescribe.hasMultipleFile) {
						_referenceId =
							spbFile[NAMESPACE_PREFIX + 'File__r'][
								NAMESPACE_PREFIX + 'ExternalId__c'
							];
					}
					if (!_recordFileMap[_referenceId]) {
						_recordFileMap[_referenceId] = [];
					}
					_recordFileMap[_referenceId].push(convertToFileObj(spbFile));
				}
			}
			for (let record of records || []) {
				if (record[objectDescribe.relationshipName])
					record[objectDescribe.relationshipName]['File'] = _recordFileMap[record.Id];
				_finalRecords.push(record);
			}
			resolve({
				records: _finalRecords,
				relatedListType,
				objectDescribe,
			});
		});
	});
};

SalesPlaybookApi.getMasterList = async (salesPlayId, relatedListType, objectDescribe) => {
	let fields = ['Id'];
	let queryStr;

	if (objectDescribe) {
		for (let formField of objectDescribe.form.fields || []) {
			if (formField.type !== 'File' && formField.nonObjectField !== true)
				fields.push(formField.name);
		}
		queryStr = 'SELECT ' + fields.join(', ') + ' FROM ' + objectDescribe.baseObjectApiName;
		if (objectDescribe.fileType && !objectDescribe.hasMultipleFile) {
			queryStr +=
				' WHERE ' + NAMESPACE_PREFIX + "FileType__c='" + objectDescribe.fileType + "'";
		}
		queryStr += ' ORDER BY Name';
	}

	//var queryStr = "SELECT "+SALESPLAY_OBJECT_FIELDS + " FROM "+NAMESPACE_PREFIX+"SpbObjectionHandling__c WHERE "+NAMESPACE_PREFIX+"SalesPlaybook__c = '"+salesPlayId+"'";
	let masterPromise = ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	let relatedPromise = SalesPlaybookApi.getRelatedList(
		salesPlayId,
		relatedListType,
		objectDescribe,
	);

	return new Promise((resolve, reject) => {
		Promise.all([masterPromise, relatedPromise]).then((response) => {
			let masterData = response[0];
			let relatedRecordResp = response[1];
			let masterIdList = [];
			let relatedData = relatedRecordResp.records;
			let masterIdSet = new Set();

			for (let relatedRecord of relatedData) {
				masterIdSet.add(relatedRecord[objectDescribe.relatedLookupApiName]);
				masterIdList.push(relatedRecord[objectDescribe.relatedLookupApiName]);
			}
			for (let masterRecord of masterData) {
				masterRecord.id = masterRecord.Id;
				if (masterIdSet.has(masterRecord.Id)) masterRecord.isSelected = true;
			}
			resolve({
				data: masterData,
				relatedData: relatedData,
				masterIds: masterIdList,
			});
		});
	});
};

SalesPlaybookApi.deleteSpbFiles = async (spbId) => {
	let queryStr = `SELECT Id, fwave__SalesPlaybook__c, fwave__File__c, fwave__File__r.Name FROM fwave__SpbFiles__c WHERE fwave__SalesPlaybook__c = '${spbId}'`;

	let spbFiles = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	let spbFileIds = [];
	for (let spbFileSO of spbFiles || []) {
		let spbFileId = spbFileSO[NAMESPACE_PREFIX + 'File__c'];
		if (spbFileId) spbFileIds.push(spbFileId);
	}
	return spbFileIds;
};

SalesPlaybookApi.saveNewRelatedItems = (newItems, salesplayId, objectDescribe) => {
	let records = [];

	for (let newRecordId of newItems) {
		let record = {};
		record[objectDescribe.relatedLookupApiName] = newRecordId;
		record[objectDescribe.salesPlayApiName] = salesplayId;
		records.push(record);
	}

	return ApiGateway.invoke(
		'sf',
		'create',
		{},
		{},
		JSON.stringify({
			objectName: objectDescribe.objectApiName,
			records: records,
		}),
	);
};

SalesPlaybookApi.getWebsiteText = (websiteUrl) => {
	return new Promise((resolve, reject) => {
		ApiGateway.invoke(
			'sf',
			'gethtml',
			{},
			{
				url: websiteUrl,
			},
			undefined,
		).then((_html) => {
			resolve((_html || '').replace(/(<([^>]+)>)/gi, ''));
		});
	});
};

export default SalesPlaybookApi;
