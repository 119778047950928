import React, { useEffect, useState } from 'react';
import SalesforceCommonApi from '../../../apis/SalesforceCommonApi';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SmallLoadingCmp from '../../SmallLoadingCmp';
import {
	AccountBalanceOutlined,
	CalendarMonthOutlined,
	JoinInnerOutlined,
	LanguageOutlined,
	LibraryAddCheckOutlined,
	LocalPhoneOutlined,
	LocationCityOutlined,
	NotesOutlined,
	NumbersOutlined,
	PercentOutlined,
	PlaylistAddCheckOutlined,
	ScheduleOutlined,
	TextFieldsOutlined,
} from '@mui/icons-material';

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

function FieldTypeIcon({ field }) {
	return (
		<>
			{field.type === 'id' && (
				<JoinInnerOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'boolean' && (
				<LibraryAddCheckOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'reference' && (
				<JoinInnerOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'percent' && (
				<PercentOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'picklist' && (
				<PlaylistAddCheckOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'textarea' && (
				<NotesOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'string' && (
				<TextFieldsOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'address' && (
				<LocationCityOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'double' && (
				<NumbersOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'int' && (
				<NumbersOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'phone' && (
				<LocalPhoneOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'url' && (
				<LanguageOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'currency' && (
				<AccountBalanceOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'datetime' && (
				<ScheduleOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
			{field.type === 'date' && (
				<CalendarMonthOutlined style={{ marginTop: 'auto', marginBottom: 'auto' }} />
			)}
		</>
	);
}
export default function SObjectFieldSelectorCmp({
	sobjectApiName,
	sobjectLabel,
	selectedFields,
	onFieldSelectionChange,
	mandatoryFields,
}) {
	const [objectMetadata, setObjectMetadata] = useState();
	const [isLoading, setLoading] = useState(true);
	// const [selectedFieldDefs, setSelectedFieldDefs] = useState();

	useEffect(() => {
		SalesforceCommonApi.getMetadata({
			sobjectApiName: sobjectApiName,
		}).then((metadata) => {
			// console.log({ metadata });
			setObjectMetadata(metadata);
			setLeft(metadata?.fields);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		if (objectMetadata && !isRightValInitialized) {
			let _selectedFields = [];
			let _defaultFields = [];

			for (let field of selectedFields || []) {
				let fieldObj = objectMetadata.fields.find((fieldObj) => fieldObj.name === field);
				_selectedFields.push(fieldObj);
			}
			let _leftFields = left.filter((leftField) => {
				return !(selectedFields || []).includes(leftField.name);
			});

			for (let field of mandatoryFields || []) {
				let fieldObj = objectMetadata.fields.find((fieldObj) => fieldObj.name === field);
				_defaultFields.push(fieldObj);
			}
			_leftFields = _leftFields.filter((leftField) => {
				return !(mandatoryFields || []).includes(leftField.name);
			});
			let _rightFields = objectMetadata.fields.filter((field) => {
				return (
					(selectedFields || []).includes(field.name) ||
					(mandatoryFields || []).includes(field.name)
				);
			});
			setRight(_rightFields);
			setLeft(_leftFields);
			//setDefaultFields(_defaultFields);
			setIsRightValInitialized(true);
		}
	}, [objectMetadata, selectedFields]);

	const [checked, setChecked] = React.useState([]);
	const [isRightValInitialized, setIsRightValInitialized] = React.useState(false);
	const [left, setLeft] = React.useState([]);
	const [right, setRight] = React.useState([]);

	const [leftChecked, setLeftChecked] = useState([]);
	const [rightChecked, setRightChecked] = useState([]);
	//const [defaultFields, setDefaultFields] = React.useState([]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	useEffect(() => {
		setLeftChecked(intersection(checked, left));
		setRightChecked(intersection(checked, right));
	}, [checked]);

	//const numberOfChecked = (items) => intersection(checked, items).length;

	// const handleToggleAll = (items) => () => {
	//   if (numberOfChecked(items) === items.length) {
	//     setChecked(not(checked, items));
	//   } else {
	//     setChecked(union(checked, items));
	//   }
	// };
	// const handleAllRight = () => {
	// 	let _right = right.concat(left);
	// 	setRight(_right);
	// 	setLeft([]);
	// 	onFieldSelectionChange?.(_right);
	// };

	const handleCheckedRight = () => {
		let _right = right.concat(leftChecked);
		setRight(_right);
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
		onFieldSelectionChange?.(_right);
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		let _right = not(right, rightChecked);
		setRight(_right);
		setChecked(not(checked, rightChecked));
		onFieldSelectionChange?.(_right);
	};

	// const handleAllLeft = () => {
	// 	setLeft(left.concat(right));
	// 	setRight([]);
	// 	onFieldSelectionChange?.([]);
	// };

	const customList = (items, type) => (
		<>
			{/*<Paper
      component="form"
      elevation={3}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
    >
       <Checkbox
                  tabIndex={-1}
                  onClick={handleToggleAll(items)}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': 'select-all',
                  }}
                  checked={numberOfChecked(items) === items.length && items.length !== 0}
                  indeterminate={
                    numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                  }
                />
       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchOutlined />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
      />
     
    </Paper>
    <Divider/> */}
			<Paper sx={{ width: 500, height: 600, overflow: 'auto' }}>
				<List dense component="div" role="list">
					{items
						.sort((a, b) => a.label.localeCompare(b.label))
						.map((field) => {
							const labelId = `transfer-list-item-${field.name}-label`;

							return (
								<ListItemButton
									//disabled={type === 'right' && mandatoryFields?.includes(field.name)}
									key={field.name}
									role="listitem"
									onClick={handleToggle(field)}
								>
									<ListItemIcon>
										<Checkbox
											checked={
												checked.findIndex((e) => field.name === e.name) !==
												-1
											}
											tabIndex={-1}
											//disabled={type === 'right' &&mandatoryFields?.includes(field.name)}
											disableRipple
											inputProps={{
												'aria-labelledby': labelId,
											}}
										/>
										<FieldTypeIcon field={field} /> &nbsp;
									</ListItemIcon>
									<ListItemText
										//disabled={type === 'right' && defaultFields?.includes(field.name)}
										id={labelId}
										primary={`${field.label} (${field.name})`}
									/>
								</ListItemButton>
							);
						})}
				</List>
			</Paper>
		</>
	);

	return (
		<div className="sObjectFieldSelectorCmp_container">
			{isLoading && <SmallLoadingCmp />}
			{!isLoading && (
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item>{customList(left, 'left')}</Grid>
					<Grid item>
						<Grid container direction="column" alignItems="center">
							{/* <Button
								sx={{ my: 0.5 }}
								variant="outlined"
								size="small"
								onClick={handleAllRight}
								disabled={left.length === 0}
								aria-label="move all right"
							>
								≫
							</Button> */}
							<Button
								sx={{ my: 0.5 }}
								variant="outlined"
								size="small"
								onClick={handleCheckedRight}
								//disabled={leftChecked.length === 0}
								aria-label="move selected right"
							>
								&gt;
							</Button>
							<Button
								sx={{ my: 0.5 }}
								variant="outlined"
								size="small"
								onClick={handleCheckedLeft}
								//disabled={rightChecked.length === 0}
								aria-label="move selected left"
							>
								&lt;
							</Button>
							{/* <Button
								sx={{ my: 0.5 }}
								variant="outlined"
								size="small"
								onClick={handleAllLeft}
								disabled={right.length === 0}
								aria-label="move all left"
							>
								≪
							</Button> */}
						</Grid>
					</Grid>
					<Grid item>{customList(right, 'right')}</Grid>
				</Grid>
			)}
		</div>
	);
}
