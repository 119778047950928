import { NAMESPACE_PREFIX } from '../Constants';
import { Tag } from './Tag';

export class RequirementTags {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new RequirementTag(item));
		}
	}
}

export class RequirementTag {
	constructor(data) {
		this.Name = data.Name;
		this.RequirementId__c = data[NAMESPACE_PREFIX + 'RequirementId__c'];
		this.TagId__c = data[NAMESPACE_PREFIX + 'TagId__c'];

		// related
		if (
			data[NAMESPACE_PREFIX + 'TagId__r'] !== null &&
			data[NAMESPACE_PREFIX + 'TagId__r'] !== undefined
		) {
			this.TagId__r = new Tag(data[NAMESPACE_PREFIX + 'TagId__r']);
		}
	}
}

export class SFDCRequirementTag {
	constructor(data) {
		this.Name = data?.Name;
		this[NAMESPACE_PREFIX + 'RequirementId__c'] = data?.RequirementId__c;
		this[NAMESPACE_PREFIX + 'TagId__c'] = data?.TagId__c;
	}
}
