export class SFDCFiles {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new SFDCFile(item));
		}
	}
}

export class SFDCFile {
	constructor(data) {
		this.ContentDocumentId = data.ContentDocumentId;
		this.Title = data.Title;
	}
}
