import React, { useEffect, useState } from 'react';
import AdminApi from '../../apis/AdminApi';
import {
	Alert,
	AlertTitle,
	Checkbox,
	Chip,
	FormControlLabel,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
} from '@mui/material';
import { FWButton } from '../mui/FWButtons';

export default function AdminSettingsScheduleExecuteCmp() {
	const [jobDetails, setJobDetails] = useState(undefined);
	const [startTime, setStartTime] = useState(1);
	const [daysChecked, setDaysChecked] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const [showExportNowAlter, setExportNowAlter] = useState(false);

	const initialize = () => {
		AdminApi.getApexJogDetails().then((apexJobDtls) => {
			if (apexJobDtls && apexJobDtls.length > 0) {
				let _jobDtls = apexJobDtls[0];
				let cronExpression = _jobDtls.CronTrigger.CronExpression;
				let expressionArr = cronExpression.split(' ');
				let startTime = expressionArr[2];
				setStartTime(startTime);
				let days = expressionArr[5];
				let dayArr = days.split(',');
				let _daysChecked = [...daysChecked];

				for (let day of dayArr) {
					let dayNumber = getNumberFromDay(day);
					_daysChecked[dayNumber] = true;
				}
				setJobDetails(Object.assign({}, _jobDtls));
				setDaysChecked(_daysChecked);
			} else {
				setJobDetails(undefined);
			}
		});
	};
	useEffect(() => {
		// AdminApi.getSObjects();
		// AdminApi.getRelationships('Account');
		initialize();
	}, []);

	const handleStartTimeChange = (evt) => {
		setStartTime(evt.target.value);
	};

	const handleDayChange = (event) => {
		let target = event.target;
		let checkboxId = target.id;
		let dayNumberStr = checkboxId.split('-')[1];
		let dayNumber = parseInt(dayNumberStr);
		let _daysChecked = [...daysChecked];
		_daysChecked[dayNumber] = target.checked;
		setDaysChecked(_daysChecked);
	};

	const handleSchedule = () => {
		let index = 0;
		let daysArr = [];
		for (let dayItem of daysChecked) {
			if (dayItem === true) {
				daysArr.push(getDayFromNumber(index));
			}
			index++;
		}
		const dayStr = daysArr.join(',');
		const cronExpression = `0 0 ${startTime} ? * ${dayStr}`;
		AdminApi.scheduleJob(cronExpression).then(() => initialize());
	};

	const handleAbort = () => {
		AdminApi.abortJob(jobDetails.CronTriggerId).then(() => initialize());
	};

	const handleExportNow = () => {
		AdminApi.exportNow();
		setExportNowAlter(true);
		setTimeout(() => setExportNowAlter(false), 5000);
	};

	return (
		<div id="adminSettingsContainer" style={{ padding: '10px' }}>
			{jobDetails && (
				<div>
					<Alert variant="outlined" style={{ width: '100%' }} severity="info">
						{' '}
						<AlertTitle>Scheduled</AlertTitle>
						<p>
							Export job is scheduled. Started on{' '}
							<Chip
								label={new Date(
									Date.parse(jobDetails?.CronTrigger?.StartTime),
								).toLocaleString()}
								variant="outlined"
							/>
						</p>
						<p>
							{jobDetails?.CronTrigger?.PreviousFireTime && (
								<p>
									Last executed on{' '}
									<Chip
										variant="outlined"
										label={new Date(
											Date.parse(jobDetails?.CronTrigger?.PreviousFireTime),
										).toLocaleString()}
									/>
								</p>
							)}
						</p>
						<p>
							Next execute on{' '}
							<Chip
								variant="outlined"
								label={new Date(
									Date.parse(jobDetails?.CronTrigger?.NextFireTime),
								).toLocaleString()}
							/>
						</p>
					</Alert>
				</div>
			)}
			{!jobDetails && (
				<div>
					<Alert variant="outlined" severity="info" style={{ width: '100%' }}>
						{' '}
						<AlertTitle>Not Scheduled</AlertTitle>Export job is not scheduled. click
						Schedule button to schedule it.
					</Alert>
				</div>
			)}

			<div>
				<FormGroup style={{ width: '300px', padding: '15px' }}>
					<div className="main-title">Recurs every week on</div>

					<FormControlLabel
						control={
							<Checkbox
								id="day-0"
								disabled={jobDetails}
								checked={daysChecked[0]}
								onChange={handleDayChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label="Sunday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-1"
								disabled={jobDetails}
								checked={daysChecked[1]}
								onChange={handleDayChange}
							/>
						}
						label="Monday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-2"
								disabled={jobDetails}
								checked={daysChecked[2]}
								onChange={handleDayChange}
							/>
						}
						label="Tuesday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-3"
								disabled={jobDetails}
								checked={daysChecked[3]}
								onChange={handleDayChange}
							/>
						}
						label="Wednesday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-4"
								disabled={jobDetails}
								checked={daysChecked[4]}
								onChange={handleDayChange}
							/>
						}
						label="Thursday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-5"
								disabled={jobDetails}
								checked={daysChecked[5]}
								onChange={handleDayChange}
							/>
						}
						label="Friday"
					/>
					<FormControlLabel
						control={
							<Checkbox
								id="day-6"
								disabled={jobDetails}
								checked={daysChecked[6]}
								onChange={handleDayChange}
							/>
						}
						label="Saturday"
					/>

					{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Start Date" />
                        <DatePicker label="End Date" />
                        </LocalizationProvider> */}

					<br />
					<InputLabel id="demo-simple-select-label">Preferred Start Time</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={startTime}
						label="Preferred Start Time"
						disabled={jobDetails}
						onChange={handleStartTimeChange}
					>
						<MenuItem value={0}>12:00 AM</MenuItem>
						<MenuItem value={1}>01:00 AM</MenuItem>
						<MenuItem value={2}>02:00 AM</MenuItem>
						<MenuItem value={3}>03:00 AM</MenuItem>
						<MenuItem value={4}>04:00 AM</MenuItem>
						<MenuItem value={5}>05:00 AM</MenuItem>
						<MenuItem value={6}>06:00 AM</MenuItem>
						<MenuItem value={7}>07:00 AM</MenuItem>
						<MenuItem value={8}>08:00 AM</MenuItem>
						<MenuItem value={9}>09:00 AM</MenuItem>
						<MenuItem value={10}>10:00 AM</MenuItem>
						<MenuItem value={11}>11:00 AM</MenuItem>
						<MenuItem value={12}>12:00 PM</MenuItem>
						<MenuItem value={13}>01:00 PM</MenuItem>
						<MenuItem value={14}>02:00 PM</MenuItem>
						<MenuItem value={15}>03:00 PM</MenuItem>
						<MenuItem value={16}>04:00 PM</MenuItem>
						<MenuItem value={17}>05:00 PM</MenuItem>
						<MenuItem value={18}>06:00 PM</MenuItem>
						<MenuItem value={19}>07:00 PM</MenuItem>
						<MenuItem value={20}>08:00 PM</MenuItem>
						<MenuItem value={21}>09:00 PM</MenuItem>
						<MenuItem value={22}>10:00 PM</MenuItem>
						<MenuItem value={23}>11:00 PM</MenuItem>
					</Select>
				</FormGroup>
				<br />
				<FWButton onClick={handleSchedule} disabled={jobDetails}>
					Schedule
				</FWButton>{' '}
				&nbsp;
				<FWButton onClick={handleAbort} disabled={!jobDetails}>
					Abort
				</FWButton>{' '}
				&nbsp;
				<FWButton onClick={handleExportNow}>Export Now</FWButton>
				<Snackbar
					open={showExportNowAlter}
					autoHideDuration={6000}
					onClose={() => {}}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert severity="info" sx={{ width: '100%' }} onClose={() => {}}>
						Export is queued successfully. Please do not make any changes in
						configuration while export is in progress!
					</Alert>
				</Snackbar>
			</div>
		</div>
	);
}

function getDayFromNumber(num) {
	if (num === 0) return 'SUN';
	if (num === 1) return 'MON';
	if (num === 2) return 'TUE';
	if (num === 3) return 'WED';
	if (num === 4) return 'THU';
	if (num === 5) return 'FRI';
	if (num === 6) return 'SAT';
	return 'SUN';
}
function getNumberFromDay(d) {
	if (d === 'SUN') return 0;
	else if (d === 'MON') return 1;
	else if (d === 'TUE') return 2;
	else if (d === 'WED') return 3;
	else if (d === 'THU') return 4;
	else if (d === 'FRI') return 5;
	else if (d === 'SAT') return 6;
	return 0;
}
