import React, { useEffect, useState } from 'react';
import FWAutocomplete from '../mui/FWAutocomplete';
import ConfigureDocumentService from '../../services/ConfigureDocumentService';

function ProductSelectionCmp({
	onChange,
	existingValue,
	disableAdd = true,
	allowToAddNewValues = false,
	provideSaveButton = false,
	recordIds,
}) {
	const [fileTagPromise, setFileTagPromise] = useState();

	useEffect(() => {
		setFileTagPromise(ConfigureDocumentService.getTags({ onlyProducts: true }));
	}, []);

	const onProductSelectionChange = (value) => {
		onChange(value);
	};
	return (
		<div className="productSelectionCmpContainer">
			<FWAutocomplete
				existingVal={existingValue}
				disableAdd={disableAdd}
				allowToAddNewValues={allowToAddNewValues}
				dataPromise={fileTagPromise}
				provideSaveButton={provideSaveButton}
				recordIds={recordIds}
				multiple={true}
				onChangeHandler={(value, oldValue) => {
					onProductSelectionChange(value);
				}}
			/>
		</div>
	);
}
export default ProductSelectionCmp;
