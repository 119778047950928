import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
} from '@mui/material';
import {
	ArrowForwardOutlined,
	CoPresentOutlined,
	DescriptionOutlined,
	ImageOutlined,
	InsertDriveFile,
	InsertDriveFileOutlined,
	NoteAltOutlined,
	PictureAsPdfOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import FWCurrency from '../mui/FWCurrency';
import FWGradiantIcon from '../mui/FWGradiantIcon';

import SalesforceService from '../../services/SalesforceService';
import { FWGradiantButton } from '../mui/FWButtons';
import { green } from '@mui/material/colors';
import SearchResultSummaryCmp from '../SearchResultSummaryCmp';
//import SalesforceCommonApi from '../../apis/SalesforceCommonApi';
//import FileApi from '../../apis/FileApi';

function FWTitle({ title }) {
	return (
		<Typography
			variant="h7"
			component="div"
			sx={{
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '22px',
				color: '#506977',
			}}
		>
			{title}
		</Typography>
	);
}

function FWSubtitle({ title }) {
	return (
		<Typography
			variant="body1"
			component="div"
			sx={{
				fontWeight: '600',
				fontSize: '16px',
				lineHeight: '22px',
				color: '#05283C',
			}}
		>
			{title}
		</Typography>
	);
}

function RecordLink({ label }) {
	const open = Boolean(null);
	return (
		<div>
			<Typography
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				//onMouseEnter={handlePopoverOpen}
				//onMouseLeave={handlePopoverClose}
				className="hover-pointer record-lookup"
				style={{ cursor: '' }}
				color="rgb(0, 114, 229)"
			>
				{label}
			</Typography>
			{/* {showPopOver && <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{popOverBody}</Typography>
            </Popover>} */}
		</div>
	);
}

function RecordAnchor({ recordId, label, onClickRow }) {
	const onClick = () => onClickRow?.(recordId);
	return (
		<div>
			<Typography
				aria-haspopup="true"
				className="hover-pointer record-lookup"
				style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}
				onClick={onClick}
				color="rgb(0, 114, 229)"
			>
				{label}
			</Typography>
		</div>
	);
}

const RecordCardCmp = ({
	record,
	objectDescribe,
	showTitle = false,
	noOfVisibleFields = 2,
	onSelectPlayBook,
}) => {
	return (
		<Card>
			<CardContent>
				<Grid container direction={'column'}>
					<Grid item className="record-lookup">
						<Typography
							className="hover-pointer record-lookup"
							style={{ cursor: 'pointer' }}
							color="rgb(0, 114, 229)"
							onClick={() => {
								if (objectDescribe.name === 'salesPlayBook')
									onSelectPlayBook(record.Id);
								else {
									window.open(
										SalesforceService.getInstanceUrl() + '/' + record.Id,
										'_blank',
									);
								}
							}}
						>
							{record.Name}
						</Typography>
						{/* <Link
            className="hover-pointer"
            
            underline="hover"
            variant="soft"
            style={{cursor : 'pointer'}}
            sx={{cursor : 'pointer'}}
            
            >{record.Name}</Link> */}
					</Grid>
					{objectDescribe.form.fields
						.filter((item) => {
							return !item.excludeInCardView && item.name !== 'Name';
						})
						.map((field) => {
							return (
								<Grid
									key={field.name || field.label}
									item
									container
									direction={'column'}
									style={{ overflow: 'hidden' }}
								>
									<Grid item>
										<Typography
											color={'primary'}
											style={{ fontWeight: 'bold' }}
										>
											{field.label}
										</Typography>
									</Grid>
									<Grid item>
										<GenericOutputCmp
											value={record[field.name]}
											inputType={field.type}
										/>
									</Grid>
								</Grid>
							);
						})}
				</Grid>
			</CardContent>
		</Card>
	);
};

function GenericOutputCmp({ inputType, value }) {
	return (
		<div
			style={{
				width: '100%',
				marginBottom: inputType !== 'Checkbox' ? '5px' : '',
			}}
		>
			{(inputType === 'TextField' || inputType === 'TextArea') && (
				<div className="overflow-text">{value}</div>
			)}
			{inputType === 'RichTextArea' && (
				<div
					style={{ height: '40px' }}
					className="overflow-text"
					dangerouslySetInnerHTML={{ __html: value }}
				/>
			)}
			{inputType === 'Checkbox' && <Checkbox disabled checked={value} />}
			{inputType === 'Number' && <div>{value}</div>}
			{inputType === 'Currency' && <FWCurrency value={value ? value : 0} prefix={'$'} />}
		</div>
	);
}

/*
action : {
    name : 'delete',
    label : 'Delete',
    icon : <DeleteIcon>,
    target : fn 
}
*/
function FileTileCmp({ fileName, label, actions }) {
	const [fileType, setFileType] = useState('other');
	const [iconColor1, setIconColor1] = useState('#3e4246');
	const [iconColor2, setIconColor2] = useState('#8f959b');

	useEffect(() => {
		let _color1, _color2;
		if (fileName) {
			let lastIndex = fileName.lastIndexOf('.');
			if (lastIndex > 0) {
				let _fileExt = fileName.substring(lastIndex + 1) || '';
				let fileType;
				_fileExt = _fileExt.toLowerCase();
				if (_fileExt === 'doc' || _fileExt === 'docx') {
					fileType = 'word';
					_color1 = '#185abe';
					_color2 = '#40a5ed';
				} else if (_fileExt === 'pdf') {
					fileType = 'pdf';
					_color1 = 'rgba(226, 15, 66, 0.78)';
					_color2 = 'rgba(154, 11, 222, 0.48)';
				} else if (_fileExt === 'txt') {
					fileType = 'text';
					_color1 = '#2d98db';
					_color2 = '#8bb9d5';
				} else if (_fileExt.startsWith('ppt')) {
					fileType = 'ppt';
					_color1 = 'rgba(222, 49, 11, 0.74)';
					_color2 = '#DEB00B';
				} else if (_fileExt.startsWith('xl')) {
					fileType = 'excel';
					_color1 = '#115c34';
					_color2 = '#2fc582';
				} else if (
					_fileExt.startsWith('jpeg') ||
					_fileExt.startsWith('jpg') ||
					_fileExt.startsWith('png') ||
					_fileExt.startsWith('bmp') ||
					_fileExt.startsWith('gif') ||
					_fileExt.startsWith('ico')
				) {
					fileType = 'image';
					_color1 = '#5824a0';
					_color2 = '#a66df5';
				} else {
					fileType = 'other';
					_color1 = '#3e4246';
					_color2 = '#8f959b';
				}
				setFileType(fileType);
				setIconColor1(_color1);
				setIconColor2(_color2);
			}
		}
	}, [fileName]);

	return (
		<Box
			display="flex"
			className="fileButton"
			p={1}
			gap={0}
			bgcolor={'#f8f8f8'}
			borderRadius={1}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			<Box>
				<FWGradiantIcon
					style={{ paddingTop: '6px' }}
					color1={iconColor1}
					color2={iconColor2}
					iconKey={fileName?.replaceAll(' ', '')}
				>
					{fileType === 'word' ? (
						<DescriptionOutlined />
					) : fileType === 'pdf' ? (
						<PictureAsPdfOutlined />
					) : fileType === 'text' ? (
						<InsertDriveFileOutlined />
					) : fileType === 'excel' ? (
						<DescriptionOutlined />
					) : fileType === 'ppt' ? (
						<CoPresentOutlined />
					) : fileType === 'image' ? (
						<ImageOutlined />
					) : (
						<InsertDriveFile />
					)}
				</FWGradiantIcon>
			</Box>
			<Grid container direction={'column'} sx={{ flex: 'auto' }}>
				<Grid item style={{ paddingBottom: '0px' }}>
					{' '}
					&nbsp;{label} &nbsp;
				</Grid>
			</Grid>
			{(actions || []).map((action) => {
				return (
					<Box key={action.name}>
						<Box>
							{action.label ? (
								<Button
									color="primary"
									size="small"
									data-id={action.name}
									onClick={action.target}
									style={{
										marginRight: '15px',
										color: '#0B5FDE',
									}}
								>
									{action.label}
								</Button>
							) : action.target ? (
								<IconButton
									size="medium"
									color="primary"
									data-id={action.name}
									onClick={action.target}
								>
									{action.icon}
								</IconButton>
							) : (
								<div style={{ paddingTop: '5px' }}>{action.icon}</div>
							)}
						</Box>
					</Box>
				);
			})}
		</Box>
	);
}

function FileLinkCmp({ fileName, label, fileUrl, contentVersionId }) {
	const [showFileSummary, setShowFileSummary] = useState(false);
	return (
		<>
			{showFileSummary && (
				<SearchResultSummaryCmp
					title={label}
					description={fileName}
					contentVersionId={contentVersionId}
					recordId={contentVersionId}
					onClose={() => {
						setShowFileSummary(false);
					}}
				/>
			)}

			<FileTileCmp
				fileName={fileName}
				label={label}
				actions={(contentVersionId
					? [
							{
								name: 'summarise',
								icon: (
									<NoteAltOutlined
										titleAccess="Summarize"
										sx={{ color: '#21BBEC' }}
									/>
								),
								target: async () => {
									setShowFileSummary(true);
								},
							},
						]
					: []
				).concat([
					{
						name: 'open',
						icon: <ArrowForwardOutlined fontSize="small" sx={{ color: '#21BBEC' }} />,
						target: () => {
							window.open(fileUrl, '_blank');
						},
					},
				])}
			/>
		</>
	);
}

function StatefulButton({
	isProcessing,
	label = 'Submit',
	processingLabel = 'Submitting...',
	onClick,
	disabled,
	props,
}) {
	const [stateIsProcessing, setStateIsprocessing] = useState(false);

	useEffect(() => {
		setStateIsprocessing(isProcessing);
	}, [isProcessing]);

	const onClickHandler = (event) => {
		setStateIsprocessing(true);
		if (onClick) onClick(event);
	};
	return (
		<Box sx={{ position: 'relative' }}>
			<FWGradiantButton
				//variant="outlined"
				//color="error"
				//sx={buttonSx}
				onClick={onClickHandler}
				disabled={stateIsProcessing || disabled}
				{...props}
			>
				{stateIsProcessing ? processingLabel : label}
			</FWGradiantButton>
			{stateIsProcessing && (
				<CircularProgress
					size={24}
					sx={{
						color: green[500],
						position: 'absolute',
						top: '50%',
						left: '50%',
						marginTop: '-12px',
						marginLeft: '-12px',
					}}
				/>
			)}
		</Box>
	);
}

function TitleCmp({ title, icon }) {
	return (
		<Grid container direction={'row'}>
			<Grid item style={{ color: '#697E8A', paddingLeft: '8px' }}>
				{icon}
			</Grid>
			<Grid
				item
				style={{
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '20px',
					color: '#697E8A',
					paddingLeft: '8px',
					paddingTop: '3px',
				}}
			>
				{title}
			</Grid>
		</Grid>
	);
}
//Use this instead of useState to fix stale state values in functions using state
//Usage example: const [state, setState] = useAsyncReference(0)
//or: const [state, setState] = useAsyncReference(0, true) //set true if passed value is from function prop
//Usage of state value in function calls: (result) => uploadHandler(result, state.current);
//or: async (result) => { await uploadHandler(result, state.current); onUpload(result); }
//If you would have used useState instead in above code, value would always be 0, because initial value is 0.
//This is an issue known as Javascript Closures & causes React stale state value bug.
/**
 * A custom hook to fix stale state values in functions using state.
 * @param {any} value - The initial state value.
 * @param {boolean} [isProp=false] - A flag indicating if the passed value is from function prop.
 * @returns {Array} An array where the first element is a ref object pointing to the current state, and the second element is a function to update the state.
 */
function useAsyncReference(value, isProp = false) {
	// Create a ref with the initial value
	const ref = useRef(value);

	// useState is used here to force a re-render
	const [, forceRender] = useState(false);

	// Function to update the state
	function updateState(newState) {
		// Only update the state if the new state is different from the current state
		if (!Object.is(ref.current, newState)) {
			ref.current = newState;
			// Force a re-render after updating the state
			forceRender((s) => !s);
		}
	}

	// If the passed value is from function prop, update the ref directly
	if (isProp) {
		ref.current = value;
		return ref;
	}

	// Return the ref and the state update function
	return [ref, updateState];
}

export {
	FWTitle,
	FWSubtitle,
	RecordLink,
	RecordAnchor,
	RecordCardCmp,
	GenericOutputCmp,
	FileTileCmp,
	FileLinkCmp,
	StatefulButton,
	TitleCmp,
	useAsyncReference,
};
