const CollectionUtils = {};

// Check for Duplicate and Return true if element pushed
CollectionUtils.push = (list, element, prop) => {
	let exists = (list || []).some((elem) => {
		return elem[prop] === element[prop];
	});
	if (!exists) list.push(element);
	return !exists;
};

function sliceIntoChunks(arr, chunkSize) {
	const res = [];
	for (let i = 0; i < arr.length; i += chunkSize) {
		const chunk = arr.slice(i, i + chunkSize);
		res.push(chunk);
	}
	return res;
}

CollectionUtils.executeBatch = ({ items, actionPromise, recordsPerChunk = 100, async = true }) => {
	//return new Promise((resolve,reject)=>{
	let chunks = sliceIntoChunks(items, recordsPerChunk);
	let promises = [];
	for (let chunk of chunks) {
		if (async) {
			//promises.push(actionPromise(chunk));
			setTimeout(() => {
				actionPromise(chunk);
			}, 10);
		}
	}
	if (promises.length > 0) {
		Promise.all(promises).then((allPromiseResults) => {
			let all = [];
			for (let promiseResults of allPromiseResults) {
				all = all.concat(promiseResults);
			}
			//resolve(all);
		});
	}
	// });
};

CollectionUtils.compareArrays = (arr1, arr2) => {
	let newItems = [],
		existingItems = [],
		removedItems = [];
	for (let item2 of arr2) {
		if (arr1.includes(item2)) existingItems.push(item2);
		else newItems.push(item2);
	}

	for (let item1 of arr1) {
		if (!arr2.includes(item1)) removedItems.push(item1);
	}
	return { newItems, existingItems, removedItems };
};

export default CollectionUtils;
