import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FWButton } from '../../mui/FWButtons';
import SmallLoadingCmp from '../../SmallLoadingCmp';
import { StatefulButton } from '../../common/UtilComponents';
import ConfigureDocumentService from '../../../services/ConfigureDocumentService';
import SalesforceService from '../../../services/SalesforceService';
import SalesPlayAdminService from '../../../services/SalesPlayAdminService';
import { OBJECT_TYPE_File } from '../../../Constants';
import { ns } from '../../../utils/Utils';
import {
	QKEY_ALL_OPPTIES,
	QKEY_FWAVE_FILES,
	QKEY_MY_OPPTIES,
	QKEY_OPPTY_REQUIREMENTS,
	QKEY_TAGS,
} from '../../../utils/QueryKeys';

const deleteFn = async (applicableFiles, queryClient) => {
	let errorMsgs = [];
	let fileIds = [];
	for (let file of applicableFiles) {
		fileIds.push(file.featurewaveFileId);
	}
	const deleteResult = await ConfigureDocumentService.deleteTag(fileIds, ns(OBJECT_TYPE_File));
	for (let deleteResultItem of deleteResult || []) {
		if (!deleteResultItem?.success) {
			for (let error of deleteResultItem.errors) {
				if (error.message) errorMsgs.push(error.message);
			}
		}
	}

	const currentUserId = SalesforceService.getCurrentUserId();
	await Promise.allSettled(
		[[QKEY_FWAVE_FILES], [QKEY_TAGS], [QKEY_ALL_OPPTIES], [QKEY_MY_OPPTIES, currentUserId]].map(
			(qKey) => queryClient.invalidateQueries({ queryKey: qKey }),
		),
	);

	if (errorMsgs.length > 0) {
		return errorMsgs.join(', ');
	}
	return undefined;
};
function FileDeleteConfirmationCmp({
	showDeleteConfirmation,
	setDeleteConfirmation,
	selectedFiles,
	onDeleteFn,
}) {
	const [applicableFiles, setApplicableFiles] = useState([]);
	const [nonApplicableFileMap, setNonApplicableFileMap] = useState({});
	const [nonApplicableFiles, setNonApplicableFiles] = useState([]);
	const [nonApplicableFileDtls, setNonApplicableFileDtls] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		let _applicableFiles = [];
		let _nonApplicableFileMap = [];
		let _nonApplicableFiles = [];

		for (let file of selectedFiles || []) {
			if (
				file.FileType === 'Customer Stories' ||
				file.FileType === 'First Call Deck' ||
				file.FileType === 'Data Sheets' ||
				file.FileType === 'Discovery and Industry Trend'
			) {
				_nonApplicableFileMap[file.featurewaveFileId] = file;
				_nonApplicableFiles.push(file);
			} else {
				_applicableFiles.push(file);
			}
		}

		setApplicableFiles(_applicableFiles);
		setNonApplicableFiles(_nonApplicableFiles);
		setNonApplicableFileMap(_nonApplicableFileMap);
	}, [selectedFiles]);

	useEffect(() => {
		if (nonApplicableFiles?.length > 0) {
			//Show Spinner and fetch file sales play information
			setIsLoading(true);
			let nonAppFileIds = [];
			for (let _nonApplicableFile of nonApplicableFiles) {
				nonAppFileIds.push(_nonApplicableFile.featurewaveFileId);
			}
			if (nonAppFileIds.length > 0) {
				SalesPlayAdminService.getFileSalesplayInfo({ fwFileIds: nonAppFileIds }).then(
					(_fileSalesplayInfoMap) => {
						let _nonApplicableFileDtls = [];
						for (let fileId in _fileSalesplayInfoMap) {
							let fileSO = nonApplicableFileMap[fileId];
							let fileSalesplayInfo = _fileSalesplayInfoMap[fileId];
							_nonApplicableFileDtls.push(
								<p>
									File <b>{fileSO.Title}</b> is <b>{fileSO.FileType}</b>,
									associated with Sales Play{' '}
									<b>{fileSalesplayInfo.salesPlayName}</b>
								</p>,
							);
						}
						setNonApplicableFileDtls(_nonApplicableFileDtls);
						setIsLoading(false);
					},
				);
			} else {
				setIsLoading(false);
			}
		}
	}, [nonApplicableFiles, nonApplicableFileMap]);

	return (
		<Dialog
			open={true}
			//onClose={handleClose}
			scroll="paper"
			maxWidth="md"
			fullWidth="true"
			height="900px"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">
				<center>Are you sure?</center>
			</DialogTitle>
			<DialogContent dividers={true}>
				{isLoading && <SmallLoadingCmp />}
				{!isLoading && (
					<>
						<div className="slds-p-around_medium slds-text-heading_small">
							<p>
								Total <b>{selectedFiles.length}</b> file(s) are selected.
							</p>
							<br />
							{applicableFiles.length > 0 && (
								<div>
									{applicableFiles.length > 0 ? (
										<b>{applicableFiles.length}</b>
									) : (
										<></>
									)}{' '}
									file(s) out of {<b>{selectedFiles.length}</b>}, will be deleted.
									Click on <b>Delete</b> to continue.
								</div>
							)}
							{applicableFiles.length === 0 && <div>No file(s) to delete. </div>}
							<br />
							<br />
							{nonApplicableFileDtls.length > 0 && (
								<div>
									<div>
										<b>{nonApplicableFileDtls.length}</b> file(s) out of{' '}
										<b>{selectedFiles.length}</b>, includes files from Sales
										Play including Customer Stories, First Call Decks &
										Documents. Details are below. These files can not be deleted
										from here. Please go to the{' '}
										<b>Admin Settings &gt; Sales Play Admin &gt; Sales Play</b>{' '}
										and delete / replace from there.
									</div>
								</div>
							)}
							{/* <p>{isOwgWideAccess(sharingItem) ?' When you stop sharing a file with a company you also stop sharing it with public groups and posts on public user profiles.' : ''}</p> */}
						</div>
						<ul>
							{nonApplicableFileDtls.map((fileDtls, index) => {
								return <li key={index}>{fileDtls}</li>;
							})}
						</ul>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<FWButton
					key="cancel-clear-mapping"
					label="Cancel"
					variant="outlined"
					onClick={() => {
						setDeleteConfirmation(false);
					}}
				>
					Cancel
				</FWButton>
				<StatefulButton
					isProcessing={isDeleting}
					key="confirm-clear-mapping"
					label="Delete"
					processingLabel="Deleting..."
					disabled={applicableFiles.length === 0}
					variant="contained"
					onClick={() => {
						let deletePromise = deleteFn(applicableFiles, queryClient);
						setIsDeleting(true);
						deletePromise.then(async (errorMsg) => {
							onDeleteFn?.(errorMsg);
							await queryClient.invalidateQueries({
								queryKey: [QKEY_OPPTY_REQUIREMENTS],
							});
						});
					}}
				/>
			</DialogActions>
		</Dialog>
	);
}
export default FileDeleteConfirmationCmp;
