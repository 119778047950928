import { NAMESPACE_PREFIX } from '../Constants';
import SalesforceService from '../services/SalesforceService';

export class Contacts {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Contact(item));
		}
	}
}

export class Contact {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'Contact__r']) {
			this.Name = data[NAMESPACE_PREFIX + 'Contact__r'].Name;
			this.Email = data[NAMESPACE_PREFIX + 'Contact__r'].Email;
			this.Phone = data[NAMESPACE_PREFIX + 'Contact__r'].Phone;
		}
	}
}

export class Files {
	constructor(data) {
		this.DataSheets = [];
		this.FirstCallDeck = [];
		this.CustomerStories = [];
		this.industryTrendFiles = [];
		this.records = [];

		// separate out the different types of files
		if (data.records !== null && data.records !== undefined && data.records.length > 0) {
			data.records.forEach((item) => {
				// sort out and store files based on file type
				if (item[NAMESPACE_PREFIX + 'File__r']) {
					switch (item[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'FileType__c']) {
						case 'Data Sheets':
							this.DataSheets.push(new File(item));
							break;
						case 'First Call Deck':
							this.FirstCallDeck.push(new File(item));
							break;
						case 'Customer Stories':
							this.CustomerStories.push(new File(item));
							break;
						case 'Discovery and Industry Trend':
							this.industryTrendFiles.push(new File(item));
							break;
						default:

						// do nothing
					}
				}
				this.records.push(new File(item));
			});
		}
	}
}

export class File {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'File__r']) {
			this.Name = data[NAMESPACE_PREFIX + 'File__r'].Name;
			this.Title = data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'Title__c'];
			this.ExternalId =
				data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'ExternalId__c'];
			this.fileLink = null;
			this.ContentVersionId =
				data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'ContentVersionId__c'];
			this.ContentDocumentId =
				data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'ContentDocumentId__c'];
			if (data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'ContentDocumentId__c']) {
				this.fileLink =
					SalesforceService.getInstanceUrl() +
					'/' +
					data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'ContentDocumentId__c'];
			}
			//this.CompetitorStrengths__c = data[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + "FileType__c"];
		}
	}
}

export class Competitions {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Competition(item));
		}
	}
}

export class Competition {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'Competition__r']) {
			this.Name = data[NAMESPACE_PREFIX + 'Competition__r'].Name;
			this.CompetitorStrengths__c =
				data[NAMESPACE_PREFIX + 'Competition__r'][
					NAMESPACE_PREFIX + 'CompetitorStrengths__c'
				];
			this.HowWeBeatThisCompetitor__c =
				data[NAMESPACE_PREFIX + 'Competition__r'][
					NAMESPACE_PREFIX + 'HowWeBeatThisCompetitor__c'
				];
			this.Url__c = data[NAMESPACE_PREFIX + 'Competition__r'][NAMESPACE_PREFIX + 'Url__c'];
			this.RecentWins__c =
				data[NAMESPACE_PREFIX + 'Competition__r'][NAMESPACE_PREFIX + 'RecentWins__c'];
		}
	}
}

export class Demos {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Demo(item));
		}
	}
}

export class Demo {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'Demo__r']) {
			this.Name = data[NAMESPACE_PREFIX + 'Demo__r'].Name;
			this.Description__c =
				data[NAMESPACE_PREFIX + 'Demo__r'][NAMESPACE_PREFIX + 'Description__c'];
			this.Url__c = data[NAMESPACE_PREFIX + 'Demo__r'][NAMESPACE_PREFIX + 'Url__c'];
			this.RecentWins__c =
				data[NAMESPACE_PREFIX + 'Demo__r'][NAMESPACE_PREFIX + 'RecentWins__c'];
			this.baseName = data[NAMESPACE_PREFIX + 'Demo__r']['Name'];
		}
	}
}

export class ElevatorPitches {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new ElevatorPitch(item));
		}
	}
}

//TODO : DisableElevatorPitch
export class ElevatorPitch {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'ElevatorPitch__r']) {
			this.Description__c =
				data[NAMESPACE_PREFIX + 'ElevatorPitch__r'][NAMESPACE_PREFIX + 'Description__c'];
		}
	}
}

export class PersonaMessaging {
	constructor(data, relatedFiles) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.files = [];
		// related
		if (data[NAMESPACE_PREFIX + 'PersonaMessagingId__r']) {
			this.Message__c =
				data[NAMESPACE_PREFIX + 'PersonaMessagingId__r'][NAMESPACE_PREFIX + 'Message__c'];
			this.Name = data[NAMESPACE_PREFIX + 'PersonaMessagingId__r']['Name'];
		}
		if (relatedFiles) {
			for (let relatedFile of relatedFiles) {
				if (
					relatedFile[NAMESPACE_PREFIX + 'File__r'][
						NAMESPACE_PREFIX + 'ExternalId__c'
					] === this.Id
				) {
					let fileObj = relatedFile[NAMESPACE_PREFIX + 'File__r'];
					fileObj.fileLink =
						SalesforceService.getInstanceUrl() +
						'/' +
						relatedFile[NAMESPACE_PREFIX + 'File__r'][
							NAMESPACE_PREFIX + 'ContentDocumentId__c'
						];
					fileObj.Title = fileObj[NAMESPACE_PREFIX + 'Title__c'];
					this.files.push(fileObj);
				}
			}
		}
	}
}

export class DiscoveryIndustryTrend {
	constructor(data, industryFiles) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.files = [];
		// related
		if (data[NAMESPACE_PREFIX + 'DiscoveryIndustryTrendId__r']) {
			this.Description__c =
				data[NAMESPACE_PREFIX + 'DiscoveryIndustryTrendId__r'][
					NAMESPACE_PREFIX + 'Description__c'
				];
			this.Name = data[NAMESPACE_PREFIX + 'DiscoveryIndustryTrendId__r']['Name'];
		}
		if (industryFiles) {
			for (let industryFile of industryFiles) {
				if (
					industryFile[NAMESPACE_PREFIX + 'File__r'][
						NAMESPACE_PREFIX + 'ExternalId__c'
					] === this.Id
				) {
					let fileObj = industryFile[NAMESPACE_PREFIX + 'File__r'];
					fileObj.fileLink =
						SalesforceService.getInstanceUrl() +
						'/' +
						industryFile[NAMESPACE_PREFIX + 'File__r'][
							NAMESPACE_PREFIX + 'ContentDocumentId__c'
						];
					fileObj.Title = fileObj[NAMESPACE_PREFIX + 'Title__c'];
					this.files.push(fileObj);
				}
			}
		}
	}
}

export class PersonaMessagings {
	constructor(data, files) {
		let relatedFiles = files?.records?.filter(
			(file) =>
				file[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'FileType__c'] ===
				'Persona Messaging',
		);
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new PersonaMessaging(item, relatedFiles));
		}
	}
}

export class DiscoveryIndustryTrends {
	constructor(data, files) {
		let industryFiles = files?.records?.filter(
			(file) =>
				file[NAMESPACE_PREFIX + 'File__r'][NAMESPACE_PREFIX + 'FileType__c'] ===
				'Discovery and Industry Trend',
		);
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map(
				(item) => new DiscoveryIndustryTrend(item, industryFiles),
			);
		}
	}
}

export class ObjectionHandlings {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new ObjectionHandling(item));
		}
	}
}

export class ObjectionHandling {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;

		// related
		if (data[NAMESPACE_PREFIX + 'ObjectionHandling__r']) {
			this.Objection__c =
				data[NAMESPACE_PREFIX + 'ObjectionHandling__r'][NAMESPACE_PREFIX + 'Objection__c'];
			this.Response__c =
				data[NAMESPACE_PREFIX + 'ObjectionHandling__r'][NAMESPACE_PREFIX + 'Response__c'];
			this.baseName = data[NAMESPACE_PREFIX + 'ObjectionHandling__r']['Name'];
		}
	}
}

export class SalesPlaybook {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.IsActive__c = data[NAMESPACE_PREFIX + 'IsActive__c'];
		this.Description__c = data[NAMESPACE_PREFIX + 'Description__c'];
		this.CriticalMessaging__c = data[NAMESPACE_PREFIX + 'CriticalMessaging__c'];

		// related
		if (data[NAMESPACE_PREFIX + 'Objection_Handlings__r']) {
			this.Objection_Handlings__r = new ObjectionHandlings(
				data[NAMESPACE_PREFIX + 'Objection_Handlings__r'],
			);
		}
		//TODO : DisableElevatorPitch
		/*if (data[NAMESPACE_PREFIX + 'Sales_Playbook_Elevator_Pitches__r']) {
            this.Sales_Playbook_Elevator_Pitches__r = new ElevatorPitches(data[NAMESPACE_PREFIX + 'Sales_Playbook_Elevator_Pitches__r']);
        }*/
		if (data[NAMESPACE_PREFIX + 'SalesPlayPersonaMessagings__r']) {
			this.SalesPlayPersonaMessagings__r = new PersonaMessagings(
				data[NAMESPACE_PREFIX + 'SalesPlayPersonaMessagings__r'],
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Files__r'],
			);
		}
		if (data[NAMESPACE_PREFIX + 'SalesPlaybookDiscoveryIndustryTrends__r']) {
			this.SalesPlaybookDiscoveryIndustryTrends__r = new DiscoveryIndustryTrends(
				data[NAMESPACE_PREFIX + 'SalesPlaybookDiscoveryIndustryTrends__r'],
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Files__r'],
			);
		}
		if (data[NAMESPACE_PREFIX + 'Sales_Playbook_Demos__r']) {
			this.Sales_Playbook_Demos__r = new Demos(
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Demos__r'],
			);
		}
		if (data[NAMESPACE_PREFIX + 'Sales_Playbook_Competitions__r']) {
			this.Sales_Playbook_Competitions__r = new Competitions(
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Competitions__r'],
			);
		}
		if (data[NAMESPACE_PREFIX + 'Sales_Playbook_Files__r']) {
			this.Sales_Playbook_Files__r = new Files(
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Files__r'],
			);
		}
		if (data[NAMESPACE_PREFIX + 'Sales_Playbook_Contacts__r']) {
			this.Sales_Playbook_Contacts__r = new Contacts(
				data[NAMESPACE_PREFIX + 'Sales_Playbook_Contacts__r'],
			);
		}
	}
}
