import ApiGateway from './ApiGateway';
import { NAMESPACE_PREFIX } from '../Constants';
import { GPTSummary } from '../models/GPTSummary';

const GPTSummaryApi = {};

GPTSummaryApi.getGPTSummary = async ({ summaryId }) => {
	// return new GPTSummary({
	//     Id : recordId,
	//     fwave__GPTSummary__c : '{"id":"msg_01LvgU19W4QkXi4vPZcfwFPY","type":"message","role":"assistant","content":[{"type":"text","text":"Here is a summary of the Amazon sales "}],"model":"claude-instant-1.2","stop_reason":"end_turn","stop_sequence":null}',
	//     fwave__RecordId__c : recordId
	// });
	var queryStr =
		'SELECT id, name, ' +
		NAMESPACE_PREFIX +
		'GPTSummary__c, ' +
		NAMESPACE_PREFIX +
		'RecordSummary__c, ' +
		NAMESPACE_PREFIX +
		'GPTSummaryModifiedDate__c, ' +
		NAMESPACE_PREFIX +
		'RecordId__c ' +
		'FROM ' +
		NAMESPACE_PREFIX +
		'GPTSummary__c WHERE ' +
		NAMESPACE_PREFIX +
		"RecordId__c = '" +
		summaryId +
		"' " +
		'ORDER BY ' +
		NAMESPACE_PREFIX +
		'GPTSummaryModifiedDate__c DESC Limit 1';
	var resultData = await ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	if (resultData.length > 0) {
		return new GPTSummary(resultData[0]);
	} else {
		return null;
	}
};

export default GPTSummaryApi;
