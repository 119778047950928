import SalesforceService from '../services/SalesforceService';
import ApiGateway from './ApiGateway';
import {
	NAMESPACE_PREFIX,
	FILE_TYPE_SALESFORCE_FILES,
	FILE_TYPE_USER_GUIDES,
	FILE_TYPE_SUPPORTING_DOCUMENTS,
	CONFIG_SALESPLAY_PRODUCT,
} from '../Constants';
import Utils from '../utils/Utils';
import SalesforceCommonApi from '../apis/SalesforceCommonApi';

const ConfigureDocumentApi = {};

ConfigureDocumentApi.importFeatures = (features) => {
	return ApiGateway.invoke('sf', 'importFeatures', {}, undefined, JSON.stringify(features));
};

ConfigureDocumentApi.clearFeatures = (fileId) => {
	return ApiGateway.invoke('sf', 'clearFeatures', { fileId });
};

ConfigureDocumentApi.getFiles = ({ recordId, fileType, isContentDocumentsOnly }) => {
	//return ApiGateway.invoke('sf','getFiles',{recordId, fileType});
	//var versionQuery = `SELECT Id, Title, Description, TextPreview, VersionNumber, ContentSize, ContentDocumentId, FileExtension, LastModifiedDate FROM ContentVersion WHERE IsLatest = TRUE AND FileExtension in ('pdf','docx') AND ${NAMESPACE_PREFIX}DocumentType__c = '${fileType}'`;
	//return ApiGateway.invoke('sf', 'query', {}, { query : versionQuery});
	let _whereCondition = '';
	return new Promise((resolve, reject) => {
		if (fileType && fileType !== '') {
			if (fileType === FILE_TYPE_USER_GUIDES || fileType === FILE_TYPE_SALESFORCE_FILES) {
				_whereCondition = `WHERE ${NAMESPACE_PREFIX}FileType__c IN( '${FILE_TYPE_USER_GUIDES}','${FILE_TYPE_SUPPORTING_DOCUMENTS}','${FILE_TYPE_SALESFORCE_FILES}')`;
			} else {
				_whereCondition = `WHERE ${NAMESPACE_PREFIX}FileType__c = '${fileType}'`;
			}
		}
		var fileQuery = `SELECT Id, Name, ${NAMESPACE_PREFIX}Title__c, ${NAMESPACE_PREFIX}Status__c, ${NAMESPACE_PREFIX}Source__c, ${NAMESPACE_PREFIX}ContentDocumentId__c, ${NAMESPACE_PREFIX}ContentVersionId__c, ${NAMESPACE_PREFIX}FileType__c, ${NAMESPACE_PREFIX}FileDetails__c, LastModifiedDate, LastModifiedById, LastModifiedBy.Name, LastModifiedBy.FullPhotoUrl FROM ${NAMESPACE_PREFIX}File__c ${_whereCondition}`;

		var versionQuery = `SELECT Id, Title, Description, TextPreview, VersionNumber, ContentSize, ContentDocumentId, FileExtension, LastModifiedDate, LastModifiedById, LastModifiedBy.Name, LastModifiedBy.FullPhotoUrl FROM ContentVersion WHERE IsLatest = TRUE AND FileExtension in ('pdf','docx','xlsx','pptx')`; //AND ${NAMESPACE_PREFIX}DocumentType__c = '${fileType}'

		let filePromise = ApiGateway.invoke('sf', 'query', {}, { query: fileQuery });
		let versionPromise = ApiGateway.invoke('sf', 'query', {}, { query: versionQuery });

		let fileResults = [];

		let fileMap = {};
		let contentVersionIds = [];

		if (isContentDocumentsOnly === true) {
			Promise.all([filePromise, versionPromise]).then((responses) => {
				let [files, documentVersions] = responses;
				let contentVersionIds = new Set();
				for (let fileSO of files) {
					let contentVersionId = fileSO[`${NAMESPACE_PREFIX}ContentVersionId__c`];
					if (contentVersionId && contentVersionId !== '') {
						contentVersionIds.add(contentVersionId);
					}
				}

				for (let versionSO of documentVersions) {
					let fileResult = {};
					let contentVersionId = versionSO.Id;
					if (contentVersionIds.has(contentVersionId)) {
						continue;
					}
					let source = '-';
					fileResult.Id = versionSO.Id;
					fileResult.featurewaveFileId = undefined;
					fileResult.ContentVersionId = contentVersionId;
					fileResult.ContentDocumentId = versionSO.ContentDocumentId;
					fileResult.Title = versionSO.Title;
					fileResult.ContentSize = versionSO ? versionSO.ContentSize : 0;
					fileResult.LastModifiedDate = new Date(versionSO.LastModifiedDate).getTime();
					fileResult.FileExtension = versionSO ? versionSO.FileExtension : '.external';
					fileResult.Status = undefined;
					fileResult.Source = source;
					fileResult.isContentDocumentsOnly = isContentDocumentsOnly;
					fileResult.LastModifiedBy = versionSO.LastModifiedBy;

					if (!fileResult.Status) fileResult.Status = 'Uploaded';
					//fileResults.push(fileResult);
					fileResults = [...fileResults, fileResult];
				}

				resolve(fileResults);
			});
		} else {
			filePromise.then((files) => {
				for (let fileSO of files) {
					let contentVersionId = fileSO[`${NAMESPACE_PREFIX}ContentVersionId__c`];
					fileMap[contentVersionId] = fileSO;
					contentVersionIds.push(contentVersionId);
				}
				if (contentVersionIds.length > 0) {
					let versionIdsWhere = Utils.convertToWhereCondition(contentVersionIds);
					var versionQuery = `SELECT Id, Title, Description, TextPreview, VersionNumber, ContentSize, ContentDocumentId, FileExtension, LastModifiedDate FROM ContentVersion WHERE IsLatest = TRUE AND FileExtension in ('pdf','docx','xlsx','pptx') AND Id IN (${versionIdsWhere})`;
					let versionPromise = ApiGateway.invoke(
						'sf',
						'query',
						{},
						{ query: versionQuery },
					);
					versionPromise.then((documentVersions) => {
						let contentMap = {};
						for (let versionSO of documentVersions || []) {
							contentMap[versionSO.Id] = versionSO;
						}
						for (let fileSO of files || []) {
							let contentVersionId = fileSO[NAMESPACE_PREFIX + 'ContentVersionId__c'];
							let versionSO = contentMap[contentVersionId] || {};
							let fileResult = {};
							fileResult.Id = fileSO.Id;
							fileResult.ContentDocumentId = versionSO[`ContentDocumentId`];
							fileResult.Title = fileSO.Name;
							fileResult.ContentSize = versionSO?.ContentSize;
							fileResult.LastModifiedDate = new Date(
								fileSO.LastModifiedDate,
							).getTime();
							fileResult.FileExtension = versionSO?.FileExtension || '.external';
							fileResult.Status = fileSO
								? fileSO[NAMESPACE_PREFIX + 'Status__c']
								: undefined;
							fileResult.featurewaveFileId = fileSO ? fileSO.Id : undefined;
							fileResult.FileType = fileSO[NAMESPACE_PREFIX + 'FileType__c'];
							if (!fileResult.Status) fileResult.Status = 'Uploaded';
							fileResult.Source = fileSO[NAMESPACE_PREFIX + 'Source__c'];
							fileResult.LastModifiedBy = fileSO.LastModifiedBy;
							//fileResults.push(fileResult);
							let fileDetails = fileSO[NAMESPACE_PREFIX + 'FileDetails__c'];
							if (fileDetails && fileDetails != '') {
								try {
									let fileDtlsObj = JSON.parse(fileDetails);
									fileResult.fileUrl = fileDtlsObj.url;
									fileResult.fileLink = fileDtlsObj.link;
								} catch (e) {
									console.warn('Error parsing file details', e);
								}
							}
							fileResults = [...fileResults, fileResult];
						}
						// for (let versionSO of documentVersions || []) {
						// 	let contentVersionId = versionSO.Id;
						// 	let fileSO = fileMap[contentVersionId];
						// 	let fileResult = {};
						// 	fileResult.Id = versionSO.Id;
						// 	fileResult.ContentDocumentId = versionSO[`ContentDocumentId`];
						// 	fileResult.Title = versionSO.Title;
						// 	fileResult.ContentSize = versionSO.ContentSize;
						// 	fileResult.LastModifiedDate = new Date(
						// 		versionSO.LastModifiedDate,
						// 	).getTime();
						// 	fileResult.FileExtension = versionSO.FileExtension;
						// 	fileResult.Status = fileSO
						// 		? fileSO[NAMESPACE_PREFIX + 'Status__c']
						// 		: undefined;
						// 	fileResult.featurewaveFileId = fileSO ? fileSO.Id : undefined;
						// 	fileResult.FileType = fileSO[NAMESPACE_PREFIX + 'FileType__c'];
						// 	if (!fileResult.Status) fileResult.Status = 'Uploaded';
						// 	//fileResults.push(fileResult);
						// 	fileResults = [...fileResults, fileResult];
						// }
						resolve(fileResults);
					});
				} else {
					resolve(fileResults);
				}
			});
		}

		/* else {
			Promise.all([filePromise, versionPromise]).then((responses) => {
				let [files, documentVersions] = responses;
				let versionMap = {};
				for (let versionSO of documentVersions) {
					versionMap[versionSO.Id] = versionSO;
				}

				for (let fileSO of files) {
					let fileResult = {};
					let contentVersionId = fileSO[`${NAMESPACE_PREFIX}ContentVersionId__c`];
					let versionSO = versionMap[contentVersionId];
					let source = fileSO[NAMESPACE_PREFIX + 'Source__c']
						? fileSO[NAMESPACE_PREFIX + 'Source__c']
						: '-';

					fileResult.Id = fileSO.Id;
					fileResult.ContentVersionId = contentVersionId;
					fileResult.ContentDocumentId =
						fileSO[NAMESPACE_PREFIX + 'ContentDocumentId__c'];
					fileResult.Title = fileSO[NAMESPACE_PREFIX + 'Title__c'];
					fileResult.ContentSize = versionSO ? versionSO.ContentSize : 0;
					fileResult.LastModifiedDate = new Date(fileSO.LastModifiedDate).getTime();
					fileResult.FileExtension = versionSO ? versionSO.FileExtension : '.external';
					fileResult.Status = fileSO ? fileSO[NAMESPACE_PREFIX + 'Status__c'] : undefined;
					fileResult.FileType = fileSO ? fileSO[NAMESPACE_PREFIX + 'FileType__c'] : undefined;
					fileResult.featurewaveFileId = fileSO ? fileSO.Id : undefined;
					fileResult.Source = source;
					fileResult.isContentDocumentsOnly = isContentDocumentsOnly;

					if (!fileResult.Status) fileResult.Status = 'Uploaded';
					//fileResults.push(fileResult);
					fileResults = [...fileResults, fileResult];
				}

				resolve(fileResults);
			});
		}*/
	});
};

ConfigureDocumentApi.deleteFilesUsingUploadIds = (uploadIds) => {
	return new Promise((resolve, reject) => {
		let uploadIdWhere = Utils.convertToWhereCondition(uploadIds);
		let deleteIds = [];
		var fileQuery = `SELECT Id FROM ${NAMESPACE_PREFIX}File__c WHERE ${NAMESPACE_PREFIX}ExternalId__c IN (${uploadIdWhere})`;
		let filePromise = ApiGateway.invoke('sf', 'query', {}, { query: fileQuery });
		filePromise.then((files) => {
			if (files) {
				for (let file of files) {
					deleteIds.push(file.Id);
				}
			}
			if (deleteIds.length > 0) {
				SalesforceCommonApi.deleteRecords(deleteIds, NAMESPACE_PREFIX + 'File__c').then(
					() => {
						resolve();
					},
				);
			} else {
				resolve();
			}
		});
		// var fileQuery = `SELECT Id FROM ${NAMESPACE_PREFIX}File__c WHERE ${NAMESPACE_PREFIX}ExternalId__c IN (${uploadIdWhere})`;

		// var versionQuery = `SELECT Id, ContentDocumentId FROM ContentVersion WHERE ${NAMESPACE_PREFIX}ExternalId__c IN (${uploadIdWhere})`;

		// let filePromise = ApiGateway.invoke('sf', 'query', {}, { query : fileQuery});
		// let versionPromise = ApiGateway.invoke('sf', 'query', {}, { query : versionQuery});
		// Promise.all([filePromise,versionPromise]).then((responses)=>{
		//         let [files, documentVersions] = responses;
		//         if(files){
		//             for(let file of files){
		//                 deleteIds.push(file.Id);
		//             }
		//         }
		//         if(documentVersions){
		//             for(let documentVersion of documentVersions){
		//                 deleteIds.push(documentVersion.ContentDocumentId);
		//             }
		//         }

		//         if(deleteIds.length > 0){
		//             SalesforceCommonApi.deleteRecords(deleteIds).then(()=>{
		//                 resolve();
		//             })
		//         }else{
		//             resolve();
		//         }
		// });
	});
};

ConfigureDocumentApi.getFeatureImportsForFile = (fileId) => {
	return ApiGateway.invoke('sf', 'getFeatureImportsForFile', { fileId });
};
ConfigureDocumentApi.uploadFile = (formdata) => {
	return ApiGateway.invoke('sf', 'uploadfiles', {}, undefined, formdata);
};

ConfigureDocumentApi.getHtmlOfWordFile = (recordId, fileType) => {
	return ApiGateway.invoke(
		'doc',
		'doctohtml',
		{},
		{
			versiondata: SalesforceService.getVersionDataUrl(recordId, fileType),
			fileType,
		},
	);
};

ConfigureDocumentApi.autoDetectDoc = ({ fileId, fileType }) => {
	return ApiGateway.invoke(
		'doc',
		'autoDetectDoc',
		{},
		{
			fileId,
			fileType,
		},
	);
};

ConfigureDocumentApi.getFileDetails = async (fileId) => {
	var queryStr = `SELECT Id, Title, Description, TextPreview, VersionNumber, ContentSize, ContentDocumentId, FileExtension, LastModifiedDate FROM ContentVersion WHERE Id = '${fileId}'`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

ConfigureDocumentApi.getTags = async ({ productItemIds, onlyProducts }) => {
	let whereCondition = '';
	let hasProductItemIds = false;
	if (productItemIds && productItemIds.length > 0) {
		let productItemIdStr = Utils.convertToWhereCondition(productItemIds);
		whereCondition = ` WHERE Id IN (${productItemIdStr})`;
		hasProductItemIds = true;
	}
	if (onlyProducts && onlyProducts === true) {
		if (!hasProductItemIds) {
			whereCondition = ` WHERE (${NAMESPACE_PREFIX}Type__c = 'Product' OR ${NAMESPACE_PREFIX}Type__c = '') AND ${NAMESPACE_PREFIX}SystemTag__c = false`;
		} else {
			whereCondition += ` AND (${NAMESPACE_PREFIX}Type__c = 'Product' OR ${NAMESPACE_PREFIX}Type__c = '') AND ${NAMESPACE_PREFIX}SystemTag__c = false`;
		}
	}
	var queryStr = `SELECT Id, Name, ${NAMESPACE_PREFIX}Description__c, ${NAMESPACE_PREFIX}SystemTag__c, ${NAMESPACE_PREFIX}Type__c, (SELECT Id, Name, ${NAMESPACE_PREFIX}OwnerId__c,  ${NAMESPACE_PREFIX}OwnerId__r.Name FROM  ${NAMESPACE_PREFIX}TagOwners__r ) FROM ${NAMESPACE_PREFIX}Tag__c${whereCondition}`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

ConfigureDocumentApi.getSalesplayTags = async (salesplayId) => {
	var queryStr = `SELECT Id, Name, ${NAMESPACE_PREFIX}TagId__c, ${NAMESPACE_PREFIX}TagId__r.Id, ${NAMESPACE_PREFIX}TagId__r.Name, ${NAMESPACE_PREFIX}TagId__r.${NAMESPACE_PREFIX}Description__c FROM ${NAMESPACE_PREFIX}SalesPlayProduct__c WHERE ${NAMESPACE_PREFIX}SalesPlayId__c = '${salesplayId}'`;
	return ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
};

ConfigureDocumentApi.saveOrUpdateTags = async (tags) => {
	let records = [];
	let isUpdate = false;
	for (let tag of tags) {
		let tagSO = {};
		tagSO.Name = tag.name;
		tagSO[NAMESPACE_PREFIX + 'Description__c'] = tag.description;
		tagSO[NAMESPACE_PREFIX + 'Type__c'] = tag.type || 'Product';
		if (tag.Id) {
			tagSO.Id = tag.Id;
			isUpdate = true;
		}
		records.push(tagSO);
	}
	var body = {
		objectName: NAMESPACE_PREFIX + 'Tag__c',
		records: records,
	};
	return await ApiGateway.invoke(
		'sf',
		isUpdate ? 'update' : 'create',
		{},
		{},
		JSON.stringify(body),
	);
};

ConfigureDocumentApi.saveProductOwners = async (productId, ownerIds) => {
	let records = [];
	for (let ownerId of ownerIds) {
		let productOwnerSO = {};
		productOwnerSO[NAMESPACE_PREFIX + 'TagId__c'] = productId;
		productOwnerSO[NAMESPACE_PREFIX + 'OwnerId__c'] = ownerId;
		records.push(productOwnerSO);
	}
	var body = {
		objectName: NAMESPACE_PREFIX + 'TagOwner__c',
		records: records,
	};
	return await ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

ConfigureDocumentApi.deleteTag = (tagIds, objectType = 'unknown') => {
	return ApiGateway.invoke(
		'sf',
		'delete',
		{},
		{},
		JSON.stringify({ objectType: objectType, recordIds: tagIds }),
	);
};

/*
ConfigureDocumentApi.saveFileTags = async (versionIds, tags) => {
    let config = {};
    let versionIdInCondition = "("+ Array.from(versionIds).map((item)=> "'"+item+"'").join(',')+")"; 
    config.baseObjectApiName = NAMESPACE_PREFIX+'File__c';
    config.basRecordIds = '';
    config.baseWhereCond =  `${NAMESPACE_PREFIX}ContentVersionId__c IN ${versionIdInCondition}`;
    config.junctionObjectName = `${NAMESPACE_PREFIX}FileTag__c`;
    config.relationName = `${NAMESPACE_PREFIX}FileTags__r`;
    config.productLookupRelName = `${NAMESPACE_PREFIX}TagId__r`;
    config.productLookupFieldName = `${NAMESPACE_PREFIX}TagId__c`;
    config.baseLookupRelName = `${NAMESPACE_PREFIX}FileId__r`;
    config.baseLookupFieldName = `${NAMESPACE_PREFIX}FileId__c`;
    return ConfigureDocumentApi.saveProductsGeneric(config, versionIds, tags);
}
*/
ConfigureDocumentApi.saveFileTags = async (versionIds, tags) => {
	let versionIdInCondition =
		'(' +
		Array.from(versionIds)
			.map((item) => "'" + item + "'")
			.join(',') +
		')';
	var fileQuery = `SELECT Id, Name, ${NAMESPACE_PREFIX}Title__c, ${NAMESPACE_PREFIX}Status__c, ${NAMESPACE_PREFIX}ContentDocumentId__c, ${NAMESPACE_PREFIX}ContentVersionId__c, (SELECT Id, Name, ${NAMESPACE_PREFIX}TagId__r.Name FROM ${NAMESPACE_PREFIX}FileTags__r )  FROM ${NAMESPACE_PREFIX}File__c WHERE ${NAMESPACE_PREFIX}ContentVersionId__c IN ${versionIdInCondition}`;
	var fileSOList = await ApiGateway.invoke('sf', 'query', {}, { query: fileQuery });
	let tagLabelToIdMap = {};
	let tagIdToLabelMap = {};
	let fileIds = new Set();
	let newTags = new Set();
	//return new Promise((resolve,reject)=>{
	for (let tag of tags) {
		newTags.add(tag.text);
		tagLabelToIdMap[tag.text] = tag.Id;
		tagIdToLabelMap[tag.Id] = tag.text;
	}
	let existingFileTagMap = {};
	let existingFileTagMapWithoutId = {};
	for (let fileSO of fileSOList) {
		fileIds.add(fileSO.Id);
		if (!existingFileTagMap[fileSO.Id]) existingFileTagMap[fileSO.Id] = new Set();
		if (!existingFileTagMapWithoutId[fileSO.Id])
			existingFileTagMapWithoutId[fileSO.Id] = new Set();
		let fileTagSOListResult = fileSO[NAMESPACE_PREFIX + 'FileTags__r'];
		let fileTagSOList = fileTagSOListResult?.records || [];
		for (let fileTagSO of fileTagSOList) {
			let tagSO = fileTagSO[NAMESPACE_PREFIX + 'TagId__r'];
			let tagName = tagSO.Name;
			let fileTagId = fileTagSO.Id;

			existingFileTagMap[fileSO.Id].add(tagName + '__' + fileTagId);

			existingFileTagMapWithoutId[fileSO.Id].add(tagName);
		}
	}

	let deleteList = [];
	let addFileTagList = [];
	for (let fileId in existingFileTagMap) {
		let fileTagSet = existingFileTagMap[fileId];
		for (let tagAndId of fileTagSet) {
			let tagAndIdArr = tagAndId.split('__');
			let tag = tagAndIdArr[0];
			let fileTagId = tagAndIdArr[1];

			if (!newTags.has(tag)) {
				deleteList.push(fileTagId);
			}
		}
	}

	for (let newTag of newTags) {
		for (let fileId in existingFileTagMapWithoutId) {
			let fileTagSet = existingFileTagMapWithoutId[fileId];
			if (!fileTagSet.has(newTag)) {
				addFileTagList.push({
					fileId: fileId,
					tag: newTag,
					tagId: tagLabelToIdMap[newTag],
				});
			}
		}
	}

	let fileTagrecords = [];
	for (let fileTag of addFileTagList) {
		let fileTagRecord = {};
		fileTagRecord[NAMESPACE_PREFIX + 'FileId__c'] = fileTag.fileId;
		fileTagRecord[NAMESPACE_PREFIX + 'TagId__c'] = fileTag.tagId;

		fileTagrecords.push(fileTagRecord);
	}
	var body = {
		objectName: NAMESPACE_PREFIX + 'FileTag__c',
		records: fileTagrecords,
	};
	let promises = [];
	if (fileTagrecords.length > 0) {
		let savePromise = ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
		promises.push(savePromise);
		savePromise.then((fileTagSaveResults) => {});
	}

	if (deleteList && deleteList.length > 0) {
		let deletePromise = ApiGateway.invoke(
			'sf',
			'delete',
			{},
			{},
			JSON.stringify({
				recordIds: deleteList,
			}),
		);
		promises.push(deletePromise);
		deletePromise.then((fileTagDeleteResults) => {});
	}

	return Promise.all(promises);

	//});
};
ConfigureDocumentApi.saveSalesPlayTags = async (salesplayIds, selectedProducts) => {
	let config = CONFIG_SALESPLAY_PRODUCT;
	config.basRecordIds = salesplayIds;
	return ConfigureDocumentApi.saveProductsGeneric(config, salesplayIds, selectedProducts);
};

/*
config.baseObjectApiName = Object to which Products are associated like File, Sales Playbook
config.basRecordIds = base objects record Ids
config.baseWhereCond = Where Condition if Base Records IDs are not available
config.junctionObjectName = Name of Relation from Base to Product Object i.e FileTag__c
config.relationName = Name of Relation from Base to Product Object i.e FileTags__r
config.productLookupRelName = Product Lookup Field API name on Junction Object b/w Base and Product i.e TagId__r
config.productLookupFieldName = Product Lookup Field API name on Junction Object b/w Base and Product i.e TagId__c
config.baseLookupRelName = Product Lookup Field API name on Junction Object b/w Base and Product i.e FileId__r
config.baseLookupFieldName = Product Lookup Field API name on Junction Object b/w Base and Product i.e FileId__c
*/
ConfigureDocumentApi.saveProductsGeneric = async (config, recordIds, selectedProducts) => {
	let whereCondition =
		config.basRecordIds && config.basRecordIds.length > 0
			? 'Id IN (' +
				Array.from(config.basRecordIds)
					.map((item) => "'" + item + "'")
					.join(',') +
				')'
			: config.baseWhereCond;
	var fileQuery = `SELECT Id, Name, (SELECT Id, Name, ${config.productLookupRelName}.Name FROM ${config.relationName} )  FROM ${config.baseObjectApiName} WHERE ${whereCondition}`;
	var fileSOList = await ApiGateway.invoke('sf', 'query', {}, { query: fileQuery });
	let tagLabelToIdMap = {};
	let tagIdToLabelMap = {};
	let fileIds = new Set();
	let newTags = new Set();
	//return new Promise((resolve,reject)=>{
	for (let tag of selectedProducts || []) {
		newTags.add(tag.text);
		tagLabelToIdMap[tag.text] = tag.Id;
		tagIdToLabelMap[tag.Id] = tag.text;
	}
	let existingFileTagMap = {};
	let existingFileTagMapWithoutId = {};
	for (let fileSO of fileSOList) {
		fileIds.add(fileSO.Id);
		if (!existingFileTagMap[fileSO.Id]) existingFileTagMap[fileSO.Id] = new Set();
		if (!existingFileTagMapWithoutId[fileSO.Id])
			existingFileTagMapWithoutId[fileSO.Id] = new Set();
		let fileTagSOListResult = fileSO[config.relationName];
		let fileTagSOList = fileTagSOListResult?.records || [];
		for (let fileTagSO of fileTagSOList) {
			let tagSO = fileTagSO[config.productLookupRelName];
			let tagName = tagSO.Name;
			let fileTagId = fileTagSO.Id;

			existingFileTagMap[fileSO.Id].add(tagName + '__' + fileTagId);

			existingFileTagMapWithoutId[fileSO.Id].add(tagName);
		}
	}

	let deleteList = [];
	let addFileTagList = [];
	for (let fileId in existingFileTagMap) {
		let fileTagSet = existingFileTagMap[fileId];
		for (let tagAndId of fileTagSet) {
			let tagAndIdArr = tagAndId.split('__');
			let tag = tagAndIdArr[0];
			let fileTagId = tagAndIdArr[1];

			if (!newTags.has(tag)) {
				deleteList.push(fileTagId);
			}
		}
	}

	for (let newTag of newTags) {
		for (let fileId in existingFileTagMapWithoutId) {
			let fileTagSet = existingFileTagMapWithoutId[fileId];
			if (!fileTagSet.has(newTag)) {
				addFileTagList.push({
					fileId: fileId,
					tag: newTag,
					tagId: tagLabelToIdMap[newTag],
				});
			}
		}
	}

	let fileTagrecords = [];
	for (let fileTag of addFileTagList) {
		let fileTagRecord = {};
		fileTagRecord[config.baseLookupFieldName] = fileTag.fileId;
		fileTagRecord[config.productLookupFieldName] = fileTag.tagId;

		fileTagrecords.push(fileTagRecord);
	}
	var body = {
		objectName: config.junctionObjectName,
		records: fileTagrecords,
	};
	let promises = [];
	if (fileTagrecords.length > 0) {
		let savePromise = ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
		promises.push(savePromise);
		savePromise.then((fileTagSaveResults) => {});
	}

	if (deleteList && deleteList.length > 0) {
		let deletePromise = ApiGateway.invoke(
			'sf',
			'delete',
			{},
			{},
			JSON.stringify({
				recordIds: deleteList,
			}),
		);
		promises.push(deletePromise);
		deletePromise.then((fileTagDeleteResults) => {});
	}

	return Promise.all(promises);
};

ConfigureDocumentApi.getExistingMapping = (fileId) => {
	let existingCategoryMap = {};
	let existingCategoryRecordMap = {};
	var queryStr = `SELECT Id, ${NAMESPACE_PREFIX}Configuration__c,${NAMESPACE_PREFIX}CategoryId__c, ${NAMESPACE_PREFIX}CategoryId__r.${NAMESPACE_PREFIX}Reference__c,  ${NAMESPACE_PREFIX}CategoryId__r.${NAMESPACE_PREFIX}CategoryDetailReference__c,${NAMESPACE_PREFIX}CategoryId__r.${NAMESPACE_PREFIX}ParentCategoryId__c, ${NAMESPACE_PREFIX}CategoryId__r.${NAMESPACE_PREFIX}ParentCategoryId__r.${NAMESPACE_PREFIX}Reference__c, ${NAMESPACE_PREFIX}CategoryId__r.${NAMESPACE_PREFIX}ParentCategoryId__r.${NAMESPACE_PREFIX}CategoryDetailReference__c FROM  ${NAMESPACE_PREFIX}Feature__c WHERE  ${NAMESPACE_PREFIX}FileId__r.${NAMESPACE_PREFIX}ContentVersionId__c = '${fileId}'`;
	let queryPromise = ApiGateway.invoke('sf', 'query', {}, { query: queryStr });
	return new Promise((resolve, reject) => {
		queryPromise.then((features) => {
			var questionsMap = {};
			for (let feature of features) {
				questionsMap[feature.Id] = {
					id: feature.Id,
					configuration: JSON.parse(feature[NAMESPACE_PREFIX + 'Configuration__c']),
				};
				let categoryRecordId = feature[NAMESPACE_PREFIX + 'CategoryId__c'];

				//Avoid Duplicate Processing
				if (existingCategoryRecordMap[categoryRecordId]) {
					continue;
				}
				let categorySObject = feature[NAMESPACE_PREFIX + 'CategoryId__r'];
				let parentCategoryRecordId = categorySObject
					? categorySObject[NAMESPACE_PREFIX + 'ParentCategoryId__c']
					: undefined;
				let parentCategorySObject = parentCategoryRecordId
					? feature[NAMESPACE_PREFIX + 'CategoryId__r'][
							NAMESPACE_PREFIX + 'ParentCategoryId__r'
						]
					: undefined;
				let referencePath = categorySObject
					? categorySObject[`${NAMESPACE_PREFIX}CategoryDetailReference__c`]
					: undefined;
				let parentReferencePath = parentCategorySObject
					? parentCategorySObject[`${NAMESPACE_PREFIX}CategoryDetailReference__c`]
					: undefined;

				if (categorySObject) {
					let cellAddress = categorySObject[NAMESPACE_PREFIX + 'Reference__c'];
					let categoryObj = existingCategoryMap[cellAddress]
						? existingCategoryMap[cellAddress]
						: {
								recordId: categoryRecordId,
								isSubcategory: parentCategoryRecordId ? true : false,
							};
					if (referencePath)
						categoryObj.categoryDtlsReferencePaths = JSON.parse(referencePath);
					existingCategoryMap[cellAddress] = categoryObj;
					existingCategoryRecordMap[categoryRecordId] = true;
				}
				if (parentCategorySObject) {
					let cellAddress = parentCategorySObject[NAMESPACE_PREFIX + 'Reference__c'];
					let categoryObj = existingCategoryMap[cellAddress]
						? existingCategoryMap[cellAddress]
						: {
								recordId: parentCategoryRecordId,
								isSubcategory: false,
							};
					if (referencePath)
						categoryObj.categoryDtlsReferencePaths = JSON.parse(parentReferencePath);
					existingCategoryMap[cellAddress] = categoryObj;
					existingCategoryRecordMap[parentCategoryRecordId] = true;
				}
			}
			resolve({ questionsMap, existingCategoryMap });
		});
	});
};

ConfigureDocumentApi.importExistingFiles = (fileIds) => {
	return ApiGateway.invoke('sf', 'importExistingFiles', { fileIds });
};

export default ConfigureDocumentApi;
