import { Owner } from './Owner';

export class Account {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.Industry = data.Industry;
		this.Rating = data.Rating;
		this.Type = data.Type;
		this.AnnualRevenue = data.AnnualRevenue;
		this.Website = data.Website;
		this.PhotoUrl = data.PhotoUrl;

		// related
		if (data.Owner !== null && data.Owner !== undefined) {
			this.Owner = new Owner(data.Owner);
		}

		this.Contacts = data.Contacts?.records;

		let roleAndTitles = new Set();
		this.Personas = []; // Contacts with unique roles, to be used as fallback when opportunity personas are not available
		if (Array.isArray(this.Contacts) && this.Contacts.length > 0) {
			this.Contacts.forEach((contact) => {
				// Select only unique and non-empty roles
				if (
					!contact?.Title ||
					contact?.Title?.trim?.()?.length < 1 ||
					roleAndTitles.has(contact.Title)
				)
					return;

				this.Personas.push({
					type: 'Contact',
					id: contact.Id,
					role: contact.Title,
					roleDisplayName: contact.Title,
					//contactId: contactSO.Id,
					//contactName: contactSO.Name,
				});
			});
		}
	}
}
