import {
	Button,
	Card,
	CardContent,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputBase,
	InputLabel,
	ListItemText,
	MenuItem,
	MenuList,
	OutlinedInput,
	Paper,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import {
	AttachFileOutlined,
	ExpandMore,
	InfoOutlined,
	ManageSearch,
	SearchOutlined,
	UnfoldMore,
} from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { Box, StyledEngineProvider } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { MuiFileInput } from 'mui-file-input';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import RichTextEditor from 'react-rte';
import remarkGfm from 'remark-gfm';
import {
	NAMESPACE_PREFIX,
	OBJECT_TYPE_File,
	SALESPLAY_SECTION_CALLDECK,
	SALESPLAY_SECTION_CALLDECK_PLURAL,
	SALESPLAY_SECTION_COMPETITION,
	SALESPLAY_SECTION_CRITICALMESSAGING,
	SALESPLAY_SECTION_CRITICALMESSAGING_PLURAL,
	SALESPLAY_SECTION_CUSTSTORY,
	SALESPLAY_SECTION_CUSTSTORY_PLURAL,
	SALESPLAY_SECTION_DEMO,
	SALESPLAY_SECTION_DEMO_PLURAL,
	SALESPLAY_SECTION_DISCOVERY,
	SALESPLAY_SECTION_DISCOVERY_PLURAL,
	SALESPLAY_SECTION_DOC,
	SALESPLAY_SECTION_DOC_PLURAL,
	SALESPLAY_SECTION_INDSEPCIALIST,
	SALESPLAY_SECTION_INDSEPCIALIST_PLURAL,
	SALESPLAY_SECTION_OBJECTION,
	SALESPLAY_SECTION_OBJECTION_PLURAL,
	SALESPLAY_SECTION_PERSONA_MSG,
	SALESPLAY_SECTION_PERSONA_MSG_PLURAL,
	SALESPLAY_SECTION_SALESPLAY,
	SALESPLAY_SECTION_SALESPLAY_PLURAL,
} from '../../../Constants';
import AdminApi from '../../../apis/AdminApi';
import ConfigureDocumentApi from '../../../apis/ConfigureDocumentApi';
import SalesPlaybookApi from '../../../apis/SalesPlaybookApi';
import SalesforceCommonApi from '../../../apis/SalesforceCommonApi';
import AdminService from '../../../services/AdminService';
import OpenAIService from '../../../services/OpenAIService';
import SalesPlayAdminService from '../../../services/SalesPlayAdminService';
import CollectionUtils from '../../../utils/CollectionUtils';
import Utils, { ns } from '../../../utils/Utils';
import ProductSelectionCmp from '../../admin/ProductSelectionCmp';
import FWSObjectTextAutoCompleteCmp from '../../common/FWSObjectTextAutoCompleteCmp';
import NoSearchResultCmp from '../../common/NoSearchResultCmp';
import SimpleTooltipCmp from '../../common/SimpleTooltipCmp';
import { RecordCardCmp, StatefulButton } from '../../common/UtilComponents';
import Alert from '../../mui/Alert';
import { FWButton, FWGradiantButton } from '../../mui/FWButtons';
import FWFileWithActionCmp from '../../mui/FWFileWithActionCmp';
import Accordion from '../../mui/accordian/Accordion';
import AccordionDetails from '../../mui/accordian/AccordionDetails';
import AccordionSummary from '../../mui/accordian/AccordionSummary';
import GPTMenuCmp from './GPTMenuCmp';
import CustomSettingService from '../../../services/CustomSettingService';

const toStringAutoCompleteOptions = (products) => {
	let productStr = '';
	let productArr = [];
	for (let product of products || []) {
		productArr.push(product.text);
	}
	productStr = productArr.join(', ');
	return productStr;
};
const SALESPLAY = {
	name: 'salesPlayBook',
	label: SALESPLAY_SECTION_SALESPLAY,
	pluralLabel: SALESPLAY_SECTION_SALESPLAY_PLURAL,
	baseObjectApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
	form: {
		fields: [
			{
				type: 'TextField',
				maxLength: 80,
				name: 'Name',
				label: 'Sales Play',
				required: true,
				searchable: true,
			},
			{
				type: 'Checkbox',
				name: NAMESPACE_PREFIX + 'IsActive__c',
				label: 'Is Active',
				excludeInCardView: true,
			},
			{
				type: 'ProductSelection',
				label: 'Products',
				name: 'ProductSelection',
				tooltip: (
					<React.Fragment>
						<p>
							If you cannot find the product/tag name, please navigate to Company
							Profile and add it under Products/Tags section.
						</p>
					</React.Fragment>
				),
				excludeInCardView: true,
				nonObjectField: true,
				notEditable: true,
				useInPrompt: true,
				toStringFn: toStringAutoCompleteOptions,
			},
			{
				type: 'SObjectTextAutoComplete',
				name: 'fwave__Industry__c',
				label: 'Industry',
				useInPrompt: true,
			},
			{
				type: 'RichTextArea',
				maxLength: 131072,
				visibleLines: 10,
				name: NAMESPACE_PREFIX + 'Description__c',
				label: 'Description',
				searchable: true,
			},
			{
				type: 'SmartRichTextArea',
				maxLength: 131072,
				visibleLines: 10,
				name: NAMESPACE_PREFIX + 'CriticalMessaging__c',
				label: SALESPLAY_SECTION_CRITICALMESSAGING,
				searchable: true,
				promptConfigName: 'SalesPlayCriticalMessaging',
			},
		],
		createActionLabel: 'Create',
	},
	hasProductSelection: true,
};
const SALESPLAY_RELATED_ITEMS = [
	{
		name: 'criticalMessaging',
		label: SALESPLAY_SECTION_CRITICALMESSAGING,
		pluralLabel: SALESPLAY_SECTION_CRITICALMESSAGING_PLURAL,
		form: {
			fields: [
				{
					type: 'SmartRichTextArea',
					maxLength: 131072,
					visibleLines: 10,
					name: NAMESPACE_PREFIX + 'CriticalMessaging__c',
					label: SALESPLAY_SECTION_CRITICALMESSAGING,
					searchable: true,
					promptConfigName: 'SalesPlayCriticalMessaging',
				},
			],
			createActionLabel: 'Update',
			createActionProcessingLabel: 'Updating..',
		},
		order: 1,
	},
	{
		name: 'personaMessaging',
		label: SALESPLAY_SECTION_PERSONA_MSG,
		pluralLabel: SALESPLAY_SECTION_PERSONA_MSG_PLURAL,
		order: 2,
		baseObjectApiName: NAMESPACE_PREFIX + 'PersonaMessaging__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbPersonaMessaging__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'PersonaMessagingId__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybookId__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Name',
					required: true,
					searchable: true,
				},
				{
					type: 'RichTextArea',
					maxLength: 131072,
					visibleLines: 3,
					name: NAMESPACE_PREFIX + 'Message__c',
					label: 'Message',
					searchable: true,
				},
				{
					type: 'File',
					name: 'File',
					label: 'Attach File',
					excludeInCardView: true,
					multiple: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'PersonaMessagingId__r',
		hasFile: true,
		hasMultipleFile: true,
		fileType: 'Persona Messaging',
	},
	{
		name: 'objectHanding',
		label: SALESPLAY_SECTION_OBJECTION,
		pluralLabel: SALESPLAY_SECTION_OBJECTION_PLURAL,
		order: 5,
		baseObjectApiName: NAMESPACE_PREFIX + 'ObjectionHandling__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbObjectionHandling__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'ObjectionHandling__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Objection Handling',
					required: true,
					searchable: true,
				},
				{
					type: 'TextArea',
					maxLength: 32768,
					visibleLines: 3,
					name: NAMESPACE_PREFIX + 'Objection__c',
					label: 'Objection',
					searchable: true,
					useInPrompt: true,
				},
				{
					type: 'SmartRichTextArea',
					maxLength: 131072,
					visibleLines: 3,
					name: NAMESPACE_PREFIX + 'Response__c',
					label: 'Response',
					searchable: true,
					promptConfigName: 'SalesPlayObjectionResponse',
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'ObjectionHandling__r',
	},
	{
		name: 'discoveryIndustryTrend',
		label: SALESPLAY_SECTION_DISCOVERY,
		pluralLabel: SALESPLAY_SECTION_DISCOVERY_PLURAL,
		order: 3,
		baseObjectApiName: NAMESPACE_PREFIX + 'DiscoveryIndustryTrend__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbDiscoveryIndustryTrend__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'DiscoveryIndustryTrendId__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Name',
					required: true,
					searchable: true,
				},
				{
					type: 'RichTextArea',
					maxLength: 131072,
					visibleLines: 3,
					name: NAMESPACE_PREFIX + 'Description__c',
					label: 'Description',
					searchable: true,
				},
				{
					type: 'File',
					name: 'File',
					label: 'Attach File',
					excludeInCardView: true,
					multiple: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'DiscoveryIndustryTrendId__r',
		hasFile: true,
		hasMultipleFile: true,
		fileType: 'Discovery and Industry Trend',
	},
	{
		name: 'demo',
		label: SALESPLAY_SECTION_DEMO,
		pluralLabel: SALESPLAY_SECTION_DEMO_PLURAL,
		order: 10,
		baseObjectApiName: NAMESPACE_PREFIX + 'Demo__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbDemo__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'Demo__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Title',
					required: true,
					searchable: true,
				},
				{
					type: 'TextField',
					maxLength: 255,
					name: NAMESPACE_PREFIX + 'Url__c',
					label: 'URL',
					searchable: true,
				},
				{
					type: 'RichTextArea',
					maxLength: 131072,
					visibleLines: 3,
					name: NAMESPACE_PREFIX + 'Description__c',
					label: 'Description',
					searchable: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'Demo__r',
	},
	{
		name: 'competition',
		label: SALESPLAY_SECTION_COMPETITION,
		pluralLabel: SALESPLAY_SECTION_COMPETITION,
		order: 4,
		baseObjectApiName: NAMESPACE_PREFIX + 'Competition__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbCompetition__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'Competition__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Competitor Name',
					required: true,
					searchable: true,
				},
				// {type : 'Number', maxLength : 18, name : NAMESPACE_PREFIX+'CustomerSize__c', label : 'Customer Size', searchable : true},
				{
					type: 'TextField',
					maxLength: 255,
					name: NAMESPACE_PREFIX + 'CompetitorStrengths__c',
					label: 'Competitor Strengths',
					searchable: true,
				},
				{
					type: 'TextField',
					maxLength: 255,
					name: NAMESPACE_PREFIX + 'HowWeBeatThisCompetitor__c',
					label: 'How we beat this competitor',
					searchable: true,
				},
				//{type : 'Currency', maxLength : 16, name : NAMESPACE_PREFIX+'Revenue__c', label : 'Revenue', searchable : true},
				{
					type: 'TextField',
					maxLength: 255,
					name: NAMESPACE_PREFIX + 'RecentWins__c',
					label: 'Recent wins over this competitor',
					searchable: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'Competition__r',
	},
	{
		name: 'contacts',
		label: SALESPLAY_SECTION_INDSEPCIALIST,
		pluralLabel: SALESPLAY_SECTION_INDSEPCIALIST_PLURAL,
		order: 11,
		baseObjectApiName: 'Contact',
		objectApiName: NAMESPACE_PREFIX + 'SpbContact__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'Contact__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'Name',
					required: true,
					searchable: true,
					excludeInForm: true,
					notEditable: true,
				},
				{
					type: 'TextField',
					maxLength: 40,
					name: 'FirstName',
					label: 'First Name',
					required: true,
					searchable: true,
				},
				{
					type: 'TextField',
					maxLength: 80,
					name: 'LastName',
					label: 'Last Name',
					required: true,
					searchable: true,
				},
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Email',
					label: 'Email',
					required: true,
					searchable: true,
					validation: 'email',
				},
				{
					type: 'TextField',
					maxLength: 40,
					name: 'Phone',
					label: 'Phone',
					required: true,
					searchable: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'Contact__r',
	},
	{
		name: 'customerStories',
		label: SALESPLAY_SECTION_CUSTSTORY,
		pluralLabel: SALESPLAY_SECTION_CUSTSTORY_PLURAL,
		order: 6,
		baseObjectApiName: NAMESPACE_PREFIX + 'File__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbFiles__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'File__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'File Name',
					required: true,
					searchable: true,
				},
				{
					type: 'File',
					name: 'File',
					label: 'Attach File',
					excludeInCardView: true,
					required: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'File__r',
		hasFile: true,
		fileType: 'Customer Stories', //Data Sheets, First Call Deck
	},
	{
		name: 'firstCallDeck',
		label: SALESPLAY_SECTION_CALLDECK,
		pluralLabel: SALESPLAY_SECTION_CALLDECK_PLURAL,
		order: 7,
		baseObjectApiName: NAMESPACE_PREFIX + 'File__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbFiles__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'File__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'File Name',
					required: true,
					searchable: true,
				},
				{
					type: 'File',
					name: 'File',
					label: 'Attach File',
					excludeInCardView: true,
					required: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'File__r',
		hasFile: true,
		fileType: 'First Call Deck', //Data Sheets, First Call Deck
	},
	{
		name: 'datasheets',
		label: SALESPLAY_SECTION_DOC,
		pluralLabel: SALESPLAY_SECTION_DOC_PLURAL,
		order: 8,
		baseObjectApiName: NAMESPACE_PREFIX + 'File__c',
		objectApiName: NAMESPACE_PREFIX + 'SpbFiles__c',
		relatedLookupApiName: NAMESPACE_PREFIX + 'File__c',
		salesPlayApiName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		form: {
			fields: [
				{
					type: 'TextField',
					maxLength: 80,
					name: 'Name',
					label: 'File Name',
					required: true,
					searchable: true,
				},
				{
					type: 'File',
					name: 'File',
					label: 'Attach File',
					excludeInCardView: true,
					required: true,
				},
			],
			createActionLabel: 'Create',
		},
		relationshipName: NAMESPACE_PREFIX + 'File__r',
		hasFile: true,
		fileType: 'Data Sheets', //Data Sheets, First Call Deck
	},
];
function SalesPlayAdminHome() {
	const [salesPlayList, setSalesPlayList] = useState([]);
	const [selectedSalesPlayId, setSelectedSalesPlayId] = useState();
	const [selectedRelatedList, setSelectedRelatedList] = useState();
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [objectDescribe, setObjectDescribe] = useState(undefined);
	const [stage, setStatge] = useState('Initialized');
	const [selectFromSalesPlayId, setSelectFromSalesPlayId] = useState();
	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

	const initializeSalesPlayBooks = () => {
		setStatge('Loading');
		SalesPlaybookApi.getSalesPlays().then((_salesPlays) => {
			setSalesPlayList(_salesPlays);
			if (_salesPlays.length > 0) {
				//if(!selectedSalesPlayId)setSelectedSalesPlayId(_salesPlays[0].Id);
			}
			setStatge('Loaded');
		});
	};

	useEffect(() => {
		initializeSalesPlayBooks();
	}, []);

	useEffect(() => {
		if (!selectedRelatedList && selectedSalesPlayId)
			setSelectedRelatedList('criticalMessaging'); //Default
	}, [selectedSalesPlayId, selectedRelatedList]);

	useEffect(() => {
		let objectDescribe;
		if (selectedRelatedList) {
			objectDescribe = SALESPLAY_RELATED_ITEMS.find(
				(item) => item.name === selectedRelatedList,
			);
		} else {
			objectDescribe = SALESPLAY;
		}
		setObjectDescribe(objectDescribe);
	}, [selectedRelatedList]);

	const handleSalesPlayChange = (panel) => (event, newExpanded) => {
		setSelectedSalesPlayId(newExpanded ? panel : undefined);
		if (newExpanded)
			setSelectedRelatedList('criticalMessaging'); //Default
		else {
			setSelectedSalesPlayId(undefined);
			setSelectedRelatedList(undefined);
		}
	};

	const handleSalesPlayRelatedItemChange = (salesplayId, relatedListType) => (event) => {
		setSelectedRelatedList(relatedListType);
	};

	const handlePlayBookClick = () => {
		setSelectedRelatedList(undefined);
		setSelectedSalesPlayId(undefined);
	};

	const handleSearchClick = () => {
		setSearchOpen(true);
	};
	const updateRefreshDate = () => {
		setIsCreateFormOpen(false);
	};

	return (
		<Grid
			direction="row"
			container
			id="salesPlayAdminHome_container"
			style={{ border: '5px solid xred' }}
		>
			<Grid
				item
				xs={5}
				sm={5}
				md={4}
				lg={4}
				xl={3}
				container
				id="salesPlayAdminHome_sidebar"
				style={{ border: '3px solid xgreen', position: 'relative' }}
			>
				<Grid
					direction={'column'}
					container
					id="salesPlayAdminHome_sidebar_container"
					style={{ border: '5px solid xblue' }}
				>
					{/* <Grid  id="salesPlayAdminHome_sidebar_logo" style={{border : '5px solid xpurple', padding : '12px', background : '#10273A', borderRadius : '8px', textAlign :'center'}}>
                        <LogoCmp/>
                    </Grid>
                    <div style={{height : '8px'}}></div> */}
					<Grid
						container
						id="salesPlayAdminHome_sidebar_playscontainer"
						direction={'row'}
						style={{ border: '5px solid xpurple', background: '#10273A' }}
					>
						<Grid
							xs={10}
							item
							id="salesPlayAdminHome_sidebar_newSalesPlay"
							className="salesplay-left-main-title"
							onClick={handlePlayBookClick}
							style={{
								height: '70px',
								paddingTop: '25px',
								paddingLeft: '20px',
								color: 'white',
								cursor: 'pointer !important',
							}}
						>
							All Sales Plays
						</Grid>
						<Grid xs={2} item id="salesPlayAdminHome_sidebar_newSalesPlay_icon">
							{/* <IconButton aria-label="delete" size="small">
                                <AddBox fontSize="inherit" />
                            </IconButton> */}
						</Grid>
					</Grid>
					<Divider />
					<Grid
						className="admin-accordion"
						style={{ height: 'calc(100vh)', overflowY: 'auto', background: '#10273A' }}
					>
						{salesPlayList.map((salesPlayItem) => (
							<Accordion
								key={salesPlayItem.Id}
								expanded={selectedSalesPlayId === salesPlayItem.Id}
								onChange={handleSalesPlayChange(salesPlayItem.Id)}
							>
								<AccordionSummary
									aria-controls="panel1d-content"
									id={salesPlayItem.Id}
									expandIcon={<ExpandMore sx={{ color: 'white' }} />}
								>
									{/* ,  pointerEvents: "auto" */}
									{/* <ImportContactsOutlined/> */}
									&nbsp;{' '}
									<Typography
										sx={{ fontSize: '20px', fontWeight: 700 }}
										className="salesplay-left-title"
									>
										{salesPlayItem.Name}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<MenuList>
										{SALESPLAY_RELATED_ITEMS.sort(
											(a, b) => a?.order - b?.order,
										).map((relatedItem) => (
											<MenuItem
												id={relatedItem.name}
												key={relatedItem.name}
												onClick={handleSalesPlayRelatedItemChange(
													salesPlayItem.Id,
													relatedItem.name,
												)}
												style={
													selectedRelatedList === relatedItem.name
														? { background: '#021422' }
														: {}
												}
											>
												<Grid container>
													{/* <Grid item><TripOriginOutlined  sx={{ fontSize: 10 }}/>&nbsp;</Grid> */}
													<Grid item>
														{relatedItem.name ===
														'criticalMessaging' ? (
															<ListItemText>
																{
																	SALESPLAY_SECTION_CRITICALMESSAGING
																}
															</ListItemText>
														) : (
															<ListItemText>
																{relatedItem.label}
															</ListItemText>
														)}
													</Grid>
												</Grid>
												{/* {relatedItem.label} */}
											</MenuItem>
										))}
									</MenuList>
								</AccordionDetails>
							</Accordion>
						))}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={7}
				sm={7}
				md={8}
				lg={8}
				xl={9}
				id="salesPlayAdminHome_maincontent"
				style={{ border: '3px solid xgreen', paddingLeft: '15px', paddingRight: '15px' }}
			>
				<Grid
					container
					id="salesPlayAdminHome_maincontent_row_container"
					direction={'column'}
					style={{ border: '5px solid xorange' }}
				>
					<Grid
						id="salesPlayAdminHome_maincontent_row1"
						direction={'row'}
						container
						style={{ padding: '5px' }}
					>
						{/* <Button variant="outlined" style={{width : '95%'}} startIcon={<SearchOutlined />} onClick={handleSearchClick}>
                            Search  
                        </Button> */}
						<Paper
							className="salesPlaySearchContainer"
							component="form"
							sx={{
								p: '2px 4px',
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								cursor: 'pointer !important',
							}}
							onClick={handleSearchClick}
						>
							<IconButton sx={{ p: '10px', padding: '0px' }} aria-label="menu">
								{/* <PageviewRounded color="primary" fontSize="large" /> */}
								<Grid
									style={{
										height: '40px',
										width: '40px',
										background: '#10273a',
										textAlign: 'center',
										borderRadius: '8px',
									}}
									container
									alignItems={'center'}
									justifyContent={'center'}
								>
									<SearchOutlined fontSize="medium" style={{ color: 'white' }} />
								</Grid>
								{/* <div  style={{height : '35px', width : '35px', background : '#10273a', textAlign : 'center', paddingLeft : '8px',paddingTop : '12px', paddingRight : '10px', borderRadius : '8px'}}>
                                    <SearchOutlined fontSize="medium" style={{color : 'white'}}/>
                                </div> */}
							</IconButton>
							<InputBase
								className="salesPlaySearch"
								style={{ color: 'red', cursor: 'pointer !important' }}
								disabled
								sx={{ ml: 1, flex: 1, color: 'red', cursor: 'pointer !important' }}
								placeholder={
									selectedRelatedList === 'criticalMessaging'
										? 'Select from existing playbooks'
										: 'Click here to search existing ' +
											objectDescribe?.pluralLabel
								}
								inputProps={{ 'aria-label': 'Search existing Sales Plays' }}
							/>
							<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
								<UnfoldMore />
							</IconButton>
						</Paper>

						{isSearchOpen && (
							<SalesPlaySearchContainerCmp
								objectDescribe={
									selectedRelatedList === 'criticalMessaging'
										? SALESPLAY
										: objectDescribe
								}
								relatedListType={
									selectedRelatedList === 'criticalMessaging'
										? undefined
										: selectedRelatedList
								}
								salesPlayId={selectedSalesPlayId}
								onClose={() => {
									setSearchOpen(false);
									updateRefreshDate();
								}}
								onSelectSalyPlayBoook={(salesPlayBookId) => {
									if (selectedRelatedList === 'criticalMessaging') {
										setSelectFromSalesPlayId(salesPlayBookId);
									} else {
										setSelectedSalesPlayId(salesPlayBookId);
									}
								}}
							/>
						)}
					</Grid>
					{/* <Grid id="salesPlayAdminHome_maincontent_row2">
                        Selected Sales Play : {selectedSalesPlayId} , 
                        Selected Related : {selectedRelatedList}
                    </Grid> */}
					{stage === 'Loaded' && selectedRelatedList === 'criticalMessaging' && (
						<Grid>
							<SalesPlayNewRecordFormCmp
								relatedListType={selectedRelatedList}
								salesPlayId={selectedSalesPlayId}
								refreshSalesPlayBooks={initializeSalesPlayBooks}
								onRecordChange={updateRefreshDate}
								setSelectedSalesPlayId={setSelectedSalesPlayId}
								selectFromSalesPlayId={selectFromSalesPlayId}
								salesPlayList={salesPlayList}
							/>
						</Grid>
					)}
					{selectedRelatedList !== 'criticalMessaging' && (
						<Grid style={{ padding: '10px' }} container direction={'row'}>
							<Grid flex={1}></Grid>
							<Grid>
								<FWGradiantButton
									onClick={() => {
										setIsCreateFormOpen(true);
									}}
								>
									Create
								</FWGradiantButton>
							</Grid>
						</Grid>
					)}
					{stage === 'Loaded' &&
						isCreateFormOpen &&
						selectedRelatedList !== 'criticalMessaging' && (
							<Dialog
								fullWidth={true}
								maxWidth={'lg'}
								onClose={() => {
									setIsCreateFormOpen(false);
								}}
								open={true}
							>
								<DialogTitle style={{ textAlign: 'center' }}>
									{objectDescribe.hasFile ? 'Create' : 'Create'}{' '}
									{objectDescribe.label}
								</DialogTitle>
								<DialogContent>
									<SalesPlayNewRecordFormCmp
										relatedListType={selectedRelatedList}
										salesPlayId={selectedSalesPlayId}
										refreshSalesPlayBooks={initializeSalesPlayBooks}
										onRecordChange={updateRefreshDate}
										setSelectedSalesPlayId={setSelectedSalesPlayId}
										selectFromSalesPlayId={selectFromSalesPlayId}
										salesPlayList={salesPlayList}
										onCancel={() => {
											setIsCreateFormOpen(false);
										}}
									/>
								</DialogContent>
								<DialogActions>
									{/* <FWButton onClick={()=>{setIsCreateFormOpen(false)}}>Close</FWButton> */}
								</DialogActions>
							</Dialog>
						)}
					{selectedRelatedList !== 'criticalMessaging' && (
						<Grid id="salesPlayAdminHome_maincontent_row3">
							<SalesplayRelatedDataTableCmp
								salesPlayId={selectedSalesPlayId}
								relatedListType={selectedRelatedList}
								refreshDate={updateRefreshDate}
								refreshSalesPlayBooks={initializeSalesPlayBooks}
								setSelectedSalesPlayId={setSelectedSalesPlayId}
								salesPlayList={salesPlayList}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

function SalesPlaySearchContainerCmp({
	relatedListType,
	salesPlayId,
	onClose,
	objectDescribe,
	onSelectSalyPlayBoook,
}) {
	const [newSelection, setNewSelection] = useState();
	const [oldSelection, setOldSelection] = useState();
	const [relatedRecords, setRelatedRecords] = useState();
	const [isApplying, setIsApplying] = useState(false);

	const onSelectionChange = (existingSelection, newSelection, relatedRecords) => {
		setNewSelection(newSelection);
		setOldSelection(existingSelection);
		setRelatedRecords(relatedRecords);
	};
	const onApplyClick = () => {
		setIsApplying(true);
		// if(!relatedListType){

		// }
		let selectionDiff = CollectionUtils.compareArrays(oldSelection, newSelection);
		let newItems = selectionDiff.newItems || [];
		let removedItems = selectionDiff.removedItems || [];

		let promiseList = [];
		let deleteIds = [];
		for (let masterId of removedItems) {
			let _deleteIds = relatedRecords
				.filter((masterRecord) => {
					return masterRecord[objectDescribe.relatedLookupApiName] === masterId;
				})
				.map((item) => item.Id);
			deleteIds = [...deleteIds, ..._deleteIds];
		}
		if (deleteIds && deleteIds.length > 0) {
			promiseList.push(SalesforceCommonApi.deleteRecords(deleteIds));
		}
		if (newItems.length > 0)
			promiseList.push(
				SalesPlaybookApi.saveNewRelatedItems(newItems, salesPlayId, objectDescribe),
			);
		if (promiseList.length > 0) {
			Promise.all(promiseList).then((response) => {
				onClose();
			});
		}
	};

	const onSelectPlayBookHandle = (salesPlayId) => {
		onSelectSalyPlayBoook(salesPlayId);
		onClose();
	};

	return (
		<div id="salesPlayAdmin_searchContainerCmp">
			<Dialog fullWidth={true} maxWidth={'lg'} onClose={onClose} open={true}>
				<DialogTitle>Search</DialogTitle>
				<DialogContent>
					<SalesPlaySearchCmp
						relatedListType={relatedListType}
						salesPlayId={salesPlayId}
						onSelectionChange={onSelectionChange}
						onSelectPlayBook={onSelectPlayBookHandle}
					/>
				</DialogContent>
				<DialogActions>
					<FWButton onClick={onClose}>Close</FWButton>
					{relatedListType && (
						<Box sx={{ m: 1, position: 'relative' }}>
							<FWGradiantButton
								//variant="outlined"
								//color="error"
								//sx={buttonSx}
								onClick={onApplyClick}
								disabled={isApplying}
								type="submit"
							>
								{isApplying ? 'Applying...' : 'Apply'}
							</FWGradiantButton>
							{isApplying && (
								<CircularProgress
									size={24}
									sx={{
										color: green[500],
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}
								/>
							)}
						</Box>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}

function SalesPlaySearchCmp({ relatedListType, salesPlayId, onSelectionChange, onSelectPlayBook }) {
	const [records, setRecords] = useState([]);
	const [relatedRecords, setRelatedRecords] = useState([]);
	const [allRecords, setAllRecords] = useState([]);
	const [objectDescribe, setObjectDescribe] = useState(undefined);
	const [masterIds, setMasterIds] = useState([]); // Final Ids
	//const [selectedRows, setSelectedRows] = useState([]);
	const [initMasterIds, setInitMasterIds] = useState([]); // Initial IDS on load
	const [stage, setStage] = useState('Loading'); // In
	const onSelectPlayBookHandle = (salesPlayId) => {
		if (onSelectPlayBook) onSelectPlayBook(salesPlayId);
	};
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const columns = [
		{
			field: 'Item',
			headerName: objectDescribe?.pluralLabel,
			flex: 1,
			sortable: false,
			renderCell: (params) => (
				<div className="cursor-pointer" style={{ width: '100%', padding: '5px' }}>
					{/* <RecordLink recordId={params.row.Id} label={params.row.Name}/><br/>
            {!relatedListType && <Button variant="contained" onClick={()=>{
                onSelectPlayBookHandle(params.row.Id);
            }}>Select</Button>} */}
					<RecordCardCmp
						record={params.row}
						objectDescribe={objectDescribe}
						onSelectPlayBook={onSelectPlayBookHandle}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		let objectDescribe;
		if (relatedListType) {
			objectDescribe = SALESPLAY_RELATED_ITEMS.find((item) => item.name === relatedListType);
		} else {
			objectDescribe = SALESPLAY;
		}
		setObjectDescribe(objectDescribe);
		initializeData(objectDescribe);
	}, [relatedListType, salesPlayId]);

	const initializeData = (objectDescribe) => {
		if (((relatedListType && salesPlayId) || !relatedListType) && objectDescribe) {
			setStage('Loading');
			SalesPlaybookApi.getMasterList(salesPlayId, relatedListType, objectDescribe).then(
				(_response) => {
					let _records = _response.data || [];
					let _relatedRecords = _response.relatedData;
					let masterIds = _response.masterIds;

					setRecords(_records);
					setAllRecords(_records);
					setRelatedRecords(_relatedRecords);
					setMasterIds(masterIds);
					setInitMasterIds(masterIds);
					setStage('Loaded');
				},
			);
		}
	};

	const onSearchTermChange = (event) => {
		let searchTerm = event.target.value?.toLowerCase();
		let _filteredRecords = (allRecords || []).filter((record) => {
			let searchFields = objectDescribe.form.fields.filter((item) => {
				return item.searchable;
			});
			let found = false;
			for (let field of searchFields) {
				let fieldVal = record[field.name] || '';
				let fieldValStr = '' + fieldVal;
				if (fieldValStr.toLowerCase().indexOf(searchTerm) !== -1) {
					found = true;
					break;
				}
			}
			return found;
			//return record.Name?.toLowerCase().indexOf(searchTerm)!==-1;
		});
		setRecords(_filteredRecords);
	};

	return (
		<div id="salesPlayAdmin_searchCmp">
			<Grid container direction={'column'}>
				<Grid item style={{ paddingBottom: '15px' }}>
					<Paper
						component="form"
						sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
					>
						<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
							<ManageSearch />
						</IconButton>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder="Search"
							inputProps={{ 'aria-label': 'Search' }}
							onChange={onSearchTermChange}
						/>
						{/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <Search />
                    </IconButton> */}
						{/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                        <Directions />
                    </IconButton> */}
					</Paper>
				</Grid>
				<Grid item style={{ minHeight: '500px' }}>
					<DataGrid
						columns={columns}
						rows={records}
						autoHeight={true}
						//selectionModel={masterIds}
						loading={stage === 'Loading'}
						slots={{
							noRowsOverlay: SalesPlayAdminService.getNoRowOverlayCmp(),
						}}
						rowSelectionModel={relatedListType ? masterIds : undefined}
						onRowSelectionModelChange={(e) => {
							setMasterIds(e);
							//const selectedIDs = new Set(e);
							//const selectedRows = allRecords.filter((r) => selectedIDs.has(r.id));
							//setSelectedRows(selectedRows);
							onSelectionChange(initMasterIds, e, relatedRecords);
						}}
						disableRowSelectionOnClick={!relatedListType}
						//checkboxSelection={recordSelection === 'Multiple'}
						checkboxSelection={relatedListType}
						keepNonExistentRowsSelected={true}
						//isRowSelected={()=>true}
						//rowSelection={true}
						density="compact"
						//rowHeight={relatedListType ? 'auto' : 350}
						getRowHeight={() => 'auto'}
						//getRowId={(row) => row.Id}
						//={stage === 'loadingFiles'}
						//pagination={true}
						pageSizeOptions={[5, 10, 20, 50, 100]}
						paginationModel={paginationModel}
						onPaginationModelChange={(paginationModel) =>
							setPaginationModel(paginationModel)
						}

						//onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						//rowsPerPageOptions={[10, 20, 50]}
						//pagination
						// components={
						//     {
						//         BaseCheckbox: CustomCheckbox
						//     }
						// }
					/>
				</Grid>
			</Grid>
		</div>
	);
}

function SmartRichTextEditorCmp({
	label,
	onContentStateChange,
	existingValue,
	inlineLayout = false,
	promptConfigName,
	formValues,
	salesPlayRecord,
	formItem,
	objectDescribe,
}) {
	//Component State Variables
	const [generateStatus, setGenerateStatus] = useState('Initial');
	const [showProgress, setShowProgress] = useState(false);
	const [smartEditorState, setSmartEditorState] = useState('initial');
	const [showGTPSection, setShowGPTSection] = useState(false);

	//const [editorState, setEditorState] = useState('Initial');
	const [customControls, setCustomControls] = useState([]);
	//const [readOnlyMode, setReadOnlyMode] = useState(false);
	const [chatGPTSearchResult, setChatGPTSearchResult] = useState(null);
	const [editorPlainVal, setEditorPlainVal] = useState('');
	const [editorHtmlVal, setEditorHtmlVal] = useState();
	const [initialVal, setInitialVal] = useState(existingValue);
	const [smartActionLabel, setSmartActionLabel] = useState('HelpMeWrite');
	const [values, setValues] = useState({});
	//const [prompts, setPrompts] = useState([]); //Prompt after repacing form values
	//const prompts = promptConfig.prompts;
	const [companyName, setCompanyName] = useState('ABC Corp');
	const [companyAboutUs, setCompanyAboutUs] = useState(
		'ABC Corp is multinational company which sells CPQ and CLM to SME and enterprise customers.',
	);
	const [controlId, setControlId] = useState();
	//const [internalCustomControls, setInternalCustomControls] = useState([]);
	const [intervalId, setIntervalId] = useState();
	const [promptConfigObj, setPromptConfigObj] = useState();
	const [showRegenWithInput, setRegenWithInput] = useState(false);

	useEffect(() => {
		AdminService.getOrgInfo({
			callbackFn: (configurationObj, cached) => {
				let _companyName = configurationObj[NAMESPACE_PREFIX + 'CompanyName__c'];
				let _companyAboutUs = configurationObj[NAMESPACE_PREFIX + 'CompanyAbout__c'];
				setCompanyName(_companyName);
				setCompanyAboutUs(_companyAboutUs);
			},
		});
		loadPromotCofig();
	}, []);

	//TODO @Dharmik as entire logic depend on prompt config, until its not fetched we dont need to proceed further. As of now we have used await to solve this problem but it need to be restructured and design properly
	const loadPromotCofig = async () => {
		let promptConfig = await CustomSettingService.getGPTPrompt({
			promptConfigName: promptConfigName,
		});
		setPromptConfigObj(promptConfig);
		setRegenWithInput(promptConfig?.fields?.length > 0);
		return promptConfig;
	};

	useEffect(() => {
		setInitialVal(existingValue);
	}, [existingValue]);

	useEffect(() => {
		if (!editorPlainVal || editorPlainVal === '') {
			setSmartActionLabel('HelpMeWrite');
			setCustomControls([{ label: 'Help Me Write', id: 'HelpMeWrite', type: 'button' }]);
		} else {
			setSmartActionLabel('Rewrite');
			setCustomControls([{ label: 'Rewrite', id: 'Rewrite', type: 'standardMenu' }]);
		}
	}, [editorPlainVal, editorHtmlVal]);

	useEffect(() => {
		var temp = document.createElement('div');
		temp.innerHTML = editorHtmlVal;
		let _plainText = temp.textContent || temp.innerText || '';
		if (_plainText === 'undefined') _plainText = '';
		setEditorPlainVal(_plainText);
	}, [editorHtmlVal]);

	useEffect(() => {
		let _controls = [];
		for (let customControl of customControls || []) {
			if (customControl.type === 'button')
				_controls.push(
					<Button
						variant="contained"
						sx={{ padding: '2px', paddingLeft: '5px', paddingRight: '5px' }}
						onClick={(event) => {
							onCustomControlClick(customControl.id, event);
						}}
					>
						{customControl.label}
					</Button>,
				);
			if (customControl.type === 'standardMenu') {
				_controls.push(
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="demo-simple-select-label">{customControl.label}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							//value={age}
							label="Rewrite"
							//onChange={handleChange}
							onChange={(event) => {
								onCustomControlClick(customControl.id, event);
							}}
						>
							<MenuItem value={'AutoImprove'}>Auto Improve</MenuItem>
							<MenuItem value={'ImproveWithInput'}>Improve</MenuItem>
							<MenuItem value={'Shorten'}>Shorten</MenuItem>
							<MenuItem value={'Expand'}>Expand</MenuItem>
							<MenuItem value={'OnlyFixMistakes'}>Only Fix mistakes</MenuItem>
						</Select>
					</FormControl>,
				);
				// _controls.push(<CustomizedMenus/>);
			}
			// _controls.push(<MinimalSelect/>)
		}
		//setInternalCustomControls(_controls);
	}, [customControls]);

	useEffect(() => {
		if (generateStatus === 'Generating') {
			let intervalId = setInterval(() => {
				var element = document.getElementById('lastGptElement');
				element?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 500);
			setIntervalId(intervalId);
		}
		if (generateStatus === 'Generated') {
			if (intervalId) clearInterval(intervalId);
		}
	}, [generateStatus]);

	const onGenerateAnswer = async (controlId) => {
		setChatGPTSearchResult(null);
		setShowGPTSection(true);
		setGenerateStatus('Generating');
		setShowProgress(true);
		//setReadOnlyMode(true);
		setTimeout(() => {
			setShowProgress(false);
		}, 2000);
		let _promptConfigObj = promptConfigObj;
		if (!_promptConfigObj) {
			_promptConfigObj = await loadPromotCofig();
		}
		let provideLastOutputAsContext = true;
		let _command = '';
		if (!controlId || controlId === 'HelpMeWrite') {
			_command = 'Write';
			provideLastOutputAsContext = false;
		} else if (controlId === 'Rewrite') {
			_command = 'Rewrite';
		} else if (controlId === 'AutoImprove') {
			_command = 'Auto Imrove';
		} else if (controlId === 'ImproveWithInput') {
			_command = 'Rewrite';
		} else if (controlId === 'Shorten') {
			_command = 'Shorten';
		} else if (controlId === 'Expand') {
			_command = 'Expand';
		} else if (controlId === 'OnlyFixMistakes') {
			_command = 'Only Fix Mistakes in';
		}

		let _existingPrompts = _promptConfigObj.prompts.slice();
		let finalInputPrompts = [];
		for (let promptItem of _existingPrompts) {
			if (promptItem?.content) {
				let _content = promptItem.content;
				if (_content.indexOf('{!command}') !== -1) {
					_content = _content.replaceAll('{!command}', _command);
					//_content = _content.replaceAll('{!subject}', promptConfig.subject);
					if (provideLastOutputAsContext === true) {
						_content = _content + ' provided as Exsting Input:';
					} else {
						_content = _content + '.';
					}
					//promptItem.content = _content;
				}

				for (let formField of objectDescribe?.form?.fields || []) {
					if (formField.useInPrompt) {
						let formFieldVal = formValues[formField.name];
						if (formField.toStringFn) {
							formFieldVal = formField.toStringFn(formFieldVal);
						}
						_content = _content.replaceAll(
							'{!' + formField.name + '}',
							formFieldVal || 'Not Provided',
						);
					}
				}

				//Means Dynamic fields need to be replaced
				//Replace Context Fields first
				for (let contextField of _promptConfigObj?.fields || []) {
					_content = _content.replaceAll(
						'{!' + contextField.name + '}',
						values[contextField.name] || 'Not Provided',
					);
				}
				_content = _content.replaceAll('{!companyName}', companyName);
				_content = _content.replaceAll('{!companyAboutUs}', companyAboutUs);
				_content = _content.replaceAll(
					'{!salesplay.ProductSelection}',
					toStringAutoCompleteOptions(
						salesPlayRecord ? salesPlayRecord['ProductSelection'] : [],
					),
				);
				_content = _content.replaceAll(
					'{!salesplay.fwave__Industry__c}',
					salesPlayRecord ? salesPlayRecord['fwave__Industry__c'] : 'Not Provided',
				);
				finalInputPrompts.push({
					role: promptItem.role,
					content: _content,
				});
			}
		}
		if (provideLastOutputAsContext === true) {
			finalInputPrompts.push({
				role: 'user',
				content: 'Exsting Input:' + editorPlainVal,
			});
		}

		let stream = await OpenAIService.getSearchResultsNew(finalInputPrompts);
		const reader = stream.getReader();
		const tempStr = [];
		const chunks = { isDone: false, data: [] };
		let done, value;
		while (!done) {
			({ value, done } = await reader.read());
			if (done) {
				chunks.isDone = true;
				setChatGPTSearchResult({ ...chatGPTSearchResult, data: tempStr, isDone: true });
				setGenerateStatus('Generated');

				// set cache result
				//updateAccountSummaryCache(props?.accountId, tempStr);
			} else {
				var str = new TextDecoder().decode(value);
				tempStr.push(str);

				// only stream if not collapsed
				setChatGPTSearchResult({ ...chatGPTSearchResult, data: tempStr, isDone: false });
				//setLoadingChatGPT(false);
			}
		}
	};

	// const onEditGenerated = ()=>{
	//     setEditorState('EditStarted')
	// }

	const onHelpMeWrite = async (controlId) => {
		setShowGPTSection(true);
		let _promptConfigObj = promptConfigObj;
		if (!_promptConfigObj) {
			_promptConfigObj = await loadPromotCofig();
		}
		if (_promptConfigObj.fields && _promptConfigObj.fields.length > 0) {
			setSmartEditorState('CaptureIdea');
		} else {
			setSmartEditorState('Initial');
			onGenerateAnswer(controlId);
		}
	};

	const onCancelGenerated = () => {
		//setReadOnlyMode(false);
		setShowGPTSection(false);
		setGenerateStatus('Initial');
		setSmartEditorState('Initial');
		setShowProgress(false);
	};

	const onGenerateDraft = () => {
		setSmartEditorState('Initial');
		onGenerateAnswer(controlId);
	};

	const onRegenerate = () => {
		setSmartEditorState('Initial');
		onGenerateAnswer('Regenerate');
	};

	const onReplaceVal = () => {
		//let content = chatGPTSearchResult?.data;
		//content = content.join('');
		let content = document.getElementById('reactMarkDownContent').innerHTML;
		setEditorHtmlVal(content);
		setInitialVal(content);
		//setReadOnlyMode(false)
		setShowGPTSection(false);
		setGenerateStatus('Initial');
	};

	const onAppendVal = () => {
		let genContent = document.getElementById('reactMarkDownContent').innerHTML;
		let content = editorHtmlVal + `<p>${genContent}</p>`;
		setEditorHtmlVal(content);
		setInitialVal(content);
		//setReadOnlyMode(false)
		setShowGPTSection(false);
		setGenerateStatus('Initial');
	};

	const onCustomControlClick = (controlId, event) => {
		setControlId(controlId);
		let _controlId = controlId;
		if (!_controlId) {
			return;
		}
		if (controlId === 'HelpMeWrite' || _controlId === 'ImproveWithInput') {
			onHelpMeWrite(controlId);
		} else if (controlId === 'Rewrite') {
			onGenerateAnswer(_controlId);
		} else if (controlId === 'Regenerate') {
			onHelpMeWrite({ isRegenerate: true });
		} else {
			onGenerateAnswer(controlId);
		}
	};

	const onChangeContextFieldVal = (name, value) => {
		setValues((oldValues) => ({ ...oldValues, [name]: value }));
	};

	const onRegenerateWithInput = () => {
		setSmartEditorState('CaptureIdea');
	};

	const onContentStateChangeInternal = (event) => {
		setEditorHtmlVal(event?.target?.value);
		onContentStateChange?.(event);
	};

	return (
		<Grid container direction={'row'} style={{ background: '', width: '100%' }}>
			<Grid item container>
				<Grid style={{ paddingTop: '2px' }}>
					<label
						style={{
							fontWeight: 400,
							fontSize: '1rem',
							marginRight: '5px',
							paddingLeft: '0px',
							paddingBottom: '18px',
						}}
					>
						{label}
					</label>
				</Grid>
				<Grid>
					<React.StrictMode>
						<StyledEngineProvider injectFirst>
							<GPTMenuCmp
								type={
									!editorPlainVal || editorPlainVal === ''
										? 'HelpMeWrite'
										: 'Rewrite'
								}
								onClick={onCustomControlClick}
							/>
						</StyledEngineProvider>
					</React.StrictMode>
				</Grid>
			</Grid>

			{showGTPSection && (
				<Dialog
					open={true}
					scroll="paper"
					maxWidth="md"
					fullWidth={true}
					PaperProps={{
						sx: {
							height: 600,
						},
					}}
					aria-labelledby="scroll-dialog-title"
					aria-describedby="scroll-dialog-description"
				>
					<DialogTitle id="scroll-dialog-title">
						<center>Generate</center>
					</DialogTitle>
					<DialogContent dividers={true}>
						<Grid
							item
							container
							direction={'row'}
							style={{ background: '', padding: '15px', width: '100%' }}
							id="gptContainer"
						>
							<Grid item style={{ width: '100%' }}>
								{generateStatus === 'Generated' &&
									smartEditorState !== 'CaptureIdea' &&
									(chatGPTSearchResult?.data ? (
										<div
											id="reactMarkDownContent"
											style={{ overflow: 'auto', height: '100%' }}
										>
											<ReactMarkdown remarkPlugins={[remarkGfm]}>
												{chatGPTSearchResult?.data?.join('')}
											</ReactMarkdown>
										</div>
									) : (
										//Else
										<Box>
											<NoSearchResultCmp msg="No results." />
										</Box>
									))}

								{smartEditorState === 'CaptureIdea' && (
									<>
										{/* <GenericInputCmp inputType={"TextArea"} existingValue={"Featurewave - Featurewave works with Salesforce CRM and runs from within any object. Featurewave is a Salesforce AppExchange partner. Get more value out of CRM than you could"} visibleLines={5} label={"Company & About us"} /> 
                            <GenericInputCmp inputType={"TextArea"} existingValue="Sales Accelerator - AI that eliminates sales ramp and accelerates deal velocity."  visibleLines={5} label={"Products"} /> 
                            <GenericInputCmp inputType={"TextArea"} visibleLines={5} label={"Provide more information about your product or service and your target audience (optional)"} onChangeValue={onChangeIdea} />  */}
										<Grid style={{ width: '100%' }}>
											<br />
											{(promptConfigObj.fields || []).map((contextField) => {
												return (
													<GenericInputCmp
														key={contextField.name}
														style={{ width: '100%' }}
														name={contextField.name}
														onChangeValue={onChangeContextFieldVal}
														inputType={contextField.type}
														existingValue={values[contextField.name]}
														visibleLines={5}
														label={contextField.label}
													/>
												);
											})}
										</Grid>
									</>
								)}
							</Grid>
							<Grid item>
								{generateStatus === 'Generating' &&
									showProgress !== true &&
									(chatGPTSearchResult?.data ? (
										<div style={{ height: '100%' }}>
											<ReactMarkdown remarkPlugins={[remarkGfm]}>
												{chatGPTSearchResult?.data?.join('')}
											</ReactMarkdown>
											<div
												id="lastGptElement"
												style={{ height: '5px' }}
											></div>
										</div>
									) : (
										<></>
									))}
								{/* {
                            generateStatus === 'Generating'  && showProgress!==true && (
                                <><FWButton variant="outlined">Stop Generating</FWButton><FWButton variant="outlined" style={{marginRight :'5px'}} onClick={onCancelGenerated}>Cancel</FWButton></>
                                
                            )
                        }  */}
								{generateStatus === 'Generating' && showProgress === true && (
									<p>Please wait...</p>
								)}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<div className="buttonContainer">
							{generateStatus === 'Generated' && (
								<>
									{smartEditorState !== 'CaptureIdea' &&
										smartActionLabel === 'Rewrite' && (
											<FWGradiantButton
												variant="outlined"
												style={{ marginRight: '5px' }}
												onClick={onReplaceVal}
											>
												Replace
											</FWGradiantButton>
										)}
									{smartEditorState !== 'CaptureIdea' &&
										smartActionLabel === 'Rewrite' && (
											<FWGradiantButton
												variant="outlined"
												style={{ marginRight: '5px' }}
												onClick={onAppendVal}
											>
												Append
											</FWGradiantButton>
										)}
									{smartEditorState !== 'CaptureIdea' &&
										smartActionLabel === 'HelpMeWrite' && (
											<FWGradiantButton
												variant="outlined"
												style={{ marginRight: '5px' }}
												onClick={onReplaceVal}
											>
												Use Draft
											</FWGradiantButton>
										)}
								</>
							)}
							{generateStatus === 'Generated' &&
								smartEditorState !== 'CaptureIdea' && (
									<>
										<FWButton
											variant="outlined"
											style={{ marginRight: '5px' }}
											onClick={onRegenerate}
										>
											Regenerate
										</FWButton>
									</>
								)}
							{generateStatus === 'Generated' &&
								smartEditorState !== 'CaptureIdea' &&
								showRegenWithInput === true && (
									<>
										<FWButton
											variant="outlined"
											style={{ marginRight: '5px' }}
											onClick={onRegenerateWithInput}
										>
											Regenerate With Input
										</FWButton>
									</>
								)}
							{smartEditorState === 'CaptureIdea' && (
								<>
									<FWGradiantButton variant="outlined" onClick={onGenerateDraft}>
										Generate Draft
									</FWGradiantButton>{' '}
									&nbsp;
								</>
							)}
							<FWButton
								variant="outlined"
								style={{ marginRight: '5px' }}
								onClick={onCancelGenerated}
							>
								Cancel
							</FWButton>
						</div>
					</DialogActions>
				</Dialog>
			)}
			{/* {
                ((generateStatus === 'Initial') || (generateStatus === 'Generated'&& editorState==='EditStarted')) &&
                <RichTextEditorCmp label={label} onContentStateChange={onContentStateChange} existingValue={existingValue} />
            } */}
			{
				<Grid item flex={'auto'} style={{}}>
					<RichTextEditorCmp
						isSmart={true}
						label={label}
						customControls={customControls}
						onCustomControlClick={onCustomControlClick}
						//onChangeVal={onChangeVal}
						onContentStateChange={onContentStateChangeInternal}
						existingValue={initialVal}
					/>
				</Grid>
			}
		</Grid>
	);
}

//https://github.com/sstur/react-rte
function RichTextEditorCmp({
	isSmart,
	label,
	onChangeVal,
	onContentStateChange,
	existingValue,
	customControls,
	onCustomControlClick,
}) {
	const [value, setValue] = useState(
		existingValue
			? RichTextEditor.createValueFromString(existingValue, 'html')
			: RichTextEditor.createEmptyValue(),
	);

	useEffect(() => {
		setValue(
			existingValue
				? RichTextEditor.createValueFromString(existingValue, 'html')
				: RichTextEditor.createEmptyValue(),
		);
		if (onContentStateChange)
			onContentStateChange({
				target: {
					value: existingValue,
				},
			});
		if (onChangeVal) onChangeVal(existingValue);
	}, [existingValue]);
	const onEditorStateChange = (_editorState) => {
		setValue(_editorState);
		if (onContentStateChange)
			onContentStateChange({
				target: {
					value: _editorState.toString('html'),
				},
			});
		if (onChangeVal) onChangeVal(_editorState.toString('html'));
	};
	return (
		<div style={{ paddingLeft: '0px', paddingBottom: '18px' }}>
			{isSmart !== true && (
				<label style={{ fontWeight: 400, fontSize: '1rem' }}>{label}</label>
			)}
			<div style={{ height: '8px' }}></div>
			<RichTextEditor
				value={value}
				placeholder={label}
				onChange={onEditorStateChange}
				className="react-rte-editor"
				//toolbarConfig={toolbarConfig}
				//customControls={internalCustomControls ? internalCustomControls : <></>}
			/>
			{/* <Editor
        style={{ border : '1px solid grey'}}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      /> */}
			{/* <textarea
        disabled
        //value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
		</div>
	);
}

const isEmail = (emailInput) => {
	var re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line no-useless-escape
	return re.test(emailInput);
};
function GenericInputCmp({
	inputType,
	required,
	name,
	label,
	maxLength,
	visibleLines,
	onChangeValue,
	existingValue,
	validation,
	multiple,
	promptConfigName,
	formValues,
	salesPlayRecord,
	formItem,
	objectDescribe,
}) {
	const [val, setVal] = useState(existingValue);
	const [error, setError] = useState(false);

	const onChange = (value) => {
		if (onChangeValue) onChangeValue(name, value);
	};
	const setInputValue = (event) => {
		let val =
			event?.target?.type === 'checkbox' ? event?.target?.checked : event?.target?.value;
		if (inputType === 'ProductSelection' || inputType === 'SObjectTextAutoComplete')
			val = event;
		if (inputType !== 'RichTextArea' && inputType !== 'SmartRichTextArea') setVal(val); //As Rich Box is causing issue while edit and editing on begining
		if (validation) {
			if (validation === 'email') {
				setError(!isEmail(val));
			}
		}
		onChange(val);
	};
	const onChangeFile = (newValue) => {
		setVal(newValue);
		onChange(newValue);
	};
	useEffect(() => {
		setVal(existingValue);
	}, [existingValue]);

	return (
		<div className="salesplay-form" style={{ width: '100%' }}>
			{(inputType === 'TextField' || inputType === 'TextArea') && (
				<TextField
					required={required}
					style={{ width: '100%' }}
					id={'input_' + name}
					label={label}
					inputProps={{
						maxLength: maxLength,
					}}
					value={val}
					multiline={inputType === 'TextArea'}
					rows={inputType === 'TextArea' ? visibleLines : undefined}
					onChange={setInputValue}
					//onBlur={setDirty(true)}
					error={error === true}
					helperText={error === true ? 'Invalid email' : undefined}
				/>
			)}
			{inputType === 'RichTextArea' && (
				<RichTextEditorCmp
					label={label}
					onContentStateChange={setInputValue}
					existingValue={val}
				/>
			)}
			{inputType === 'SmartRichTextArea' && (
				<SmartRichTextEditorCmp
					label={label}
					onContentStateChange={setInputValue}
					existingValue={val}
					promptConfigName={promptConfigName}
					formValues={formValues}
					salesPlayRecord={salesPlayRecord}
					formItem={formItem}
					objectDescribe={objectDescribe}
				/>
			)}
			{inputType === 'Checkbox' && (
				<FormControlLabel
					style={{ paddingLeft: '5px' }}
					control={<Checkbox defaultChecked={existingValue} />}
					label={label}
					onChange={setInputValue}
				/>
			)}
			{inputType === 'Number' && (
				<TextField
					id={name}
					label={label}
					type="number"
					InputLabelProps={{
						shrink: true,
					}}
					onChange={setInputValue}
				/>
			)}
			{inputType === 'Currency' && (
				<FormControl fullWidth sx={{ m: 1 }}>
					<InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
						label={label}
						onChange={setInputValue}
					/>
				</FormControl>
			)}
			{inputType === 'File' && !(multiple === true) && (
				<FWFileInput
					existingValue={existingValue}
					onChange={onChangeFile}
					value={val}
					label={required ? label + '*' : label}
					placeholder={label}
				/>
			)}
			{inputType === 'File' && multiple === true && (
				<FWFileInput
					existingValue={existingValue}
					multiple={true}
					onChange={onChangeFile}
					value={val}
					label={label}
					placeholder={label}
				/>
			)}
			{inputType === 'ProductSelection' && (
				<ProductSelectionCmp
					existingValue={existingValue}
					disabledValues={existingValue}
					onChange={setInputValue}
				/>
			)}
			{inputType === 'SObjectTextAutoComplete' && (
				<FWSObjectTextAutoCompleteCmp
					onChange={setInputValue}
					existingVal={existingValue}
					sobjectApiName={'fwave__SalesPlaybook__c'}
					fieldApiName={name}
					label={label}
					defaultOptions={[
						'Agriculture',
						'Apparel',
						'Banking',
						'Biotechnology',
						'Chemicals',
						'Communications',
						'Construction',
						'Consulting',
						'Education',
						'Electronics',
						'Energy',
						'Engineering',
						'Entertainment',
						'Environmental',
						'Finance',
						'Food & Beverage',
						'Government',
						'Healthcare',
						'Hospitality',
						'Insurance',
						'Machinery',
						'Manufacturing',
						'Media',
						'Not For Profit',
						'Recreation',
						'Retail',
						'Shipping',
						'Technology',
						'Telecommunications',
						'Transportation',
						'Utilities',
						'Other',
					]}
				/>
			)}
		</div>
	);
}

function FWFileInput({ onChange, value, required, label, multiple, existingValue }) {
	//const [val, setVal] = useState();
	const [savedFile, setSavedFile] = useState();
	const [showDelete, setShowDelete] = useState(false);
	const [currentRecordId, setCurrentRecordId] = useState();
	const [currentFile, setCurrentFile] = useState();
	//const [currentSpbFileId, setCurrentSpbFileId] = useState();
	const [showToast, setShowToast] = useState();
	const [unsavedNativeFileVal, setUnsavedNativeFileVal] = useState(); //Save List of Native Unsaved file to show file state in input
	const onChangeFileInternal = (event) => {
		if (multiple === true) {
			setUnsavedNativeFileVal(event);
		} else {
			setUnsavedNativeFileVal(event);
		}
		if (onChange) {
			onChange(
				multiple !== true && !event
					? { featurewaveFileId: savedFile?.featurewaveFileId }
					: event,
			);
		}
	};
	useEffect(() => {
		let _defaultFile;
		let _files = existingValue || [];
		if (multiple === true) {
			_defaultFile = [];
			for (let _file of _files) {
				if (_file.fileName) {
					//Temporary fix for undefined files after upload
					const file1 = new File([_file.fileName], _file.fileName, {
						//type: "text/plain",
					});

					_defaultFile.push({
						fileNativeObj: file1,
						featurewaveFileId: _file.featurewaveFileId,
						spbFileId: _file.spbFileId,
					});
				}
			}
		} else {
			let _file = existingValue && existingValue.length > 0 ? existingValue[0] : undefined;
			if (_file) {
				_defaultFile = {
					fileNativeObj: new File([_file.fileName], _file.fileName, {
						//type: "text/plain",
						featurewaveFileId: _file.featurewaveFileId,
					}),
					featurewaveFileId: _file.featurewaveFileId,
					spbFileId: _file.spbFileId,
				};
			}
			onChange({ featurewaveFileId: _file?.featurewaveFileId });
		}
		setSavedFile(_defaultFile);
	}, [existingValue, multiple]);

	const onDeleteFile = (fileItem, event) => {
		let fwFileId = fileItem.featurewaveFileId; //spbFileId = fileItem.fwFileId;
		setCurrentFile(fileItem);
		setCurrentRecordId(fwFileId);
		//setCurrentSpbFileId(spbFileId);
		setShowDelete(true);
	};

	return (
		<div className="fwFileInputContainer">
			<Dialog
				open={showDelete}
				//onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth={true}
				//fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Are you sure?</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<center className="slds-p-around_medium slds-text-heading_small">
						<p>Are you sure want to delete?</p>
					</center>
				</DialogContent>
				<DialogActions>
					<FWButton
						key="cancel-clear-mapping"
						label="Cancel"
						variant="outlined"
						onClick={() => {
							setShowDelete(false);
							setCurrentRecordId(undefined);
						}}
					>
						Cancel
					</FWButton>
					<FWGradiantButton
						key="confirm-clear-mapping"
						label="Confirm"
						variant="contained"
						onClick={() => {
							if (currentFile.featurewaveFileId) {
								//Saved File
								SalesforceCommonApi.deleteRecords([currentFile.spbFileId]).then(
									() => {
										SalesforceCommonApi.deleteRecords(
											[currentFile.featurewaveFileId],
											ns(OBJECT_TYPE_File),
										).then(() => {
											if (multiple === true) {
												let _files = savedFile;
												_files = _files.filter((fileItem) => {
													return (
														fileItem.featurewaveFileId !==
														currentRecordId
													);
												});
												setSavedFile(_files);
											} else {
												setSavedFile(undefined);
											}
											setShowToast(true);
											setShowDelete(false);
											setCurrentRecordId(undefined);
										});
									},
								);
							} else {
								//UnsavedFile
								if (multiple === true) {
									let _finalUnsavedItems = (unsavedNativeFileVal || []).filter(
										(_file) => {
											return _file.name !== currentFile.name;
										},
									);
									setUnsavedNativeFileVal(_finalUnsavedItems);
									if (onChange) {
										onChange(_finalUnsavedItems);
									}
								} else {
									setUnsavedNativeFileVal(undefined);
									if (onChange) {
										onChange(undefined);
									}
								}
								setShowToast(true);
								setShowDelete(false);
								setCurrentRecordId(undefined);
							}
						}}
					>
						Delete
					</FWGradiantButton>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={showToast}
				autoHideDuration={5000}
				onClose={() => setShowToast(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info" sx={{ width: '100%' }} onClose={() => setShowToast(false)}>
					File is deleted successfully.
				</Alert>
			</Snackbar>
			<Card style={{ marginLeft: '10px' }}>
				<CardContent>
					<MuiFileInput
						inputProps={{
							id: 'muiFileInputId',
							class: 'muiFileInputId',
							className: 'muiFileInputId',
						}}
						InputProps={{
							startAdornment: <AttachFileOutlined />,
						}}
						className="mui-file-input"
						id="muiFileInputId"
						style={{ display: multiple !== true && savedFile ? 'none' : '' }}
						onChange={onChangeFileInternal}
						value={unsavedNativeFileVal}
						label={required ? label + '*' : label}
						placeholder={label}
						multiple={multiple === true}
					/>
					{multiple === true && (
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{ paddingLeft: '20px' }}
							spacing={2}
							className="headingSpaceTop"
						>
							{(unsavedNativeFileVal || []).map((fileItem) => (
								<Grid key={fileItem.name} item style={{ padding: '5px' }}>
									<FWFileWithActionCmp
										fileId={fileItem.featurewaveFileId}
										fileName={fileItem?.name}
										fileSize={'1.0KB'}
										showDelete={multiple === true}
										onDelete={(event) => {
											onDeleteFile(fileItem, event);
										}}
									/>
								</Grid>
							))}
						</Grid>
					)}
					{multiple === true && (
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{ paddingLeft: '20px' }}
							spacing={2}
							className="headingSpaceTop"
						>
							{(savedFile || []).map((fileItem) => (
								<Grid key={fileItem.name} item style={{ padding: '5px' }}>
									<FWFileWithActionCmp
										fileId={fileItem.featurewaveFileId}
										fileName={fileItem.fileNativeObj?.name}
										fileSize={'1.0KB'}
										showDelete={multiple === true}
										onDelete={(event) => {
											onDeleteFile(fileItem, event);
										}}
									/>
								</Grid>
							))}
						</Grid>
					)}
					{multiple !== true && unsavedNativeFileVal && (
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{ paddingLeft: '20px' }}
							spacing={2}
							className="headingSpaceTop"
						>
							<Grid item flex={'auto'} style={{ padding: '5px' }}>
								<FWFileWithActionCmp
									fileName={unsavedNativeFileVal?.name}
									fileSize={'1.0KB'}
									showDelete={true}
									onDelete={(event) => {
										onDeleteFile(unsavedNativeFileVal, event);
									}}
								/>
							</Grid>
						</Grid>
					)}
					{multiple !== true && savedFile && (
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							style={{ paddingLeft: '20px' }}
							spacing={2}
							className="headingSpaceTop"
						>
							<Grid item flex={'auto'} style={{ padding: '5px' }}>
								<FWFileWithActionCmp
									fileId={savedFile.featurewaveFileId}
									fileName={savedFile?.fileNativeObj?.name}
									fileSize={'1.0KB'}
									showDelete={multiple === true}
									onDelete={(event) => {
										onDeleteFile(savedFile, event);
									}}
									showReplace={!(multiple === true) && !unsavedNativeFileVal}
									onReplace={(event) => {
										document
											.getElementsByClassName('muiFileInputId')[0]
											.click();
									}}
								/>
							</Grid>
						</Grid>
					)}
				</CardContent>
			</Card>
		</div>
	);
}
function SalesPlayNewRecordFormCmp({
	relatedListType,
	salesPlayId,
	refreshSalesPlayBooks,
	onRecordChange,
	setSelectedSalesPlayId,
	selectFromSalesPlayId,
	salesPlayList,
	onCancel,
	isEdit,
	editRecord,
}) {
	const [objectDescribe, setObjectDescribe] = useState();
	const [formDescribe, setFormDescribe] = useState();
	const [values, setValues] = useState({});
	const [salesPlayRecord, setSalesPlayRecord] = useState();
	const [isSaving, setIsSaving] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState();
	const [existingValues, setExistingValues] = useState({});
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	//const [salesPlayRecord, setSalesPlayRecord] = useState({}); // THis is required for related list prompt to get dynamic fields related to sales play like Industry and Products

	const setInputValueNew = (name, value) => {
		setValues((oldValues) => ({ ...oldValues, [name]: value }));
	};

	useEffect(() => {
		let objectDescribe;
		let initializeForm = true;
		setSalesPlayRecord(
			salesPlayList.find((record) => {
				return record.Id === salesPlayId;
			}),
		);
		if (relatedListType) {
			objectDescribe = SALESPLAY_RELATED_ITEMS.find((item) => item.name === relatedListType);
			if (relatedListType === 'criticalMessaging') {
				let selectedPlayBook = salesPlayList.find((record) => {
					return record.Id === salesPlayId;
				});
				//setSalesPlayRecord(selectedPlayBook);
				let criticalMessaging = selectedPlayBook[NAMESPACE_PREFIX + 'CriticalMessaging__c'];
				if (!criticalMessaging) criticalMessaging = '<p></p>';
				setExistingValues((oldValues) => ({
					...oldValues,
					[NAMESPACE_PREFIX + 'CriticalMessaging__c']: criticalMessaging,
				}));
				initializeForm = false;
			}
		} else {
			objectDescribe = SALESPLAY;
			if (isEdit === true) {
				setExistingValues((oldValues) => ({ ...oldValues, editRecord }));
			}
		}
		if (objectDescribe) {
			setObjectDescribe(objectDescribe);
			setFormDescribe(objectDescribe.form);
		}
		if (initializeForm) setValues(Object.create({}));
	}, [relatedListType, salesPlayId]);

	useEffect(() => {
		if (selectFromSalesPlayId && salesPlayId !== selectFromSalesPlayId) {
			if (relatedListType === 'criticalMessaging') {
				let selectedPlayBook = salesPlayList.find((record) => {
					return record.Id === selectFromSalesPlayId;
				});
				let criticalMessaging = selectedPlayBook[NAMESPACE_PREFIX + 'CriticalMessaging__c'];
				if (!criticalMessaging) criticalMessaging = '<p></p>';
				setExistingValues((oldValues) => ({
					...oldValues,
					[NAMESPACE_PREFIX + 'CriticalMessaging__c']: criticalMessaging,
				}));
			}
		}
	}, [selectFromSalesPlayId]);

	const onFormSubmit = (e) => {
		e.preventDefault();
		setShowError(false);
		setErrorMessage(undefined);
		if (relatedListType === 'criticalMessaging') {
			values['Id'] = salesPlayId;
		}
		//Validate File
		if (
			objectDescribe.hasFile === true &&
			!objectDescribe.hasMultipleFile &&
			(!values.File || !values.File?.name)
		) {
			//Show Error
			setErrorMessage('No file. Select file and try again.');
			setShowError(true);
			return;
		}

		let _savePromise = AdminApi.createSalesPlayBookRelated(
			values,
			relatedListType === 'criticalMessaging'
				? SALESPLAY.baseObjectApiName
				: objectDescribe.baseObjectApiName,
			objectDescribe.objectApiName,
			objectDescribe.relatedLookupApiName,
			objectDescribe.salesPlayApiName,
			salesPlayId,
			objectDescribe,
		);
		//setSavePromise(_savePromise);
		setIsSaving(true);

		// _savePromise.catch((response)=>{
		//     if(response && response.length>0){
		//         if(response[0].errors && response[0].errors.length>0){
		//             let firstError = response[0].errors[0];
		//             let statusCode = firstError.statusCode;
		//             if(statusCode === "DUPLICATES_DETECTED"){
		//                 setErrorMessage('Duplicate record found. In order to use existing, use above search bar.');
		//                 setShowError(true);
		//             }
		//         }
		//     }
		// })
		_savePromise
			.then((createResponse) => {
				if (!relatedListType || relatedListType === 'criticalMessaging')
					refreshSalesPlayBooks(); //Added Critical Message due to bug: https://featurewave-io.atlassian.net/browse/ENG-274
				if (!relatedListType) {
					let newSalesPlayBookId = createResponse[0];
					setSelectedSalesPlayId(newSalesPlayBookId);
				}

				let message =
					objectDescribe.label +
					' was ' +
					(relatedListType === 'criticalMessaging' ? 'updated' : 'created.');
				if (relatedListType && relatedListType !== 'criticalMessaging')
					message += ' and added to the current sales play.';
				else message += '.';
				setToastMessage(message);
				setShowToast(true);
				// let oldFormDescribe = {...formDescribe};
				// setFormDescribe(undefined);
				// setIsSaving(false)
				// setTimeout(()=>{
				//     setFormDescribe(oldFormDescribe)
				// },1000)
				setTimeout(() => {
					if (relatedListType === 'criticalMessaging') {
						setExistingValues(Object.create(values));
						setValues(Object.create({}));
					} else {
						setValues(Object.create({}));
					}
					setFormDescribe(undefined);
					if (onRecordChange) onRecordChange();
					if (onCancel) onCancel();
				}, 700);
			})
			.catch((response) => {
				if (response && response.length > 0) {
					if (response[0].errors && response[0].errors.length > 0) {
						let firstError = response[0].errors[0];
						let statusCode = firstError.statusCode;
						if (statusCode === 'DUPLICATES_DETECTED') {
							setErrorMessage(
								'This record looks like an existing record. Make sure to check any potential duplicate records before saving.',
							);
						} else {
							setErrorMessage(
								'Unexpected error occurred while saving the record, please contact your system admin. ' +
									(statusCode ? 'Error code is: ' + statusCode : ''),
							);
						}
						setIsSaving(false);
						setShowError(true);
					}
				}
			});
	};
	return (
		<div>
			{' '}
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { m: 1, width: '100%' },
				}}
				//noValidate
				autoComplete="off"
				onSubmit={onFormSubmit}
			>
				<Grid container style={{ background: '#F5F8FD', padding: '20px' }}>
					<Grid container item direction={'column'} style={{ width: '100%' }}>
						<Grid item container direction={'row'}>
							<Grid sx={{ flexGrow: 1 }} className="salesplay-title">
								&nbsp;{objectDescribe?.label}
							</Grid>
							<Grid>
								<Box sx={{ m: 1, position: 'relative' }}>
									{relatedListType !== 'criticalMessaging' && (
										<FWButton onClick={onCancel}>Cancel</FWButton>
									)}{' '}
									&nbsp;
									<FWGradiantButton
										//variant="outlined"
										//color="error"
										//sx={buttonSx}
										disabled={isSaving}
										type="submit"
									>
										{isSaving
											? objectDescribe?.form?.createActionProcessingLabel
												? objectDescribe.form.createActionProcessingLabel
												: 'Creating..'
											: objectDescribe?.form?.createActionLabel
												? objectDescribe.form.createActionLabel
												: 'Create'}
									</FWGradiantButton>
									{isSaving && (
										<CircularProgress
											size={24}
											sx={{
												color: green[500],
												position: 'absolute',
												top: '50%',
												left: '75%',
												marginTop: '-12px',
												marginLeft: '-12px',
											}}
										/>
									)}
								</Box>
							</Grid>
						</Grid>
						<Grid item container direction={'column'}>
							{
								<div>
									{formDescribe?.fields
										?.filter((field) => !field.excludeInForm)
										.map((item) => {
											return (
												<Grid
													item
													container
													key={item.name}
													direction={'row'}
													alignItems={'center'}
													justifyContent={'center'}
												>
													{/* <TextField
                                            required={item.required}
                                            id={'input_'+item.name}
                                            label={item.label}  
                                            inputProps={{
                                                maxLength : item.maxLength
                                            }}
                                            value={values[item.name]}
                                            onChange={setInputValue(item.name)}

                                        /> */}
													<Grid container item direction={'column'}>
														<table style={{ width: '100%' }}>
															<tr style={{ width: '100%' }}>
																<td
																	style={{
																		width: item.tooltip
																			? '98%'
																			: '100%',
																	}}
																>
																	<GenericInputCmp
																		inputType={item.type}
																		existingValue={
																			existingValues[
																				item.name
																			]
																		}
																		name={item.name}
																		label={item.label}
																		maxLength={item.maxLength}
																		required={item.required}
																		key={item.name}
																		visibleLines={
																			item.visibleLines
																		}
																		onChangeValue={
																			setInputValueNew
																		}
																		validation={item.validation}
																		multiple={item.multiple}
																		promptConfigName={
																			item.promptConfigName
																		}
																		formValues={
																			relatedListType
																				? values
																				: values
																		}
																		salesPlayRecord={
																			salesPlayRecord
																		}
																		formItem={item}
																		objectDescribe={
																			objectDescribe
																		}
																	/>
																</td>
																{item.tooltip && (
																	<td
																		style={{
																			width: '2%',
																			paddingLeft: '15px',
																		}}
																	>
																		<Grid item>
																			<SimpleTooltipCmp
																				title={item.tooltip}
																			>
																				<InfoOutlined color="secondary" />
																			</SimpleTooltipCmp>
																		</Grid>
																	</td>
																)}
															</tr>
														</table>
													</Grid>
													{/* {
                                            item.tooltip && <Grid item xs={1} style={{paddingLeft : '15px'}}>
                                            <SimpleTooltipCmp 
                                                title={item.tooltip}
                                            >
                                                <InfoOutlined color="secondary"/>
                                            </SimpleTooltipCmp>
                                        </Grid>
                                        } */}
												</Grid>
											);
										})}
								</div>
							}
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Snackbar
				open={showToast}
				autoHideDuration={3000}
				onClose={() => setShowToast(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info" sx={{ width: '100%' }} onClose={() => setShowToast(false)}>
					{toastMessage}
				</Alert>
			</Snackbar>
			<Snackbar
				open={showError}
				autoHideDuration={3000}
				onClose={() => setShowError(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error" sx={{ width: '100%' }} onClose={() => setShowError(false)}>
					{errorMessage}
				</Alert>
			</Snackbar>
		</div>
	);
}
//Purpose of this component to render record in edit mode and pass record data to parent component
function SalesPlayEditRecordFormCoreCmp({
	relatedListType,
	salesPlayId,
	salesPlayRecord,
	refreshSalesPlayBooks,
	recordList,
	onCancel,
	editRecordId,
	record,
	onSubmitForm,
	successCallback,
	refreshDate,
	actionLabel = 'Update',
}) {
	const [objectDescribe, setObjectDescribe] = useState();
	const [formDescribe, setFormDescribe] = useState();
	const [values, setValues] = useState({});
	//const [editRecord, setEditRecord] = useState(record);
	const [isSaving, setIsSaving] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState();
	const [existingValues, setExistingValues] = useState({});

	const setInputValueNew = (name, value) => {
		setValues((oldValues) => ({ ...oldValues, [name]: value }));
	};

	useEffect(() => {
		let objectDescribe;
		//let salesPlayRecord;
		let objectRecord;
		if (relatedListType) {
			objectDescribe = SALESPLAY_RELATED_ITEMS.find((item) => item.name === relatedListType);
			let junctionRecord = recordList.find((item) => item.Id === editRecordId);
			let relatedLookupApiName = Utils.getRelationshipNameFromField(
				objectDescribe.relatedLookupApiName,
			);
			objectRecord = junctionRecord[relatedLookupApiName];
		} else {
			objectDescribe = SALESPLAY;
			objectRecord = recordList.find((item) => item.Id === editRecordId);
			//salesPlayRecord = objectRecord;
		}

		//setEditRecord(salesPlayRecord);
		setExistingValues((oldValues) => ({ ...objectRecord }));
		setValues(objectRecord);

		if (objectDescribe) {
			setObjectDescribe(objectDescribe);
			setFormDescribe(objectDescribe.form);
		}
	}, [editRecordId]);

	const onFormSubmit = (e) => {
		e.preventDefault();
		let finalObjectValues = {};
		if (actionLabel === 'Clone') {
			for (let field of objectDescribe.form.fields) {
				if (
					field.type !== 'File' &&
					field.type !== 'ProductSelection' &&
					!field.notEditable
				) {
					finalObjectValues[field.name] = values[field.name];
				}
			}
		} else {
			finalObjectValues = values;
		}

		let savePromise;
		if (onSubmitForm) {
			savePromise = onSubmitForm(finalObjectValues);
		}
		if (savePromise) {
			setIsSaving(true);
			savePromise.then((response) => {
				setExistingValues(Object.create(values));
				setValues(Object.create({}));

				if (successCallback) successCallback(response);
				setIsSaving(false);

				let message =
					actionLabel === 'Clone'
						? 'The sales play was cloned successfully.'
						: 'The ' + objectDescribe?.label + ' was updated successfully.';
				setToastMessage(message);
				setShowToast(true);
				setTimeout(() => {
					onCancel();
				}, 1000);
			});
		}
	};
	return (
		<div>
			{' '}
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { m: 1, width: '100%' },
				}}
				//noValidate
				autoComplete="off"
				onSubmit={onFormSubmit}
			>
				<Grid container style={{ background: '#F5F8FD', padding: '20px' }}>
					<Grid container item direction={'column'} style={{ width: '100%' }}>
						<Grid item container direction={'row'}>
							<Grid sx={{ flexGrow: 1 }} className="salesplay-title">
								{objectDescribe?.label}
							</Grid>
							<Grid>
								<Box sx={{ m: 1, position: 'relative' }}>
									{relatedListType !== 'criticalMessaging' && (
										<FWButton onClick={onCancel}>Cancel</FWButton>
									)}{' '}
									&nbsp;
									<FWGradiantButton
										//variant="outlined"
										//color="error"
										//sx={buttonSx}
										disabled={isSaving}
										type="submit"
									>
										{isSaving
											? actionLabel === 'Update'
												? 'Updating..'
												: actionLabel === 'Clone'
													? 'Cloning..'
													: 'Update'
											: actionLabel}
									</FWGradiantButton>
									{isSaving && (
										<CircularProgress
											size={24}
											sx={{
												color: green[500],
												position: 'absolute',
												top: '50%',
												left: '75%',
												marginTop: '-12px',
												marginLeft: '-12px',
											}}
										/>
									)}
								</Box>
							</Grid>
						</Grid>
						<Grid item container direction={'column'}>
							{
								<div>
									{formDescribe?.fields
										?.filter((field) => !field.excludeInForm)
										.map((item) => {
											return (
												<Grid item key={item.name}>
													<table style={{ width: '100%' }}>
														<tr style={{ width: '100%' }}>
															<td
																style={{
																	width: item.tooltip
																		? '98%'
																		: '100%',
																}}
															>
																<GenericInputCmp
																	inputType={item.type}
																	existingValue={
																		existingValues[item.name]
																	}
																	name={item.name}
																	label={item.label}
																	maxLength={item.maxLength}
																	required={item.required}
																	key={item.name}
																	visibleLines={item.visibleLines}
																	onChangeValue={setInputValueNew}
																	validation={item.validation}
																	multiple={item.multiple}
																	promptConfigName={
																		item.promptConfigName
																	}
																	formValues={
																		relatedListType
																			? values
																			: salesPlayRecord
																	}
																	salesPlayRecord={
																		salesPlayRecord
																	}
																	formItem={item}
																	objectDescribe={objectDescribe}
																/>
															</td>
															{item.tooltip && (
																<td
																	style={{
																		width: '2%',
																		paddingLeft: '15px',
																	}}
																>
																	<Grid item>
																		<SimpleTooltipCmp
																			title={item.tooltip}
																		>
																			<InfoOutlined color="secondary" />
																		</SimpleTooltipCmp>
																	</Grid>
																</td>
															)}
														</tr>
													</table>
												</Grid>
											);
										})}
								</div>
							}
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Snackbar
				open={showToast}
				autoHideDuration={5000}
				onClose={() => setShowToast(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="info" sx={{ width: '100%' }} onClose={() => setShowToast(false)}>
					{toastMessage}
				</Alert>
			</Snackbar>
		</div>
	);
}

function SalesplayRelatedDataTableCmp({
	salesPlayId,
	relatedListType,
	refreshDate,
	refreshSalesPlayBooks,
	setSelectedSalesPlayId,
	salesPlayList,
}) {
	const [records, setRecords] = useState([]);
	const [columns, setColumns] = useState([]);
	const [currentRecordId, setCurrentRecordId] = useState();
	const [currentRecord, setCurrentRecord] = useState();
	const [showDelete, setShowDelete] = useState(false);
	const [showClone, setShowClone] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [objectDescribe, setObjectDescribe] = useState(undefined);
	const [stage, setStage] = useState('Initialized');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const [salesPlayRecord, setSalesPlayRecord] = useState({});

	useEffect(() => {
		let objectDescribe;
		if (relatedListType) {
			objectDescribe = SALESPLAY_RELATED_ITEMS.find((item) => item.name === relatedListType);
		} else {
			objectDescribe = SALESPLAY;
		}
		setColumns([]);
		setRecords([]);
		setObjectDescribe(objectDescribe);
		initializeData(objectDescribe);
		let _columns = SalesPlayAdminService.getColumns({
			relatedListType,
			objectDescribe,
			setCurrentRecordId,
			setCurrentRecord,
			setShowDelete,
			setShowClone,
			setShowEdit,
			onClickRow: setSelectedSalesPlayId,
		});
		setColumns(_columns);
	}, [relatedListType, salesPlayId]);

	useEffect(() => {
		initializeData(objectDescribe);
	}, [refreshDate]);

	useEffect(() => {
		setRecords(salesPlayList);
		if (salesPlayId) {
			let selectedPlayBook = salesPlayList.find((record) => {
				return record.Id === salesPlayId;
			});
			setSalesPlayRecord(selectedPlayBook);
		}
	}, [salesPlayList, salesPlayId]);

	const initializeData = (objectDescribe) => {
		setStage('Loading');
		if (((relatedListType && salesPlayId) || !relatedListType) && objectDescribe) {
			if (!relatedListType || relatedListType === SALESPLAY.name) {
				SalesPlaybookApi.getSalesPlays().then((_records) => {
					//let _records = relatedRecordResp.records;

					/*
                    Check has been added due to async API Calling and setting data did not working fine. Causing below issues:
                        * https://featurewave-io.atlassian.net/browse/ENG-381
                        * https://featurewave-io.atlassian.net/browse/ENG-372
                    */
					//console.log('Before Load : relatedListType:'+relatedListType+', Name:'+ objectDescribe.name + ', Records:',_records);
					if (!relatedListType && objectDescribe.name === SALESPLAY.name) {
						setRecords(_records);
						setStage('Loaded');
						//console.log('After Load: relatedListType:'+relatedListType+', Name:'+ objectDescribe.name + ', Records:',_records);
					}
				});
			} else {
				SalesPlaybookApi.getRelatedList(salesPlayId, relatedListType, objectDescribe).then(
					(relatedRecordResp) => {
						let _records = relatedRecordResp.records;

						/*
                    Check has been added due to async API Calling and setting data did not working fine. Causing below issues:
                        * https://featurewave-io.atlassian.net/browse/ENG-381
                        * https://featurewave-io.atlassian.net/browse/ENG-372
                    */
						//console.log('Before Load : relatedListType:'+relatedListType+', Name:'+ objectDescribe.name + ', Records:',_records);
						if (relatedListType && relatedListType === objectDescribe.name) {
							setRecords(_records);
							setStage('Loaded');
							//console.log('After Load: relatedListType:'+relatedListType+', Name:'+ objectDescribe.name + ', Records:',_records);
						}
					},
				);
			}
		}
	};

	useEffect(() => {}, [salesPlayId, relatedListType]);

	return (
		<div id="salesPlayAdminHome_relatedDataTableContainer" style={{ background: '#F5F8FD' }}>
			<DataGrid
				columns={columns}
				rows={records}
				autoHeight={true}
				showCellVerticalBorder={false}
				showColumnVerticalBorder={false}
				loading={stage === 'Loading'}
				slots={{
					noRowsOverlay: SalesPlayAdminService.getNoRowOverlayCmp(),
				}}
				//selectionModel={selectionModel}
				disableRowSelectionOnClick
				//checkboxSelection={recordSelection === 'Multiple'}
				// checkboxSelection={true}
				//density="compact"
				getRowId={(row) => row.Id}
				//={stage === 'loadingFiles'}
				//pageSize={5}
				//pageSize={5}
				pageSizeOptions={[5, 10, 20, 50, 100]}
				paginationModel={paginationModel}
				onPaginationModelChange={(paginationModel) => setPaginationModel(paginationModel)}
				//onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				//rowsPerPageOptions={[10, 20, 50]}
				//pagination
				style={{ border: 'none' }}
				//rowHeight={!relatedListType? 75 : 60}
				getRowHeight={() => 'auto'}
			/>

			<Dialog
				open={showDelete}
				//onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth={true}
				//fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Are you sure?</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<center className="slds-p-around_medium slds-text-heading_small">
						<p>Are you sure want to delete?</p>
					</center>
				</DialogContent>
				<DialogActions>
					<FWButton
						key="cancel-clear-mapping"
						label="Cancel"
						variant="outlined"
						onClick={() => {
							setShowDelete(false);
							setCurrentRecordId(undefined);
							setCurrentRecord(undefined);
						}}
					>
						Cancel
					</FWButton>
					<StatefulButton
						key="confirm-clear-mapping"
						label="Delete"
						processingLabel="Deleting..."
						variant="contained"
						onClick={async () => {
							let spbFileIds;
							if (!relatedListType || relatedListType == '') {
								spbFileIds = await SalesPlaybookApi.deleteSpbFiles(currentRecordId);
							} else {
								//If its Sales Play File
								let fwFileId = currentRecord[NAMESPACE_PREFIX + 'File__c'];
								if (fwFileId) {
									spbFileIds = [fwFileId];
								}
							}
							SalesforceCommonApi.deleteRecords([currentRecordId]).then(() => {
								if (spbFileIds?.length > 0) {
									SalesforceCommonApi.deleteRecords(
										spbFileIds,
										NAMESPACE_PREFIX + OBJECT_TYPE_File,
									).then((deleteResult) => {
										setShowDelete(false);
										setCurrentRecordId(undefined);
										initializeData(objectDescribe);
										refreshSalesPlayBooks();
									});
								} else {
									setShowDelete(false);
									setCurrentRecordId(undefined);
									initializeData(objectDescribe);
									refreshSalesPlayBooks();
								}
							});
						}}
					/>
				</DialogActions>
			</Dialog>

			<Dialog
				open={showClone}
				scroll="paper"
				maxWidth="md"
				fullWidth={true}
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Clone Sales Play</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<Grid>
						<SalesPlayEditRecordFormCoreCmp
							relatedListType={relatedListType}
							salesPlayRecord={salesPlayRecord}
							salesPlayId={salesPlayId}
							recordList={records}
							editRecordId={currentRecordId}
							actionLabel="Clone"
							onCancel={() => {
								setShowClone(false);
							}}
							onSubmitForm={(values) => {
								return SalesforceCommonApi.cloneRecord(values, currentRecordId);
							}}
							successCallback={() => {
								refreshSalesPlayBooks();
								refreshDate();
							}}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
			<Dialog
				open={showEdit}
				scroll="paper"
				maxWidth="md"
				fullWidth={true}
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Edit {objectDescribe?.label}</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<Grid>
						<SalesPlayEditRecordFormCoreCmp
							salesPlayRecord={salesPlayRecord}
							relatedListType={relatedListType}
							salesPlayId={salesPlayId}
							recordList={records}
							editRecordId={currentRecordId}
							actionLabel="Update"
							onCancel={() => {
								setShowEdit(false);
								if (relatedListType === 'discoveryIndustryTrend') {
									refreshSalesPlayBooks();
									refreshDate();
								}
							}}
							onSubmitForm={(values) => {
								let finalValues = {};
								let objectDescribe;
								let salesPlayRecord;
								let spbRecordId;
								if (relatedListType) {
									objectDescribe = SALESPLAY_RELATED_ITEMS.find(
										(item) => item.name === relatedListType,
									);
									let junctionRecord = records.find(
										(item) => item.Id === currentRecordId,
									);
									spbRecordId = junctionRecord.Id;
									let relatedLookupApiName = Utils.getRelationshipNameFromField(
										objectDescribe.relatedLookupApiName,
									);
									salesPlayRecord = junctionRecord[relatedLookupApiName];
								} else {
									objectDescribe = SALESPLAY;
									salesPlayRecord = records.find(
										(item) => item.Id === currentRecordId,
									);
									ConfigureDocumentApi.saveSalesPlayTags(
										[salesPlayRecord.Id],
										values.ProductSelection,
									);
								}
								for (let item of objectDescribe.form.fields) {
									if (!item.notEditable) {
										finalValues[item.name] = values[item.name];
									}
								}
								values.Id = salesPlayRecord.Id;
								return AdminApi.updateSalesPlayBookRelated(
									values,
									spbRecordId,
									objectDescribe.baseObjectApiName,
									objectDescribe.objectApiName,
									objectDescribe.relatedLookupApiName,
									objectDescribe.salesPlayApiName,
									salesPlayId,
									objectDescribe,
									values?.File?.featurewaveFileId,
								);
							}}
							successCallback={() => {
								refreshSalesPlayBooks();
								refreshDate();
							}}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default SalesPlayAdminHome;
