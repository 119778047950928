import React, { forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const Alert = forwardRef((props, ref) => (
	<MuiAlert
		elevation={6}
		ref={ref}
		variant="filled"
		sx={{
			background:
				props.severity === 'error'
					? 'linear-gradient(100.05deg, rgba(226, 15, 66, 1) 0%, rgba(154, 11, 222, 1) 100%)'
					: props.severity === 'warning'
						? 'linear-gradient(90.88deg, rgba(248, 77, 39, 1) 4.25%, #ECD71F 92.78%)'
						: props.severity === 'info'
							? 'linear-gradient(100.05deg, #0F6EE2 0%, #1DA9ED 100%)'
							: props.severity === 'success'
								? 'linear-gradient(100.05deg, #0FA3E2 0%, #0BDE79 100%)'
								: null,
		}}
		{...props}
	/>
));
Alert.displayName = 'Alert';
export default styled(Alert)(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// '&:before': {
	//   display: 'none',
	// },
}));
