import { ns } from '../utils/Utils';

export class FWFiles {
	constructor(data) {
		this.records = [];
		if (Array.isArray(data?.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new FWFile(item));
		} else if (Array.isArray(data) && data.length > 0) {
			this.records = data.map((item) => new FWFile(item));
		}
	}
}

export class FWFile {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.ExternalId__c = data[ns('ExternalId__c')];
		this.Source__c = data[ns('Source__c')];
		this.Status__c = data[ns('Status__c')];
		this.Title__c = data[ns('Title__c')];
		this.ContentDocumentId__c = data[ns('ContentDocumentId__c')];
		this.ContentVersionId__c = data[ns('ContentVersionId__c')];
		this.FileType__c = data[ns('FileType__c')];
		this.FileTags__r = data[ns('FileTags__r')]?.records;
		if (Array.isArray(this.FileTags__r) && this.FileTags__r.length > 0) {
			this.fileTags = this.FileTags__r.map((t) => t?.[ns('TagId__r')]).filter(
				(t) => (t?.Name || '').length > 0,
			);
		}
	}
}
