export const QKEY_ALL_OPPTIES = 'allOppties';
export const QKEY_MY_OPPTIES = 'myOppties';
export const QKEY_OPPTY_DETAIL = 'opptyDetail';
export const QKEY_OPPTY_REQUIREMENTS = 'opptyRequirements';
export const QKEY_OPPTY_SALESPLAYBOOK = 'opptySalesplayBook';

export const QKEY_RELATED_MATERIALS = 'relatedMaterials';
export const QKEY_SIMILAR_ACCOUNTS = 'similarAccounts';
export const QKEY_SIMILAR_FEATURES = 'similarFeatures';

export const QKEY_DASHBOARD_FEATURES = 'dashboardFeatures';
export const QKEY_REQUIREMENTS_QUERYCLUSTER = 'requirementsQueryCluster';
export const QKEY_OPPTIES = 'oppties';

export const QKEY_ACCOUNT = 'account';
export const QKEY_ACCOUNT_OPPORTUNITIES = 'accountOpportunities';

export const QKEY_FEATURE = 'feature';
export const QKEY_SEARCH = 'search';
export const QKEY_GOOGLE_SEARCH = 'googleSearch';
export const QKEY_SEARCH_RESULT = 'searchResult';
export const QKEY_SEARCH_HTML_RESULTS = 'searchHtmlResults';

export const QKEY_USER = 'user';
export const QKEY_ORG_INFO = 'orgInfo';

export const QKEY_FWAVE_FILES = 'featurewaveFiles';

export const QKEY_TAGS = 'tags';
export const QKEY_PRODUCT_TAGS = 'productTags';
export const QKEY_SALESPLAY_TAGS = 'salesplayTags';

export const QKEY_PRODUCTS = 'products';

export const QKEY_GPT_SUMMARY = 'gptSummary';
