import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Button, Grid } from '@mui/material';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';

import SmallLoadingCmp from '../SmallLoadingCmp';

import GPTSummaryService from '../../services/GPTSummaryService';

import { GEN_AI_SERVICE_CLAUDE } from '../../Constants';
import { QKEY_GPT_SUMMARY } from '../../utils/QueryKeys';
import GenAiService from '../../services/common/GenAiService';

/**
 * JSX Component, It will check if GPTSummary Record is already there it will display it, otherwise it will generate text and display it and parallel it will save in GPTSummary
 * @param {string} recordId Its ID of the records against which GPT Summary Need to be saved
 * @param {Array} prompts prompts to generate GPT text
 * @returns
 */
export default function GptCommonCmp({
	recordId,
	flow,
	resolveMergeFields,
	getPrompts,
	stream = false,
	displayShowMore = false,
	prompt,
}) {
	const [isLoading, setIsLoading] = useState(true);
	const [outputText, setOutputText] = useState();
	const [overflowStatus, setOverflowStatus] = useState('None');
	const [isContentFullyExpanded, setContentFullyExpanded] = useState(false);
	const [prompts, setPrompts] = useState([]);
	const [chatGPTSearchResult, setChatGPTSearchResult] = useState(null);
	const [generateStatus, setGenerateStatus] = useState('Initial');
	const [isOutputStream, setIsOutputStream] = useState(stream);

	const generate = () => {
		setGenerateStatus('Generating');
		GenerateGPT(prompts);
	};

	const { data: gptSummaryRecord, isLoading: gptSummaryRecordLoading } = useQuery({
		queryKey: [QKEY_GPT_SUMMARY, recordId],
		queryFn: async () =>
			prompt ? undefined : await GPTSummaryService.getGPTSummary({ summaryId: recordId }),
	});

	useEffect(() => {
		if (gptSummaryRecordLoading) return;

		// Check first if there is record exist in GPTSummary
		if (!gptSummaryRecord || prompt) {
			if (generateStatus !== 'Generating' && generateStatus !== 'Generated') generate();
		} else {
			//setIsLoading(false);
			setOutputText(gptSummaryRecord.GPTSummary__c);
			setIsLoading(false);
			setIsOutputStream(false);
			const promptTxt = gptSummaryRecord.RecordSummary__c;
			if (promptTxt && promptTxt !== '[]') {
				try {
					setPrompts(JSON.parse(promptTxt));
				} catch (e) {
					console.error('Error parsing prompts. ', e);
				}
			}
		}
	}, [gptSummaryRecord, gptSummaryRecordLoading, generateStatus]);

	useEffect(() => {
		setTimeout(() => {
			let element = document.getElementById('gptSummaryContainer' + recordId);
			if (element) {
				if (
					element.offsetHeight < element.scrollHeight ||
					element.offsetWidth < element.scrollWidth
				) {
					setOverflowStatus('Overflowed');
					setContentFullyExpanded(false);
				} else {
					setOverflowStatus('NotOverflowed');
					setContentFullyExpanded(true);
				}
			}
		}, 200);
	}, [outputText, recordId]);

	useEffect(() => {
		if (stream === true && chatGPTSearchResult?.data?.length > 0 && isLoading === true) {
			setIsLoading(false);
		}
	}, [stream, chatGPTSearchResult]);

	const handleStream = async (generateStream, prompts) => {
		const reader = generateStream.getReader();
		let done, value;
		const tempStr = [];
		const chunks = { isDone: false, data: [] };
		while (!done) {
			({ value, done } = await reader.read());
			if (done) {
				chunks.isDone = true;
				setChatGPTSearchResult({ ...chatGPTSearchResult, data: tempStr, isDone: true });
				setGenerateStatus('Generated');
				GenAiService.saveGptSummary(recordId, prompts, tempStr.join(''));
			} else {
				var str = new TextDecoder().decode(value);
				tempStr.push(str);
				setChatGPTSearchResult({ ...chatGPTSearchResult, data: tempStr, isDone: false });
			}
		}
	};

	const GenerateGPT = async (prompts) => {
		setIsLoading(true);
		setContentFullyExpanded(false);

		if (stream === true) {
			let genResponse = await GenAiService.generate({
				input: {
					flow: !prompt ? flow : undefined,
					resolveMergeFields: resolveMergeFields,
					summaryId: recordId,
					prompts: !prompt
						? undefined
						: [
								{
									role: 'system',
									content: '',
								},
								{
									role: 'user',
									content: prompt,
								},
							],
				},
				config: {
					stream: true,
					useCache: true,
				},
			});
			let response = genResponse?.response;
			let resolvedPrompts = genResponse?.resolvedPrompts;
			if (typeof response === 'string') {
				setIsLoading(false);
				setOutputText(response);
				setIsOutputStream(false);
			} else {
				if (response) handleStream(response, resolvedPrompts);
				//setGeneratedPrompts(genResponse?.resolvedPrompts);
				setIsOutputStream(true);
			}
		} else {
			let genResponse = await GenAiService.generate({
				input: {
					flow: !prompt ? flow : undefined,
					resolveMergeFields: resolveMergeFields,
					summaryId: recordId,
					prompts: !prompt
						? undefined
						: [
								{
									role: 'system',
									content: '',
								},
								{
									role: 'user',
									content: prompt,
								},
							],
				},
				config: {
					stream: false,
					service: GEN_AI_SERVICE_CLAUDE,
					useCache: true,
				},
			});
			let finalSummary = genResponse.response;
			setIsLoading(false);
			setOutputText(finalSummary);
		}
	};

	return (
		<Grid container>
			{isLoading ? (
				<Grid
					container
					style={{ paddingTop: '25px' }}
					flex={1}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<SmallLoadingCmp />
				</Grid>
			) : (
				<Grid container direction={'column'}>
					{isOutputStream !== true && displayShowMore === true && (
						<>
							<Grid
								item
								id={'gptSummaryContainer' + recordId}
								className={isContentFullyExpanded ? '' : 'line-clamp-4'}
							>
								<Markdown remarkPlugins={[remarkGfm]}>{outputText}</Markdown>
							</Grid>

							<Grid
								item
								container
								flex={1}
								alignItems={'center'}
								justifyContent={'center'}
							>
								{overflowStatus === 'Overflowed' && (
									<Button
										endIcon={
											isContentFullyExpanded ? (
												<ExpandLessOutlined />
											) : (
												<ExpandMoreOutlined />
											)
										}
										onClick={() =>
											setContentFullyExpanded(!isContentFullyExpanded)
										}
									>
										{isContentFullyExpanded ? 'Show Less' : 'Show More'}
									</Button>
								)}
							</Grid>
						</>
					)}
					{isOutputStream !== true && displayShowMore !== true && (
						<>
							<Grid item>
								<Markdown remarkPlugins={[remarkGfm]}>{outputText}</Markdown>
							</Grid>
						</>
					)}

					{isOutputStream === true && (
						<div style={{ height: '100%' }}>
							<Markdown remarkPlugins={[remarkGfm]}>
								{chatGPTSearchResult?.data?.join('')}
							</Markdown>
							<div id="lastGptElement" style={{ height: '5px' }}></div>
						</div>
					)}
				</Grid>
			)}
		</Grid>
	);
}
