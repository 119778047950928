import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import AnthropicService from '../services/AnthropicService';
import { GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT } from '../Constants';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function GPTPlaygroundCmp() {
	const [promptText, setPromptText] = useState([]);
	const [hasError, setHasError] = useState(false);
	const [error, setError] = useState(undefined);
	const [outputText, setOutputText] = useState(undefined);

	const setInputValue = (event) => {
		let _promptTxt = event?.target?.value;
		setPromptText(_promptTxt);
	};

	const onGenerate = () => {
		setOutputText('Loading...');
		let _prompts = [];
		try {
			_prompts = [
				{
					role: 'user',
					content: promptText,
				},
			];
			//setPrompts(_prompts);
		} catch (e) {
			_prompts = [];
			setHasError(true);
			setError(e);
			return;
		}
		AnthropicService.generate(_prompts, [], GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT).then(
			(_genResult) => {
				// console.log(_genResult);
				let outputMsgs = [];
				if (_genResult) {
					let contents = _genResult.content || [];
					for (let content of contents) {
						outputMsgs.push(content.text);
					}
				}
				let finalSummary = outputMsgs.join(',');
				setOutputText(finalSummary);
			},
		);
	};
	return (
		<Grid container style={{ width: '100%' }}>
			<Grid item style={{ width: '100%' }}>
				<TextField
					style={{ width: '100%' }}
					id={'input_'}
					label="Prompts"
					inputProps={{
						maxLength: 999999,
					}}
					multiline={true}
					rows={20}
					onChange={setInputValue}
					//onBlur={setDirty(true)}
					error={error === true}
				/>
				{hasError && <p>{error}</p>}

				{outputText && (
					<p>
						<Markdown remarkPlugins={[remarkGfm]}>{outputText}</Markdown>
					</p>
				)}

				<Button onClick={onGenerate}>Generate</Button>
			</Grid>
		</Grid>
	);
}

export default GPTPlaygroundCmp;
