import {
	Alert,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SalesforceCommonApi from '../../apis/SalesforceCommonApi';
import { StatefulButton } from '../common/UtilComponents';
import OpportunityService from '../../services/OpportunityService';
import SalesPlaybookService from '../../services/SalesPlaybookService';
import PersonaInsight from '../PersonaInsight';
import AdminApi from '../../apis/AdminApi';
import OpportunityOverviewSectionContentCmp from '../OpportunityOverviewSectionContentCmp';
import { AddToPhotosOutlined } from '@mui/icons-material';
import { FWButton } from '../mui/FWButtons';
import {
	GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT,
	PERSONA_INSIGHT_CHATGPT_PROMPT,
} from '../../Constants';

export default function AdminSettingsPersonaInsightCmp() {
	const [prompt, setPrompt] = useState('');
	const [updatedPrompt, setUpdatedPrompt] = useState();
	const [isLoadingAccounts, setLoadingAccounts] = useState(true);
	const [accounts, setAccounts] = useState();
	const [selectedAccount, setSelectedAccount] = useState();
	const [opportunities, setOpportunities] = useState();
	const [isLoadingOpportunituies, setLoadingOpportunituies] = useState(false);
	const [selectedOpportunity, setSelectedOpportunity] = useState();
	const [generationStatus, setGenerationStatus] = useState('Initial');
	const [saveStatus, setSaveStatus] = useState('Initial');
	const [opportunitySO, setOpportunitySO] = useState();
	const [salesPlaySO, setSalesPlaySO] = useState();
	const [selectedField, setSelectedField] = useState('{!companyName}');
	const [cursorPosition, setCursorPosition] = useState();
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showToast, setShowToast] = useState(false);

	useEffect(() => {
		AdminApi.getPersonaInsightPrompt().then((response) => {
			let _prompt = response.content;
			//let _gptRecord = response.record;
			//setGptRecord(_gptRecord);
			setPrompt(_prompt);
			setUpdatedPrompt(_prompt);
		});

		SalesforceCommonApi.query({ query: `SELECT Id, Name FROM Account` }).then((_accounts) => {
			setAccounts(_accounts);
			setLoadingAccounts(false);
		});
	}, []);

	useEffect(() => {
		if (selectedAccount) {
			setLoadingOpportunituies(true);
			SalesforceCommonApi.query({
				query: `SELECT Id, Name FROM Opportunity WHERE AccountId = '${selectedAccount}'`,
			}).then((_opportunities) => {
				setOpportunities(_opportunities);
				setLoadingOpportunituies(false);
			});
		}
	}, [selectedAccount]);

	const handleAccountChange = (event) => {
		setSelectedAccount(event.target.value);
	};

	const handleOpportunityChange = (event) => {
		setSelectedOpportunity(event.target.value);
	};

	const onChangePrompt = (event) => {
		setPrompt(event.target.value);
		setUpdatedPrompt(event.target.value);
		setCursorPosition(event.target.selectionStart);
	};

	const onTestClickHandler = async () => {
		setGenerationStatus('Generating');

		if (selectedOpportunity) {
			let _opptySO = await OpportunityService.getOppDetail(
				selectedOpportunity,
				false,
				false,
				true,
			);
			let _salesPlaySO;
			if (_opptySO && _opptySO.SalesPlaybook__c)
				_salesPlaySO = await SalesPlaybookService.getSalesPlaybookDetail(
					_opptySO?.SalesPlaybook__c,
				);
			setOpportunitySO(_opptySO);
			setSalesPlaySO(_salesPlaySO);
			setGenerationStatus('Generated');
		}
	};

	const handleContextField = (event) => {
		setSelectedField(event.target.value);
	};

	const contextFields = [
		{ name: '{!companyName}', label: 'Company Name' },
		{ name: '{!accountName}', label: 'Account Name' },
		{ name: '{!opportunityName}', label: 'Opportunity Name' },
		{ name: '{!productName}', label: 'Product Name' },
		{ name: '{!productDescription}', label: 'Product Description' },
		{ name: '{!role}', label: 'Persona Role' },
	];

	const appendInLast = () => {
		let textBeforeCursorPosition = updatedPrompt.substring(0, cursorPosition);
		let textAfterCursorPosition = updatedPrompt.substring(cursorPosition, updatedPrompt.length);
		let newVal = textBeforeCursorPosition + '"' + selectedField + '"' + textAfterCursorPosition;
		setPrompt(newVal);
		setUpdatedPrompt(newVal);
	};

	const printCursor = (event) => {
		setCursorPosition(event.target.selectionStart);
	};

	const resetPromptToDefault = () => {
		let defaultPrompt = PERSONA_INSIGHT_CHATGPT_PROMPT;
		setPrompt(defaultPrompt);
		setUpdatedPrompt(defaultPrompt);
	};
	const savePromptClick = async () => {
		setShowConfirmation(true);
	};
	const savePrompt = async () => {
		setSaveStatus('Saving');
		let _updatedPrompt = updatedPrompt || '';
		_updatedPrompt = _updatedPrompt.replaceAll("'", '"');
		_updatedPrompt = _updatedPrompt.replaceAll('"', '\\\\"');
		//_updatedPrompt = _updatedPrompt.replaceAll('"', '\\\\"');
		await AdminApi.updatePrompt({
			developerName: GEN_FLOW_OPPTY_OVERVIEW_PERSONA_INSIGHT,
			masterLabel: 'Opportunity Overview Persona Insight',
			prompt: _updatedPrompt,
		});
		await deleteCache();
		setSaveStatus('Saved');
		setShowConfirmation(false);
	};

	const deleteCache = async () => {
		let cacheRecords = await SalesforceCommonApi.query({
			query: "SELECT Id, Name, fwave__RecordId__c, fwave__RecordSummary__c FROM fwave__GPTSummary__c WHERE fwave__RecordId__c LIKE '006%'",
		});
		let cacheIds = [];
		if (cacheRecords) {
			for (let cacheRecord of cacheRecords) {
				cacheIds.push(cacheRecord.Id);
			}
		}
		if (cacheIds.length > 0)
			await SalesforceCommonApi.deleteRecords(cacheIds, 'fwave__GPTSummary__c');
	};

	return (
		<Card style={{ height: 'calc(90vh)' }}>
			<CardContent style={{ padding: 'auto', margin: 'auto' }}>
				<Grid container direction={'column'} spacing={1}>
					<Grid item container direction={'row'}>
						<Grid item>
							<Snackbar
								open={showToast}
								autoHideDuration={5000}
								onClose={() => setShowToast(false)}
								anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							>
								<Alert
									severity="info"
									sx={{ width: '100%' }}
									onClose={() => setShowToast(false)}
								>
									Prompt was saved successfully.
								</Alert>
							</Snackbar>
							<Dialog
								open={showConfirmation}
								//onClose={handleClose}
								scroll="paper"
								maxWidth="md"
								fullWidth="true"
								aria-labelledby="scroll-dialog-title"
								aria-describedby="scroll-dialog-description"
							>
								<DialogTitle id="scroll-dialog-title">
									<center>Save Prompt</center>
								</DialogTitle>
								<DialogContent dividers={true}>
									<center className="slds-p-around_medium slds-text-heading_small">
										<p>
											This will regenerate persona insight on opportunity
											runtime. Are you sure want to continue?
										</p>
										{/* <p>{isOwgWideAccess(sharingItem) ?' When you stop sharing a file with a company you also stop sharing it with public groups and posts on public user profiles.' : ''}</p> */}
									</center>
								</DialogContent>
								<DialogActions>
									<FWButton
										key="cancel-clear-mapping"
										label="Cancel"
										variant="outlined"
										onClick={() => {
											setShowConfirmation(false);
											setSaveStatus('Initial');
										}}
									>
										Cancel
									</FWButton>
									<StatefulButton
										isProcessing={saveStatus === 'Saving'}
										label="Save Prompt"
										processingLabel="Saving Prompt..."
										key={'savePromptButton2'}
										onClick={savePrompt}
									/>
									{/* <FWButton
									key="confirm-clear-mapping"
									label="Confirm"
									variant="contained"
									onClick={() => {
										setDisableDelete(true);
										SalesforceCommonApi.deleteRecords([productItemObj.Id]).then(() => {
											if (refresh) refresh();
											setDialogOpen(false);
										});
									}}
								>
									Delete
								</FWButton> */}
								</DialogActions>
							</Dialog>
							<FormControl size="small" style={{ width: '300px' }}>
								<InputLabel id="demo-simple-select-label">
									Context Fields
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedField}
									// defaultValue={selectedSObject.name}
									label="Context Fields"
									onChange={handleContextField}
								>
									{contextFields.map((contextField) => {
										return (
											<MenuItem
												key={contextField.name}
												value={contextField.name}
											>
												{contextField.label}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<IconButton title="Append" aria-label="Append" onClick={appendInLast}>
								<AddToPhotosOutlined />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item>
						<TextField
							required={true}
							style={{ width: '100%' }}
							id={'input_prompt'}
							label={'Prompt'}
							value={prompt}
							multiline={true}
							rows={5}
							onClick={printCursor}
							onChange={onChangePrompt}
						/>
					</Grid>
					<Grid item>
						{isLoadingAccounts && <p>Loading Accounts...</p>}
						{!isLoadingAccounts && (
							<FormControl size="small">
								<InputLabel id="demo-simple-select-label">
									Select Account to summarize
								</InputLabel>
								<Select
									size="small"
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedAccount}
									label="Select Account to summarize"
									onChange={handleAccountChange}
									style={{ width: '500px' }}
								>
									{(accounts || []).map((account) => {
										return (
											<MenuItem key={account.Id} value={account.Id}>
												{account.Name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}
					</Grid>
					<Grid item>
						{isLoadingOpportunituies && <p>Loading Opportunities...</p>}
						{!isLoadingOpportunituies && (
							<FormControl size="small">
								<InputLabel id="demo-simple-select-label">
									Select Opportunity to summarize
								</InputLabel>
								<Select
									size="small"
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedOpportunity}
									label="Select Opportunity to summarize"
									onChange={handleOpportunityChange}
									style={{ width: '500px' }}
								>
									{(opportunities || []).map((opportunity) => {
										return (
											<MenuItem key={opportunity.Id} value={opportunity.Id}>
												{opportunity.Name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}
					</Grid>
					<Grid item container>
						<Grid item style={{ marginRight: '5px' }}>
							<StatefulButton
								disabled={!selectedOpportunity}
								isProcessing={generationStatus === 'Generating'}
								label="Preview Insights"
								processingLabel="Previewing Insights..."
								key={'generateSummaryButton'}
								onClick={onTestClickHandler}
							/>
						</Grid>
						<Grid item>
							<FWButton
								//isProcessing={saveStatus === 'Saving'}
								label="Save Prompt"
								//processingLabel="Saving Prompt..."
								key={'savePromptButton'}
								onClick={savePromptClick}
							>
								Save Prompt
							</FWButton>
						</Grid>
						<Grid item>
							&nbsp;&nbsp;
							<FWButton
								label="Reset Prompt to Default"
								onClick={resetPromptToDefault}
							>
								Reset Prompt to Default{' '}
							</FWButton>
						</Grid>
					</Grid>
					<Grid>
						<br />
						{generationStatus === 'Generated' && (
							<Grid item xs={12}>
								<OpportunityOverviewSectionContentCmp
									label={'Persona Insights'}
									color="linear-gradient(180deg, #d985ee 0%, #5b0bde 100%)"
									showIcons={false}
									showRefresh={false}
									//refreshDate={acctSummaryRefreshDate}
								>
									<PersonaInsight
										opportunity={opportunitySO}
										salesPlayBook={salesPlaySO}
										//setShowPersonaInsight={setShowPersonaInsight}
										showEmptyScreen={false}
										prompt={updatedPrompt}
									/>
								</OpportunityOverviewSectionContentCmp>
							</Grid>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
