import React from 'react';

import { Grid } from '@mui/material';
import { Box } from '@mui/system';

import noSearchResultImage from '../../assets/noSearchResult.gif';

function NoSearchResultCmp({ msg }) {
	return (
		<Box key="noSearchResultCmp">
			<Grid
				container
				spacing={1}
				justifyContent="center"
				alignItems="center"
				style={{ background: '' }}
			>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Box
						sx={{
							width: '90px',
						}}
						component="img"
						src={noSearchResultImage} // placeholder image for no search results
						alt="no result image"
						loading="lazy"
					/>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Box
						sx={{
							fontSize: '14pt',
							fontWeight: '400',
							color: '#05283C',
						}}
					>
						{msg ? msg : 'No Results'}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
NoSearchResultCmp.displayName = 'NoSearchResultCmp';
export default NoSearchResultCmp;
