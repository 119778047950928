import { INTERNAL_PRODUCT_TAG } from '../Constants';
import Utils, { ns } from '../utils/Utils';

export class Features {
	constructor(data, fileTagMap) {
		this.records = [];
		if (Array.isArray(data.records) && data.records.length > 0) {
			this.records = data.records.map((item) => new Feature(item, fileTagMap));
		}
	}
}

export class Feature {
	constructor(data, fileTagMap) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.Description__c = data?.[ns('Description__c')];
		this.DescriptionRichText__c = data?.[ns('DescriptionRichText__c')];
		this.FeatureName__c = data?.[ns('FeatureName__c')];
		this.Product__c = data?.[ns('Product__c')];
		this.Video_Links__c = data?.[ns('Video_Links__c')]
			? data?.[ns('Video_Links__c')].split(',')
			: [];
		this.ExternalId__c = data?.[ns('ExternalId__c')];
		this.isInternal = false;
		if (data?.[ns('FileId__r')]) {
			// get file tags
			let fileAttribute = data?.[ns('FileId__r')]?.attributes;
			if (fileAttribute?.type === ns('File__c')) {
				this.fileId = Utils.getFileId({ data: data });
			} else {
				this.fileId = null;
			}
			if (this.fileId) {
				if (fileTagMap) {
					if (fileTagMap.has(this.fileId)) {
						this.fileTags = fileTagMap.get(this.fileId);
						if (Array.isArray(this.fileTags) && this.fileTags.length > 0) {
							// check for internal or external
							this.fileTags.forEach((tag) => {
								if (tag?.Name?.toLowerCase() === INTERNAL_PRODUCT_TAG) {
									this.isInternal = true;
								}
							});
						}
					}
				}
			}
		}
	}
}
