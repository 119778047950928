import SalesforceService from '../services/SalesforceService';
import HttpUtils from '../utils/HttpUtils';
import Utils from '../utils/Utils';

const isLocalhost = Utils.isLocalhost();

const invokeFn = (url, headers = {}, params = {}, body = {}, method = 'POST') =>
	HttpUtils.invoke({
		url,
		//parameters, //Adding params to header temporary as not getting value
		headers: { ...headers, ...params },
		body,
		method,
	});

const ApiGateway = {
	//APiEndPoint moved under function as environment is not available when app load
	getApiEndPoint: () => {
		//PLEASE DO NOT CHANGE THIS.
		//for Local API DEVELOPEMENT, USE ApiGateway.invokeLocal in api.js files
		//That will enable us to use Both APIs. (Hosted and Local at same time)
		if (isLocalhost) return '/v1/';

		//let environment = SalesforceService.getEnvironment();
		//let urlPrefix = environment? ('/'+environment)  : '';
		return SalesforceService.getApiUrl();
		//return `https://door.fwapps.com${urlPrefix}/v1/`;
	},

	invoke: (controller, method, params, headers, body) =>
		invokeFn(`${ApiGateway.getApiEndPoint()}${controller}/${method}`, headers, params, body),

	invokeLocal: (controller, method, params, headers, body) =>
		invokeFn(`/api/${method}`, headers, params, body),
};
export default ApiGateway;
