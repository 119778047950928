import { COMPANY_NAME } from '../Constants';
import AnthropicApi from '../apis/AnthropicApi';
import Utils from '../utils/Utils';
import { getEncoding } from 'js-tiktoken';

var apiKey =
	'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';
var model = 'claude-2.1';

var promptPrefix =
	`You are ${COMPANY_NAME} Sales Assistant. ` +
	'You do not provide fictional scenarios as responses. ' +
	'You have access to previous chats and responses.';

var promptPrefixSalesPlay =
	`You are ${COMPANY_NAME} Sales Assistant. ` +
	'You will respond with "Our Sales Assistant is unable to get you the right answer. You might want to refer to the internal and internet search results." when you do not have a confident resopnse. ' +
	'You have access to previous chats and responses. ' +
	'You also have access to updated real-time news and information. ' +
	'You will keep conversation to a minimum and answer to the best of your abilities.';
var isLocalhost = Utils.isLocalhost();
var defaultConfig = {
	max_tokens: 300,
	model: model,
};

if (isLocalhost) {
	// localhost vars
}

const AnthropicService = {};

AnthropicService.getSearchResults = async (searchTerm, prevResponse, flow) => {
	return await AnthropicApi.getSearchResults(searchTerm, prevResponse, flow);
};

AnthropicService.generate = async (prompts, prevPrompts, flow) => {
	return await AnthropicApi.generate(prompts, prevPrompts, flow);
};

AnthropicService.processPrompt = (messages) => {
	// because anthropic doesn't allow multiple messages from the same role, we have to consolidate it
	var outputMessages = [];
	var previousRole;
	var previousContent;
	messages?.forEach((message, idx) => {
		if (message?.role === 'system') {
			message.role = 'user';
		}
		if (idx === 0) {
			if (message.role === 'assistant') {
				// add placeholder message starting with role of user so api doesn't fail
				outputMessages.push({ role: 'user', content: 'please ignore this message' });
			}
		}
		if (previousRole != message?.role) {
			outputMessages.push({ role: message.role, content: message.content });
			previousRole = message.role;
			previousContent = message.content;
		} else {
			var lastElement = outputMessages.pop();
			previousContent = previousContent + ' ' + lastElement.content;
			outputMessages.push({ role: message.role, content: previousContent });
		}
	});
	return outputMessages;
};

AnthropicService.getPromptPreix = (flow) => {
	return flow && flow === 'SALESPLAY' ? promptPrefixSalesPlay : promptPrefix;
};

AnthropicService.getApiKey = () => {
	return apiKey;
};

AnthropicService.getModel = () => {
	return model;
};

AnthropicService.countToken = (messages) => {
	var output = 0;
	var encoding;
	try {
		encoding = getEncoding('cl100k_base');
	} catch (err) {
		console.error('Error getting encoding. ', err);
	}
	if (encoding) {
		messages?.forEach((message) => {
			var tempEncode = encoding.encode(message?.content);
			output += tempEncode?.length ? tempEncode?.length : 0;
		});
	}
	return output;
};

AnthropicService.getDefaultConfig = async () => {
	return defaultConfig;
};

export default AnthropicService;
