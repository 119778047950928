import React, { useState } from 'react';
import {
	Alert,
	Button,
	Card,
	CardContent,
	FormControlLabel,
	Grid,
	Snackbar,
	Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import ConfirmationDialog from '../mui/ConfirmationDialog';
import SalesforceService from '../../services/SalesforceService';
import CustomSettingService from '../../services/CustomSettingService';
import { SFDCConfiguration, SFDCRequirementConfiguration } from '../../models/Configuration';
import SearchService from '../../services/SearchService';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AdminSettinsSetupOtherSettingCmp() {
	const [enableSalesPlay, setEnableSalesPlay] = useState(SalesforceService.isSalesPlayEnabled());
	const [enableRequirements, setEnableRequirements] = useState(
		SalesforceService.isRequirementsEnabled(),
	);

	const [showClearHistoryConfirmation, setShowClearHistoryConfirmation] = useState(false);
	const [showClearSearchHistoryMessage, setShowClearSearchHistoryMessage] = useState(false);
	const [clearSearchHistoryMessage, setClearSearchHistoryMessage] = useState(null);
	const [isClearHistoryLoading, setIsClearHistoryLoading] = useState(false);

	const handleEnableSalesPlayChange = async (evt) => {
		try {
			setEnableSalesPlay(evt.target.checked);
			SalesforceService.setSalesPlayEnabled(evt.target.checked);
			var customSetting = await CustomSettingService.getCustomSettings();
			customSetting.EnableSalesPlay__c = evt.target.checked;
			await CustomSettingService.updateCustomSettings(new SFDCConfiguration(customSetting));
		} catch (err) {
			console.error('Error setting sales play enabled checkbox. ', err);
		}
	};

	const handleEnableRequirementsChange = async (evt) => {
		try {
			setEnableRequirements(evt.target.checked);
			SalesforceService.setRequirementsEnabled(evt.target.checked);
			var customSetting = await CustomSettingService.getCustomSettings();
			customSetting.EnableRequirements__c = evt.target.checked;
			await CustomSettingService.updateCustomSettings(
				new SFDCRequirementConfiguration(customSetting),
			);
		} catch (err) {
			console.error('Error setting requirements enabled checkbox. ', err);
		}
	};

	const handleShowClearSearchHistory = () => {
		setShowClearHistoryConfirmation(true);
	};

	const handleCloseClearSearchHistory = () => {
		setShowClearHistoryConfirmation(false);
	};

	const handleClearSearchHistory = async (evt) => {
		try {
			setIsClearHistoryLoading(true);
			let result = await SearchService.clearSearchHistory();
			if (Array.isArray(result)) {
				setShowClearSearchHistoryMessage(true);
				setClearSearchHistoryMessage('Search History Cleared.');
			} else if (result?.Message) {
				setShowClearSearchHistoryMessage(true);
				setClearSearchHistoryMessage(result?.Message);
			}
			setIsClearHistoryLoading(false);
			handleCloseClearSearchHistory();
		} catch (err) {
			console.error('Error clearing search history ', err);
			setShowClearSearchHistoryMessage(true);
			setClearSearchHistoryMessage(
				'An error has occured, please contact your admin for more details.',
			);
			handleCloseClearSearchHistory();
			setIsClearHistoryLoading(false);
		}
	};

	return (
		<Card style={{ height: '500px' }}>
			<CardContent style={{ padding: 'auto', margin: 'auto' }}>
				<Snackbar
					open={showClearSearchHistoryMessage}
					autoHideDuration={6000}
					onClose={() => {
						setShowClearSearchHistoryMessage(false);
					}}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert
						variant="outlined"
						style={{ width: '100%', background: '#FFFFFF' }}
						severity="info"
					>
						{clearSearchHistoryMessage}
					</Alert>
				</Snackbar>
				<Grid container direction={'column'}>
					<Grid item>
						<Box
							sx={{
								paddingRight: '10px',
								paddingTop: '7px',
							}}
						>
							<FormControlLabel
								control={
									<Switch
										checked={enableSalesPlay}
										onChange={handleEnableSalesPlayChange}
									/>
								}
								label="Enable Sales Play"
								labelPlacement="start"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={enableRequirements}
										onChange={handleEnableRequirementsChange}
									/>
								}
								label="Enable Requirements"
								labelPlacement="start"
							/>
							<Button
								sx={{ marginLeft: '16px' }}
								variant="outline"
								onClick={handleShowClearSearchHistory}
							>
								Clear Search History
								<DeleteIcon />
							</Button>
							<ConfirmationDialog
								title={'Clear Search History Confirmation'}
								content={
									"You're about to clear all search history in the org. Are you sure?"
								}
								handleCancel={handleCloseClearSearchHistory}
								handleOk={handleClearSearchHistory}
								open={showClearHistoryConfirmation}
								isLoading={isClearHistoryLoading}
							/>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
