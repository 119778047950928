import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
	PendingActionsOutlined,
	ManageAccountsOutlined,
	SupervisorAccountOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import FwBackNavigation from '../common/FwBackNavigation';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FWTab, FWTabs } from '../mui/FWTabs';
import FwAppHeader from '../common/FwAppHeader';
import SalesforceService from '../../services/SalesforceService';

function TabPanel({ children, value, index, ...other }) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

function AdminSettingsSetupCmp(props) {
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const withBackNavigation = true;

	const navigate = useNavigate();

	const params = useLocation();
	const pathname = params.pathname;

	const getTabIndex = (pathname) => {
		if (!pathname) {
			return 0;
		} else if (pathname?.indexOf('otherSettings') !== -1) {
			return 2;
		} else if (pathname?.indexOf('personaInsight') !== -1) {
			return 1;
		} else {
			return 0;
		}
	};
	const [tabValue, setTabValue] = useState(getTabIndex(pathname));

	return (
		<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
			<FwAppHeader
				navigationTitle="Dashboard"
				navigationPath={`/dashboard/${SalesforceService.getCurrentUserId()}`}
			/>
			<Grid container direction={'row'}>
				{withBackNavigation && (
					<Grid
						item
						justifyContent={'center'}
						alignItems={'center'}
						style={{ paddingTop: '2px', marginTop: '6px' }}
					>
						{/* https://github.com/remix-run/react-router/discussions/9922 */}
						<FwBackNavigation
							title={'Settings'}
							subtitle={''}
							onBack={() => navigate('/admin/companyprofile')}
						/>
					</Grid>
				)}

				<Grid item style={{ paddingLeft: '10px' }}>
					<FWTabs value={tabValue} onChange={handleTabChange}>
						<FWTab
							label="Account Summary"
							to="/admin/setup/scheduleInsight"
							component={Link}
							{...a11yProps(0)}
							icon={<PendingActionsOutlined />}
							iconPosition="start"
						/>
						<FWTab
							label="Persona Insight"
							to="/admin/setup/personaInsight"
							component={Link}
							{...a11yProps(1)}
							icon={<SupervisorAccountOutlined />}
							iconPosition="start"
						/>
						<FWTab
							label="Other Settings"
							to="/admin/setup/otherSettings"
							component={Link}
							{...a11yProps(2)}
							icon={<ManageAccountsOutlined />}
							iconPosition="start"
						/>
					</FWTabs>
				</Grid>
			</Grid>
			<Outlet />
			{/* <FWTabPanel value={tabValue} index={0} active={'/scheduleInsight'} className="no-padding-tab-container">
				<AdminSettingsScheduleCmp />
			</FWTabPanel>
			<FWTabPanel value={tabValue} index={1} active={'/otherSettings'} className="no-padding-tab-container">
				<AdminSettingsOtherCmp />
			</FWTabPanel> */}
			{/* <Routes>
				<Route exact path="/" element={<Navigate to="/admin/setup/scheduleInsight" />} />
				<Route path="/otherSettings" element={<AdminSettingsSetupCmp />} />
			</Routes> */}
		</Box>
	);
}

export default AdminSettingsSetupCmp;
