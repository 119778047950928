import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	MenuItem,
	Select,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import UserIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import ComputerIcon from '@mui/icons-material/Computer';
import { FWButton } from '../../mui/FWButtons';
import FWAutocomplete from '../../mui/FWAutocomplete';

import AdminService from '../../../services/AdminService';
import SalesforceService from '../../../services/SalesforceService';

const isOwner = (sharingItem) => {
	return sharingItem.LinkedEntity.Type === 'User' && sharingItem.ShareType === 'I';
};

const isNonStandardEntity = (sharingItem) => {
	return (
		sharingItem.LinkedEntity.Type !== '00D' &&
		sharingItem.LinkedEntity.Type !== 'User' &&
		sharingItem.LinkedEntity.Type !== 'CollaborationGroup'
	);
};

const isStandardEntity = (sharingItem) => {
	return !isNonStandardEntity(sharingItem);
};

const isOwgWideAccess = (sharingItem) => {
	return sharingItem.LinkedEntity.Type === '00D';
};

const sortDocumentLinks = (links) => {
	const sorter = (a, b) => {
		if (!(isOwgWideAccess(a) || isOwner(a)) && (isOwgWideAccess(b) || isOwner(b))) {
			return 1;
		}
		if ((isOwgWideAccess(a) || isOwner(a)) && !(isOwgWideAccess(b) || isOwner(b))) {
			return -1;
		}
		return a['ShareType'] - b['ShareType'];
	};
	links.sort(sorter);
};
const FileSharingContainer = function ({
	recordIds,
	pendingSharingItems,
	onExistingSharingItemLoad,
	reloadData,
	onSharingItemDelete,
}) {
	const [sharingItems, setSharingItems] = React.useState([]);
	const [toBeDeletedItems, setToBeDeletedItems] = React.useState([]);

	React.useEffect(() => {
		AdminService.getDocumentLinks(recordIds).then((links) => {
			let orgWideItem = links.filter(
				(sharingItem) => sharingItem.LinkedEntity.Name === '00D',
			);
			if (!orgWideItem || orgWideItem.length === 0) {
				AdminService.saveGroupAccess([SalesforceService.getSfOrgId()], 'N', recordIds).then(
					(saveResult) => {
						AdminService.getDocumentLinks(recordIds).then((links) => {
							sortDocumentLinks(links);
							setSharingItems(links);
						});
					},
				);
				if (onExistingSharingItemLoad) {
					onExistingSharingItemLoad(links);
				}
			} else {
				sortDocumentLinks(links);
				setSharingItems(links);
				if (onExistingSharingItemLoad) {
					onExistingSharingItemLoad(links);
				}
			}
		});
	}, [reloadData]);

	const onSharingItemDeleteHandle = (sharingItem) => {
		let _sharingItems = [...sharingItems];
		let _toBeDeletedItems = [...toBeDeletedItems];

		if (sharingItem.Id) _toBeDeletedItems.push(sharingItem);
		_sharingItems.splice(
			_sharingItems.findIndex((item) => item.Id === sharingItem.Id),
			1,
		);
		if (onSharingItemDelete) onSharingItemDelete(sharingItem);
		setSharingItems(_sharingItems);
		setToBeDeletedItems(_toBeDeletedItems);
	};

	return (
		<div>
			<List
				sx={{
					width: '100%',
					maxWidth: '100%',
					bgcolor: 'background.paper',
				}}
				subheader={<ListSubheader>Who Can Access</ListSubheader>}
			>
				{[...sharingItems, ...pendingSharingItems].map((sharingItem) => (
					<FileSharingItem
						key={sharingItem.Id}
						sharingItem={sharingItem}
						onSharingItemDelete={onSharingItemDeleteHandle}
					/>
				))}
			</List>
		</div>
	);
};

const SharingAccessInput = function ({
	id,
	label = 'Access',
	value,
	isDisabled = false,
	isOwner,
	sharingItem,
	onChange,
}) {
	const [sharingAccess, setSharingAccess] = React.useState(value);
	const handleSharingAccessChange = (evt) => {
		setSharingAccess(evt.target.value);
		if (onChange) onChange(evt.target.value);
	};

	return (
		<div id={'sharingItemInput_' + id}>
			<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
				<InputLabel id={'sharingItemInput_' + id}>{label}</InputLabel>
				<Select
					labelId={'sharingItemInputLabel_' + id}
					id={'sharingItemSelect_' + id}
					value={sharingAccess}
					label={label}
					onChange={handleSharingAccessChange}
					disabled={isOwner}
				>
					<MenuItem value={'V'}>Viewer</MenuItem>
					{sharingItem.LinkedEntity.Type === '00D' && (
						<MenuItem value={'N'}>No Access</MenuItem>
					)}
					{isOwner && <MenuItem value={'I'}>Owner</MenuItem>}

					{isStandardEntity(sharingItem) && <MenuItem value={'C'}>Collaborator</MenuItem>}
					{isNonStandardEntity(sharingItem) && (
						<MenuItem value={'I'}>Set By Record</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
};

const IconItem = function ({ sharingItem }) {
	return (
		<>
			{sharingItem?.LinkedEntity.Type === '00D' ? (
				<AccountTreeIcon />
			) : sharingItem?.LinkedEntity.Type === 'User' ? (
				<UserIcon />
			) : sharingItem?.LinkedEntity.Type === 'CollaborationGroup' ? (
				<GroupsIcon />
			) : (
				<ComputerIcon />
			)}
		</>
	);
};

const FileSharingItem = function ({ sharingItem, onSharingItemDelete }) {
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [isProcessing, setIsProcessing] = React.useState(false);
	const handleDeleteClick = () => {
		if (!sharingItem.Id || isProcessing) return;
		setDialogOpen(true);
	};
	const handleClose = () => {
		setDialogOpen(false);
	};
	return (
		<ListItem>
			<ListItemIcon>
				<IconItem sharingItem={sharingItem} />
			</ListItemIcon>
			<ListItemText
				id={'listText_' + sharingItem.LinkedEntityId}
				primary={sharingItem.LinkedEntity.Name}
			/>
			<SharingAccessInput
				id={sharingItem.LinkedEntityId}
				value={sharingItem.ShareType}
				sharingItem={sharingItem}
				isOwner={isOwner(sharingItem)}
				onChange={(val) => {
					setIsProcessing(true);
					sharingItem.ShareType = val;
					AdminService.updateAccess([sharingItem]).then((updateResponse) => {
						setIsProcessing(false);
					});
				}}
			/>
			<IconButton aria-label="delete" onClick={handleDeleteClick}>
				{!isOwner(sharingItem) && (!sharingItem.Id || isProcessing) && (
					<CircularProgress color="primary" size={20} />
				)}
				{!isOwner(sharingItem) && !isOwgWideAccess(sharingItem) && !isProcessing && (
					<DeleteIcon />
				)}
			</IconButton>
			<Dialog
				open={dialogOpen}
				//onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Are you sure?</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<center className="slds-p-around_medium slds-text-heading_small">
						<p>
							Stop sharing {sharingItem.ContentDocument?.Title} with{' '}
							{sharingItem.LinkedEntity?.Name}?
						</p>
						{/* <p>{isOwgWideAccess(sharingItem) ?' When you stop sharing a file with a company you also stop sharing it with public groups and posts on public user profiles.' : ''}</p> */}
					</center>
				</DialogContent>
				<DialogActions>
					<FWButton
						key="cancel-clear-mapping"
						label="Cancel"
						variant="outlined"
						onClick={handleClose}
					>
						Cancel
					</FWButton>
					<FWButton
						key="confirm-clear-mapping"
						label="Confirm"
						variant="contained"
						onClick={() => {
							if (sharingItem.Id) {
								AdminService.removeAccess([sharingItem.Id]).then(
									(deleteResponse) => {
										// console.log('linked entity deleted:', deleteResponse);
									},
								);
							}
							if (onSharingItemDelete) onSharingItemDelete(sharingItem);
							setDialogOpen(false);
						}}
					>
						Unshare
					</FWButton>
				</DialogActions>
			</Dialog>
		</ListItem>
	);
};

function FileSharingSettings({ recordIds }) {
	const [groupPromise, setGroupPromise] = React.useState(undefined);
	const [selectedGroups, setSelectedGroups] = React.useState(undefined);
	const [groupAccess, setGroupAccess] = React.useState('V');
	const [pendingSharingItems, setPendingSharingItems] = React.useState([]);
	const [existingGroups, setExistingGroups] = React.useState([]);
	const [reloadData, setReloadData] = React.useState(false); //flag to rerender component

	const autoSelectRef = React.useRef();

	// const [orgWideAccess, setOrgWideAccess] = React.useState('N');

	React.useEffect(() => {
		setGroupPromise(AdminService.getCollaborationGroups());
	}, []);

	const handleGroupAccessChange = (evt) => {
		setGroupAccess(evt.target.value);
	};

	//   const handleOrgWideAccessChange = (evt)=>{
	//     setOrgWideAccess(evt.target.value);
	//  }

	const saveGroupAccess = () => {
		// AdminService.saveGroupAccess(selectedGroups, groupAccess, recordIds).then((linkSaveResult)=>{
		//   console.log({linkSaveResult});
		// })
		let _pendingSharingItems = [...pendingSharingItems];
		let _existingGroups = [...existingGroups];
		let saveGroupIds = [];
		for (let val of selectedGroups) {
			_existingGroups.push(val.Id);
			saveGroupIds.push(val.Id);
			_pendingSharingItems.push({
				LinkedEntityId: val.Id,
				LinkedEntity: {
					Name: val.text,
					Type: 'CollaborationGroup',
				},
				ShareType: groupAccess,
			});
		}
		setPendingSharingItems(_pendingSharingItems);

		AdminService.saveGroupAccess(saveGroupIds, groupAccess, recordIds).then(
			(linkSaveResult) => {
				setPendingSharingItems([]);
				setReloadData(!reloadData);
			},
		);
		setExistingGroups(_existingGroups);

		autoSelectRef.current?.reset();
	};

	const onExistingSharingItemLoad = (existingSharingItems) => {
		let _existingGroups = [...existingGroups];
		for (let sharingItem of existingSharingItems) {
			if (sharingItem.LinkedEntity.Type === 'CollaborationGroup')
				_existingGroups.push(sharingItem.LinkedEntityId);
		}
		setExistingGroups(_existingGroups);
	};

	const onSharingItemDeleteHandle = (sharingItem) => {
		let _existingGroups = [...existingGroups];
		if (sharingItem.Id && sharingItem.LinkedEntity.Type === 'CollaborationGroup') {
			if (_existingGroups.includes(sharingItem.LinkedEntityId)) {
				_existingGroups = _existingGroups.filter(
					(item) => item !== sharingItem.LinkedEntityId,
				);
				setExistingGroups(_existingGroups);
			}
		}
	};

	return (
		<Grid container direction={'column'}>
			<Grid container item alignItems={'center'} spacing={2}>
				<Grid item xs={8}>
					<FWAutocomplete
						label="Groups"
						disableAdd={true}
						allowToAddNewValues={false}
						dataPromise={groupPromise}
						provideSaveButton={false}
						multiple={true}
						disabledValues={existingGroups}
						onValueChange={(values, oldValues) => {
							setSelectedGroups(values);
						}}
						width={'100%'}
						ref={autoSelectRef}
					/>
				</Grid>
				<Grid item xs={2}>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="demo-select-small-label">Group Access</InputLabel>
						<Select
							labelId="demo-select-small-label"
							id="demo-select-small"
							value={groupAccess}
							label="Group Access"
							onChange={handleGroupAccessChange}
						>
							<MenuItem value={'V'}>Viewer</MenuItem>
							<MenuItem value={'C'}>Collaborator</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2}>
					<FWButton
						onClick={saveGroupAccess}
						disabled={!selectedGroups || selectedGroups.length === 0}
					>
						Save
					</FWButton>
				</Grid>
			</Grid>
			<Grid>
				<FileSharingContainer
					recordIds={recordIds}
					pendingSharingItems={pendingSharingItems}
					onExistingSharingItemLoad={onExistingSharingItemLoad}
					reloadData={reloadData}
					onSharingItemDelete={onSharingItemDeleteHandle}
				/>
			</Grid>
		</Grid>
	);
}

export default function FileSharingSettingsCmp({ recordIds, onClose }) {
	const handleCloseFn = () => onClose?.();

	return (
		<Dialog
			open={true}
			onClose={handleCloseFn}
			scroll="paper"
			maxWidth="md"
			fullWidth="true"
			height="900px"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Share</DialogTitle>
			<DialogContent dividers={true}>
				<FileSharingSettings recordIds={recordIds} />
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseFn}>Done</Button>
			</DialogActions>
		</Dialog>
	);
}
