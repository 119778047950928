import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { CloudDoneOutlined, DeleteOutline, HighlightOffOutlined } from '@mui/icons-material';
import { FileTileCmp } from '../common/UtilComponents';

export default function FWFileWithActionCmp({
	fileId,
	fileName,
	onDelete,
	showDelete = true,
	showReplace = false,
	onReplace,
}) {
	const [actions, setActions] = useState([]);

	useEffect(() => {
		let _actions = [];
		if (showDelete === true) {
			_actions.push({
				name: 'delete',
				icon: fileId ? (
					<DeleteOutline fontSize="medium" />
				) : (
					<HighlightOffOutlined fontSize="medium" />
				),
				target: onDelete,
			});
		}
		if (showReplace === true) {
			_actions.push({
				name: 'replace',
				label: 'Replace',
				icon: undefined,
				target: onReplace,
			});
		}
		if (fileId) {
			_actions.push({
				name: 'uploadStatus',
				icon: (
					<Tooltip title="Uploaded" placement="top">
						<CloudDoneOutlined style={{ color: 'green' }} />
					</Tooltip>
				),
				target: undefined,
			});
		}
		setActions(_actions);
	}, [showDelete, showReplace]);

	return <FileTileCmp label={fileName} fileName={fileName} actions={actions} />;
}
