import { User } from '../models/User';
import ApiGateway from './ApiGateway';

const getUser = async (userId) => {
	if (!userId) return;
	const resultData = await ApiGateway.invoke(
		'sf',
		'query',
		{},
		{ query: `SELECT id, FirstName, LastName FROM User WHERE id = '${userId}' Limit 1` },
	);
	return new User(resultData[0]);
};

const getUsers = async () =>
	await ApiGateway.invoke(
		'sf',
		'query',
		{},
		{ query: 'SELECT id, FirstName, LastName FROM User WHERE IsActive = true' },
	);

export { getUser, getUsers };
