import React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const FWTabs = styled((props) => {
	if (props.variant === 'outline') {
		return (
			<Tabs
				{...props}
				TabIndicatorProps={{
					style: {
						height: '3px',
						background: '#ced3da',
					},
				}}
			/>
		);
	} else {
		return (
			<Tabs
				sx={{ paddingTop: '10px' }}
				{...props}
				TabIndicatorProps={{ style: { display: 'none' } }}
			/>
		);
	}
})(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));
FWTabs.displayName = 'FWTabs';

const FWTab = styled((props) => {
	if (props.variant === 'outline') {
		return (
			<Tab
				{...props}
				sx={{
					borderRadius: '100px',
					fontStyle: 'normal',
					fontWeight: '600',
					fontSize: '16px',
					lineHeight: '20px',
					color: '#343537',
					padding: '6px 10px',
					textTransform: 'none',
					height: '32px',
					minHeight: '20px',
					'&:hover': {
						color: 'transparent',
						background: '#ced3da',
						backgroundClip: 'text',
					},
					'&.Mui-selected': {
						fontWeight: '600',
						fontSize: '16px',
						lineHeight: '20px',
						color: '#343537',
						':hover': {
							background: 'none',
						},
					},
				}}
			/>
		);
	} else {
		return (
			<Tab
				{...props}
				sx={{
					borderRadius: '100px',
					fontStyle: 'normal',
					//fontWeight: '600',
					fontSize: '15px',
					lineHeight: '20px',
					color: '#343537',
					padding: '18px 10px',
					textTransform: 'none',
					height: '32px',
					minHeight: '20px',
					'&.Mui-selected': {
						background: '#ced3da',
						//fontWeight: '700',
						fontSize: '15px',
						lineHeight: '20px',
						//color: '#FFFFFF',
						padding: '18px 10px',
					},
				}}
			/>
		);
	}
})(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// '&:not(:last-child)': {
	//   borderBottom: 0,
	// },
	// '&:before': {
	//   display: 'none',
	// },
}));
FWTab.displayName = 'FWTab';

export { FWTabs, FWTab };
