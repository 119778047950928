import { NAMESPACE_PREFIX } from '../Constants';
import SalesforceService from '../services/SalesforceService';
import ApiGateway from './ApiGateway';

const SalesforceCommonApi = {};
SalesforceCommonApi.deleteRecords = (recordIdList, objectType = 'unknown') => {
	let deletePromise = ApiGateway.invoke(
		'sf',
		'delete',
		{},
		{},
		JSON.stringify({ objectType: objectType, recordIds: recordIdList }),
	);

	return deletePromise;
};

SalesforceCommonApi.cloneRecord = (values, recordId) => {
	let records = [];
	let record = values;
	record[NAMESPACE_PREFIX + 'SourceId__c'] = recordId;
	record['Id'] = undefined;
	records.push(record);

	var body = {
		objectName: NAMESPACE_PREFIX + 'SalesPlaybook__c',
		records: records,
	};
	return ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

SalesforceCommonApi.createRecord = (record, objectApiName) => {
	let records = [];
	records.push(record);

	var body = {
		objectName: objectApiName,
		records: records,
	};
	return ApiGateway.invoke('sf', 'create', {}, {}, JSON.stringify(body));
};

SalesforceCommonApi.updateRecords = (records, objectApiName) => {
	var body = {
		objectName: objectApiName,
		records: records,
	};
	return ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body));
};

SalesforceCommonApi.updateRecord = (values, recordId, objectApiName) => {
	if (!objectApiName) objectApiName = NAMESPACE_PREFIX + 'SalesPlaybook__c';

	let records = [];
	let record = values;
	record['Id'] = recordId;
	records.push(record);

	var body = {
		objectName: objectApiName,
		records: records,
	};
	return ApiGateway.invoke('sf', 'update', {}, {}, JSON.stringify(body));
};

SalesforceCommonApi.getMetadataRecord = async ({ objectApiName, fields, recordDevName }) => {
	return new Promise((resolve, reject) => {
		let fieldStr = fields.join(', ');
		let queryStr = `SELECT ${fieldStr} FROM ${objectApiName} WHERE DeveloperName='${recordDevName}' AND NamespacePrefix = 'fwave'`;
		ApiGateway.invoke(
			'sf',
			'query',
			{},
			{
				query: queryStr,
			},
		).then((records) => {
			if (records && records.length > 0) resolve(records[0]);
			else {
				resolve(undefined);
			}
		});
	});
};

SalesforceCommonApi.query = async ({ query }) => {
	return new Promise((resolve, reject) => {
		ApiGateway.invoke(
			'sf',
			'query',
			{},
			{
				query: query,
			},
		).then((records) => {
			resolve(records);
		});
	});
};

SalesforceCommonApi.invokeSalesforceApi = ({ path }) => {
	let apiVersion = SalesforceService.getApiVersion();
	var query = `/data/${apiVersion}/${path}`;
	return ApiGateway.invoke('sf', 'executeSalesforceRest', {}, { query: query });
};

SalesforceCommonApi.invokeSalesforceCustomApi = ({ method, parameters }) => {
	let body = {
		method: method,
		parameters: parameters,
	};
	var query = `/apexrest/fwave/customapi/`;
	return ApiGateway.invoke(
		'sf',
		'executeSalesforceRest',
		{},
		{ query: query, body: JSON.stringify(body) },
	);
};

SalesforceCommonApi.getMetadata = ({ sobjectApiName }) => {
	return SalesforceCommonApi.invokeSalesforceApi({
		path: `sobjects/${sobjectApiName}/describe/`,
	});
};
export default SalesforceCommonApi;
