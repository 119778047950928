import React from 'react';
import Moment from 'react-moment';

import CropFreeIcon from '@mui/icons-material/CropFree';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FWIconButton } from './mui/FWButtons';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

import { ENABLE_PLACEHOLDER } from '../Constants';
import SalesforceService from '../services/SalesforceService';

function OpportunityOverviewSectionContentCmp(props) {
	return (
		<Card elevation={3} {...props} sx={{ borderRadius: '10px' }}>
			<Box sx={{ height: '3px', background: props.color || null }}></Box>
			<CardContent
				sx={{ padding: '11px 20px 18px', '&:last-child': { paddingBottom: '18px' } }}
			>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignment="center"
					spacing={1}
				>
					<Grid item>
						<Typography
							variant="h5"
							component="div"
							sx={{
								fontWeight: '700',
								fontSize: '18px',
								lineHeight: '25px',
								color: '#000000',
							}}
						>
							{props.label}
						</Typography>
					</Grid>
					{props.showIcons && (
						<Grid item>
							{props.showRefresh && (
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
								>
									<Grid item>
										Last updated:
										{props?.refreshDate ? (
											<Moment format="MM/DD/YYYY">
												{props?.refreshDate}
											</Moment>
										) : (
											'Unknown'
										)}
									</Grid>
								</Grid>
							)}
							{props.showRecordOpen && props.recordId && (
								<FWIconButton
									onClick={() =>
										window.open(
											`${SalesforceService.getInstanceUrl()}/${props.recordId}`,
										)
									}
								>
									<OpenInNewIcon fontSize="small" />
								</FWIconButton>
							)}
							{props.showEnhancedSalesPlay && (
								<Tooltip title="More Info" placement="left">
									<IconButton
										sx={{ color: '#2220A7' }}
										onClick={props?.showSearchSalesplay}
									>
										<AutoFixNormalIcon />
									</IconButton>
								</Tooltip>
							)}
							{ENABLE_PLACEHOLDER && (
								<>
									<FWIconButton>
										<ThumbUpIcon fontSize="small" />
									</FWIconButton>
									<FWIconButton>
										<FileCopyIcon fontSize="small" />
									</FWIconButton>
									<FWIconButton>
										<CropFreeIcon fontSize="small" />
									</FWIconButton>
								</>
							)}
						</Grid>
					)}
				</Grid>
				{props.children}
			</CardContent>
		</Card>
	);
}

export default OpportunityOverviewSectionContentCmp;
