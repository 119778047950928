import { NAMESPACE_PREFIX } from '../Constants';
import { Feature } from './Feature';
import { Requirement } from './Requirement';

export class Solutions {
	constructor(data, isList) {
		if (isList) {
			this.records = [];
			if (data !== null && data !== undefined && data.length > 0) {
				data.forEach((item) => {
					this.records.push(new Solution(item));
				});
			}
		} else {
			this.records = [];
			if (data.records !== null && data.records !== undefined && data.records.length > 0) {
				data.records.forEach((item) => {
					this.records.push(new Solution(item));
				});
			}
		}
	}
}

export class Solution {
	constructor(data) {
		this.Id = data.Id;
		this.Name = data.Name;
		this.RelevanceScore__c = data[NAMESPACE_PREFIX + 'RelevanceScore__c'];
		this.IsFavorite__c = data[NAMESPACE_PREFIX + 'IsFavorite__c'];

		// init file tags for processing later
		this.FileTags = [];
		if (data['FileTags']) {
			this.FileTags = data['FileTags'];
		}

		// related
		if (
			data[NAMESPACE_PREFIX + 'Feature__r'] !== null &&
			data[NAMESPACE_PREFIX + 'Feature__r'] !== undefined
		) {
			this.Feature__r = new Feature(data[NAMESPACE_PREFIX + 'Feature__r']);
		}
		if (
			data[NAMESPACE_PREFIX + 'Requirement__r'] !== null &&
			data[NAMESPACE_PREFIX + 'Requirement__r'] !== undefined
		) {
			this.Requirement__r = new Requirement(data[NAMESPACE_PREFIX + 'Requirement__r']);
		}
	}
}

export class SFDCSolution {
	constructor(data) {
		if (data) {
			this.Id = data?.Id;
			this.Name = data?.Name;
			this[NAMESPACE_PREFIX + 'RelevanceScore__c'] = data?.RelevanceScore__c;
			this[NAMESPACE_PREFIX + 'IsFavorite__c'] = data?.IsFavorite__c;
		}
	}
}
