import React from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';

function SmallLoadingCmp(props) {
	return (
		<Box>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<CircularProgress />
				</Grid>
				<Grid item>
					<Box sx={{ fontSize: '24px', fontWeight: '400' }}>
						{props.loadingMsg ? props.loadingMsg : ''}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
export default SmallLoadingCmp;
