import React from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import { styled } from '@mui/material/styles';

const AccordionSummary = styled((props) => <MuiAccordionSummary disableRipple={true} {...props} />)(
	({ theme }) => ({
		// backgroundColor:
		//   theme.palette.mode === 'dark'
		//     ? 'rgba(255, 255, 255, .05)'
		//     : 'rgba(0, 0, 0, .03)',
		// flexDirection: 'row-reverse',
		// '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		//   transform: 'rotate(90deg)',
		// },
		// '& .MuiAccordionSummary-content': {
		//   marginLeft: theme.spacing(0),
		// },
		'& .MuiAccordionSummary-content': {
			margin: '0px',
		},
	}),
);
AccordionSummary.displayName = 'AccordionSummary';
export default AccordionSummary;
