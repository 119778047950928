import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Login } from '@microsoft/mgt-react';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { Providers } from '@microsoft/mgt-element';

import {
	Autocomplete,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';
import CustomIcon from '../../mui/CustomIcon';
import { FWButton } from '../../mui/FWButtons';

import FileListCmp from './FileListCmp';
import { withLazySuspense } from '../../common/SuspenseUtils';
import { FWSubtitle, useAsyncReference } from '../../common/UtilComponents';

import ConfigureDocumentService from '../../../services/ConfigureDocumentService';

import {
	FILE_SCAN_BYPAGE,
	FILE_SCAN_BYSECTION,
	FILE_TYPE_USER_GUIDES,
	FILES_SUPPORTED_DOCTYPES,
	INTEGRATION_SHARE_POINT,
} from '../../../Constants';
import { QKEY_FWAVE_FILES, QKEY_PRODUCT_TAGS, QKEY_TAGS } from '../../../utils/QueryKeys';

const UppyFilePickerCmp = withLazySuspense(() => import('../../utils/UppyFilePicker'), null);

const SCAN_OPTIONS = [
	{ Id: FILE_SCAN_BYPAGE, text: 'Full Page' },
	{ Id: FILE_SCAN_BYSECTION, text: 'Section' },
];

const fileUploadHandler = async (result, tags, queryClient) => {
	const successResults = result.successful;
	if (!Array.isArray(successResults) || successResults.length < 1) return;

	try {
		const fileIds = successResults
			.map(
				(item) =>
					item?.response?.body?.find?.((b) => b?.isSuccess === true && b?.fileId)?.fileId,
			)
			.filter((f) => f?.trim?.()?.length >= 15);
		if (fileIds.length > 0 && tags)
			await ConfigureDocumentService.saveFileTags(new Set(fileIds), tags);
		await Promise.allSettled(
			[[QKEY_FWAVE_FILES], [QKEY_TAGS]].map((qKey) =>
				queryClient.invalidateQueries({ queryKey: qKey }),
			),
		);
	} catch (error) {
		console.error('Error saving file tags. ', error);
	}
};

const sharepointIntegrationItem = {
	name: 'OneDrive / SharePoint',
	icon: 'sharepointOnedrive.jpeg',
	description: 'Import and save files with OneDrive / SharePoint',
	status: 'Not Configured',
	iconWidth: 80,
	actionName: 'Configure',
};
const FileExternalItegerations = () => {
	useEffect(() => {
		if (!Providers.globalProvider)
			Providers.globalProvider = new Msal2Provider({
				isMultiAccountEnabled: false,
				clientId: '3e4fc59a-725f-4e4b-89b9-c1afaaca2866',
				loginType: 0,
				scopes: [
					'User.Read',
					'User.Read.All',
					'Files.Read',
					'Files.Read.All',
					'Sites.Read.All',
				],
				prompt: 'login',
			});
	}, []);
	const [configureIntegrationsOpen, setConfigureIntegrationsOpen] = useState(false);
	const [currentConfiguredIntegration, setCurrentConfiguredIntegration] = useState(null);
	const navigate = useNavigate();

	const createNewIntegration = (integration, evt) => {
		if (integration?.name === INTEGRATION_SHARE_POINT) {
			// open modal for configuring integration
			setCurrentConfiguredIntegration(integration);
			openConfigureIntegrationModal();
		} else {
			alert('This integration will be coming in a future release, stay tuned!');
		}
	};
	const openConfigureIntegrationModal = () => {
		// get cached int option
		loadCachedIntOptions();

		// open modal
		setConfigureIntegrationsOpen(true);
	};
	const closeConfigureIntegrationModal = () => {
		setConfigureIntegrationsOpen(false);
		setCurrentConfiguredIntegration(null);
	};
	const redirectToSharepoint = () => {
		navigate('/filesOtherSources');
	};
	const saveConfiguredInformation = () => {
		// save configuration in cache for now
		saveCachedIntOptions();

		// verify the info and get auth code
		verifyIntOptionAuth(currentConfiguredIntegration);

		// redirect to sharepoint file picker
		redirectToSharepoint();
	};

	const loadCachedIntOptions = () => {
		var cachedIntOptions = localStorage.getItem('integrationConfigOptions');
		if (cachedIntOptions) {
			setCurrentConfiguredIntegration(JSON.parse(cachedIntOptions));
		}
	};
	const saveCachedIntOptions = () => {
		if (currentConfiguredIntegration) {
			localStorage.setItem(
				'integrationConfigOptions',
				JSON.stringify(currentConfiguredIntegration),
			);
		}
	};
	const verifyIntOptionAuth = (integration) => {
		if (integration?.name === INTEGRATION_SHARE_POINT) {
			// call api to auth
		}
	};

	return (
		<>
			<Card>
				<CardContent
					style={{
						overflowY: 'auto',
						textAlign: 'center',
						margin: 'auto',
					}}
				>
					<Grid
						container
						direction="column"
						alignItems="flex-start"
						flexGrow={1}
						spacing={1.5}
					>
						<Grid item container justifyContent="space-between" alignItems="center">
							<Grid item>
								<CustomIcon
									width={sharepointIntegrationItem.iconWidth}
									name={sharepointIntegrationItem.icon}
								/>
							</Grid>
							<Grid item>
								<FWButton
									onClick={(evt) => {
										createNewIntegration(sharepointIntegrationItem, evt);
									}}
								>
									{sharepointIntegrationItem.actionName || 'Configure'}
								</FWButton>
							</Grid>
						</Grid>
						<Grid item container direction="column" alignItems="flex-start">
							<Grid item className="main-title">
								{sharepointIntegrationItem.name}
							</Grid>
							<Grid item className="sub-title" textAlign="left">
								{sharepointIntegrationItem.description}
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<Dialog open={configureIntegrationsOpen} onClose={closeConfigureIntegrationModal}>
				<DialogTitle>Configure {currentConfiguredIntegration?.name}</DialogTitle>
				<DialogContent>
					<DialogContentText sx={{ paddingTop: '5px', paddingBottom: '25px' }}>
						Please fill in the below details to authenticate into your{' '}
						{currentConfiguredIntegration?.name} instance.
					</DialogContentText>
					<Login />
				</DialogContent>
				<DialogActions sx={{ paddingBottom: '16px' }}>
					<FWButton onClick={closeConfigureIntegrationModal}>Cancel</FWButton>
					<FWButton onClick={saveConfiguredInformation}>Confirm</FWButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

const UppyFileUploader = ({ fileType = FILE_TYPE_USER_GUIDES, title, primaryActions }) => {
	const { data: tags = [], isLoading: isTagsLoading } = useQuery({
		queryKey: [QKEY_PRODUCT_TAGS],
		queryFn: ConfigureDocumentService.getTags,
	});

	const queryClient = useQueryClient();

	const [selectedTags, setSelectedTags] = useAsyncReference([]);
	const [scanOption, setScanOption] = useState(SCAN_OPTIONS[0]);

	return (
		<Grid container spacing={2}>
			<Grid item container xs={12} sm={4} lg={3} spacing={2} alignContent="flex-start">
				<Grid item xs={12} paddingBottom={1}>
					<center>
						<h4>{title}</h4>
					</center>
				</Grid>
				{Array.isArray(primaryActions) && primaryActions.length > 0 && (
					<Grid item container xs={12} paddingBottom={1} justifyContent="center">
						{primaryActions.map((primaryAction) => (
							<Grid key={primaryAction.label || primaryAction.name} item>
								<Button variant="contained" onClick={primaryAction.actionFn}>
									{primaryAction.label}
								</Button>
							</Grid>
						))}
					</Grid>
				)}
				<Grid item xs={12}>
					<FileExternalItegerations />
				</Grid>
				<Grid item xs={12}>
					<center>
						<FWSubtitle title="File Upload Options"></FWSubtitle>
					</center>
				</Grid>
				{/* Scan Options Selector */}
				<Grid item xs={12}>
					<Autocomplete
						options={SCAN_OPTIONS}
						disableClearable
						defaultValue={SCAN_OPTIONS[0]}
						getOptionKey={(option) => option.Id}
						getOptionLabel={(option) => option.text}
						onChange={(e, value) => setScanOption(value)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Scan Option"
								size="small"
								placeholder="Search"
								sx={{ width: '100%' }}
							/>
						)}
						renderOption={(props, option) => <li {...props}>{option.text}</li>}
					/>
				</Grid>
				{/* Tags Selector */}
				<Grid item xs={12}>
					<Autocomplete
						multiple
						options={tags}
						loading={isTagsLoading}
						getOptionKey={(option) => option.Id}
						getOptionLabel={(option) => option.text}
						onChange={(e, value) => setSelectedTags(value)}
						renderInput={(params) => (
							<TextField
								{...params}
								label={isTagsLoading ? 'Loading...' : 'Products / Tags'}
								size="small"
								placeholder="Search"
								sx={{ width: '100%' }}
							/>
						)}
						renderOption={(props, option) => <li {...props}>{option.text}</li>}
					/>
				</Grid>
			</Grid>
			{/* Uppy File Picker */}
			<Grid item xs={12} sm={8} lg={9}>
				<UppyFilePickerCmp
					width="100%"
					height={500}
					allowedFileTypes={FILES_SUPPORTED_DOCTYPES}
					filesMetaData={{
						parseType: scanOption?.Id,
						fileType,
					}}
					onUploadComplete={async (result) =>
						await fileUploadHandler(result, selectedTags.current, queryClient)
					}
				/>
			</Grid>
		</Grid>
	);
};

const FileUploadWithListCmp = ({
	fileType,
	title,
	actions,
	recordSelection,
	supportedFiles,
	showGuidance = false,
	primaryActions,
}) => {
	//const [currentFile, setCurrentFile] = useState();
	const [currentFiles, setCurrentFiles] = useState([]);
	const fileListRef = useRef();

	return (
		<Grid
			container
			spacing={2}
			direction="column"
			maxWidth="100%"
			position="relative"
			flex="1 1 auto"
			style={{ paddingLeft: '20px' }}
		>
			<Grid item>
				<UppyFileUploader
					fileType={fileType}
					title={title}
					supportedFiles={supportedFiles}
					primaryActions={showGuidance ? primaryActions : undefined}
				/>
			</Grid>
			{currentFiles.length > 0 && Array.isArray(actions) && actions.length > 0 && (
				<Grid
					item
					container
					style={{ paddingRight: '20px' }}
					className="standard-spacing"
					justifyContent="flex-end"
					direction="row"
					columnGap={1}
				>
					{actions.map((action) => {
						return (
							<>
								{action.getApplicableTooltip &&
									!action.isApplicableFn(currentFiles) && (
										<Grid style={{ paddingTop: '5px' }} item>
											{action.getApplicableTooltip(currentFiles)}
										</Grid>
									)}
								<Button
									key={action.label || action.name}
									variant="contained"
									disabled={!action.isApplicableFn(currentFiles)}
									onClick={() => {
										action.actionFn(currentFiles);
									}}
								>
									{action.label}
								</Button>
							</>
						);
					})}
				</Grid>
			)}
			<Grid item container flex="1 1 auto">
				<FileListCmp
					ref={fileListRef}
					fileType={fileType}
					recordSelection={recordSelection}
					onSelectionChange={(selectedFileIds, selectedFileItems) => {
						// setCurrentFile(
						// 	selectedFileItems?.length > 0 ? selectedFileItems[0] : undefined,
						// );
						setCurrentFiles(selectedFileItems);
					}}
				/>
			</Grid>
		</Grid>
	);
};
FileUploadWithListCmp.displayName = 'FileUploadWithListCmp';
export default FileUploadWithListCmp;
