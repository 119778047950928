import React, { useState } from 'react';

import AboutCompanyCmp from './AboutCompanyCmp';
import AccountSummary from './AccountSummary';
import PersonaInsight from '../PersonaInsight';
import OpportunityOverviewSectionContentCmp from '../OpportunityOverviewSectionContentCmp';

const AccountInsightsStandaloneContentCmp = ({
	tabId,
	account,
	oppty,
	salesPlayBook,
	accountId,
}) => {
	const [acctSummaryRefreshDate, setAcctSummaryRefreshDate] = useState(null);

	const updateAcctSummaryRefreshTime = (refreshDate) => {
		if (acctSummaryRefreshDate?.getTime() === refreshDate?.getTime()) return;
		setAcctSummaryRefreshDate(refreshDate);
	};

	return (
		<>
			{tabId === 'aboutCompany' && (
				<OpportunityOverviewSectionContentCmp
					color="linear-gradient(180deg, #d985ee 0%, #5b0bde 100%)"
					showIcons={false}
					showRefresh={false}
				>
					<AboutCompanyCmp companyName={account?.Name} companyUrl={account?.Website} />
				</OpportunityOverviewSectionContentCmp>
			)}
			{tabId === 'accountSummary' && (
				<OpportunityOverviewSectionContentCmp
					color="linear-gradient(180deg, #d985ee 0%, #5b0bde 100%)"
					showIcons={true}
					showRefresh={true}
					refreshDate={acctSummaryRefreshDate}
				>
					{accountId && (
						<AccountSummary
							accountId={accountId}
							updateAcctSummaryRefreshTime={updateAcctSummaryRefreshTime}
							showEmptyScreen={true}
						/>
					)}
				</OpportunityOverviewSectionContentCmp>
			)}
			{tabId === 'personaInsights' && (
				<OpportunityOverviewSectionContentCmp
					color="linear-gradient(180deg, #d985ee 0%, #5b0bde 100%)"
					showIcons={false}
					showRefresh={false}
					refreshDate={acctSummaryRefreshDate}
				>
					<PersonaInsight
						account={account}
						opportunity={oppty}
						salesPlayBook={salesPlayBook}
						showEmptyScreen={true}
					/>
				</OpportunityOverviewSectionContentCmp>
			)}
		</>
	);
};
export default AccountInsightsStandaloneContentCmp;
