import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Utils from './utils/Utils';
import { withLazySuspense } from './components/common/SuspenseUtils';

import './index.scss';

const theme = createTheme({
	palette: {
		primary: {
			main: 'rgb(5, 40, 60)',
		},
		secondary: {
			main: '#97c4d8',
		},
	},
});

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
	queries: {
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
		refetchInterval: 1000 * 60 * 5,
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 15,
	},
});

const ReactQueryDevtools = withLazySuspense(
	() => import('@tanstack/react-query-devtools').then((d) => ({ default: d.ReactQueryDevtools })),
	null,
);

const AppContextProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</BrowserRouter>
		{Utils.isLocalhost && <ReactQueryDevtools />}
	</QueryClientProvider>
);

const root = createRoot(document.getElementById('root'));
root.render(
	<AppContextProvider>
		<App />
	</AppContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
