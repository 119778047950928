import { Account } from '../models/Account';
import ApiGateway from './ApiGateway';

export const ACCOUNT_BASE_FIELDS = [
	'Id',
	'Name',
	'Industry',
	'Owner.Name',
	'Owner.SmallPhotoUrl',
	'Rating',
	'Type',
	'AnnualRevenue',
	'Website',
	'PhotoUrl',
];

export const CONTACT_BASE_FIELDS = ['Id', 'FirstName', 'LastName', 'Name', 'Title'];

export const getAccount = async (accountId, includeContacts) => {
	if (!accountId) return null;

	const accFields = ACCOUNT_BASE_FIELDS.join(', ');
	const allFields = includeContacts
		? `${accFields}, (SELECT ${CONTACT_BASE_FIELDS.join(', ')} FROM Contacts)`
		: accFields;

	const query = `SELECT ${allFields} FROM Account WHERE Id='${accountId}'`;
	const accounts = await ApiGateway.invoke('sf', 'query', {}, { query });
	if (!accounts?.[0]) return null;
	return new Account(accounts[0]);
};
