import {
	Alert,
	AlertTitle,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SalesforceCommonApi from '../../apis/SalesforceCommonApi';
import AdminApi from '../../apis/AdminApi';
import {
	NAMESPACE_PREFIX,
	SOBJECT_EXPORT_FILENAME,
	SUMMARY_PREVIEW_SAMPLE_MSG,
} from '../../Constants';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import SObjectFieldCopyCmp from './sObjectSummary/SObjectFieldCopyCmp';
import { StatefulButton } from '../common/UtilComponents';
import SimpleTooltipCmp from '../common/SimpleTooltipCmp';
import { InfoOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FWButton } from '../mui/FWButtons';

export default function AdminSettingsSummaryPreviewCmp() {
	const [isLoadingAccounts, setLoadingAccounts] = useState(true);
	const [accounts, setAccounts] = useState();
	const [selectedAccount, setSelectedAccount] = useState();
	const [prompt, setPrompt] = useState('');
	const [updatedPrompt, setUpdatedPrompt] = useState();
	const [gptRecord, setGptRecord] = useState();
	const [sObjectExport, setSObjectExport] = useState();
	const [summary, setSummary] = useState();
	const [recordSummary, setRecordSummary] = useState();
	const [sobjects, setSObjects] = useState([]);
	const [sObjectFieldMap, setSObjectFieldMap] = useState({});
	const [generationStatus, setGenerationStatus] = useState('Initial');
	const [saveStatus, setSaveStatus] = useState('Initial');
	const [showSourceData, setShowSourceData] = useState(false);
	const [exportDate, setExportDate] = useState();
	const [cursorPosition, setCursorPosition] = useState();

	useEffect(() => {
		AdminApi.getAccountSummaryPrompt().then((response) => {
			let _prompt = response.content;
			let _gptRecord = response.record;
			setGptRecord(_gptRecord);
			setPrompt(_prompt);
			setUpdatedPrompt(_prompt);
		});
		SalesforceCommonApi.query({ query: `SELECT Id, Name FROM Account` }).then((_accounts) => {
			setAccounts(_accounts);
			setLoadingAccounts(false);
		});
		AdminApi.getSObjectExports({}).then((_sobjectExports) => {
			let _sObjects = [];
			let _sobjectFieldMap = {};
			if (_sobjectExports && _sobjectExports.length > 0) {
				for (let _sobjectExport of _sobjectExports) {
					let _sobjectName = _sobjectExport[NAMESPACE_PREFIX + 'SObjectName__c'];
					let _sobjectLabel = _sobjectExport[NAMESPACE_PREFIX + 'SObjectLabel__c'];
					let _fieldStr = _sobjectExport[NAMESPACE_PREFIX + 'Fields__c'];
					let _defaultFieldStr = _sobjectExport[NAMESPACE_PREFIX + 'DefaultFields__c'];
					if (_sobjectName === 'Account') {
						setSObjectExport(_sobjectExport);
					}
					if (!_fieldStr || _fieldStr === '') _fieldStr = '[]';
					if (!_defaultFieldStr || _defaultFieldStr === '') _defaultFieldStr = '[]';
					let _fields = JSON.parse(_fieldStr);
					let _defaultFields = JSON.parse(_defaultFieldStr);
					let _finalFields = [];
					for (let _field of _fields) {
						if (!_finalFields.includes(_field)) _finalFields.push(_field);
					}
					for (let _defaultField of _defaultFields) {
						if (!_finalFields.includes(_defaultField)) _finalFields.push(_defaultField);
					}
					_sobjectFieldMap[_sobjectName] = _finalFields;
					_sObjects.push({ name: _sobjectName, label: _sobjectLabel });
				}
			}

			setSObjectFieldMap(_sobjectFieldMap);
			setSObjects(_sObjects);
		});
	}, []);

	const handleAccountChange = (event) => {
		setSelectedAccount(event.target.value);
	};

	const savePrompt = async () => {
		setSaveStatus('Saving');
		let _updatedPrompt = updatedPrompt || '';
		_updatedPrompt = _updatedPrompt.replaceAll("'", '"');
		_updatedPrompt = _updatedPrompt.replaceAll('"', '\\\\"');
		//_updatedPrompt = _updatedPrompt.replaceAll('"', '\\\\"');
		await AdminApi.updatePrompt({
			record: gptRecord,
			developerName: 'AccountSummary',
			masterLabel: 'Account Summary',
			prompt: _updatedPrompt,
		});
		setSaveStatus('Saved');
	};

	const onTestClickHandler = async () => {
		setGenerationStatus('Generating');
		let prefix = SOBJECT_EXPORT_FILENAME;
		let exportId = sObjectExport.Id;
		let _updatedPrompt = updatedPrompt || '';
		_updatedPrompt = _updatedPrompt.replaceAll("'", '"');
		_updatedPrompt = _updatedPrompt.replaceAll('"', '\\"');
		let exportFiles = await SalesforceCommonApi.query({
			query: `SELECT Id, ContentDocumentId, VersionData, CreatedDate FROM ContentVersion WHERE ContentDocument.Title LIKE '${prefix}%' AND ExternalDocumentInfo1 = '${selectedAccount}'`,
		});
		let exportFile;
		if (exportFiles && exportFiles.length > 0) {
			exportFile = exportFiles[0];
		} else {
			await AdminApi.exportSingleRecord({
				recordId: selectedAccount,
				exportId: exportId,
			});
			exportFiles = await SalesforceCommonApi.query({
				query: `SELECT Id, ContentDocumentId, VersionData, CreatedDate FROM ContentVersion WHERE ContentDocument.Title LIKE '${prefix}%' AND ExternalDocumentInfo1 = '${selectedAccount}'`,
			});
			if (exportFiles && exportFiles.length > 0) {
				exportFile = exportFiles[0];
			}
		}
		setExportDate(exportFile?.CreatedDate);
		let response = await AdminApi.getSummary({
			recordId: selectedAccount,
			documentId: exportFile.ContentDocumentId,
			promptValue: _updatedPrompt,
		});
		let summary = '',
			_recordSummary = '';
		try {
			let gptSummaryRecord = JSON.parse(JSON.parse(response.result));
			summary = JSON.parse(gptSummaryRecord[NAMESPACE_PREFIX + 'GPTSummary__c']).content[0]
				.text;
			_recordSummary = JSON.parse(gptSummaryRecord[NAMESPACE_PREFIX + 'RecordSummary__c'])
				.messages[0].content;
			if (_recordSummary) {
				_recordSummary = _recordSummary?.split('End Account (Account)')[0];
			}
			setRecordSummary(_recordSummary);
		} catch (e) {
			console.error(e);
		}
		setSummary(summary);
		setGenerationStatus('Generated');
	};

	const onChangePrompt = (event) => {
		setPrompt(event.target.value);
		//let editorHtmlVal = event.target.value;

		// var temp = document.createElement('div');
		// temp.innerHTML = editorHtmlVal;
		// let _plainText = temp.textContent || temp.innerText || '';
		// if (_plainText === 'undefined') _plainText = '';
		setUpdatedPrompt(event.target.value);
		setCursorPosition(event.target.selectionStart);
	};

	const printCursor = (event) => {
		setCursorPosition(event.target.selectionStart);
	};

	const onAppend = (fieldText) => {
		let textBeforeCursorPosition = updatedPrompt.substring(0, cursorPosition);
		let textAfterCursorPosition = updatedPrompt.substring(cursorPosition, updatedPrompt.length);
		let newVal = textBeforeCursorPosition + fieldText + textAfterCursorPosition;
		setPrompt(newVal);
		setUpdatedPrompt(newVal);
	};

	return (
		<div
			className="AdminSettingsSummaryPreviewCmp_container"
			style={{ padding: '50px', width: '1000px' }}
		>
			{showSourceData && (
				<AdminSettingsSummarySourceDataCmp
					sourceData={recordSummary}
					setShowSourceData={setShowSourceData}
					exportDate={exportDate}
				/>
			)}
			<Grid
				container
				direction={'column'}
				spacing={5}
				alignItems={'flex-start'}
				justifyContent={'center'}
				style={{ width: '100%' }}
			>
				<Grid container item direction={'column'} flex={1} spacing={2}>
					<Grid item style={{ width: '100%' }}>
						<SObjectFieldCopyCmp
							sObjects={sobjects}
							fieldFilterMap={sObjectFieldMap}
							onAppend={onAppend}
						/>
					</Grid>
					<Grid item style={{ width: '100%' }}>
						<TextField
							required={true}
							style={{ width: '100%' }}
							id={'input_prompt'}
							label={'Prompt'}
							inputProps={
								{
									// maxLength: maxLength,
								}
							}
							value={prompt}
							multiline={true}
							rows={5}
							onChange={onChangePrompt}
							onClick={printCursor}
							//onBlur={setDirty(true)}
							//error={error === true}
							//helperText={error === true ? 'Invalid email' : undefined}
						/>
						{/* <RichTextEditorCmp
                                label={"Prompt"}
                                existingValue={prompt}
                                onContentStateChange={onChangePrompt}
                            /> */}
					</Grid>
				</Grid>

				<Grid item>
					{isLoadingAccounts && <p>Loading Accounts...</p>}
					{!isLoadingAccounts && (
						<FormControl size="small">
							<InputLabel id="demo-simple-select-label">
								Select Account to summarize
							</InputLabel>
							<Select
								size="small"
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={selectedAccount}
								label="Select Account to summarize"
								onChange={handleAccountChange}
								style={{ width: '500px' }}
							>
								{accounts.map((account) => {
									return (
										<MenuItem key={account.Id} value={account.Id}>
											{account.Name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)}
				</Grid>
				<Grid item container>
					<Grid item style={{ marginRight: '5px' }}>
						<StatefulButton
							disabled={!selectedAccount || !sObjectExport}
							isProcessing={generationStatus === 'Generating'}
							label="Preview Summary"
							processingLabel="Previewing Summary..."
							key={'generateSummaryButton'}
							onClick={onTestClickHandler}
						/>
					</Grid>
					<Grid item>
						<StatefulButton
							isProcessing={saveStatus === 'Saving'}
							label="Save Prompt"
							processingLabel="Saving Prompt..."
							key={'savePromptButton'}
							onClick={savePrompt}
						/>
					</Grid>
				</Grid>
				{summary && (
					<Grid item container>
						<Grid item container>
							<Grid item>
								<b>Sample Account Summary</b>&nbsp;
								<br />
							</Grid>
							<Grid item>
								<SimpleTooltipCmp
									placement="right-start"
									title={SUMMARY_PREVIEW_SAMPLE_MSG}
								>
									<InfoOutlined color="secondary" />
								</SimpleTooltipCmp>
							</Grid>
						</Grid>
						<Grid item style={{ width: '100%', paddingBottom: '8px' }}>
							<Alert severity="info">
								<AlertTitle>Disclaimer </AlertTitle>
								Please check the{' '}
								<Link
									component="button"
									variant="body2"
									onClick={() => {
										setShowSourceData(true);
									}}
								>
									{'source data'}
								</Link>{' '}
								to make sure the summary generated is 100% accurate.
							</Alert>
						</Grid>
						<Grid item>
							<Card elevation={5}>
								<CardContent>
									<Markdown remarkPlugins={[remarkGfm]}>{summary}</Markdown>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)}
				<Grid item></Grid>
			</Grid>
		</div>
	);
}

function AdminSettingsSummarySourceDataCmp({ sourceData, setShowSourceData, exportDate }) {
	return (
		<Dialog
			//onClose={handleClose}
			open={true}
			scroll="paper"
			maxWidth="lg"
			fullWidth="true"
			height="900px"
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">
				<center>
					{'Source Data' +
						(exportDate ? ' - ' + new Date(exportDate).toLocaleString() : '')}
				</center>
			</DialogTitle>
			<DialogContent dividers={true}>
				<Markdown remarkPlugins={[remarkGfm]}>{sourceData}</Markdown>
			</DialogContent>
			<DialogActions>
				<FWButton
					key="cancel-clear-mapping"
					label="Cancel"
					variant="outlined"
					onClick={() => {
						setShowSourceData(false);
					}}
				>
					Close
				</FWButton>
			</DialogActions>
		</Dialog>
	);
}
