import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
	Avatar,
	Button,
	Card,
	CardContent,
	Chip,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import {
	AddCircleOutline as AddCircleOutlineIcon,
	Clear as ClearIcon,
	Person as PersonIcon,
	Business,
	Delete,
	Edit,
	Language,
	LocalPhone,
	SellOutlined,
	WorkOutlined,
} from '@mui/icons-material';
import FWAutocomplete from '../mui/FWAutocomplete';
import { FWButton } from '../mui/FWButtons';

import { FWTitle, StatefulButton } from '../common/UtilComponents';

import UserService from '../../services/UserService';
import ConfigureDocumentService from '../../services/ConfigureDocumentService';
import SalesforceService from '../../services/SalesforceService';
import AdminService from '../../services/AdminService';
import AdminApi from '../../apis/AdminApi';
import SalesforceCommonApi from '../../apis/SalesforceCommonApi';

import CacheUtils from '../../utils/CacheUtils';

import { COMPANY_NAME, NAMESPACE_PREFIX, OBJECT_TYPE_USER } from '../../Constants';
import { QKEY_PRODUCTS } from '../../utils/QueryKeys';

const HEADER_LOGO_SIZE = 75;

const CompanyProfileCmpNew = ({ scrollToTab }) => {
	useEffect(() => {
		if (scrollToTab) scrollToTab();
	}, [scrollToTab]);

	return (
		<div id="companyProfileContainer" style={{ height: 'calc(86vh)' }}>
			<Grid
				container
				direction="column"
				spacing={1}
				style={{
					marginTop: 'unset',
					marginLeft: 'unset',
					width: '100%',
				}}
			>
				<Grid item style={{ padding: '5px' }}>
					<CompanyProfileHeader />
				</Grid>
				<Grid item xs={12} style={{ padding: '5px', height: 'calc(78vh)' }}>
					<CompanyProfileProducts />
				</Grid>
			</Grid>
		</div>
	);
};

function stringToColor(string) {
	// if(!string) string = COMPANY_NAME;
	// let hash = 0;
	// let i;

	// /* eslint-disable no-bitwise */
	// for (i = 0; i < string.length; i += 1) {
	//   hash = string.charCodeAt(i) + ((hash << 5) - hash);
	// }

	// let color = '#';

	// for (i = 0; i < 3; i += 1) {
	//   const value = (hash >> (i * 8)) & 0xff;
	//   color += `00${value.toString(16)}`.slice(-2);
	// }
	/* eslint-enable no-bitwise */

	return '#05283c';
}

function stringAvatar(name) {
	if (!name) name = COMPANY_NAME;
	let avatarTxt =
		name.split(' ').length >= 2
			? name.split(' ')[0][0] + (name.split(' ')[1][0] ? name.split(' ')[1][0] : '')
			: name.split(' ')[0][0];
	return {
		sx: {
			bgcolor: stringToColor(name),
			width: HEADER_LOGO_SIZE,
			height: HEADER_LOGO_SIZE,
			marginLeft: '4px',
			marginRight: '4px',
		},
		children: `${avatarTxt}`,
	};
}

const IconLabelPair = ({ icon, label }) => (
	<Grid item container alignItems="center" style={{ lineHeight: '21px' }}>
		{icon}
		&nbsp;
		<div style={{ fontSize: '15px' }}>{label}</div>
	</Grid>
);

const CompanyProfileHeader = function () {
	const [companyInfoId, setCompanyInfoId] = useState(undefined);
	const [mode, setMode] = useState('R');
	const [about, setAbout] = useState('');
	const [url, setUrl] = useState(undefined);
	const [orgName, setOrgName] = useState('');
	const [phone, setPhone] = useState('');
	const [loading, setLoading] = useState(true);
	const [website, setWebsite] = useState('');

	useEffect(() => {
		let logoUrl = CacheUtils.getItem('companyInfo', 'logoUrl');
		setUrl(logoUrl);
		AdminService.getOrgInfo({
			callbackFn: (configurationObj, cached) => {
				setLoading(false);
				if (configurationObj) processConfiguration(configurationObj);
			},
		});
	}, []);

	const processConfiguration = (configuration) => {
		CacheUtils.setItem(
			'companyInfo',
			'configuration',
			configuration ? JSON.stringify(configuration) : '',
		);
		let phone = configuration[NAMESPACE_PREFIX + 'CompanyPhone__c'];
		let website = configuration[NAMESPACE_PREFIX + 'CompanyWebsite__c'];
		let about = configuration[NAMESPACE_PREFIX + 'CompanyAbout__c'];
		let companyName = configuration[NAMESPACE_PREFIX + 'CompanyName__c'];
		let logo = configuration[NAMESPACE_PREFIX + 'CompanyLogo__c'];
		setCompanyInfoId(configuration.Id);
		setAbout(about);
		setPhone(phone);
		setWebsite(website);
		setOrgName(companyName);

		if (logo) {
			AdminService.getLogoDocument(logo).then((docs) => {
				if (docs && docs.length > 0) {
					let logoUrl = SalesforceService.getLogoUrl(docs[0].Id);
					setUrl(logoUrl);
					CacheUtils.setItem('companyInfo', 'logoUrl', logoUrl);
					setLoading(false);
				} else {
					setLoading(false);
					CacheUtils.setItem('companyInfo', 'logoUrl', '');
				}
			});
		} else {
			setLoading(false);
			CacheUtils.setItem('companyInfo', 'logoUrl', '');
		}
	};

	const saveCompanyInfo = () => {
		AdminApi.updateCompanyInfo(companyInfoId, {
			[NAMESPACE_PREFIX + 'CompanyPhone__c']: phone,
			[NAMESPACE_PREFIX + 'CompanyWebsite__c']: website,
			[NAMESPACE_PREFIX + 'CompanyAbout__c']: about,
			[NAMESPACE_PREFIX + 'CompanyName__c']: orgName,
		}).then(() => {
			setMode('R');
		});
	};

	const cancelCompanyEdit = () => {
		setMode('R');
	};

	return (
		<Grid
			container
			id="comapnyProfileHeaderContainer"
			style={{ background: '#e5e8ec', padding: '5px', height: '120px', overflow: 'hidden' }}
		>
			{/* Company Profile */}
			{mode === 'R' && (
				<Grid
					item
					container
					spacing={1}
					direction="row"
					xs={4}
					md={4}
					style={{ marginTop: '0px' }}
				>
					<Grid
						item
						container
						direction={'column'}
						xs="auto"
						alignItems={'flex-end'}
						style={{ paddingLeft: '15px' }}
					>
						<Grid item style={{ marginTop: '6px' }}>
							{loading && (
								<Avatar
									sx={{
										width: HEADER_LOGO_SIZE,
										height: HEADER_LOGO_SIZE,
									}}
								/>
							)}
							{!loading && url && (
								<Avatar
									alt={orgName}
									src={url}
									sx={{
										width: HEADER_LOGO_SIZE,
										height: HEADER_LOGO_SIZE,
									}}
								/>
							)}
							{!loading && !url && <Avatar {...stringAvatar(orgName)} />}
						</Grid>
					</Grid>
					<Grid item container direction="column" xs="auto" style={{ background: '' }}>
						<Grid item>
							<FWTitle title="Company Profile" />
						</Grid>
						{orgName && (
							<IconLabelPair
								icon={<Business fontSize="inherit" color="primary" />}
								label={
									<strong
										style={{
											top: '2px',
											position: 'relative',
										}}
									>
										{orgName}
									</strong>
								}
							/>
						)}
						{website && (
							<IconLabelPair
								icon={<Language fontSize="inherit" color="primary" />}
								label={
									<Link color="primary" href={website}>
										{website}
									</Link>
								}
							/>
						)}
						{phone && (
							<IconLabelPair
								icon={<LocalPhone fontSize="inherit" color="primary" />}
								label={phone}
							/>
						)}
					</Grid>
				</Grid>
			)}
			{mode === 'E' && (
				<Grid item spacing={1} container xs={4} md={4} direction="row" alignItems="center">
					<Grid
						item
						container
						direction={'column'}
						xs="2"
						alignItems={'center'}
						style={{ paddingLeft: '15px' }}
					>
						<Grid flexGrow={1} item>
							&nbsp;
						</Grid>
						<Grid item>
							{loading && (
								<Avatar
									sx={{
										width: HEADER_LOGO_SIZE,
										height: HEADER_LOGO_SIZE,
									}}
								/>
							)}
							{!loading && url && (
								<Avatar
									alt={orgName}
									src={url}
									sx={{
										width: HEADER_LOGO_SIZE,
										height: HEADER_LOGO_SIZE,
									}}
								/>
							)}
							{!loading && !url && <Avatar {...stringAvatar(orgName)} />}
						</Grid>
						<Grid item>&nbsp;</Grid>
					</Grid>
					<Grid
						item
						container
						direction="column"
						xs="10"
						justifyContent={'space-around'}
						style={{
							paddingTop: '15px',
							paddingBottom: '15px',
							background: '',
							paddingRight: '50px',
							paddingLeft: '20px',
						}}
					>
						<Grid item>
							<FWTitle title="Company Profile" />
						</Grid>
						<Grid item>
							<TextField
								id="companyNameInput"
								//label="Name"
								placeholder="Company Name"
								title="Name"
								sx={{ width: '100%' }}
								value={orgName}
								hiddenLabel
								variant="standard"
								onChange={(evt) => {
									setOrgName(evt.target?.value);
								}}
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Business color="primary" />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item>
							<TextField
								id="companyWebsiteInput"
								//label="Website"
								title="Website"
								placeholder="Company Website"
								hiddenLabel
								sx={{ width: '100%' }}
								value={website}
								variant="standard"
								onChange={(evt) => {
									setWebsite(evt.target?.value);
								}}
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Language color="primary" />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item>
							<TextField
								id="companyPhoneInput"
								//label="Phone"
								title="Phone"
								placeholder="Company Phone"
								hiddenLabel
								sx={{ width: '100%' }}
								value={phone}
								variant="standard"
								onChange={(evt) => {
									setPhone(evt.target?.value);
								}}
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<LocalPhone color="primary" />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}

			{/* About Us */}
			{mode === 'R' && (
				<Grid
					item
					style={{
						whiteSpace: 'pre-line',
						marginTop: '8px',
						paddingLeft: '14px',
					}}
					xs={7}
					md={7}
				>
					<FWTitle title="About Us" />
					<Typography fontSize="15px" textOverflow="ellipsis">
						{about}
					</Typography>
					<br />
					<br />
					<br />
				</Grid>
			)}
			{mode === 'E' && (
				<Grid item style={{ textAlign: 'left', background: '' }} xs={6} md={6}>
					<TextField
						style={{ width: '100%' }}
						id="companyAboutInputId"
						label="About Us"
						placeholder="About Us"
						multiline
						value={about}
						onChange={(evt) => {
							setAbout(evt.target?.value);
						}}
						rows={2}
						defaultValue=""
						inputProps={{ maxLength: 255 }}
					/>
				</Grid>
			)}

			{/* Actions */}
			{mode === 'R' && (
				<Grid
					container
					justifyContent="flex-end"
					alignItems="flex-start"
					xs={2}
					md={1}
					style={{ paddingRight: '12px', paddingTop: '6px' }}
				>
					<IconButton aria-label="delete" size="small" onClick={() => setMode('E')}>
						<Edit fontSize="inherit" />
					</IconButton>
				</Grid>
			)}
			{mode === 'E' && (
				<Grid
					container
					justifyContent="flex-end"
					alignItems="flex-start"
					xs={2}
					md={2}
					style={{ paddingRight: '12px' }}
				>
					{' '}
					<Button variant="outlined" color="primary" onClick={cancelCompanyEdit}>
						Cancel
					</Button>
					&nbsp;
					<Button variant="contained" color="primary" onClick={saveCompanyInfo}>
						Save
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

const ProductDtlsCmp = forwardRef(({ productItem, onChange, refresh, onNewSave }, ref) => {
	const [mode, setMode] = useState('R');
	const [isProcessing, setIsProcessing] = useState(false);
	const [productItemObj, setproductItemObj] = useState(productItem || {});
	const [isSystemTag] = useState(productItem?.systemTag);
	const [productName, setProductName] = useState(productItem?.productName);
	const [productDesc, setProductDesc] = useState(productItem?.productDesc);
	const [type, setType] = useState(productItem?.type || 'Product');
	const [showEditIcon, setShowEditIcon] = useState(false);
	const queryClient = useQueryClient();
	useEffect(() => {
		setproductItemObj(productItem);
	}, [productItem]);

	const onAddProductHandle = () => {
		setMode('E');
	};
	const onAddProductDesc = () => {
		setMode('E');
	};

	useImperativeHandle(ref, () => ({
		saveDtls() {
			return saveProductDtls();
		},
	}));

	const saveProductDtls = () => {
		setIsProcessing(true);
		return new Promise((resolve, reject) => {
			ConfigureDocumentService.saveOrUpdateTags([
				{
					Id: productItemObj.Id,
					name: productName,
					description: productDesc,
					type: type,
				},
			]).then((saveResult) => {
				// if(saveResult && saveResult.length>0 &&saveResult[0].id)setproductItemId(saveResult[0].id);
				// setproductItemObj({
				//     productName : productName,
				//     productDesc : productDesc,
				//     Id : productItemId
				// });
				setMode('R');
				setIsProcessing(false);
				let productItemId = productItemObj.Id;
				if (!productItemId) {
					productItemId =
						saveResult && saveResult.length > 0 ? saveResult[0].id : undefined;
					if (onNewSave) onNewSave(productItemId);
				}
				queryClient
					.invalidateQueries({
						queryKey: [QKEY_PRODUCTS],
					})
					.then(() => {
						if (refresh) refresh(productItemId);
						resolve(productItemId);
					});
			});
		});
	};

	const handleTypeChange = (event, newAlignment) => {
		setType(newAlignment);
	};

	return (
		<>
			<Dialog
				open={mode === 'E'}
				//onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>{productItemObj?.Id ? 'Edit' : 'Add'}</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<Grid container direction={'column'} spacing={2}>
						<Grid item>
							<TextField
								id="productNameInput"
								label="Product / Tag Name"
								title="Product / Tag Name"
								sx={{ width: '100%' }}
								value={productName}
								onChange={(evt) => {
									setProductName(evt.target?.value);
								}}
								size="small"
							/>
						</Grid>
						<Grid item>
							<ToggleButtonGroup
								color="primary"
								value={type}
								exclusive
								onChange={handleTypeChange}
								aria-label="Platform"
							>
								<ToggleButton value="Product">Product</ToggleButton>
								<ToggleButton value="Tag">Tag</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
						<Grid item>
							<TextField
								id="productDescInput"
								label="Product / Tag Description"
								title="Product / Tag Description"
								multiline
								rows={10}
								value={productDesc}
								onChange={(evt) => {
									setProductDesc(evt.target?.value);
								}}
								sx={{ width: '100%' }}
								size="small"
							/>
						</Grid>
						{/* <Grid item style={{textAlign : 'right'}}>
								<FWButton size="small" onClick={saveProductDtls}>Save</FWButton>
							</Grid> */}
					</Grid>
				</DialogContent>
				<DialogActions>
					<FWButton
						key="cancel-clear-mapping"
						label="Cancel"
						variant="outlined"
						onClick={() => {
							setMode('R');
						}}
					>
						Cancel
					</FWButton>
					<StatefulButton
						isProcessing={isProcessing}
						label="Save"
						processingLabel="Saving..."
						key={productItemObj.Id}
						onClick={saveProductDtls}
					/>
					{/* <FWButton
						key="confirm-clear-mapping"
						label="Confirm"
						variant="contained"
						onClick={saveProductDtls}
					>
						Save
					</FWButton> */}
				</DialogActions>
			</Dialog>

			{mode === 'R' && (
				<div>
					{!productItemObj?.Id ? (
						<div>
							<Button
								startIcon={<AddCircleOutlineIcon />}
								onClick={onAddProductHandle}
							>
								Add Product / Tag
							</Button>
							<br />
							<Button startIcon={<AddCircleOutlineIcon />} onClick={onAddProductDesc}>
								Add Product / Tag description and details...
							</Button>
						</div>
					) : (
						<Grid container direction="column">
							<Grid
								item
								container
								onMouseEnter={() => {
									!isSystemTag && setShowEditIcon(true);
								}}
								onMouseLeave={() => {
									setShowEditIcon(false);
								}}
								onDoubleClick={() => {
									setMode('E');
								}}
							>
								<Grid item xs="11">
									<b>{productItemObj.productName}</b>
								</Grid>
								<Grid item xs="1">
									{
										<IconButton
											style={{
												display: showEditIcon ? '' : 'none',
											}}
											aria-label="delete"
											size="small"
											onClick={() => setMode('E')}
										>
											<Edit fontSize="inherit" />
										</IconButton>
									}
								</Grid>
							</Grid>
							<Grid
								item
								container
								onMouseEnter={() => {
									!isSystemTag && setShowEditIcon(true);
								}}
								onMouseLeave={() => {
									setShowEditIcon(false);
								}}
								onDoubleClick={() => {
									setMode('E');
								}}
							>
								<Grid item xs="11">
									{productItemObj.productDesc}
								</Grid>
								<Grid item xs="1">
									{
										<IconButton
											style={{
												display: showEditIcon ? '' : 'none',
											}}
											aria-label="delete"
											size="small"
											onClick={() => setMode('E')}
										>
											<Edit fontSize="inherit" />
										</IconButton>
									}
								</Grid>
							</Grid>
						</Grid>
					)}
				</div>
			)}
		</>
	);
});
ProductDtlsCmp.displayName = 'ProductDtlsCmp';

const ProductOwnerItem = function ({ owner, onDelete }) {
	const queryClient = useQueryClient();
	const onDeleteHandle = () => {
		ConfigureDocumentService.deleteTag([owner.Id], OBJECT_TYPE_USER).then((deleteResult) => {
			queryClient
				.invalidateQueries({
					queryKey: [QKEY_PRODUCTS],
				})
				.then(() => {
					if (onDelete) onDelete(owner.Id, owner.OwnerId);
				});
		});
	};
	return (
		<ListItemButton disableRipple key={owner.Id} sx={{ py: 0 }}>
			<ListItemIcon sx={{ color: 'inherit' }}>
				<PersonIcon />
			</ListItemIcon>
			<ListItemText
				primary={owner.Name}
				primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
			/>
			<IconButton aria-label="delete" size="small" onClick={onDeleteHandle}>
				<ClearIcon fontSize="inherit" />
			</IconButton>
		</ListItemButton>
	);
};

const ProductOwnerCmp = forwardRef(({ productItem, refresh }, ref) => {
	const [mode, setMode] = useState('R');
	const [productItemObj, setproductItemObj] = useState(productItem);
	const [owners, setOwners] = useState(productItemObj?.owners);
	const [dataPromise, setDataPromise] = useState(undefined);
	const [selectedOwners, setSelectedOwners] = useState(undefined);
	const [existingOwnerIds, setExistingOwnerIds] = useState([]);
	const autoCompleteRef = useRef();
	const queryClient = useQueryClient();
	//R = Read mode it will display owners and add owner button,
	//E = Edit Mode where Single User Selection Available but No Add Owner
	//ED = When New Owners selected and Save is pending. Once Saved mode = R

	useEffect(() => {
		setproductItemObj(productItem);
		setOwners(productItem?.owners);

		let _existingOwnerIds = [...existingOwnerIds];
		productItemObj?.owners?.forEach((owner) => {
			_existingOwnerIds.push(owner.OwnerId);
		});
		setExistingOwnerIds(_existingOwnerIds);
	}, [productItem]);

	useEffect(() => {
		setDataPromise(UserService.getUsers());
	}, []);

	const onAddOwnerHandle = () => {
		setMode('E');
	};
	useImperativeHandle(ref, () => ({
		saveDtls() {
			return saveProductOwnerDtls();
		},
		close() {
			if (mode !== 'R' && (!Array.isArray(selectedOwners) || selectedOwners.length < 1))
				setMode('R');
		},
	}));

	const saveProductOwnerDtls = (recordIds, ownerIds) => {
		return new Promise((resolve, reject) => {
			let ownerIds = [];
			//let _owners = [...owners];
			let _existingOwnerIds = [...existingOwnerIds];

			for (let selectedOwner of selectedOwners || []) {
				ownerIds.push(selectedOwner.Id);
				_existingOwnerIds.push(selectedOwner.Id);
				// _owners.push({
				//     Id : selectedOwner.Id,
				//     Name : selectedOwner.text,
				//     OwnerId : selectedOwner.Id
				// });
			}
			setExistingOwnerIds(_existingOwnerIds);
			if (ownerIds && ownerIds.length > 0) {
				ConfigureDocumentService.saveProductOwners(productItem.Id, ownerIds).then(() => {
					setSelectedOwners([]);
					autoCompleteRef?.current?.reset();
					//setOwners(_owners);
					setMode('R');
					queryClient
						.invalidateQueries({
							queryKey: [QKEY_PRODUCTS],
						})
						.then(() => {
							refresh(productItem.Id);
							resolve();
						});
				});
			} else {
				resolve();
				refresh(productItem.Id);
			}
		});
	};

	const onDeleteOwner = (ownerRecordId, ownerUserId) => {
		let _owners = [...owners] || [];
		_owners = _owners.filter((owner) => owner.Id !== ownerRecordId);

		let _existingOwnerIds = [...existingOwnerIds];
		_existingOwnerIds = _existingOwnerIds.filter((_ownerId) => _ownerId !== ownerUserId);
		setOwners(_owners);
		setExistingOwnerIds(_existingOwnerIds);
	};

	return (
		<>
			{mode === 'R' && (
				<div>
					<Button
						startIcon={<AddCircleOutlineIcon />}
						onClick={onAddOwnerHandle}
						disabled={!productItemObj?.Id}
					>
						Product / Tag Owners
					</Button>
				</div>
			)}
			{owners?.length > 0 && (
				<Grid container direction="column">
					{owners.map((owner) => (
						<ProductOwnerItem
							key={owner.Id || owner.id}
							owner={owner}
							onDelete={onDeleteOwner}
						/>
					))}
				</Grid>
			)}

			{mode === 'E' && (
				<Grid container direction={'column'}>
					<Grid item>
						<FWAutocomplete
							label="Users"
							disableAdd={false}
							allowToAddNewValues={false}
							dataPromise={dataPromise}
							provideSaveButton={true}
							saveFunction={saveProductOwnerDtls}
							multiple={true}
							disabledValues={existingOwnerIds}
							onValueChange={(values, oldValues) => {
								setSelectedOwners(values);
							}}
							width={'100%'}
							ref={autoCompleteRef}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
});
ProductOwnerCmp.displayName = 'ProductOwnerCmp';

const ProductItemCmp = function ({ productItem, onNewSave, refresh }) {
	const [productItemObj, setproductItemObj] = useState(productItem);
	const [isSystemTag] = useState(productItem?.systemTag);
	const productDtlsRef = useRef();
	const productOwnerDtlsRef = useRef();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [disableDelete, setDisableDelete] = useState(false);
	const queryClient = useQueryClient();

	// const saveDtls = () => {
	// 	let productDtlsPromise = productDtlsRef?.current?.saveDtls();
	// 	let productOwnerPromise = productOwnerDtlsRef?.current?.saveDtls();

	// 	Promise.all([productDtlsPromise, productOwnerPromise]).then(
	// 		(productDtlsResult, ownerDtlsResult) => {
	// 			let productItemId;
	// 			if (productItemObj?.Id) {
	// 				productItemId = productItemObj.Id;
	// 			} else {
	// 				if (productDtlsResult && productDtlsResult.length > 0) {
	// 					productItemId = productDtlsResult[0];
	// 					if (onNewSave) onNewSave(productItemId);
	// 				}
	// 			}

	// 			refresh(productItemId);
	// 		},
	// 	);
	// };

	const refreshItem = (productItemId) => {
		ConfigureDocumentService.getProducts([productItemId]).then((_productItems) => {
			if (_productItems && _productItems.length > 0) {
				setproductItemObj(_productItems[0]);
			}
		});
	};

	const deleteProductHandle = () => {
		setDialogOpen(true);
	};

	const handleDialogCance = () => {
		setDialogOpen(false);
	};

	return (
		<div
			id={'productItemContainer_' + (productItemObj?.Id ? productItemObj.Id : 'newProduct')}
			style={{ padding: '5px 15px 5px 15px' }}
		>
			<Card elevation={3}>
				<CardContent sx={{ padding: '15px 20px 0px 20px' }}>
					<Grid container direction={'row'}>
						{!disableDelete && productItemObj.Id && (
							<Grid xs={12} container item>
								<Grid item xs={1} padding={1}>
									<Chip
										icon={
											productItem?.type === 'Tag' ? (
												<SellOutlined />
											) : (
												<WorkOutlined />
											)
										}
										label={productItem?.type}
									/>
								</Grid>
								<Grid item xs={10}></Grid>
								<Grid item xs={1} style={{ textAlign: 'right' }}>
									{!isSystemTag && (
										<IconButton
											aria-label="delete"
											onClick={deleteProductHandle}
										>
											<Delete />
										</IconButton>
									)}
								</Grid>
							</Grid>
						)}
						<Grid item container spacing={2}>
							<Grid item xs={7}>
								<Card elevation={3}>
									<CardContent
										style={{
											height: '140px',
											overflowY: 'auto',
										}}
									>
										<ProductDtlsCmp
											ref={productDtlsRef}
											productItem={productItemObj}
											refresh={refreshItem}
											onNewSave={onNewSave}
										/>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={5}>
								<ClickAwayListener
									onClickAway={() => {
										//Set timeout to wait for an item if selected, opening the popup is also considered outside click
										setTimeout(
											() => productOwnerDtlsRef?.current?.close?.(),
											200,
										);
									}}
								>
									<Card elevation={3}>
										<CardContent
											style={{
												height: '140px',
												overflowY: 'auto',
											}}
										>
											<ProductOwnerCmp
												ref={productOwnerDtlsRef}
												productItem={productItemObj}
												refresh={refreshItem}
											/>
										</CardContent>
									</Card>
								</ClickAwayListener>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
				{/* <CardActions
					sx={{
						alignSelf: 'stretch',
						display: 'flex',
						justifyContent: 'flex-end',
						padding: '15px',
					}}
				>
					<FWButton
						size="small"
						onClick={deleteProductHandle}
						disabled={disableDelete || !productItemObj.Id}
					>
						Delete
					</FWButton>{' '}
					&nbsp; 
					<FWButton size="small" onClick={saveDtls}>
						Save
					</FWButton>{' '}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</CardActions> */}
			</Card>
			<Dialog
				open={dialogOpen}
				//onClose={handleClose}
				scroll="paper"
				maxWidth="md"
				fullWidth="true"
				height="900px"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<center>Delete Product</center>
				</DialogTitle>
				<DialogContent dividers={true}>
					<center className="slds-p-around_medium slds-text-heading_small">
						<p>Are you sure you want to delete this product?</p>
						{/* <p>{isOwgWideAccess(sharingItem) ?' When you stop sharing a file with a company you also stop sharing it with public groups and posts on public user profiles.' : ''}</p> */}
					</center>
				</DialogContent>
				<DialogActions>
					<FWButton
						key="cancel-clear-mapping"
						label="Cancel"
						variant="outlined"
						onClick={handleDialogCance}
					>
						Cancel
					</FWButton>
					<StatefulButton
						isProcessing={disableDelete}
						label="Delete"
						processingLabel="Deleting..."
						key={productItemObj.Id}
						onClick={() => {
							setDisableDelete(true);
							SalesforceCommonApi.deleteRecords([productItemObj.Id]).then(() => {
								if (refresh) {
									queryClient
										.invalidateQueries({
											queryKey: [QKEY_PRODUCTS],
										})
										.then(() => {
											refresh();
											setDialogOpen(false);
										});
								}
							});
						}}
					/>
					{/* <FWButton
						key="confirm-clear-mapping"
						label="Confirm"
						variant="contained"
						onClick={() => {
							setDisableDelete(true);
							SalesforceCommonApi.deleteRecords([productItemObj.Id]).then(() => {
								if (refresh) refresh();
								setDialogOpen(false);
							});
						}}
					>
						Delete
					</FWButton> */}
				</DialogActions>
			</Dialog>
		</div>
	);
};

const BLANK_PRODUCT = {
	productName: '',
	productDesc: '',
	owners: [],
};

const CompanyProfileProducts = function () {
	const [productItems, setProductItems] = useState([]);

	const {
		data: products = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [QKEY_PRODUCTS],
		queryFn: ConfigureDocumentService.getProducts,
	});

	useEffect(() => {
		if (isLoading) return;
		setProductItems([...(products || []), BLANK_PRODUCT]);
	}, [products, isLoading]);

	const onNewSaveHandle = (newProductItemId) => {
		setProductItems([...productItems, BLANK_PRODUCT]);
	};

	return (
		<div
			id="comapnyProfileProductContainer"
			style={{
				background: '#e5e8ec',
				height: 'calc(78vh)',
				overflow: 'auto',
				paddingTop: '13px',
			}}
		>
			<div style={{ paddingLeft: '16px' }}>
				<FWTitle title="Products / Tag We Offer" />
			</div>
			{isLoading && <LinearProgress color="primary" style={{ margin: '10px' }} />}
			{productItems.map((item) => (
				<ProductItemCmp
					key={item.Id}
					productItem={item}
					onNewSave={onNewSaveHandle}
					refresh={refetch}
				/>
			))}
		</div>
	);
};
CompanyProfileCmpNew.displayName = 'CompanyProfileCmpNew';
export default CompanyProfileCmpNew;
