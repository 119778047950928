import { COMPANY_NAME } from '../Constants';
import OpenAISearchApi from '../apis/OpenAISearchApi';
import Utils from '../utils/Utils';
import { getEncoding } from 'js-tiktoken';

var apiKey = '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';

// this is the old version of the code
/*var promptPrefix = {
	role: 'system',
	content:
		`You are ${COMPANY_NAME} Sales Assistant. ` +
		'You will respond with "Our Sales Assistant is unable to get you the right answer. You might want to refer to the internal and internet search results." when you do not have a confident response. ' +
		'You have access to previous chats and responses. ' +
		'You also have access to updated real-time news and information. ' +
		'You will keep conversation to a minimum and answer to the best of your abilities.',
};*/

var promptPrefix = {
	role: 'system',
	content:
		`You are ${COMPANY_NAME} Sales Assistant. ` +
		'You do not provide fictional scenarios as responses. ' +
		'You have access to previous chats and responses.',
};

var promptPrefixSalesPlay = {
	role: 'system',
	content:
		`You are ${COMPANY_NAME} Sales Assistant. ` +
		'You will respond with "Our Sales Assistant is unable to get you the right answer. You might want to refer to the internal and internet search results." when you do not have a confident resopnse. ' +
		'You have access to previous chats and responses. ' +
		'You also have access to updated real-time news and information. ' +
		'You will keep conversation to a minimum and answer to the best of your abilities.',
};
var isLocalhost = Utils.isLocalhost();

if (isLocalhost) {
	// localhost vars
}

const OpenAIService = {};

OpenAIService.getSearchResults = async (searchTerm, prevResponse) => {
	return await OpenAISearchApi.getSearchResults(searchTerm, prevResponse);
};

OpenAIService.getSearchResultsNew = async (prompts, prevPrompts) => {
	return await OpenAISearchApi.getSearchResultsNew(prompts, prevPrompts);
};

OpenAIService.getPromptPreix = (flow) => {
	return flow && flow === 'SALESPLAY' ? promptPrefixSalesPlay : promptPrefix;
};

OpenAIService.getApiKey = () => {
	return apiKey;
};

OpenAIService.getModel = (messages) => {
	var tokenLength = 0;

	// count tokens
	tokenLength = OpenAIService.countToken(messages);

	// limit at 4k just in case our tokenizer calculations are slightly off
	if (tokenLength > 16000) {
		// no model to use
		return null;
	} else if (tokenLength > 4000) {
		// use 16k
		return 'gpt-4o-mini';
	} else {
		// default
		return 'gpt-4o-mini';
	}
};

OpenAIService.countToken = (messages) => {
	var output = 0;
	var encoding;
	try {
		encoding = getEncoding('cl100k_base');
	} catch (err) {
		console.error('Error getting encoding: ', err);
	}
	if (encoding) {
		messages?.forEach((message) => {
			var tempEncode = encoding.encode(message?.content);
			output += tempEncode?.length ? tempEncode?.length : 0;
		});
	}
	return output;
};

OpenAIService.complete = async (prompts, config) => {
	return OpenAISearchApi.complete(prompts, config);
};
export default OpenAIService;
