import FileApi from '../apis/FileApi';

const FileService = {};

FileService.getFiles = async (recordId, objectType, filterStr) => {
	return await FileApi.getFiles(recordId, objectType, filterStr);
};

FileService.getFeaturewaveFiles = async (filterStr) => {
	return await FileApi.getFeaturewaveFiles(filterStr);
};

FileService.getFileContent = async (recordId) => {
	return await FileApi.getFileContent(recordId);
};

FileService.getFileTextContent = async (recordId) => {
	// esId: "ab3a53fa48484c99ad6c"
	// html: "<div><svg width=\"612\" height=\"792\" preserveas
	// index: 2
	// sfId: "068O40000018LEnIAM"
	// text: "MS-EXCEL LECTUR

	let contents = await FileApi.getFileTextContent(recordId);
	let output = { html: [], text: [] };
	for (let content of contents || []) {
		output.html.push(content.html);
		output.text.push(content.text);
	}
	return output;
};

FileService.getFileInfo = async (recordId) => {
	return await FileApi.getFileInfo(recordId);
};

export default FileService;
