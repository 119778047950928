import React from 'react';

import Utils from '../../utils/Utils';

import SalesforceService from '../../services/SalesforceService';

function CustomIcon({ name, size, width }) {
	if (!width) width = 30;
	if (size === 'small') width = 20;
	let basePath = require('../../assets/icons/' + name);
	if (!Utils.isLocalhost()) {
		basePath = basePath.replace('/static', SalesforceService.getResourceUrl() + '/static');
	}
	return <img width={width} alt={name} aria-hidden="true" src={basePath}></img>;
	//return <img width={width} alt={name} aria-hidden="true" src={require(Utils.isLocalhost() ? '../../assets/icons/'+name : SalesforceService.getResourcePrefix()+"/static/media/"+name)}></img>
}
CustomIcon.displayName = 'CustomIcon';
export default CustomIcon;
