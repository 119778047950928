import React, { useEffect, useMemo, useState } from 'react';
import AdminApi from '../../apis/AdminApi';
import {
	Alert,
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Snackbar,
} from '@mui/material';
import {
	AccountTreeOutlined,
	DeleteOutline,
	Inventory,
	SettingsOutlined,
} from '@mui/icons-material';
import { ACCOUNT_SUMMARY_CHATGPT_PROMPT, NAMESPACE_PREFIX } from '../../Constants';
import FWAutocomplete from '../mui/FWAutocomplete';
import ConfigureDocumentService from '../../services/ConfigureDocumentService';
import CustomSettingService from '../../services/CustomSettingService';
import AdminSettingsScheduleFieldCmp from './AdminSettingsScheduleFieldCmp';
import { FWButton } from '../mui/FWButtons';
import { StatefulButton } from '../common/UtilComponents';
import SmallLoadingCmp from '../SmallLoadingCmp';

export default function AdminSettingsScheduleSObjectCmp() {
	const [items, setItems] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		initialize();
	}, []);

	// const handleAddClick = (e, item) => {
	// 	AdminApi.createSObjectExport(undefined).then(() => initialize());
	// };

	const initialize = async (doNotRunDefaultSetup) => {
		// let items = [
		//     {name:'Account__c', label : 'Account', children : [
		//         {name:'Opportunity__r', label : 'Opportunities', children : [
		//             {name:'Activity__r', label : 'Activities'}
		//         ]}
		//     ] }, {name:'Contact__c', label : 'Contact'}
		// ]
		// for(let item of items){
		//     for(let childItem of (item.children||[])){
		//         childItem.parent = item;
		//         for(let grandChildItem of (childItem.children||[])){
		//             grandChildItem.parent = childItem;
		//         }
		//     }
		// }
		setIsLoading(true);
		AdminApi.getSObjectExports({}).then((exports) => {
			if (!exports || exports.length === 0) {
				//Check if default setup is executed or not
				if (AdminApi.isDefaultSObjectExportSetup()) {
					// Do nothing
				} else {
					//Create Default SObject Setup
					if (!doNotRunDefaultSetup) {
						createDefaultExports().then((exports) => {
							initializeWithExports(exports);
						});
					} else {
						initializeWithExports(exports);
					}
				}
			} else {
				initializeWithExports(exports);
			}
			setIsLoading(false);
		});
	};

	const createDefaultExports = () => {
		let exports = [];
		return new Promise((resolve, reject) => {
			let accExportObj = {};
			accExportObj[NAMESPACE_PREFIX + 'SObjectName__c'] = 'Account';
			accExportObj[NAMESPACE_PREFIX + 'SObjectLabel__c'] = 'Account';
			accExportObj[NAMESPACE_PREFIX + 'DefaultFields__c'] =
				'["Name","Rating","Type","AnnualRevenue","Industry","OwnerId"]';
			let accExpPromise = AdminApi.createSObjectExport(undefined, accExportObj);
			accExpPromise.then((accExpResponse) => {
				accExportObj.Id = accExpResponse[0].id;
				exports.push(accExportObj);

				let exportObjs = [];
				let opptyExportObj = {};
				opptyExportObj[NAMESPACE_PREFIX + 'SObjectName__c'] = 'Opportunity';
				opptyExportObj[NAMESPACE_PREFIX + 'SObjectLabel__c'] = 'Opportunity';
				opptyExportObj[NAMESPACE_PREFIX + 'RelationshipName__c'] = 'Opportunities';
				opptyExportObj[NAMESPACE_PREFIX + 'RelationshipLabel__c'] = 'Opportunities';
				opptyExportObj[NAMESPACE_PREFIX + 'DefaultFields__c'] =
					'["Amount","CloseDate","Name","NextStep","Type","Probability","StageName","IsWon"]';

				exportObjs.push(opptyExportObj);

				let taskExportObj = {};
				taskExportObj[NAMESPACE_PREFIX + 'SObjectName__c'] = 'Task';
				taskExportObj[NAMESPACE_PREFIX + 'SObjectLabel__c'] = 'Task';
				taskExportObj[NAMESPACE_PREFIX + 'RelationshipName__c'] = 'Tasks';
				taskExportObj[NAMESPACE_PREFIX + 'RelationshipLabel__c'] = 'Tasks';
				taskExportObj[NAMESPACE_PREFIX + 'DefaultFields__c'] =
					'["AccountId","OwnerId","IsClosed","Description","ActivityDate","Priority","Status","Subject","TaskSubtype"]';

				exportObjs.push(taskExportObj);

				let opptyExpPromise = AdminApi.createSObjectExports(accExportObj.Id, exportObjs);
				opptyExpPromise.then((opptyExpResponse) => {
					opptyExportObj.Id = opptyExpResponse[0].id;
					exports.push(opptyExportObj);
					resolve(exports);
				});
			});
		});
	};
	const initializeWithExports = (exports) => {
		let _items = [];
		for (let exportItem of exports) {
			let parentId = exportItem[NAMESPACE_PREFIX + 'ParentSObjectExport__c'];
			if (parentId) {
				let parentItem = _items.find((item) => {
					return item.Id === parentId;
				});
				if (!parentItem) {
					for (let _item of _items) {
						if (_item.children) {
							parentItem = _item.children.find((item) => {
								return item.Id === parentId;
							});
							if (parentItem) break;
						}
					}
				}
				if (parentItem) {
					if (!parentItem.children) parentItem.children = [];
					parentItem.children.push(exportItem);
				}
			} else {
				_items.push(exportItem);
			}
		}
		for (let _item of _items) {
			for (let childItem of _item.children || []) {
				childItem.parent = _item;
				for (let grandChildItem of childItem.children || []) {
					grandChildItem.parent = childItem;
				}
			}
		}
		setItems(_items);
	};

	return (
		<div id="sObjectSelectionContainer">
			<Grid style={{ height: '500px' }}>
				{isLoading && (
					<div style={{ width: '400px', textAlign: 'center', paddingTop: '100px' }}>
						<SmallLoadingCmp />
					</div>
				)}
				{!isLoading &&
					items.map((item) => (
						<SObjectItem1
							key={item.Id || item.id}
							item={item}
							level={0}
							refresh={initialize}
						/>
					))}
				{/* <Grid container direction={'row'} style={{ minWidth: '500px' }}>
					<Grid item sx={{ minWidth: '50px' }}></Grid>
					<Grid item sx={{ marginLeft: '5px', marginRight: '5px' }}>
						<Grid container direction={'row'}>
							<Grid item style={{ paddingTop: '15px' }}>
								<Button
									variant="outlined"
									onClick={(e) => handleAddClick(e)}
									startIcon={<AddBoxOutlined />}
								>
									Add SObject
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}
			</Grid>
		</div>
	);
}

function SObjectItem1({ item, level = 0, refresh }) {
	const [sObjectSummaryPrompt, setSSobjectSummaryPrompt] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const [successMessage, setSuccessMessage] = useState();
	const [showFieldSelection, setShowFieldSelection] = useState(false);
	const [currentItem, setCurrentItem] = useState();
	const handleAddClick = (e, item) => {
		AdminApi.createSObjectExport(item.Id).then(() => refresh?.());
	};
	const handleDeleteClick = (e, item) => {
		ConfigureDocumentService.deleteTag([item.Id]).then(() =>
			refresh?.({ doNotRunDefaultSetup: true }),
		);
	};
	const handleFieldClick = (e, item) => {
		setCurrentItem(item);
		setShowFieldSelection(true);
	};
	const handleErrorClose = () => {
		setErrorMessage(null);
	};
	const handleSuccessClose = () => {
		setSuccessMessage(null);
	};
	// const updateSObjectSummaryPrompt = (e) => {
	// 	setSSobjectSummaryPrompt(e?.target?.value);
	// };
	// const savePrompt = async (e) => {
	// 	try {
	// 		var isSuccess = false;
	// 		const customSetting = await CustomSettingService.getCustomSettings();
	// 		customSetting.AccountSummaryPrompt__c = sObjectSummaryPrompt;
	// 		const result = await CustomSettingService.updateCustomSettings(
	// 			new SFDCConfiguration(customSetting),
	// 		);
	// 		if (result?.graphs?.length > 0) {
	// 			if (result.graphs[0].isSuccessful === true) {
	// 				// success
	// 				isSuccess = true;
	// 				setSuccessMessage('Summary prompt saved sccuessfully');
	// 			}
	// 		}
	// 		if (isSuccess === false) {
	// 			// surface error
	// 			setErrorMessage(
	// 				'Something went wrong while saving the summary prompt, please try again later.',
	// 			);
	// 		}
	// 	} catch (err) {
	// 		setErrorMessage(
	// 			'Something went wrong while saving the summary prompt : ' + err?.message,
	// 		);
	// 	}
	// };
	const fetchCustomSettingData = async () => {
		const customSetting = await CustomSettingService.getCustomSettings();
		setSSobjectSummaryPrompt(
			customSetting?.AccountSummaryPrompt__c || ACCOUNT_SUMMARY_CHATGPT_PROMPT,
		);
	};

	// add hook to update sObjectSummaryPrompt
	useEffect(() => {
		if (level !== 0) return;
		if (!item[NAMESPACE_PREFIX + 'SObjectName__c']) return;
		if (item[NAMESPACE_PREFIX + 'SObjectName__c'] !== 'Account') return;
		// get custom settings first
		if (!sObjectSummaryPrompt) fetchCustomSettingData();
	}, [item, level, sObjectSummaryPrompt]);
	const [finalFields, setFinalFields] = useState([]);
	const onFieldSelectionChange = (fields) => {
		setFinalFields(fields);
	};
	const [isSaving, setIsSaving] = useState(false);
	const [showFieldSaveMessage, setShowFieldSaveMessage] = useState(false);
	const saveFields = () => {
		setIsSaving(true);
		AdminApi.saveSObjectFields({
			recordId: currentItem.Id,
			fields: finalFields,
		}).then((response) => {
			setIsSaving(false);
			// let _currentItem = Object.assign({}, currentItem);
			// _currentItem[NAMESPACE_PREFIX + 'Fields__c'] = JSON.stringify(finalFields);
			// setCurrentItem(_currentItem);
			// AdminApi.getSObjectExports({
			// 	sObjectApiName: currentItem[NAMESPACE_PREFIX + 'SObjectName__c'],
			// }).then((exports) => {
			// 	if (exports && exports.length > 0) {
			// 		setCurrentItem(exports[0]);
			// 	}
			// });
			refresh?.({ doNotRunDefaultSetup: true });
			setShowFieldSaveMessage(true);
			setShowFieldSelection(false);
		});
	};

	return (
		<>
			<Snackbar
				open={showFieldSaveMessage}
				autoHideDuration={5000}
				onClose={() => setShowFieldSaveMessage(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					severity="info"
					sx={{ width: '100%' }}
					onClose={() => setShowFieldSaveMessage(false)}
				>
					Fields are saved successfully.
				</Alert>
			</Snackbar>
			{showFieldSelection && (
				<div>
					<Dialog
						//onClose={handleClose}
						open={true}
						scroll="paper"
						maxWidth="lg"
						fullWidth="true"
						height="900px"
						aria-labelledby="scroll-dialog-title"
						aria-describedby="scroll-dialog-description"
					>
						<DialogTitle id="scroll-dialog-title">
							<center>Select Fields</center>
						</DialogTitle>
						<DialogContent dividers={true}>
							<AdminSettingsScheduleFieldCmp
								onFieldSelectionChange={onFieldSelectionChange}
								recordId={currentItem.Id}
								sobjectApiName={currentItem[NAMESPACE_PREFIX + 'SObjectName__c']}
								sObjectLabel={currentItem[NAMESPACE_PREFIX + 'SObjectLabel__c']}
								fields={
									currentItem[NAMESPACE_PREFIX + 'Fields__c']
										? currentItem[NAMESPACE_PREFIX + 'Fields__c']
										: undefined
								}
								defaultFields={
									currentItem[NAMESPACE_PREFIX + 'DefaultFields__c']
										? currentItem[NAMESPACE_PREFIX + 'DefaultFields__c']
										: undefined
								}
								refresh={() => {
									refresh();
								}}
							/>
						</DialogContent>
						<DialogActions>
							<FWButton
								key="cancel-clear-mapping"
								label="Cancel"
								variant="outlined"
								onClick={() => {
									setShowFieldSelection(false);
								}}
							>
								Cancel
							</FWButton>
							<StatefulButton
								isProcessing={isSaving}
								label="Save"
								processingLabel="Saving..."
								key={currentItem.Id}
								onClick={saveFields}
							/>
							{/* <FWButton
								key="confirm-clear-mapping"
								label="Confirm"
								variant="contained"
								onClick={saveProductDtls}
							>
								Save
							</FWButton> */}
						</DialogActions>
					</Dialog>
				</div>
			)}
			<Snackbar
				open={errorMessage}
				onClose={handleErrorClose}
				autoHideDuration={5000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="error" onClose={handleErrorClose}>
					{errorMessage}
				</Alert>
			</Snackbar>
			<Snackbar
				open={successMessage}
				onClose={handleSuccessClose}
				autoHideDuration={5000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert severity="success" onClose={handleSuccessClose}>
					{successMessage}
				</Alert>
			</Snackbar>
			<Grid xs={12}>
				<Grid container direction={'row'} justifyContent={'flex-start'}>
					<Grid
						item
						style={{
							background: 'white',
							width: (level + 1) * 50 + 'px',
						}}
					>
						{/* {(12-((level+1)+1))} */}
					</Grid>
					<Grid item style={{ background: 'white' }}>
						<Grid
							container
							direction={'row'}
							justifyContent="flex-start"
							alignItems="center"
							spacing={1}
						>
							<Grid item>
								<Paper
									elevation={3 - level}
									style={{
										width: '500px',
										height: '50px',
										padding: '5px',
										margin: '5px',
										background: 'white',
									}}
								>
									{item[NAMESPACE_PREFIX + 'SObjectName__c'] && (
										<Grid
											container
											direction={'row'}
											alignContent={'center'}
											alignItems={'center'}
											justifyContent={'center'}
											justifyItems={'center'}
										>
											<Grid item xs={1}>
												<Avatar
													sx={{
														bgcolor: '#bdbdbd',
														width: 40,
														height: 40,
													}}
													variant="rounded"
												>
													<Inventory />
												</Avatar>
											</Grid>
											<Grid item xs={3}>
												<Grid item className="main-title">
													&nbsp;
													{item[NAMESPACE_PREFIX + 'SObjectName__c']}
												</Grid>
												<Grid item className="sub-title">
													&nbsp;
													{item[NAMESPACE_PREFIX + 'RelationshipName__c']
														? item[
																NAMESPACE_PREFIX +
																	'RelationshipName__c'
															]
														: 'Primary SObject'}
												</Grid>
											</Grid>
											<Grid
												item
												xs={8}
												container
												spacing={1}
												direction={'row'}
												justifyContent={'right'}
											>
												<Grid item>
													{level <= 1 &&
														item[
															NAMESPACE_PREFIX + 'SObjectName__c'
														] && (
															// <IconButton
															// 	aria-label="add"
															// 	size="small"
															// 	color="primary"
															// 	onClick={(e) =>
															// 		handleAddClick(e, item)
															// 	}
															// >
															// 	<AddBoxOutlined fontSize="inherit" />
															// </IconButton>
															<Button
																size="small"
																variant="outlined"
																startIcon={<AccountTreeOutlined />}
																onClick={(e) =>
																	handleAddClick(e, item)
																}
															>
																Add
															</Button>
														)}
												</Grid>

												<Grid item>
													{item[NAMESPACE_PREFIX + 'SObjectName__c'] && (
														// <IconButton
														// 	aria-label="fields"
														// 	size="small"
														// 	title="Select Fields"
														// 	color="primary"
														// 	onClick={(e) =>
														// 		handleFieldClick(e, item)
														// 	}
														// >
														// 	<SettingsOutlined fontSize="inherit" />
														// </IconButton>
														<Button
															size="small"
															variant="outlined"
															startIcon={<SettingsOutlined />}
															onClick={(e) =>
																handleFieldClick(e, item)
															}
														>
															Fields
														</Button>
													)}
												</Grid>
												<Grid item>
													{item[NAMESPACE_PREFIX + 'SObjectName__c'] && (
														// <IconButton
														// 	aria-label="delete"
														// 	size="small"
														// 	color="primary"
														// 	onClick={(e) =>
														// 		handleDeleteClick(e, item)
														// 	}
														// >
														// 	<DeleteOutline fontSize="inherit" />
														// </IconButton>
														<Button
															size="small"
															color="error"
															variant="outlined"
															startIcon={<DeleteOutline />}
															onClick={(e) =>
																handleDeleteClick(e, item)
															}
														>
															Delete
														</Button>
													)}
												</Grid>
											</Grid>
										</Grid>
									)}
									{!item[NAMESPACE_PREFIX + 'SObjectName__c'] && (
										<Grid xs={12} container>
											{' '}
											<Grid item xs={9} style={{ paddingBottom: '0px' }}>
												<SObjectAutoSelect
													item={item}
													refresh={refresh}
													isRelationship={level > 0}
													sObject={
														level > 0 &&
														item.parent[
															NAMESPACE_PREFIX + 'SObjectName__c'
														]
													}
												/>
											</Grid>
											<Grid item xs={3} style={{ paddingTop: '8px' }}>
												{/* <IconButton
													aria-label="delete"
													size="small"
													color="primary"
													onClick={(e) => handleDeleteClick(e, item)}
												>
													<DeleteOutline fontSize="inherit" />
												</IconButton> */}
												<Button
													size="small"
													variant="outlined"
													startIcon={<DeleteOutline />}
													onClick={(e) => handleDeleteClick(e, item)}
												>
													Delete
												</Button>
											</Grid>
										</Grid>
									)}
								</Paper>
							</Grid>
							{/* {level === 0 &&
							item[NAMESPACE_PREFIX + 'SObjectName__c'] === 'Account' ? (
								<Grid item sx={{ margin: '5px' }}>
									<Grid container direction={'row'} spacing={1}>
										<Grid
											item
											sx={{
												minWidth: '400px',
												width: 'calc(100vw - 590px)',
											}}
										>
											<TextField
												id={'fw-SummaryPrompt' + level}
												label="Summary Prompt"
												size="small"
												value={sObjectSummaryPrompt || ''}
												onChange={updateSObjectSummaryPrompt}
												fullWidth
											/>
										</Grid>
										<Grid item>
											<IconButton
												color="primary"
												title="Save Prompt"
												onClick={savePrompt}
											>
												<SaveIcon fontSize="inherit" />
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							) : (
								<></>
							)} */}
						</Grid>

						{/* {item[NAMESPACE_PREFIX+'SObjectName__c']} - {item[NAMESPACE_PREFIX+'RelationshipName__c']}
                {
                   !item[NAMESPACE_PREFIX+'SObjectName__c'] && <SObjectAutoSelect item={item} refresh={refresh} isRelationship={level>0} sObject={level>0 && item.parent[NAMESPACE_PREFIX+'SObjectName__c']} />
                }
                {
                    level<=1 && item[NAMESPACE_PREFIX+'SObjectName__c'] && <IconButton aria-label="add" size="small" onClick={e=>handleAddClick(e,item)}>
                    <AddBoxOutlined fontSize="inherit" />
               </IconButton>
                }
                {
                     item[NAMESPACE_PREFIX+'SObjectName__c'] && <IconButton aria-label="delete" size="small" onClick={e=>handleAddClick(e,item)}>
                    <DeleteOutline fontSize="inherit" />
               </IconButton>
                } */}
					</Grid>
				</Grid>
			</Grid>
			{item.children &&
				item.children.length > 0 &&
				item.children
					.sort((a, b) => a.Id - b.Id)
					.map((childItem) => (
						<SObjectItem1
							key={childItem.Id || childItem.id}
							item={childItem}
							level={level + 1}
							refresh={refresh}
						/>
					))}
		</>
	);
}

function SObjectAutoSelect({ isRelationship = false, sObject = undefined, item, refresh }) {
	const dataPromise = useMemo(
		() => (isRelationship ? AdminApi.getRelationships(sObject) : AdminApi.getSObjects()),
		[isRelationship, sObject],
	);

	return (
		<Grid container>
			<Grid>
				<FWAutocomplete
					label={isRelationship ? 'Relationship' : 'SObject'}
					disableAdd={false}
					allowToAddNewValues={false}
					dataPromise={dataPromise}
					provideSaveButton={true}
					textVariant="filled"
					saveFunction={(recordIds, value) => {
						return new Promise((resolve, reject) => {
							if (value) {
								let selectedSObject = value[0];
								let sObjectFields = {};
								if (isRelationship) {
									sObjectFields[NAMESPACE_PREFIX + 'RelationshipName__c'] =
										selectedSObject.Id;
									sObjectFields[NAMESPACE_PREFIX + 'RelationshipLabel__c'] =
										selectedSObject.text;
									sObjectFields[NAMESPACE_PREFIX + 'SObjectName__c'] =
										selectedSObject.object;
									sObjectFields[NAMESPACE_PREFIX + 'SObjectLabel__c'] =
										selectedSObject.object;
								} else {
									sObjectFields[NAMESPACE_PREFIX + 'SObjectName__c'] =
										selectedSObject.Id;
									sObjectFields[NAMESPACE_PREFIX + 'SObjectLabel__c'] =
										selectedSObject.text;
								}
								AdminApi.updateSObjectExport(item.Id, sObjectFields).then(() => {
									if (refresh) refresh();
									resolve();
								});
							}
						});
					}}
					multiple={false}
					//disabledValues={existingGroups}
					width={'275px'}
					//ref={autoSelectRef}
				/>
				<br />
			</Grid>
		</Grid>
	);
}
