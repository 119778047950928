import React from 'react';
import { Card, CardActions, CardContent, Grid, styled } from '@mui/material';
import { CONFIG_GPT_PROMPTS, NAMESPACE_PREFIX } from '../Constants';
import { ContentCopyTwoTone, DeleteOutlineTwoTone, ModeEditTwoTone } from '@mui/icons-material';
import { FWIconButton } from '../components/mui/FWButtons';
import { Box } from '@mui/system';
import { RecordAnchor } from '../components/common/UtilComponents';
import NoSearchResultCmp from '../components/common/NoSearchResultCmp';
import SalesforceCommonApi from '../apis/SalesforceCommonApi';
import Utils from '../utils/Utils';

const SalesPlayAdminService = {};

SalesPlayAdminService.getColumns = ({
	relatedListType,
	objectDescribe,
	setCurrentRecordId,
	setCurrentRecord,
	setShowDelete,
	setShowClone,
	setShowEdit,
	onClickRow,
}) => {
	let columns = [];
	const nameComparator = (v1, v2) => {
		return v1 - v2;
	};

	let keyColumn = {
		field: 'Item',
		headerName: objectDescribe?.pluralLabel,
		flex: 1,
		headerClassName: 'salesplay-title',
		sortComparator: nameComparator,
		sortable: false,
	};

	//if (true) {
	//Sales Play Columns
	keyColumn.renderCell = (params) => {
		return (
			//     <div>
			//         {params.row.Id} -
			//         {params.row.Name} -
			//         {params.row[NAMESPACE_PREFIX+'Description__c']}
			//    </div>
			<div style={{ padding: '3px', width: '100%' }} className="salesplay-item-container">
				<Card style={{ width: '100%' }} className="salesplay-item-card">
					<Grid container direction={'row'} className="salesplay-item-grid">
						<Grid
							item
							xs={10}
							style={{ overflow: 'hidden' }}
							className="salesplay-item-grid2"
						>
							<CardContent
								style={{ marginTop: '8px' }}
								className="salesplay-item-cardcontent"
							>
								{!relatedListType && (
									<Grid container direction={'column'}>
										<Grid item className="record-lookup">
											{/* <Link
                                        color="primary"
                                        underline="hover"
                                        variant="soft"
                                        href={"/"+ params.row.Id}
                                        target="_blank"
                                        className="salesplay-title"
                                      >{params.row.Name}</Link> */}
											{/* <Typography className="hover-pointer record-lookup" style={{cursor : 'pointer'} } color="rgb(0, 114, 229)">{params.row.Name}</Typography> */}
											{/* <RecordLink label={params.row.Name} recordId={params.row.Id} showPopOver={true} 
                                        popOverBody={
                                          <RecordCardCmp objectDescribe={objectDescribe} record={params.row} />
                                        } 
                                      /> */}
											<RecordAnchor
												label={params.row.Name}
												recordId={params.row.Id}
												onClickRow={onClickRow}
											/>
										</Grid>
										<Grid item className="overflow-text">
											<div
												dangerouslySetInnerHTML={{
													__html: params.row[
														NAMESPACE_PREFIX + 'Description__c'
													],
												}}
											/>
										</Grid>
									</Grid>
								)}
								{relatedListType && (
									<Grid container direction={'column'}>
										<Grid item>
											{/* <Link
                                    color="primary"
                                    underline="hover"
                                    variant="soft"
                                    href={"/"+ params.row[objectDescribe.relationshipName]?.Id}
                                    target="_blank"
                                    className="salesplay-title"
                                  >{params.row[objectDescribe.relationshipName]?.Name}</Link> */}
											{/* <Typography className="hover-pointer record-lookup" style={{cursor : 'pointer'} } color="rgb(0, 114, 229)">{params.row[objectDescribe.relationshipName]?.Name}</Typography> */}
											{/* <RecordLink label={params.row[objectDescribe.relationshipName]?.Name} recordId={params.row[objectDescribe.relationshipName]?.Id} showPopOver={true} 
                                      //popOverBody={<RecordCardCmp objectDescribe={objectDescribe} record={params.row[objectDescribe.relationshipName]} />}
                                   /> */}
											<RecordAnchor
												style={{
													cursor: 'pointer',
												}}
												label={
													params.row[objectDescribe.relationshipName]
														?.Name
												}
												recordId={
													params.row[objectDescribe.relationshipName]?.Id
												}
												onClickRow={() => {
													//if(!objectDescribe.hasFile || objectDescribe.hasMultipleFile){
													setCurrentRecordId(params.row.Id);
													setShowEdit(true);
													//}
												}}
											/>
										</Grid>
									</Grid>
								)}
							</CardContent>
						</Grid>
						<Grid item xs={2}>
							<CardActions
								disableSpacing
								sx={{
									alignSelf: 'stretch',
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'flex-start',
									// 👇 Edit padding to further adjust position
									p: 0,
								}}
								style={{ padding: '10px' }}
							>
								{
									//(!objectDescribe.hasFile || objectDescribe.hasMultipleFile) &&
									<FWIconButton
										aria-label="edit"
										size="large"
										onClick={() => {
											setCurrentRecordId(params.row.Id);
											setShowEdit(true);
										}}
									>
										<ModeEditTwoTone color="primary" fontSize="inherit" />
									</FWIconButton>
								}
								{!relatedListType && (
									<FWIconButton
										aria-label="clone"
										size="large"
										onClick={() => {
											setCurrentRecordId(params.row.Id);
											setShowClone(true);
										}}
									>
										<ContentCopyTwoTone color="primary" fontSize="inherit" />
									</FWIconButton>
								)}

								<FWIconButton
									aria-label="delete"
									size="large"
									onClick={() => {
										if (setCurrentRecord) setCurrentRecord(params.row);
										setCurrentRecordId(params.row.Id);
										setShowDelete(true);
									}}
								>
									<DeleteOutlineTwoTone color="primary" fontSize="inherit" />
								</FWIconButton>
							</CardActions>
						</Grid>
					</Grid>
				</Card>
			</div>
		);
	};
	//}
	// else{
	//     keyColumn.renderCell = (params)=>{
	//         return(
	//             <div>
	//                 {
	//                      <div>
	//                         {params.row.Id} - {params.row.Name} - {params.row[objectDescribe.relatedLookupApiName]} - {params.row[objectDescribe.relationshipName]?.Name}
	//                     </div>
	//                 }
	//             </div>
	//         )
	//     }
	// }
	// let deleteAction = {
	// 	field: 'Actions',
	// 	headerName: 'Actions',
	// 	flex: 1,
	// 	renderCell: (params) => (
	// 		<div>
	// 			<IconButton aria-label="delete" size="small" onClick={() => {
	// 						setCurrentRecordId(params.row.Id);
	// 						setShowDelete(true);
	// 					}}>
	// 				<DeleteOutlineTwoTone
	// 					color="primary"
	// 					fontSize="inherit"
	// 				/>
	// 			</IconButton>
	// 		</div>
	// 	),
	// };

	columns.push(keyColumn);
	//columns.push(deleteAction);

	return columns;
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	'& .ant-empty-img-1': {
		fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
	},
	'& .ant-empty-img-2': {
		fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
	},
	'& .ant-empty-img-3': {
		fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
	},
	'& .ant-empty-img-4': {
		fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
	},
	'& .ant-empty-img-5': {
		fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
		fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
	},
}));
SalesPlayAdminService.getNoRowOverlayCmp = () => {
	return function CustomNoRowsOverlay() {
		return (
			<StyledGridOverlay style={{ minHeight: '100px' }}>
				<NoSearchResultCmp />
				<Box sx={{ mt: 1 }}>
					No records found. Use <b>Search bar</b> for existing ones & <b>Create</b> button
					to create new one.
				</Box>
			</StyledGridOverlay>
		);
	};
};

SalesPlayAdminService.getLogo = () => {
	return (
		<svg
			width="275"
			height="59"
			viewBox="0 0 275 59"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_43_307)">
				<path
					d="M21.16 22.6999V36.0799L25.59 28.7499C26 28.0799 26.72 27.6699 27.51 27.6699H38.2L35.17 32.1499H28.77L20.84 45.2799C20.43 45.9699 19.69 46.3599 18.92 46.3599C18.72 46.3599 18.52 46.3299 18.32 46.2699C17.35 45.9999 16.68 45.1199 16.68 44.1199V21.9299C16.68 21.4299 16.85 20.9499 17.15 20.5599L22.6 13.5099C23.03 12.9599 23.68 12.6399 24.37 12.6399H42.31L39.28 17.1199H25.47L21.16 22.6899V22.6999Z"
					fill="white"
				/>
				<path
					d="M29.5 59C13.23 59 0 45.76 0 29.5C0 13.24 13.23 0 29.5 0C45.77 0 59 13.23 59 29.5C59 45.77 45.76 59 29.5 59ZM29.5 2.66C14.7 2.66 2.65 14.7 2.65 29.5C2.65 44.3 14.69 56.35 29.49 56.35C44.29 56.35 56.34 44.31 56.34 29.5C56.34 14.69 44.3 2.65 29.5 2.65V2.66Z"
					fill="white"
				/>
				<path
					d="M89.72 30.4101H78.69V36.8501H75V23.9801C75 22.9601 75.36 22.0901 76.07 21.3801C76.78 20.6701 77.65 20.3101 78.67 20.3101H89.72V23.9801H79.05C78.99 23.9801 78.91 24.0101 78.81 24.0601C78.72 24.1201 78.67 24.2101 78.67 24.3401V26.7301H89.72V30.4001V30.4101Z"
					fill="white"
				/>
				<path
					d="M108.48 36.8501H95.61C94.6 36.8501 93.74 36.4901 93.01 35.7801C92.28 35.0601 91.92 34.2001 91.92 33.1801V23.9901C91.92 22.9701 92.28 22.1001 92.99 21.3901C93.71 20.6801 94.57 20.3201 95.59 20.3201H108.47V23.9901H95.97C95.9 23.9901 95.83 24.0201 95.73 24.0701C95.64 24.1301 95.59 24.2201 95.59 24.3501V26.7401H108.47V30.4101H95.59V32.8001C95.59 32.8701 95.62 32.9401 95.68 33.0401C95.74 33.1301 95.83 33.1801 95.96 33.1801H108.48V36.8501V36.8501Z"
					fill="white"
				/>
				<path
					d="M128.8 36.85H115.47L117.19 33.18H123.06L119.54 25.67C119.48 25.53 119.37 25.46 119.21 25.46C119.05 25.46 118.93 25.53 118.86 25.67L113.64 36.85H109.59L116.65 21.71C117.16 20.6 118.01 20.04 119.19 20.04C119.76 20.04 120.27 20.19 120.69 20.48C121.11 20.77 121.46 21.18 121.71 21.7L128.79 36.84L128.8 36.85Z"
					fill="white"
				/>
				<path
					d="M141.44 23.9801H124.9V20.3101H141.44V23.9801V23.9801ZM135.02 36.8501H131.35V25.8201H135.02V36.8501Z"
					fill="white"
				/>
				<path
					d="M159.6 32.4201C159.6 33.6901 159.12 34.7201 158.17 35.5001C157.39 36.1301 156.29 36.5901 154.86 36.8801C153.8 37.1001 152.62 37.2001 151.32 37.2001C149.94 37.2001 148.75 37.1101 147.74 36.9101C146.32 36.6401 145.23 36.1901 144.47 35.5701C143.52 34.7901 143.04 33.7401 143.04 32.4201V20.3201H146.71V32.0601C146.71 32.5101 147.12 32.8501 147.94 33.0901C148.76 33.3301 149.88 33.4501 151.29 33.4501C152.7 33.4501 153.86 33.3301 154.67 33.1001C155.49 32.8601 155.89 32.5201 155.89 32.0601V20.3201H159.58V32.4201H159.6Z"
					fill="white"
				/>
				<path
					d="M179.48 36.8501H175.81L174.89 30.7901C174.84 30.5401 174.71 30.4101 174.51 30.4101H166.06V36.8501H162.37V26.7401H174.49C174.59 26.7401 174.69 26.7001 174.77 26.6301C174.85 26.5601 174.89 26.4701 174.89 26.3701V24.3401C174.89 24.1001 174.76 23.9801 174.51 23.9801H162.37V20.3101H174.87C175.88 20.3101 176.74 20.6701 177.47 21.3801C178.2 22.1001 178.56 22.9601 178.56 23.9801V26.7301C178.56 27.0901 178.5 27.4401 178.39 27.7801C178.28 28.1201 178.1 28.3901 177.86 28.5601C178.05 28.7201 178.21 29.0101 178.34 29.4001C178.43 29.6901 178.5 30.0201 178.55 30.3901L179.49 36.8301L179.48 36.8501Z"
					fill="white"
				/>
				<path
					d="M198.43 36.8501H185.56C184.55 36.8501 183.69 36.4901 182.96 35.7801C182.23 35.0601 181.87 34.2001 181.87 33.1801V23.9901C181.87 22.9701 182.23 22.1001 182.94 21.3901C183.65 20.6801 184.52 20.3201 185.54 20.3201H198.43V23.9901H185.93C185.86 23.9901 185.79 24.0201 185.69 24.0701C185.59 24.1301 185.54 24.2201 185.54 24.3501V26.7401H198.43V30.4101H185.54V32.8001C185.54 32.8701 185.57 32.9401 185.63 33.0401C185.69 33.1301 185.78 33.1801 185.9 33.1801H198.43V36.8501V36.8501Z"
					fill="white"
				/>
				<path
					d="M227.95 20.3101L220.9 35.4501C220.39 36.5501 219.54 37.0901 218.36 37.0901C217.18 37.0901 216.36 36.5401 215.84 35.4501L213.78 31.0301L211.71 35.4501C211.19 36.5501 210.35 37.0901 209.19 37.0901C208.03 37.0901 207.16 36.5401 206.65 35.4501L199.57 20.3101H203.6L208.82 31.4801C208.88 31.6301 209 31.7101 209.17 31.7101C209.31 31.7101 209.42 31.6301 209.49 31.4801L211.75 26.6801L208.75 20.3101H212.8L213.76 22.3401L214.72 20.3101H218.77L215.79 26.6801L218.03 31.4801C218.11 31.6301 218.22 31.7101 218.36 31.7101C218.51 31.7101 218.63 31.6301 218.71 31.4801L223.93 20.3101H227.98H227.95Z"
					fill="white"
				/>
				<path
					d="M242.44 36.85H229.12L230.84 33.18H236.71L233.19 25.67C233.13 25.53 233.02 25.46 232.86 25.46C232.7 25.46 232.58 25.53 232.51 25.67L227.29 36.85H223.23L230.28 21.71C230.79 20.6 231.64 20.04 232.82 20.04C233.39 20.04 233.89 20.19 234.32 20.48C234.75 20.77 235.09 21.18 235.34 21.7L242.41 36.84L242.44 36.85Z"
					fill="white"
				/>
				<path
					d="M257.29 20.3101L250.21 35.4501C249.96 35.9801 249.59 36.3901 249.12 36.6701C248.65 36.9501 248.11 37.0901 247.49 37.0901C246.2 37.0901 245.3 36.5401 244.77 35.4501L237.7 20.3101H241.93L247.15 31.4801C247.23 31.6301 247.33 31.7101 247.48 31.7101C247.63 31.7101 247.74 31.6301 247.81 31.4801L253.04 20.3101H257.28H257.29Z"
					fill="white"
				/>
				<path
					d="M275 36.8501H262.13C261.12 36.8501 260.26 36.4901 259.53 35.7801C258.8 35.0601 258.44 34.2001 258.44 33.1801V23.9901C258.44 22.9701 258.8 22.1001 259.51 21.3901C260.22 20.6801 261.09 20.3201 262.11 20.3201H275V23.9901H262.5C262.44 23.9901 262.36 24.0201 262.26 24.0701C262.17 24.1301 262.11 24.2201 262.11 24.3501V26.7401H275V30.4101H262.11V32.8001C262.11 32.8701 262.14 32.9401 262.19 33.0401C262.25 33.1301 262.34 33.1801 262.47 33.1801H274.99V36.8501H275Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_43_307">
					<rect width="275" height="59" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

SalesPlayAdminService.getGPTPrompt = ({ recordDevName }) => {
	return new Promise((resolve, reject) => {
		try {
			SalesforceCommonApi.getMetadataRecord({
				recordDevName: recordDevName,
				objectApiName: NAMESPACE_PREFIX + 'GPTPrompt__mdt',
				fields: [
					'MasterLabel',
					'DeveloperName',
					NAMESPACE_PREFIX + 'Fields__c',
					NAMESPACE_PREFIX + 'Prompt__c',
				],
			}).then((mdtRecord) => {
				if (mdtRecord) {
					resolve(mdtRecord);
				} else {
					resolve(CONFIG_GPT_PROMPTS[recordDevName]);
				}
			});
		} catch (e) {
			console.error('Error fetching GPT Prompt metadata. ', e);
			resolve(CONFIG_GPT_PROMPTS[recordDevName]);
		}
	});
};

SalesPlayAdminService.getFileSalesplayInfo = ({ fwFileIds }) => {
	return new Promise((resolve, reject) => {
		let fileInfoMap = {};
		let fileIdStr = Utils.convertToWhereCondition(fwFileIds);
		let query = `SELECT Id, Name, fwave__SalesPlaybook__c, fwave__SalesPlaybook__r.Name, fwave__File__c FROM fwave__SpbFiles__c WHERE fwave__File__c IN (${fileIdStr})`;
		SalesforceCommonApi.query({ query: query }).then((records) => {
			for (let spbFileSO of records || []) {
				let fwFileId = spbFileSO[NAMESPACE_PREFIX + 'File__c'];
				fileInfoMap[fwFileId] = {
					salesPlayName: Utils.getRelatedFieldValue(
						spbFileSO,
						`${NAMESPACE_PREFIX}SalesPlaybook__r.Name`,
					),
				};
			}
			resolve(fileInfoMap);
		});
	});
};

export default SalesPlayAdminService;
