import SolutionApi from '../apis/SolutionApi';

const SolutionService = {};

SolutionService.findSolution = async (opptyId) => {
	return await SolutionApi.findSolution(opptyId);
};
SolutionService.findRelatedAccounts = async (featureId) => {
	return await SolutionApi.findRelatedAccounts(featureId);
};
SolutionService.updateSolution = async (sol) => {
	return await SolutionApi.updateSolution(sol);
};

export default SolutionService;
