import AdminApi from '../apis/AdminApi';
import CacheUtils from '../utils/CacheUtils';

const AdminService = {};

AdminService.getCollaborationGroups = () => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			AdminApi.getCollaborationGroups().then((_groups) => {
				let groupOptions = _groups?.map((groupItem) => {
					return {
						text: groupItem.Name,
						Id: groupItem.Id,
					};
				});
				resolve(groupOptions);
			});
		}, 3000);
	});
};

AdminService.getDocumentLinks = (contentDocumentIds) => {
	return AdminApi.getDocumentLinks(contentDocumentIds);
	//   let contentDocLinks = await AdminApi.getDocumentLinks(contentDocumentIds);
	//    let documentLinkMap = {};
	//     for(let contentDocLinkSO of contentDocLinks){
	//         let contentDocId = contentDocLinkSO.ContentDocumentId;
	//         let entityId = contentDocLinkSO.LinkedEntityId;
	//         let entityType = contentDocLinkSO.LinkedEntity.Type;
	//         let entityName = contentDocLinkSO.LinkedEntity.Name;
	//         if(!documentLinkMap[contentDocId]) documentLinkMap[contentDocId] = {};
	//         if(entityType === '00D') documentLinkMap[contentDocId].orgWideSharing = contentDocLinkSO.ShareType;
	//         if(entityType === 'User') documentLinkMap[contentDocId].users = contentDocLinkSO.ShareType;
	//     }
};

AdminService.saveGroupAccess = (groups, access, contentDocumentIds) => {
	return AdminApi.saveGroupAccess(groups, access, contentDocumentIds);
};

AdminService.updateAccess = (items) => {
	let contentDocumentLinks = [];

	for (let item of items) {
		contentDocumentLinks.push({
			//LinkedEntityId : item.LinkedEntityId,
			Id: item.Id,
			//Visibility : 'AllUsers',
			ShareType: item.ShareType,
		});
	}
	var updateLinkReq = {
		objectName: 'ContentDocumentLink',
		records: contentDocumentLinks,
	};
	return AdminApi.updateAccess(updateLinkReq);
};

AdminService.removeAccess = (linkIds, objectType) => {
	return AdminApi.removeAccess(linkIds, objectType);
};

AdminService.getLogoDocument = (documentName) => {
	return AdminApi.getLogoDocument(documentName);
};

AdminService.getOrgInfo = ({ callbackFn }) => {
	let configurationStr = CacheUtils.getItem('companyInfo', 'configuration');
	let configurationObj;
	if (configurationStr) {
		try {
			configurationObj = JSON.parse(configurationStr);
			callbackFn(configurationObj, true);
			CacheUtils.setItem(
				'companyInfo',
				'configuration',
				configurationObj ? JSON.stringify(configurationObj) : '',
			);
		} catch (e) {
			console.error('Error while getting company information', e);
		}
	}

	AdminApi.getOrgInfo().then((configurations) => {
		if (configurations && configurations.length > 0) {
			let configuration = configurations[0];
			callbackFn(configuration, false);
		} else {
			CacheUtils.setItem('companyInfo', 'configuration', '');
			CacheUtils.setItem('companyInfo', 'logoUrl', '');
		}
	});
};

export default AdminService;
